import React from 'react'
import navIcon from "../../../assets/images/mobile-dark-nav-icon.svg";
import logo2 from "../../../assets/images/Amaken-logo2.svg"
import { Link } from 'react-router-dom';
import user from "../../../assets/images/resource/user.png";
import storage from "../../../helpers/storage"
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../redux/AgencySlice";

function MobileHeader({ setMobileMenu }) {

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser())
    storage.clearToken()
    localStorage.removeItem('userType');
    localStorage.removeItem('AG_token');
    localStorage.removeItem('user-info');
    localStorage.removeItem('refresh-token');
    window.location.href = process.env.REACT_APP_AGENCY_WEB_URL;
  }

  // OPEN MOBILE SIDEBAR ON MENU CLICK
  const handleMenuClick = () => {
    setMobileMenu(true)
  }

  return (
    <div id="page" className="mobilie_header_nav stylehome1">
      <div className="mobile-menu">
        <div className="header innerpage-style">
          <div className="menu_and_widgets">
            <div className="mobile_menu_bar d-flex justify-content-between align-items-center">
              <button className="menubar btn" onClick={handleMenuClick}>
                <img src={navIcon} alt="nav-icon" />
              </button>
              <Link className="mobile_logo" to="#">
                <img src={logo2} alt="logo" />
                {/* <img src={logo2} alt="logo" /> */}
              </Link>
              <li className="user_setting">
                <div className="dropdown">
                  <a className="btn" href="#" data-bs-toggle="dropdown">
                    <img
                      className="bdrs24"
                      src={user}
                      alt="user"
                    />
                  </a>
                  <div className="dropdown-menu">
                    <div className="user_setting_content">
                      <p className="fz12 fw400 ff-heading ">
                        Welcome: <span className="fz16 fw600">Tariq</span>
                      </p>
                      <p className="fz14 fw400 ff-heading text-gray mb-1 bb1">
                        MANAGE ACCOUNT
                      </p>
                      <a
                        className="dropdown-item lh0 mb5"
                        href="#"
                      >
                        <i className="flaticon-protection mr10" />
                        My Favorites
                      </a>
                      <a
                        className="dropdown-item lh0 mb5"
                        href="profile"
                      >
                        <i className="flaticon-user mr10" />
                        My Profile (Agent)
                      </a>
                      <a
                        className="dropdown-item lh0 mb5"
                        href="profileagency"
                      >
                        <i className="flaticon-user mr10" />
                        My Profile (Agency)
                      </a>
                      <Link
                        className="dropdown-item lh0 mb5"
                        onClick={(e) => {
                          e.preventDefault();
                          handleLogout();
                        }}
                      >
                        <i className="flaticon-logout mr10"></i>Logout
                      </Link>

                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileHeader
