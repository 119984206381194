import { Autocomplete, TextField } from '@mui/material'
import React from 'react'

function SelectWithSearch({ label, options, value, onChange, minWidth, name, variant,errorsField, touched, disabled, isHtmlLabel }) {

    return (
        <Autocomplete
            options={options}
            disablePortal
            value={value}
            onChange={onChange}
            label={label}
            fullWidth
            name={name}
            error={errorsField && touched}
            helpertext={errorsField && touched ? errorsField : ""}
            disabled={disabled ? true : false}
         
            
            renderInput={(params) => <TextField {...params} autoComplete={'false'} label={label} variant={variant ?? "standard"} />}
        />
    )
}

export default SelectWithSearch
