import Cookies from "universal-cookie";
import React, { useState, useEffect } from 'react'
import InputText from "../../../components/common/InputText"
import { LoginSchema } from '../../../validations/ValidationSchema';
import { getUserDataApi, signInAPI } from '../../../services/userService';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from "formik"
import { toast } from "react-toastify";
import storage from "../../../helpers/storage"
import logoImg2 from "assets/images/Amaken-logo2.svg"
import forgotBg from "assets/images/icon/login-page-icon.svg"
import { setUser } from "../../../redux/AgencySlice";
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { nameBasedRoutes } from 'router/public';
import LoaderMu from "components/common/LoaderMu";
import { nameBasedProtectedRoutes } from "router/protected";
import { Checkbox } from "antd";

export const Login = () => {

  const { t } = useTranslation()
  const { i18n } = useTranslation()

  const cookies = new Cookies();

  const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'


  const navigate = useNavigate()
  const dispatch = useDispatch()

  // data
  const [data, setData] = useState({
    email: "",
    password: "",
    captcha: "",
  });

  // REMEMBER ME
  const rememberMeEmail = cookies.get("agency_email");
  const rememberMePassword = cookies.get("agency_password");
  const alreadyChecked = cookies.get("agency_checked");
  const [rememberMe, setRememberMe] = useState(!!alreadyChecked);
  const [loading, setLoading] = useState(false)
  const [showEye, setShowEye] = useState(false)



  // For remember details
  useEffect(() => {
    if (rememberMeEmail && rememberMePassword) {
      setData((data) => ({ ...data, email: rememberMeEmail }));
      setData((data) => ({ ...data, password: rememberMePassword }));
      setTimeout(() => { }, 100);
      setLoading(true)
    }
    setLoading(false)
  }, []);

  // HANDLE SUBMIT
  const handleSubmit = async (value, actions) => {
    submitForm(value)
  }

  // SUBMIT LOGIN FORM
  const submitForm = async (value) => {
    setLoading(true)
    if (rememberMe === false) {
      cookies.remove("agency_email");
      cookies.remove("agency_password");
      cookies.remove("agency_checked");
    }
    const password = value.password
    const email = value.email

    // SIGN IN API
    signInAPI({
      username: email.toString(),
      email: email.toString(),
      password: password.toString(),
      panel: 'AGENCY',
      deviceType: 'BROWSER',

    })
      .then(response => {
        setLoading(false)
        toast.success('Login successful')

        if (rememberMe === true) {
          cookies.set("agency_email", email);
          cookies.set("agency_password", password);
          cookies.set("agency_checked", rememberMe);
        }
        // GET USER DATA
        getUserDataApi()
          .then((user) => {
            setLoading(false);
            // SET DATA IN REDUX STATE
            dispatch(
              setUser({ userData: user, token: response?.id_token })
            );
            cookies.remove("langKey")
            cookies.set("langKey", user?.langKey)
            // FOR CHANGE LANGUAGE BASIS ON PREFERRED LANGUAGE SELECTED
            i18n.changeLanguage(user?.langKey)
            // NAVIGATE TO DASHBOARD
            navigate(nameBasedProtectedRoutes?.dashboard?.path ?? "/dashboard");
          })
          .catch((error) => {
            setLoading(false);
            toast.error(
              error?.response?.data?.error_description
                ? error?.response?.data?.error_description
                : error?.response?.data?.message ?? error?.message
            );
          });
        storage.setToken(response.id_token)
        storage.setRefreshToken(response.id_token)
        storage.setData("user-info", response)
        storage.setData("userType", 'AGENCY')
        storage.setData("refresh-token", response.id_token)
      })

      .catch(error => {
        //window.location.reload();
        setLoading(false)
        toast.error(error.response.data.error_description ? error.response.data.error_description : error.response.data?.message)

      })
  }

  // TOGGLE EYE BUTTON FOR PASSWORD
  const showToggle = (event) => {
    setShowEye(event);
  };


  return (
    <div className="d-flex flex-column pageContainer p-3 bgc-f7 h-100 overflow-auto" >
      <LoaderMu loading={loading} />
      <div className="wrapper ovh"></div>
      <div className="body_content">
        {/* Our Compare Area */}
        <section className="our-compare pt60 pb60">
          <img className="login-bg-icon wow fadeInLeft" src={forgotBg} alt="Forgot bg" />
          <div className="container">
            <div className="row wow fadeInRight" data-wow-delay="300ms">
              <div dir={currentLanguage === "ar" ? 'rtl' : ''} className="col-lg-6">
                <div className="log-reg-form signup-modal form-style1 bgc-white p50 p30-sm default-box-shadow2 bdrs12">
                  <div className="header-logo text-center mb40">
                    {/* USING A TAG BECAUSE OF EXTERNAL LINK */}
                    <a href={process.env.REACT_APP_WEBSITE_WEB_URL}>
                      <img className="mb25" src={logoImg2} alt="reset logo" />
                    </a>
                    <h2>{t("AGENCIES/AGENTS SIGN IN")}</h2>
                    <p className="text">
                      {t('SIGN IN TO YOUR ACCOUNT WITH YOUR CREDENTIALS.')}
                    </p>
                  </div>
                  <Formik
                    validationSchema={LoginSchema}
                    initialValues={
                      {
                        email: data.email ? data.email : "",
                        password: data.password ? data.password : "",
                      }
                    }
                    onSubmit={(values, actions) => {
                      actions.setSubmitting(false)
                      handleSubmit(values, actions)
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      setFieldValue,
                      touched,
                      isValid,
                      errors
                    }) => (
                      <Form>
                        {/* ENTER EMAIL */}
                        <div className="mb5">
                          <InputText
                            autoFocus={true}
                            controlId="email"
                            label={t("ENTER EMAIL")}
                            value={values.email}
                            name="email"
                            type="email"
                            maxLength="300"
                            errorsField={errors.email}
                            touched={touched.email}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            className="form-control"
                          />
                        </div>
                        {/* ENTER PASSWORD */}
                        <div className="mb5">
                          <div
                            className={`position-relative ${showEye ? "form-right-icon" : ""
                              }`}
                          >
                            <InputText
                              controlId="password"
                              label={t("ENTER PASSWORD")}
                              value={values.password}
                              name="password"
                              type={showEye ? "text" : "password"}
                              maxLength="300"
                              errorsField={errors.password}
                              touched={touched.password}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className="form-control"
                            />
                            <div onClick={() => setShowEye(!showEye)}>
                              {showEye ? (
                                <FaEye
                                  size="22"
                                  className="eyePosition"
                                  onClick={() => showToggle(true)}
                                />
                              ) : (
                                <FaEyeSlash
                                  size="22"
                                  className="eyePosition"
                                  onClick={() => showToggle(true)}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        {/* REMEMBER ME */}
                        <div className="checkbox-style1 d-block d-sm-flex align-items-center justify-content-between mb25 mt25">
                          <Checkbox name="remember_me" checked={rememberMe ?? false} onChange={(e) => setRememberMe(e.target.checked)}>
                            {t('REMEMBER ME')}
                          </Checkbox>
                          {/* <label className="custom_checkbox fz14 ff-heading">
                            {t('REMEMBER ME')}
                            <input
                              type="checkbox"
                              label={t('REMEMBER ME')}
                              checked={rememberMe}
                              className='mb-0'
                              onChange={(e) => setRememberMe(e.target.checked)}
                            />
                            <span className="checkmark mt-1" />
                          </label> */}
                          <Link className="fz14 ff-heading" to="/forgot-password">
                            {t('FORGET PASSWORD')}
                          </Link>
                        </div>
                        {/* HANDLE SUBMIT */}
                        <div className="d-grid mb20">
                          <button
                            onClick={handleSubmit}
                            className="ud-btn btn-thm"
                            type="submit"
                            disabled={loading ?? false}
                          >
                            {t("LOGIN")}
                            <i className="fal fa-arrow-right-long" />
                          </button>
                        </div>
                        <p className="dark-color text-center mb0 mt10">
                          {t('NOT SIGNED UP')} {" "}
                          <Link className="dark-color fw600"
                            to={nameBasedRoutes?.register?.path}>
                            {t('CREATE AN ACCOUNT.')}
                          </Link></p>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}