import { Link, useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import Pagination from '@mui/material/Pagination';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ActionTemplate, AgentNameTemplate, startedAtTemplate, statusTemplate, typeTemplate } from "./DataTableTemplates";
import { getAgentListingData } from "services/agentService";
import { toast } from "react-toastify";
import LoaderMu from "components/common/LoaderMu";
import { useTranslation } from "react-i18next";
import { nameBasedProtectedRoutes } from "router/protected";


export const Dashboardagents = () => {
  const { t } = useTranslation();

  
  const navigate = useNavigate();

  // GET DATA FROM URL SEARCH PARAMS
  const location = useLocation();
  const params = new URLSearchParams(location?.search)
  const search = params.get("search") ?? ''
  const [currentPage, setCurrentPage] = useState(params.get("page") ?? 1)

  const [searchQuery, setSearchQuery] = useState(search ?? "")
  // const [sortValue, setSortValue] = useState("")
  const [loading, setLoading] = useState(false)

  // PAGINATION STATES
  const [pageSize, setPageSize] = useState(5)
  const [totalRowCount, setTotalRowCount] = useState(0)
  const pageOffset = (parseInt(currentPage) - 1) * pageSize + 1

  //AGENT LISTING DATA STATE
  const [agentListingData, setAgentListingData] = useState([])

  // HANDLE PAGE CHAGE
  const handlePageChange = (event, value) => {
    setCurrentPage(value)
    navigate(`${nameBasedProtectedRoutes?.agentListing?.path}?page=${value}${search !== '' ? '&search=' + search : ''}`)
  }

  // FETCH AGENT LISTING DATA
  const fetchListingData = () => {
    setLoading(true)
    let params = {
      size: pageSize
    }
    if (searchQuery) {
      params.search = searchQuery ? searchQuery.trim() : ''
    }
    if (currentPage) {
      params.page = currentPage - 1
    }
    getAgentListingData(params).then(response => {
      setAgentListingData(response?.data ?? [])
      setTotalRowCount(response?.headers["x-total-count"])
      setLoading(false)
      if (response?.headers["x-total-count"] < pageSize) {
        navigate(`${nameBasedProtectedRoutes?.agentListing?.path}?page=${1}${search !== '' ? '&search=' + search : ''}`)
        setCurrentPage(1)
      }
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }

  // NAVIGATE CHECK PACKAGE VALIDATION
  const navigateToAddAgent = () => {
    navigate(nameBasedProtectedRoutes?.addAgent?.path)
  }
  // THIS IS FOR SEARCHING
  const handleValueChange = (value) => {
    setSearchQuery(value)
    if (value !== "") {
      navigate(`${nameBasedProtectedRoutes?.agentListing?.path}?page=${currentPage}&search=${encodeURIComponent(value)}`)
    } else {
      navigate(`${nameBasedProtectedRoutes?.agentListing?.path}?page=${currentPage}`)
    }
  };

  // FUNCTION CALL ON COMPONENT RENDER  AND ON SEARCH OR CURRENT PAGE CHANGE
  useEffect(() => {
    fetchListingData()
  }, [searchQuery, currentPage])

  return (
    <div className="dashboard__content property-page bgc-f7">
      <LoaderMu loading={loading} />
      <div className="row align-items-center pb20">
        <div className="col-xxl-3">
          <div className="dashboard_title_area">
            <h2>{t('AGENTS')}</h2>
          </div>
        </div>
        <div className="col-xxl-9">
          {/* SEARCH INPUT */}
          <div className="dashboard_search_meta d-md-flex align-items-center justify-content-xxl-end">
            <div className="item1 mb15-sm">
              <div className="search_area me-2">
                <InputText
                  value={searchQuery}
                  onChange={(e) => {
                    handleValueChange(e?.target?.value);
                  }}
                  placeholder={t("SEARCH")}
                  className="form-control bdrs12"
                />
                <label>
                  <span className="flaticon-search" />
                </label>
              </div>
            </div>
            {/* ADD AGENT IF USER PACKAGE ALLOWS */}
            <div onClick={navigateToAddAgent} className="ud-btn btn-thm cursorPointer">
              {t("ADD AGENT")}
              <i className="fal fa-arrow-right-long" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <div className="packages_table table-responsive">

              {/* RENDER PRIME REACT DATA TABLE */}
              <DataTable
                value={agentListingData ?? []}
                tableClassName="table-style3 table at-savesearch"
                cellClassName="vam"
                
              >
                {/* AGENT NAME */}
                <Column
                  field="firstName"
                  header={t("AGENT NAME")}
                  sortable
                  body={AgentNameTemplate}
                ></Column>
                {/* DATE STARTED */}
                <Column field="createdAt" header={t("DATE STARTED")} sortable body={startedAtTemplate}></Column>
                {/* STATUS */}
                <Column
                  field="status"
                  header={t("STATUS")}
                  sortable
                  body={statusTemplate}
                ></Column>
                {/* AGENT TYPE */}
                <Column field="partialAgent" header={t("TYPE")} sortable body={typeTemplate}></Column>
                {/* ACTION */}
                <Column
                  field="action"
                  header={t("ACTION")}
                  body={(row) => <ActionTemplate row={row} getData={fetchListingData} />}
                ></Column>
              </DataTable>
              {/* PAGINATION */}
              <div className="mbp_pagination text-center mt30 dataTable_pagination">
                <Pagination count={Math.ceil(totalRowCount / pageSize)} siblingCount={1} page={parseInt(currentPage)} className="d-inline-block" size="large" onChange={(event, value) => handlePageChange(event, value)} />
                {
                  totalRowCount < pageSize ?
                    <p className="mt10 pagination_page_count text-center">
                      {pageOffset} – {totalRowCount} {t("OF")} {totalRowCount} {t("AGENTS AVAILABLE")}
                    </p> : <p className="mt10 pagination_page_count text-center">
                      {pageOffset} – {currentPage * pageSize} {t("OF")} {totalRowCount}+ {t("AGENTS AVAILABLE")}
                    </p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
