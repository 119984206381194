import { ErrorMessage } from 'formik'
import React from 'react'
import { FormLabel } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function AgencyFileUpload({ id, name, label, handleChange, handleBlur, type, className,error, multiple, fileName,labelText, fileLabelClass,accept, labelMargin, ...rest }) {

  const {t} = useTranslation()

  return (
    <div className="mb20">
    {
      label ? 
      <FormLabel className={`heading-color ff-heading fw600  ${labelMargin ? labelMargin : 'mb10'}`}>
        {label}
      </FormLabel> :""
    }
     
      {/* <input
      className="form-control p15 pl30 ud-btn2"
      type="file"
      id="myFile"
      name="filename"
    />
    <span className="form-label fz12 mb0 fw600 mx10 text-danger" /> */}
      {/* <input
        id={id}
        name={name}
        multiple={multiple}
        onChange={handleChange}
        onBlur={handleBlur}
        type="file"
        accept={accept}
        className={className}
      /> */}

      <label htmlFor={id} className={`mt10 align-items-center d-flex cursor-pointer form-control gap-2 mt-0 position-relative w-100 ${fileLabelClass ?? ''}`}>
        <span className="btn bg-light-secondary fs-14 fw-bold text-body text-nowrap">{labelText ?? t('CHOOSE FILE')}</span>
        <span className="fw-bold fw-bold text-truncate"  title={!fileName?.name ? t("NO FILE CHOOSEN") :fileName?.name}>{!fileName?.name ? t("NO FILE CHOOSEN") :fileName?.name }</span>
        <input
          id={id}
          name={name}
          multiple={multiple}
          onChange={handleChange}
          onBlur={handleBlur}
          type="file"
          accept={accept}
          className={` mt-0 opacity-0 p15 pl30 position-absolute start-0 top-0 ud-btn2 zi-1 ${className}`}
        />
      </label>
      

      {error ? <p className='text-danger'>{error}</p> : ""}
      {/* <ErrorMessage name={name} component="div" className="text-danger" /> */}
     
      {/* {
        !rest.outsideForm ? <ErrorMessage name={name} component="div" className="text-danger" /> : ""
      } */}
    </div>
  )
}

export default AgencyFileUpload
