import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { agentWiseProperties, convertToCSV } from 'services/dashboardService';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import DownloadCSVButton from 'components/common/DownloadCSVButton';
import { PropertyInfoApi } from 'services/propertyService';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

// COMMON COMPONENT FOR AGENCY OR AGENT DASHBOARD


const colorArray = ['rgba(33, 150, 243, 0.6)', 'rgba(103, 58, 183,1)', 'rgba(79, 181, 181, 1)', 'rgba(79, 181, 181, 0.6)', 'rgba(233, 30, 99, 1)',]

const AgentWisePropertyTypes = ({ startDate, endDate }) => {

    const { t } = useTranslation()

    const { i18n } = useTranslation()

    const cookies = new Cookies()

    // GET CURRENT LANGUAGE FROM i18 OR COOKIES

    const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

    const [chartData, setChartData] = useState([])
    const [loading, setLoading] = useState(true)


    const [propertyInfo, setPropertyInfo] = useState({})
    // FETCH GOVERNORATE CHART DATA
    const fetchPropertyInfo = () => {
        setLoading(true)
        PropertyInfoApi()
            .then((response) => {
                setPropertyInfo(response?.propertyStatus)
            })
            .catch(error => {
                toast.error(
                    error?.response?.data?.error_description
                        ? error?.response?.data?.error_description
                        : error?.response?.data?.message ?? error?.message
                );
            }).finally(() => {
                setLoading(false)
            })

    }



    // FETCH GOVERNORATE CHART DATA
    const fetchPropertyChartData = () => {
        setLoading(true)
        const params = {
            startDate: startDate,
            endDate: endDate
        }

        agentWiseProperties(params)
            .then((response) => {
                setChartData(response)
            })
            .catch(error => {
                toast.error(error?.response?.data?.message ?? error?.message)
            }).finally(() => {
                setLoading(false)
            })

    }

    // FETCH GOVERNORATE CHART DATA
    useEffect(() => {
        fetchPropertyChartData()
        fetchPropertyInfo()
    }, [startDate, endDate])

    // Aggregate property status counts by name
    const aggregatedData = chartData?.reduce((agg, item) => {
        const name = `${item?.firstName} ${item?.lastName}`;
        if (!agg[name]) {
            agg[name] = {};
        }
        Object.keys(item?.propertyStatusesCount).forEach(status => {
            agg[name][status] = item?.propertyStatusesCount[status];
        });
        return agg;
    }, {});
    // Extract labels and data for the chart
    const labels = Object.keys(aggregatedData);
    const statusValues = Array.from(new Set(chartData.flatMap(item => Object.keys(item.propertyStatusesCount))));
    const datasets = statusValues.map((status, index) => ({
        label: propertyInfo[status],
        data: labels.map(label => aggregatedData[label][status] || 0),
        backgroundColor: colorArray[index % colorArray.length],
        borderColor: colorArray[index % colorArray.length].replace('0.6', '1'), // Use same color but with full opacity for border

    }));

    // Prepare chart data
    const chart_Data = {
        labels: labels,
        datasets: datasets
    };

    const options = {
        // indexAxis : 'y',
        plugins: {
            title: {
                display: true,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false
                }
            },
        },
    };
    // CONVERT TO CSV
    function convertJsonToCsv(jsonData) {
        // Extract unique column headers
        let headers = Array.from(new Set(Object.values(jsonData).flatMap(obj => Object.keys(obj))));
        headers = headers?.map((status) => propertyInfo[status])
        // Create CSV string with headers
        let csv = t("AGENT NAME") + ',' + headers.join(',') + '\n';

        // Iterate over JSON data and populate CSV rows
        for (const name in jsonData) {
            const row = [name];
            const data = jsonData[name];

            // Populate row with data
            for (const header of headers.slice(1)) {
                row.push(data[header] || 0);
            }

            // Add row to CSV string
            csv += row.join(',') + '\n';
        }

        return csv;
    }
    // GENERATE CSV DATA
    const csvData = convertJsonToCsv(aggregatedData);

    return (
        <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <div className="navtab-style1">
                <div className="d-sm-flex align-items-center justify-content-between">
                    <h4 className="title fz17 mb0">{t("AGENT WISE PROPERTY STATUS")}</h4>
                    {/* EXPORT CSV BUTTON */}
                    {
                        csvData && csvData?.length > 0 ? <div>
                            <DownloadCSVButton csvData={csvData} filename={'agentPropertyStatus.csv'} />
                        </div> : ""
                    }
                </div>
                <div className="tab-content" >
                    <Bar options={options} data={chart_Data} />
                </div>
            </div>
        </div>
    )
}

export default AgentWisePropertyTypes