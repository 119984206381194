import { Link, useNavigate } from "react-router-dom";
import "./header.scss";
import logoImg2 from "../../../assets/images/Amaken-logo2.svg";
import logoImgAr from "../../../assets/images/Amaken-logo2-ar.svg"
import navIcon from "../../../assets/images/dark-nav-icon.svg";
import storage from "../../../helpers/storage"
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../redux/AgencySlice";
import { useTranslation } from "react-i18next";
import userImg from "../../../assets/images/resource/defaultuser.png";
import Cookies from "universal-cookie";
import { nameBasedRoutes } from "router/public";

/**
 * Header Sidebar & Toggle Manager
 * @date 4/12/2023 - 12:56:12 PM
 *
 * @param {{ isActiveSidebar: any; toggleSidebarButton: any; }} {
  isActiveSidebar, toggleSidebarButton
}
 * @returns {*}
 */

export const Header = ({ toggleSidebarButton }) => {

  const { t } = useTranslation()

  const userData = useSelector((state) => state?.user?.userData)

  const cookies = new Cookies()

  const navigate = useNavigate()

  const {i18n} = useTranslation()

  const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en';

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logoutUser())
    storage.clearToken()
    localStorage.removeItem('userType');
    localStorage.removeItem('AG_token');
    localStorage.removeItem('user-info');
    localStorage.removeItem('refresh-token');
    navigate(nameBasedRoutes?.login?.path)
    // window.location.href = process.env.REACT_APP_AGENCY_WEB_URL;
  }

  return (
    <header className="header-nav nav-innerpage-style menu-home4 dashboard_header main-menu">
      {/* Ace Responsive Menu */}
      <nav className="posr">
        <div className="container-fluid pr30 pr15-xs pl30 posr menu_bdrt1">
          <div className="row align-items-center justify-content-between">
            <div className="col-6 col-lg-auto">
              <div className="text-center text-lg-start d-flex align-items-center">
                <div className="dashboard_header_logo position-relative me-2 me-xl-5">
                 {/* <img src={logoImgAr} alt="Header Logo" />  */}
                 {/* <img src={logoImg2} alt="Header Logo" /> */}
                  <Link to={process.env.REACT_APP_AGENCY_WEB_URL} className="logo">
                    {
                      currentLanguage && currentLanguage === 'ar' ?
                      <img src={logoImgAr} alt="Header Logo" /> 
                      :
                      <img src={logoImg2} alt="Header Logo" /> 
                    }
                  
                  </Link>
                </div>
                <div className="fz20 ms-2 ms-sm-5">
                  <button
                    className="btn dashboard_sidebar_toggle_icon text-thm1 vam"
                    onClick={() => {
                      toggleSidebarButton();
                    }}
                  >
                    <img src={navIcon} alt="nav-icon" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-6 col-lg-auto">
              <div className="text-center text-lg-end header_right_widgets">
                <ul className="mb0 d-flex justify-content-center justify-content-sm-end p-0">
                  <li className=" user_setting">
                    <div className="dropdown">
                      <Link className="btn" to="#" data-bs-toggle="dropdown">
                        {
                          userData?.imageUrl ? <img className="bdrs24 userimg-sm" src={userData?.imageUrl} alt="Logo" /> : <img className="bdrs24 userimg-sm" src={userImg} alt="Logo" />
                        }
                      </Link>
                      <div className={`dropdown-menu ${currentLanguage === 'ar' ? 'text-right':''}`}>
                        <div className="user_setting_content">
                          <p className="fz12 fw400 ff-heading ">
                            {t("WELCOME")}: <span className="fz16 fw600">{userData?.firstName}</span>
                          </p>
                          <p className="fz14 fw400 ff-heading text-gray mb-1 bb1">
                            {t("MANAGE ACCOUNT")}
                          </p>
                          <Link
                            className="dropdown-item lh0 mb5"
                            to="/profile"
                          >
                            <i className="flaticon-user mr10" />
                            {t("MY PROFILE (AGENT)")}
                          </Link>

                          {
                            userData?.authorities?.includes("ROLE_AGENCY_ADMIN") ?
                              <Link
                                className="dropdown-item lh0 mb5"
                                to="/profileagency"
                              >
                                {/* <i className="flaticon-user mr10" /> */}
                                <i className="fa-regular fa-building mr10"></i>
                                {t("AGENCY PROFILE")}
                              </Link> : ""
                          }
                          <Link
                            className="dropdown-item lh0 mb5"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLogout();
                            }}
                          >
                            <i className="flaticon-logout mr10"></i>{t("LOGOUT")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header >
  );
};
