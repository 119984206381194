import { ForgotPassword } from "../pages/Auth/ForgetPassword";
import { ResetPassword } from "../pages/Auth/ResetPassword";
import { EmailVerify } from "../pages/Auth/EmailVerify";
import { Login } from "../pages/Auth/Login";
import { Register } from "../pages/Register";
import { Error404 } from "../pages/Error404";
import { Uielement } from "../pages/Uielement";
import { AccountActivation } from "pages/Auth/AccountActivate";
import { BulkUpload } from "pages/BulkUpload";

import AuthenticatePaymentResponse from "pages/Package/AuthenticatePaymentResponse";


// const basePath = process.env.REACT_APP_BASE_URL;

/**
 * Public Routes Available in App
 * @type {{}}
 */
export const publicRoutes = [
  {
    path: `/`,
    element: <Login />,
  },

  {
    path: `email-verify`,
    element: <EmailVerify />,
  },
  {
    path: `activate-account`,
    element: <AccountActivation />
  },
  {
    path: `/page-ui-element`,
    element: <Uielement />,
  },
  {
    path: `/register`,
    element: <Register />,
  },
  {
    path: `*`,
    element: <Error404 />,
  },
  {
    path: `/login`,
    element: <Login />,
  },
  {
    path: `/forgot-password`,
    element: <ForgotPassword />,
  },
  {
    path: `/reset-password`,
    element: <ResetPassword />,
  },
  {
    path: `/bulk-upload`,
    element: <BulkUpload />
  },
  {path : "/payment/authenticate-response",element :<AuthenticatePaymentResponse/> },


];

export const nameBasedRoutes = {
  home: {
    name: "Home",
    path: `/`,
  },
  login: {
    name: "Login",
    path: `/login`,
  },
  register: {
    name: "Register",
    path: `/register`,
  },
  forgotPassword: {
    name: "Forgot Passowrd",
    path: `/forgot-password`,
  },
  resetPassword: {
    name: "Reset Passowrd",
    path: `/reset-password`,
  },
  emailVerify: {
    name: "Email Verify",
    path: `/email-verify`,
  },
  activateAccount: {
    name: "Activate Account",
    path: `/activate-account`
  }

}