import LoaderMu from "components/common/LoaderMu";
import { Description_media } from "components/property/editproperty/Description_media";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GetPropertyData } from "services/propertyService";


function EditProperty() {

  const [loading, setLoading] = useState(false)

  const [propertyData, setPropertyData] = useState({})
  
  const {t} = useTranslation()

  const { id } = useParams()
  //FETCH PROPERTY FORM DATA
  const getPropertyFormData = () => {
    setLoading(true)
    GetPropertyData(id).then(response => {
      setPropertyData(response)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }

  useEffect(() => {
    getPropertyFormData()
    window.scrollTo({
      top : 0,
      left : 0,
      behavior : "smooth"
    })
  }, [id])



  return (
    <div className="dashboard__content property-page bgc-f7">
      <LoaderMu loading={loading}/>
      <div className="row align-items-center pb20">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>{t("EDIT PROPERTY")}</h2>
          </div>
        </div>
      </div>
      <Description_media
        property_id={id}
        propertyData={propertyData}
        getPropertyData={getPropertyFormData} />
    </div>
  );
}

export default EditProperty
