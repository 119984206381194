import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { convertToCSV, governorateChartAgency, governorateChartAgent } from 'services/dashboardService';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import DownloadCSVButton from 'components/common/DownloadCSVButton';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

// COMMON COMPONENT FOR AGENCY OR AGENT DASHBOARD

const GovernorateChart = ({ startDate, endDate, currentRole }) => {

    const {t} = useTranslation()

    const { i18n } = useTranslation()

    const cookies = new Cookies()

    // GET CURRENT LANGUAGE FROM i18 OR COOKIES

    const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

    const [chartData, setChartData] = useState([])
    const [loading, setLoading] = useState(true)

    // FETCH GOVERNORATE CHART DATA
    const fetchGovernorateChartData = () => {
        setLoading(true)
        const params = {
            startDate: startDate,
            endDate: endDate
        }
        // IF CURRENT ROLE IS AGENCY THEN IT IS FOR AGENCY DASHBOARD ELSE FOR AGENT DASHBOARD
        if (currentRole === 'AGENCY') {
            governorateChartAgency(params)
                .then((response) => {
                    const responseData = response?.map((element) => {
                        const { governorateId , title ,capital, ...rest } = element; // Destructure the keys and capture the rest of the object
                        return {city: capital, ...rest}; // Spread the rest of the object and add 'city' key
                    });
                    setChartData(responseData)
                })
                .catch(error => {
                    toast.error(
                        error?.response?.data?.error_description
                          ? error?.response?.data?.error_description
                          : error?.response?.data?.message ?? error?.message
                      );
                }).finally(() => {
                    setLoading(false)
                })
        } else {
            governorateChartAgent(params)
                .then((response) => {
                    const responseData = response?.map((element) => {
                        const { governorateId , capital, ...rest } = element; // Destructure the 'capital' key and capture the rest of the object
                        return {city: capital, ...rest}; // Spread the rest of the object and add 'city' key
                    });
                    setChartData(responseData)
                })
                .catch(error => {
                    toast.error(
                        error?.response?.data?.error_description
                          ? error?.response?.data?.error_description
                          : error?.response?.data?.message ?? error?.message
                      );
                }).finally(() => {
                    setLoading(false)
                })
        }
    }

    // FETCH GOVERNORATE CHART DATA
    useEffect(() => {
        fetchGovernorateChartData()
    }, [startDate, endDate])


    // CHART OPTIONS
    const options = {
        plugins: {
            title: {
                display: true,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false
                }
            },
        },
    };
    // CHART LABELS
    // Mapping month numbers to month names

    const chart_labels = chartData.map(item => item?.city[currentLanguage]);
    // CHART DATA
    const data = {
        labels: chart_labels,
        datasets: [
            {
                label:  t("TOTAL PROPERTIES"),
                data: chartData.map(item => item?.totalProperties),
                backgroundColor: 'rgba(79, 181, 181, 0.6)',
            },
            {
                label:  t("ACTIVE PROPERTIES"),
                data: chartData.map(item => item?.activeProperties),
                backgroundColor: 'rgba(79, 181, 181, 1)',
            }
        ]
    };
    
    // GENERATE CSV DATA
    const csvData = convertToCSV(chartData,currentLanguage);

    return (
        <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <div className="navtab-style1">
                <div className="d-sm-flex align-items-center justify-content-between">
                    <h4 className="title fz17 mb0">{t("CITY")}</h4>
                    {/* EXPORT CSV BUTTON */}
                    {
                        csvData && csvData?.length > 0 ? <div>
                            <DownloadCSVButton csvData={csvData} filename={'location.csv'} />
                        </div> : ""
                    }
                </div>
                <div className="tab-content" >
                    <Bar options={options} data={data} />
                </div>
            </div>
        </div>
    )
}

export default GovernorateChart