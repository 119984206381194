import { Button } from "antd";
import AgencyInputText from "components/common/AgencyInputText";
import LoaderMu from "components/common/LoaderMu";
import { Form, Formik, getIn } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { nameBasedProtectedRoutes } from "router/protected";
import { cardPayment, subscribePackageApi } from "services/packageService";

import { userCardDetails } from "validations/ValidationSchema";

function PaymentCard() {

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isDisplayIframe , setDisplayIframe] = useState(false)
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [responseHtml, setResponseHtml] = useState('');

  const currentPlan = useSelector(state=> state?.userPackage)

  const contentRef = useRef();
  const location = useLocation();

  // GET PLAN ID FROM URL PARAMS
  const { planId } = useParams();

  useEffect(() => {

    if (responseHtml && contentRef.current) {
      // Create a temporary div to parse the HTML
      const div = document.createElement('div');
      div.innerHTML = responseHtml;
  
      // Remove all <script> elements from the parsed HTML
      const scripts = div.querySelectorAll('script');
      scripts.forEach(script => script.remove());
  
      // Set the sanitized HTML content to the target element
      contentRef.current.innerHTML = div.innerHTML;

      Array.from(scripts).forEach(script => {
        const newScript = document.createElement('script');
        newScript.innerHTML = script.innerHTML;
        document.body.appendChild(newScript);
        document.body.removeChild(newScript); // Clean up
      });
  }

    // if (responseHtml && contentRef.current) {
    //   // Extract script content
    //   const div = document.createElement('div');
    //   div.innerHTML = responseHtml;
    //   const scripts = div.getElementsByTagName('script');
      

    //   // Set the HTML content without scripts
    //   contentRef.current.innerHTML = div.innerHTML.replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '');

    //   // Execute each script
      // Array.from(scripts).forEach(script => {
      //   const newScript = document.createElement('script');
      //   newScript.innerHTML = script.innerHTML;
      //   document.body.appendChild(newScript);
      //   document.body.removeChild(newScript); // Clean up
      // });
    // }
  }, [responseHtml]);

  useEffect(() => {
    if (!location.state) {
      navigate(nameBasedProtectedRoutes?.plans?.path); // Redirect to the subscription page
    }
  }, [location.state, navigate]);

// USER CAN NOT NAVIGATE HERE IF ANY SUBSCRIPTIONS ACTIVE
  useEffect(()=>{
    if(currentPlan?.status === "ACTIVE"){
      navigate(nameBasedProtectedRoutes?.package?.path)
    }
  },[currentPlan])


  const packagePrice = location?.state?.price ? location?.state?.price : 0;
  const noOf3dViewUpload = location?.state?.noOf3dViewUpload ?? 0;
  const noOfFeaturedProperty = location?.state?.noOfFeaturedProperty ?? 0;
  const planValidityType = location?.state?.validityType ?? '';

  // HANDLE PAYMENT FORM SUBMIT
  const handleSubmit = (values, actions) => {
    setLoading(true);

    const subscriptionData = {
      planId: planId,
      validityType: planValidityType,
      customNoOf3dViewUpload: noOf3dViewUpload,
      customNoOfActiveFeaturedProperty: noOfFeaturedProperty,
      cardName: values.cardName.toString(),
      cardNumber: values.cardNumber.toString(),
      expiryYear: values.expiryYear.toString(),
      expiryMonth: values.expiryMonth.toString(),
    };

    subscribePackageApi(subscriptionData)
      .then((response) => {
        setLoading(false);
        if (response.status === 'SUCCESS') {
          const modifiedHtml = response.html.replace('height: 100vh', 'height: 50vh');
          setResponseHtml(modifiedHtml);
          setDisplayIframe(true)
          // setShowModal(true); // Open the modal
          actions.resetForm()
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });
  };

  if (!location.state) {
    return null; // Prevent rendering if redirect is happening
  }

  return (
    <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative  bgc-f7 box-height">
      <LoaderMu loading={loading} />
      <div className="dashboard_title_area">
        <h2 className="">{t("PAYMENT")} </h2>
      </div>

      {
        !isDisplayIframe ?   <Formik
        initialValues={{
          cardName: "",
          cardNumber: "",
          expiryMonth: "",
          expiryYear: "",
        }}
        validationSchema={userCardDetails}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => (
          <Form autoComplete="off" className="form-style1 card-style">
            <div className="row">
              <div className="col-sm-12">
                {/* CARD HOLDER NAME */}
                <AgencyInputText
                  type="text"
                  label={t('CARD HOLDER')}
                  id="cardName"
                  placeholder={t('CARD HOLDER')}
                  name="cardName"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.cardName}
                  className={`form-control ${touched?.cardName && errors?.cardName ? "error" : ""
                    }`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                {/* CARD NUMBER */}
                <AgencyInputText
                  type="text"
                  label={t('CARD')}
                  id="cardNumber"
                  placeholder={t('PLACEHOLDER CARD')}
                  name="cardNumber"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  maxLength="16"
                  value={values?.cardNumber}
                  className={`form-control ${touched?.cardNumber && errors?.cardNumber ? "error" : ""
                    }`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                {/* EXPIRY MONTH */}
                <AgencyInputText
                  type="text"
                  id="expiryMonth"
                  name="expiryMonth"
                  label={t('MONTH')}
                  placeholder={t('PLACEHOLDER MONTH')}
                  // controlId="expiryMonth"
                  value={values?.expiryMonth}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  autoComplete="off"
                  maxLength="2"
                  className={`form-control ${touched?.expiryMonth && errors?.expiryMonth ? "error" : ""
                    }`}
                />
              </div>
              <div className="col-sm-6">
                {/* EXPIRY YEAR */}
                <AgencyInputText
                  type="text"
                  id="expiryYear"
                  name="expiryYear"
                  label={t('YEAR')}
                  placeholder={t('PLACEHOLDER YEAR')}
                  // controlId={"expiryYear"}
                  value={values?.expiryYear}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  autoComplete={"off"}
                  maxLength="2"
                  className={`form-control ${touched?.expiryYear && errors?.expiryYear ? "error" : ""
                    }`}
                />
              </div>
            </div>
            <div className="row">
              {/* AGREE */}
              <div className="col-md-12 mb20">
                <label className="custom_checkbox fz14 ff-heading">
                  {t('ACCEPT SUBSCRIPTION RECURRING')}
                  <input onChange={(e) => { setFieldValue('i_agree', e.target.checked) }} type="checkbox" value={values?.i_agree} />

                  <span className="checkmark" />
                </label>
                <span className='text-danger answer-type-error fs-11'>{getIn(errors, `i_agree`)}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="text-start">
                  <button type="submit" onClick={handleSubmit} className="ud-btn btn-dark w-100">
                    {t('PAY')} {packagePrice} {t('CURRENCY SYMBOL')}
                    <i className="fal fa-arrow-right-long" />
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik> : ""
      }

      <div ref={contentRef}></div>

      {/* <Modal show={showModal} fullscreen={false}  onHide={() => setShowModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{t("Payment Confirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div ref={contentRef}></div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            {t("Close")}
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}

export default PaymentCard;
