import {
    ALL_PLANS_API,
    GET_SESSION_ID_API,
    GET_PAYMENT_RESPONSE_API,
    GET_PAYMENT_TRANSACTION_API,
    GET_PAYMENT_SUBSCRIPTION_LIST_API,
    GET_UNSUBSCRIBED_API,
    CARD_PAYMENT,
    ADD_PAYMENT_SOURCE,
    PAYMENT_SOURCE_LIST,
    DELETE_PAYMENT_SOURCE,
    MARK_SOURCE_DEFAULT,
    NEW_SUBSCRIPTION,
    PAY_CIT,
    CURRENT_SUBSCRIPTION,
    MODIFY_PLAN,
    UPGRADE_PLAN,
    CANCEL_PLAN,
    SUBSCRIPTION_LIST,
    ORDER_LIST,
    RETRY_PAYMENT,
    AUTO_RENEW_TOGGLE
} from "../constants/paths"
import { axios } from "./axios"

// FETCH ALL PLANS
export const allPlansApi = async (params) => {
    const response = await axios.get(ALL_PLANS_API, { params })
    return response?.data
}

// Get session Id token
export const getSessionIdApi = async (id) => {
    const response = await axios.get(`${GET_SESSION_ID_API}${id}`);
    return response?.data
}

// AGENCY PROFILE UPLOAD LOGO
export const getPaymentResponse = async (data) => {
    const response = await axios.post(GET_PAYMENT_RESPONSE_API, data)
    return response?.data
}

// FETCH TRANSACTION
export const allTransactionApi = async (params) => {
    const response = await axios.get(GET_PAYMENT_TRANSACTION_API, { params })
    return response?.data
}

// FETCH subscribed-list TRANSACTION
export const getSubscribedListTransactionApi = async (params) => {
    const response = await axios.get(GET_PAYMENT_SUBSCRIPTION_LIST_API, { params })
    return response
}

// un-subscribed Api
export const unsubscribedApi = async (subscribedId) => {
    const response = await axios.get(`${GET_UNSUBSCRIBED_API}/${subscribedId}`);
    return response?.data
}

// Card payment
export const cardPayment = async (data, subscribedId) => {
    // const response = await axios.post(CARD_PAYMENT, data)
    const response = await axios.post(`${CARD_PAYMENT}/${subscribedId}`, data)
    return response?.data
}

// ADD PAYMENT SOURCE
export const addPaymentSource = async (data) => {
    const response = await axios.post(ADD_PAYMENT_SOURCE, data)
    return response?.data
}
// GET PAYMENT SOURCE LIST
export const getPaymentSourceList = async () => {
    const response = await axios.get(PAYMENT_SOURCE_LIST)
    return response?.data
}
// DELETE PAYMENT SOURCE 
export const deletePaymentSource = async (id) => {
    const response = await axios.delete(`${DELETE_PAYMENT_SOURCE}/${id}`)
    return response?.data
}
// MARK DEFAULT PAYMENT SOURCE
export const markDefaultPaymentSource = async (id) => {
    const response = await axios.patch(`${MARK_SOURCE_DEFAULT}/${id}`)
    return response?.data
}

//  NEW PAYMENT API'S
export const subscribePackageApi = async (data) => {
    const response = await axios.post(NEW_SUBSCRIPTION, data)
    return response?.data
}

// PAY CIT API
export const payCitApi = async (params) => {
    const response = await axios.get(PAY_CIT, { params })
    return response?.data
}

// CURRENT PACKAGE
export const currentPackageApi = async () => {
    const response = await axios.get(CURRENT_SUBSCRIPTION)
    return response?.data
}

// MODIFY PLAN
export const modifyPlanApi = async (subscriptionId, data) => {
    const response = await axios.put(`${MODIFY_PLAN}/${subscriptionId}`, data)
    return response?.data
}
// UPGRADE PLAN
export const upgradePlanApi = async (subscriptionId, data) => {
    const response = await axios.put(`${UPGRADE_PLAN}/${subscriptionId}`, data)
    return response?.data
}
// CANCEL PLAN 
export const cancelPlanApi = async (subscriptionId) => {
    const response = await axios.patch(`${CANCEL_PLAN}/${subscriptionId}`)
    return response?.data
}
// RETRY PAYMENT
export const retryPaymentApi = async (subscriptionId,data) => {
    const response = await axios.put(`${RETRY_PAYMENT}/${subscriptionId}`,data)
    return response?.data
}
// SUBSCRIPTION LIST
export const subscriptionListApi = async (params) => {
    const response = await axios.get(SUBSCRIPTION_LIST, { params })
    return response
}
// ORDER LIST
export const orderListApi = async(params)=>{
    const response = await axios.get(ORDER_LIST, { params })
    return response
}
// AUTO RENEW TOGGLE
export const autoRenewToggleApi = async(subscriptionId , status)=>{
    const response = await axios.patch(`${AUTO_RENEW_TOGGLE}/${subscriptionId}?autoRenew=${status}`)
    return response?.data
}
