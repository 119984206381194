import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { nameBasedProtectedRoutes } from 'router/protected'
import { mostViewedPropertiesAgency, mostViewedPropertiesAgent } from 'services/dashboardService'
import Cookies from 'universal-cookie'

// COMMON COMPONENT FOR AGENCY AND AGENT DASHBOARD
const MostViewedProps = ({ currentRole, startDate, endDate }) => {


    const { i18n } = useTranslation()

    const { t } = useTranslation()

    const cookies = new Cookies()

    // GET CURRENT LANGUAGE FROM i18n OR COOKIES

    const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

    // STATE FOR GRID DATE
    const [gridData, setGridData] = useState([])
    const [loading, setLoading] = useState(true)

    // FETCH TOP TEN AGENTS
    const fetchTopAgentsChartData = () => {
        setLoading(true)
        const params = {
            startDate: startDate,
            endDate: endDate
        }

        // IF CURRENT ROLE IS AGENCY THEN GET AGENCY DATA ELSE GET AGENT DATA
        if (currentRole === 'AGENCY') {
            mostViewedPropertiesAgency(params)
                .then((response) => {
                    setGridData(response)
                })
                .catch(error => {
                    toast.error(
                        error?.response?.data?.error_description
                          ? error?.response?.data?.error_description
                          : error?.response?.data?.message ?? error?.message
                      );
                }).finally(() => {
                    setLoading(false)
                })
        } else {
            mostViewedPropertiesAgent(params)
                .then((response) => {
                    setGridData(response)
                })
                .catch(error => {
                    toast.error(
                        error?.response?.data?.error_description
                          ? error?.response?.data?.error_description
                          : error?.response?.data?.message ?? error?.message
                      );
                }).finally(() => {
                    setLoading(false)
                })
        }
    }

    // CALL FUNCTION ON COMPONENT RENDER
    useEffect(() => {
        fetchTopAgentsChartData()
    }, [startDate, endDate])


    return (
        <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <div className="navtab-style1">
                <div className="d-sm-flex align-items-center justify-content-between">
                    <h4 className="title fz17 mb20">{t("MOST VIEWED")}</h4>
                </div>
            </div>

            {/* USING TABLE TO DISPLAY DATA */}
            <div className="table-responsive-sm">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">{t("AMAKENID")}</th>
                            <th scope="col">{t("PROPERTY TITLE")}</th>
                            <th scope="col "><p className='text-end fw-bold mb-0'>{t("VIEWS")}</p>    </th>
                        </tr>
                    </thead>
                    <tbody>{gridData && gridData?.length > 0 ? gridData.map((data, index) => (
                        <tr key={index + 1} className=''>
                            <td><Link className='fw500 text-decoration-underline' to={`${nameBasedProtectedRoutes?.propertyList?.path}?search=${data?.amakenId}`}>{data?.amakenId}</Link></td>
                            <td className="text fz25">{<div className="text fz25">{data?.title[currentLanguage]}</div>}</td>
                            <td><p className='text fz25 text-end text-theme fw-bold mb0'>{data?.totalPropertyViews}</p></td>
                        </tr>
                    )) : null}</tbody>
                </table>
            </div>
        </div>
    )
}
export default MostViewedProps