import { Pagination } from '@mui/material'
import LoaderMu from 'components/common/LoaderMu'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { nameBasedProtectedRoutes } from 'router/protected'
import { orderListApi } from 'services/packageService'
import { Amount, Description, EndDate, OrderRefId, StartDate, Status, TransactionRefId } from './RowTemplates'
import { SubscriptionId } from 'components/package/RowTemplates'

const Orders = () => {
  const { t } = useTranslation()

  const location = useLocation();
  const params = new URLSearchParams(location?.search)
  const search = params.get("search") ?? ''
  const [currentPage, setCurrentPage] = useState(params.get("page") ? parseInt(params.get("page")) : 1)

  const navigate = useNavigate()

  // const [sortValue, setSortValue] = useState("")
  const [loading, setLoading] = useState(false)
  const [orderListData, setOrderList] = useState([])

  // PAGINATION STATES
  const [pageSize, setPageSize] = useState(5)
  const [totalRowCount, setTotalRowCount] = useState(0)

  const pageOffset = (parseInt(currentPage) - 1) * pageSize + 1
  // HANDLE PAGE CHAGE
  const handlePageChange = (event, value) => {
    setCurrentPage(value)
    navigate(`${nameBasedProtectedRoutes?.orders?.path}?page=${value}`)
  }


  // FETCH ORDER LISTING DATA
  const fetchListingData = () => {
    setLoading(true)
    let params = {
      size: pageSize,
      sort: 'createdAt,desc'
    }

    if (currentPage) {
      params.page = currentPage - 1
    }
    // GET TRANSACTION LIST // ALL PREVIOUS PACKAGES
    orderListApi(params).then(response => {
      setOrderList(response?.data ?? [])
      setTotalRowCount(response?.headers["x-total-count"])
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(error?.response?.data?.error_description
        ? error?.response?.data?.error_description
        : error?.response?.data?.message)
    })

  }

  // CAL FUNCTION ON COMPONENT RENDER OR CURRENT PAGE CHANGE
  useEffect(() => {
    fetchListingData()
  }, [currentPage])




  return (
    <div className="dashboard__content bgc-f7">
      <LoaderMu loading={loading} />
      <div className="row align-items-center pb20">
        <div className="col-lg-12">
          <div className="dashboard_title_area d-flex justify-content-between align-items-center">
            <h2>{t("ORDERS")}</h2>
            <Link to={nameBasedProtectedRoutes?.package?.path} className='ud-btn btn-thm'>{t("VIEW PACKAGE")}</Link>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <div className="packages_table table-responsive">
              <div className="packages_table table-responsive">
                {/* RENDER PRIME REACT DATATABLE */}
                <DataTable
                  value={orderListData ?? []}
                  tableClassName="table-style3 table at-savesearch"
                  cellClassName="vam"
                >
                  {/* ORDER REF ID */}
                  <Column
                    field="orderRefId"
                    header={t("ORDER ID")}
                    body={(row) => <OrderRefId row={row} />}
                  ></Column>
                  {/* TRANSACTION ID */}
                  <Column
                    field="transactionRefId"
                    header={t("TRANSACTION ID")}
                    body={(row) => <TransactionRefId row={row} />}
                  ></Column>
                  {/* SUBSCRIPTION ID */}
                  <Column
                    field="subscriptionRefId"
                    header={t("SUBSCRIPTION ID")}
                    body={(row) => <SubscriptionId row={row} />}
                  ></Column>
                  {/* DESCRIPTION*/}
                  <Column
                    field="description"
                    header={t("DESCRIPTION")}
                    body={(row) => <Description row={row} />}
                  ></Column>
                  {/* AMOUNT */}
                  <Column
                    field="amount"
                    header={t("AMOUNT")}
                    body={(row) => <Amount row={row} />}
                  ></Column>
                  {/* START DATE */}
                  <Column field="startDate" header={t("START DATE")}
                    //body={{(row) => <span> row.price +' '+ t('CURRENCY SYMBOL')}</span>}
                    body={(row) => <StartDate row={row} />}
                  ></Column>
                  {/* END DATE */}
                  <Column
                    field="endDate"
                    header={t("END DATE")}
                    body={(row) => <EndDate row={row} />}
                  ></Column>
                  {/* STATUS */}
                  <Column
                    field="status"
                    header={t("ORDER STATUS")}
                    body={(row) => <Status row={row} />}
                  ></Column>
                </DataTable>
                {/* PAGINATION */}
                <div className="mbp_pagination text-center mt30 dataTable_pagination">
                  <Pagination count={Math.ceil(totalRowCount / pageSize)} siblingCount={1} page={currentPage} className="d-inline-block" size="large" onChange={(event, value) => handlePageChange(event, value)} />
                  {
                    totalRowCount < pageSize ?
                      <p className="mt10 pagination_page_count text-center">
                        {pageOffset} – {totalRowCount} {t("OF")} {totalRowCount} {t("ORDERS AVAILABLE")}
                      </p> : <p className="mt10 pagination_page_count text-center">
                        {pageOffset} – {currentPage * pageSize} {t("OF")} {totalRowCount}+ {t("ORDERS AVAILABLE")}
                      </p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Orders