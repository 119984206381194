import moment from "moment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { nameBasedProtectedRoutes } from "router/protected";
import Cookies from "universal-cookie";


// ACTIVITY TEMPLATE
export const EntityNameTemplate = ({ row }) => {

  

  return (
    <div className="listing-style1 dashboard-style d-xxl-flex align-items-center mb-0">
      <div className="list-content py-0 p-0 mt-2 mt-xxl-0">
        <div className=" list-title">
          <span >{row?.entityName}</span>
        </div>
      </div>
    </div>
  );
};

// MESSAGE TEMPLATE
export const MessageTemplate = ({ row }) => {

  const { i18n } = useTranslation()

  const cookies = new Cookies()

  const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

  const entityData = JSON.parse(row?.entityData)
  return (
    <div className="listing-style1 dashboard-style d-xxl-flex align-items-center mb-0">
      <div className="list-content py-0 p-0 mt-2 mt-xxl-0">
        <div className=" list-title">
          <Link to={`${nameBasedProtectedRoutes?.propertyList?.path}?search=${entityData?.amakenId}`}>{row?.message[currentLanguage]}</Link>
        </div>
      </div>
    </div>
  );
};

export const NameTemplate = ({ row }) => {

  return <span className="">{row?.user?.firstName ?? ""} {row?.user?.lastName}</span>;
}

export const DateTemplate = ({ row }) => {
  return <span className="">{moment(row?.createdAt).format('DD MMM YYYY, hh:mm:ss')}</span>;
};
