import React, { useState } from 'react'
import { DatePicker } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
const { RangePicker } = DatePicker;
const DateFilter = ({
    startDate,
    endDate,
    setStartDate,
    setEndDate
}) => {


    const { t } = useTranslation()
    const [selectedFilter, setSelectedFilter] = useState("this_year")

    const dateFormat = "YYYY-MM-DD";

    // Get the current year
    // const currentYear = moment().year();


    // FOR SET DATE ACCORDING TO FILTER
    const handleDateSelection = (filter) => {
        setSelectedFilter(filter)
        switch (filter) {
            case 'this_year': {
                const start_date = moment().startOf('year').format("YYYY-MM-DD");
                const end_date = moment().format("YYYY-MM-DD");
                setStartDate(start_date);
                setEndDate(end_date);
                break;
            }
            case 'last_year': {
                const start_date = moment().subtract(1, 'year').startOf('year').format("YYYY-MM-DD");
                const end_date = moment().subtract(1, 'year').endOf('year').format("YYYY-MM-DD");
                setStartDate(start_date);
                setEndDate(end_date);
                break;
            }
            case 'last_quarter': {
                // Calculate the start date of the last quarter
                const start_date = moment().subtract(1, 'quarters').startOf('quarter').format("YYYY-MM-DD");

                // Calculate the end date of the last quarter
                const end_date = moment().subtract(1, 'quarters').endOf('quarter').format("YYYY-MM-DD");

                // Set the start and end dates
                setStartDate(start_date);
                setEndDate(end_date);
                break;
            }
            case 'last_month': {
                const previousMonthStart = moment().subtract(1, 'months').startOf('month');
                const previousMonthEnd = moment().subtract(1, 'months').endOf('month');
                setStartDate(previousMonthStart.format("YYYY-MM-DD"));
                setEndDate(previousMonthEnd.format("YYYY-MM-DD"));
                break;
            }
            case 'last_week': {
                const previousSunday = moment().subtract(1, 'weeks').startOf('week');
                const previousSaturday = moment().subtract(1, 'weeks').endOf('week');
                setStartDate(previousSunday.format("YYYY-MM-DD"));
                setEndDate(previousSaturday.format("YYYY-MM-DD"));
                break;
            }
            default:
                break;
        }
    }

    // HANDLE DATE RANGE SELECT
    const handleDateRangeSelect = (date, dateString) => {
        setSelectedFilter('custom');
        if (dateString && dateString.length > 1 && dateString[0] !== '' && dateString[1] !== '') {
            const start_date = dateString[0];
            let end_date = dateString[1];
            const today = new Date();
            const end = new Date(endDate);

            if (end > today) {
                toast.warning(t("DATES GREATER THAN TODAY ARE NOT PERMITTED")) // Set end date to today's date
            } else {
                setStartDate(start_date);
                setEndDate(end_date);
            }
        }
    };

    return (
        <div className="text-md-end">
            <RangePicker
                // inputReadOnly={true}
                className={`${selectedFilter === 'custom' ? 'active' : ''} antd-rangePicker me-2`}
                value={[dayjs(startDate, dateFormat), dayjs(endDate, dateFormat)]}
                format={dateFormat}
                onChange={handleDateRangeSelect}
                footer={true}
                allowClear={false}
                maxDate={dayjs(new Date())}
            />
            <button className={`ud-btn px-4 py-2 ${selectedFilter === "this_year" ? "btn-dark" : "btn-white"} me-2 my-2 fw-bold`} onClick={() => handleDateSelection('this_year')}>{t("THIS YEAR")}</button>
            <button className={`ud-btn px-4 py-2 ${selectedFilter === "last_year" ? "btn-dark" : "btn-white"} me-2 my-2 fw-bold`} onClick={() => handleDateSelection('last_year')}>{t("LAST YEAR")}</button>
            <button className={`ud-btn px-4 py-2 ${selectedFilter === "last_quarter" ? "btn-dark" : "btn-white"} me-2 my-2 fw-bold`} onClick={() => handleDateSelection('last_quarter')}>{t("LAST QUARTER")}</button>
            <button className={`ud-btn px-4 py-2 ${selectedFilter === "last_month" ? "btn-dark" : "btn-white"} my-2 me-2 fw-bold`} onClick={() => handleDateSelection('last_month')}>{t("LAST MONTH")}</button>
            <button className={`ud-btn px-4 py-2 ${selectedFilter === "last_week" ? "btn-dark" : "btn-white"}  my-2 me-2 fw-bold`} onClick={() => handleDateSelection('last_week')}>{t("LAST WEEK")}</button>


        </div>
    )
}

export default DateFilter