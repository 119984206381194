import { Link } from "react-router-dom";
import DefaultImg from "../../assets/images/default-image.jpg";
import moment from "moment";
import { nameBasedProtectedRoutes } from "router/protected";
import { Switch } from "antd";
import { useSelector } from "react-redux";
import { toggleExactLocationApi } from "services/propertyService";
import { toast } from "react-toastify";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaUserTie } from "react-icons/fa";
export const ListingTitleTemplate = ({ row }) => {
  const { t } = useTranslation()

  const userData = useSelector((state) => state?.user?.userData?.authorities)

  let styleClass = 'style1'
  if (row?.status === 'DRAFT') {
    styleClass = 'style1'
  }
  if (row?.status === 'OFF_MARKET' || row?.status === 'EXPIRED') {
    styleClass = 'style2'
  }
  if (row?.status === 'PUBLISHED' || row?.status === 'SOLD') {
    styleClass = 'style4'
  }

  return (
    <div className="listing-style1 dashboard-style d-xxl-flex align-items-start mb-0 mw-300 max-w-500">
      <div className="list-thumb ">
        <img src={row?.propertyImage?.thumbnailImageName ?? DefaultImg} className="w-100" alt="Properties For You" />
      </div>
      <div className="list-content py-0 p-0 mt-2 mt-xxl-0 ps-xxl-4 text-start">
        <div className="h6 list-title">
          {
            row?.status === "PUBLISHED" ? <a href={`${process.env.REACT_APP_WEBSITE_WEB_URL}/property-details/${row?.id}`}
              target="_blank"
              className="text-decoration-underline"
            >{row?.title ?? ''}</a> : <span>{row?.title ?? ''}</span>
          }
        </div>
        <p className="list-text mb-0">{
          `${row?.village?.village ? row?.village?.village + ',' : ''}
            ${row?.directorate?.directorate ? row?.directorate?.directorate + ',' : ''}
            ${row?.governorate?.governorate ?? ''}`
        }</p>
        <div className="list-price">
          <Link to="#">
            JOD {row?.priceInJod}/<span>yr</span>
          </Link>
          <p className="mb-0">{t("AMAKEN ID")} : {row?.amakenId}</p>
        </div>
        {userData?.includes("ROLE_AGENCY_ADMIN") ?
          <div>
            <Link target="blank" to={`${nameBasedProtectedRoutes?.agentEdit?.path}/${row?.agentId}`} className="d-inline-block  text-decoration-underline text-orange"> <FaUserTie className="mb-1" /> {`${row?.agent?.firstName ?? ''} ${row?.agent?.lastName ?? ''}`}</Link>
          </div> : ""}
      </div>
    </div>
  );
};

// change name of template to updatedDate
export const UpdatedDateTemplate = ({ row }) => {
  return <div className="w-120"><span>{row?.updatedAt ? moment(row?.updatedAt).format('DD MMM YYYY') : moment(row?.createdAt).format('DD MMM YYYY')}</span></div>
}

export const ViewDateTemplate = ({ row }) => {
  return <div className="w-120"> <span>{moment(row?.listingDate).format('DD MMM YYYY')}</span></div>
}
// SHOW EXACT LOCATION ON PROPERTY DETAILS OR NOT
export const ShowLocationTemplate = ({ row }) => {
  const { t } = useTranslation()
  const [showLocation, setShowLocation] = useState(row?.hideExactLocation ?? false)
  const [loading, setLoading] = useState(false)
  // HIDE OR SHOW EXACT LOCATION
  const handleShowToggle = (value) => {
    setLoading(true)
    toggleExactLocationApi(row?.id, value).then((response) => {
      setShowLocation(!showLocation)
      toast.success(response?.message)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error.response.data.error_description
          ? error.response.data.error_description
          : error.response.data?.message ?? error?.message
      )
    })
  }

  return <Switch disabled={loading} checked={showLocation} checkedChildren={t("HIDE")} unCheckedChildren={t("SHOW")} onChange={handleShowToggle} />
}

export const AgentTemplate = ({ row }) => {
  return <Link target="blank" to={`${nameBasedProtectedRoutes?.agentEdit?.path}/${row?.agentId}`} className="d-inline-block w-120 text-decoration-underline"> {`${row?.agent?.firstName ?? ''} ${row?.agent?.lastName ?? ''}`}</Link>
}

export const StatusTemplate = ({ row }) => {
  let styleClass = 'style1'
  if (row?.status === 'DRAFT') {
    styleClass = 'style1'
  }
  if (row?.status === 'OFF_MARKET' || row?.status === 'EXPIRED') {
    styleClass = 'style2'
  }
  if (row?.status === 'PUBLISHED' || row?.status === 'SOLD') {
    styleClass = 'style4'
  }

  return <span className={`pending-style ${styleClass}`}>{row?.status}</span>;
};
