import { useTranslation } from "react-i18next";

// ORDER REF ID
export const OrderRefId = ({ row }) => {
  return <span>{row?.orderRefId}</span>;
}

// TRANSACTION REF ID
export const TransactionRefId = ({ row }) => {
  return <span>{row?.transactionRefId}</span>;
}

// SUBSCRIPTION REF ID
export const SubscriptionRefId = ({ row }) => {
  return <span>{row?.subscriptionRefId}</span>;
}

// DESCRIPTION
export const Description = ({ row }) => {
  return <span>{row?.description}</span>;
}

// START DATE
export const StartDate = ({ row }) => {
  return <span>{row?.startDate}</span>;
}

// END DATE
export const EndDate = ({ row }) => {
  return <span>{row?.endDate}</span>;
}

// AMOUNT
export const Amount = ({ row }) => {

  const {t} = useTranslation()
  return <span>{row?.amount && (row?.amount).toFixed(2) + " " + t('CURRENCY SYMBOL')}  </span>;
}

// STATUS
export const Status = ({ row }) => {
  return <span>{row?.status}</span>;
}