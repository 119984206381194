import React, { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import AgencyFileUpload from 'components/common/AgencyFileUpload';
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { MdOutlineAddHomeWork } from "react-icons/md";
import { floorValidationSchema } from 'validations/ValidationSchema';
import { FloorNoDropdownList20 } from 'jsonData/StaticData';
import AgencySelect from 'components/common/AgencySelect';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { deleteFloorPlan, saveFloorPlan } from 'services/propertyService';
import { toast } from 'react-toastify';
import { Image, Popconfirm } from 'antd';
import LoaderMu from 'components/common/LoaderMu';

const FloorPlansForm = ({ propertyFloorImages, propertyId }) => {

	// const { id } = useParams()

	const { i18n } = useTranslation()

	const cookies = new Cookies()

	const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

	const { t } = useTranslation()

	const [floorData, setFloorData] = useState(propertyFloorImages ?? [])

	const [initialValues, setInitialValues] = useState({ floors: [{ floorNumber: "", floorImg: "" }] })

	useEffect(() => {
		setFloorData(propertyFloorImages)
	}, [propertyFloorImages])


	const [loading, setLoading] = useState(false)
	const floorList = FloorNoDropdownList20?.map((floor) => ({ label: floor?.label[currentLanguage], value: floor?.value }))

	// HANDLE FLOOR PLAN SUBMIT
	const handleSubmit = (values, actions) => {
		setLoading(true)
		const formData = new FormData()
		formData.append("propertyId", propertyId)

		if (values?.floors && values?.floors?.length > 0) {

			values?.floors?.forEach((floor, index) => {
				formData.append(`floorPlans[${index}].floorNumber`, floor?.floorNumber)
				formData.append(`floorPlans[${index}].browseImage`, floor?.floorImg)
			})
		}

		// SAVE FLOOR PLAN API
		saveFloorPlan(propertyId, formData).then((response) => {
			toast.success(response?.message ?? 'Floor plan saved.')
			setInitialValues({ floors: [{ floorNumber: "", floorImg: "" }] })
			actions.resetForm()
			if (response && response?.length > 0) {
				setFloorData([...floorData, ...response])
			}
		}).catch((error) => {
			toast.error(error?.response?.data?.error_description
				? error?.response?.data?.error_description
				: error?.response?.data?.message ?? error?.message)
		}).finally(() => {
			setLoading(false)
		})
	}

	// HANDLE FLOOR PLAN DELETE
	const handleFloorPlanDelete = (floorId) => {
		setLoading(true)
		deleteFloorPlan(floorId).then((response) => {
			toast.success(response?.message ?? t('Floor plan deleted successfully.'))
			const newFloorData = floorData?.filter(floor => floor?.id !== floorId)
			setFloorData(newFloorData)
		}).catch((error) => {
			toast.error(error?.response?.data?.error_description
				? error?.response?.data?.error_description
				: error?.response?.data?.message ?? error?.message)
		}).finally(()=>{
			setLoading(false)
		})
	}
	const handleFileUpload = (event, setFieldValue, index) => {
		const file = event.target.files[0];
		setFieldValue(`floors.${index}.floorImg`, file);
		// Reset the file input
		event.target.value = '';
	};
	return (
		<div>
			<LoaderMu loading={loading} />
			<h4 className="title fz17 mb30">{t("FLOOR PLANS")}</h4>
			<Formik
				initialValues={initialValues}
				validationSchema={floorValidationSchema}
				enableReinitialize={true}
				onSubmit={(values, actions) => {
					handleSubmit(values, actions)
				}}
			>
				{({ values, setFieldValue, handleSubmit, handleBlur, handleChange, errors, touched }) => (
					<Form>
						<FieldArray
							name="floors"
							render={(arrayHelpers) => (
								<div>
									{/* Add Floor Button */}
									<div>
										<button
											type="button"
											className="btn ud-btn btn-white mb-3"
											onClick={() => {
												const availableLength = floorData?.length + values.floors.length
												if (availableLength < 17) {
													arrayHelpers.push({ floorNumber: '', floorImg: '' })
												} else {
													toast.error(t("FURTHER FLOOR ADDITIONS ARE NOT ALLOWED."))
												}
											}}
										>
											<MdOutlineAddHomeWork size={20} /> {t("ADD FLOOR")}
										</button>
									</div>
									{
										floorData && floorData?.length > 0 ?
											<div className='row'>
												{
													floorData?.map((floor, index) => {
														const floorTitle = floorList.find(f => f.value === floor?.floorNumber);
														return <div key={index + 1} className='col-sm-6 col-md-4 col-lg-4 col-xl-3  mb20'>
															<div className="floorPlan-thumb px-4 position-relative mb20-sm mr20 ">
																<label>{t("FLOOR")} {index + 1}</label>
																<div className="d-flex justify-content-between align-items-center">
																	<p className='mb-0 fw500 fs-6'>{floorTitle?.label}</p>
																	<Popconfirm
																		title={t("ARE YOU SURE TO DELETE THIS FLOOR PLAN")}
																		onConfirm={() => handleFloorPlanDelete(floor?.id)}
																		okText={t("OK")}
																		cancelText={t("CANCEL")}
																	>
																		<button
																			type="button"
																			className="btn"
																		>
																			<IoIosRemoveCircleOutline className="text-danger" size={22} />
																		</button>
																	</Popconfirm>
																</div>
																<Image src={floor?.imageName} alt="floor_plan" />
															</div>
														</div>
													})
												}
											</div> : ""
									}
									{/* Floors List */}
									{floorData?.length < 17 ?
										values.floors && values.floors.length > 0 ? (
											values.floors.map((floor, index) => {
												const floorPlanNo = parseInt(floorData?.length ?? 0) + (index + 1)
												// Filter out selected floor numbers for the current dropdown
												const selectedFloorNumbers = [
													...values.floors.map(f => f.floorNumber),
													...(Array.isArray(floorData) ? floorData.map(f => f.floorNumber) : [])
												];

												// To remove duplicates (if needed)
												const uniqueFloorNumbers = [...new Set(selectedFloorNumbers)];
												const availableOptions = floorList.filter(option => !uniqueFloorNumbers.includes(option.value) || option.value === floor.floorNumber);
												return <div key={index} className="border rounded-1 p-4 my-2">
													<div className="row align-items-center justify-content-between">
														<div className="col-auto">
															<label htmlFor={`floors.${index}.floorNumber`}>{t("FLOOR")} {floorPlanNo}</label>
														</div>
														<div className="col-auto">
															<button
																type="button"
																className="btn"
																onClick={() => arrayHelpers.remove(index)} // remove a floor from the list
															>
																<IoIosRemoveCircleOutline className="text-danger" size={30} />
															</button>
														</div>
													</div>
													<div className="row">
														<div className="col-xl-5">
															<AgencySelect
																label={t("FLOOR NUMBER")}
																id={`floors.${index}.floorNumber`}
																placeholder={t("FLOOR NUMBER")}
																name={`floors.${index}.floorNumber`}
																handleChange={(value) => {
																	setFieldValue(`floors.${index}.floorNumber`, value)
																}}
																disabled={floor?.id ? true : false}
																handleBlur={handleBlur}
																value={floor?.floorNumber}
																className={`selectpicker form-control ${touched?.floors?.[index]?.floorNumber &&
																	errors?.floors?.[index]?.floorNumber
																	? "error"
																	: ""
																	}`}
																options={availableOptions ? [{ value: '', label: t('SELECT') }, ...availableOptions] : []}
															/>
														</div>

														<div className="col-xl-5">
															<AgencyFileUpload
																label={t("FLOOR PLAN")}
																id={`floors.${index}.floorImg`}
																accept=".jpg, .jpeg, .png"
																name={`floors.${index}.floorImg`}
																handleBlur={handleBlur}
																handleChange={(event) => handleFileUpload(event, setFieldValue, index)}
																fileName={floor?.floorImg}
																className={`form-control p15 pl30 ud-btn2 ${errors?.floors?.[index]?.floorImg ? 'error' : ''
																	}`}
															/>
														</div>
													</div>
												</div>
											})
										) : (
											""
										) : ""}
									{
										values?.floors?.length > 0 ? <div className='text-end'>
											<button type="submit" disabled={loading} onClick={handleSubmit} className="btn ud-btn btn-dark">{t("SAVE")}</button>
										</div> : ""
									}
								</div>
							)}
						/>
					</Form>
				)}
			</Formik>
		</div>
	)
}

export default FloorPlansForm