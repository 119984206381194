import { Row } from 'antd'
import AgencySelect from 'components/common/AgencySelect'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { IoMdDownload } from 'react-icons/io'
import { useAsyncError } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getAgentListingData } from 'services/agentService'
import { GovernorateListApi } from 'services/propertyService'
import { propertyReportDownload, villageSearchApi } from 'services/reportsServices'

const PropertyReport = ({ startDate, endDate }) => {

  const { t } = useTranslation()

  const [loading, setLoading] = useState()
  // STATES FOR DROPDOWN LIST
  const [governorateList, setGovernorateList] = useState([])
  const [villageList, setVillageList] = useState([])
  const [agentList, setAgentList] = useState([])

  // STATE FOR DROPDOWN SEARCH
  const [governorateSearch, setGovernorateSearch] = useState("")
  const [villageSearch, setVillageSearch] = useState("")
  const [agentSearch, setAgentSearch] = useState("")


  // FUNCTION FOR FETCH GOVERNORATE LIST
  const getGovernorateList = () => {
    setLoading(true)
    let params = {
      search: governorateSearch ?? '',
      size: 50,
    }
    // CALLING GOVERNORATE LIST API
    GovernorateListApi(params).then(response => {
      if (response?.data && response?.data?.length > 0) {
        let governorates = []
        response?.data?.forEach((governorate) => {
          governorates.push({ label: governorate?.governorate, value: governorate?.id })
        })
        setGovernorateList(governorates)
      } else {
        setGovernorateList([])
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  // GET GOVERNORATE LIST
  useEffect(() => {
    getGovernorateList()
  }, [governorateSearch])

  // FUNCTION FOR FETCH VILLAGE LIST
  const getVillageList = () => {
    setLoading(true)
    let params = {
      search: villageSearch ?? '',
      size: 50,
    }
    // CALLING VILLAGE SEARCH API
    villageSearchApi(params).then(response => {
      if (response?.data && response?.data?.length > 0) {
        let villages = []
        response?.data?.forEach((village) => {
          villages.push({ label: village?.village, value: village?.id })
        })
        setVillageList(villages)
      } else {
        setVillageList([])
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  // GET VILLAGE LIST
  useEffect(() => {
    getVillageList()
  }, [villageSearch])

  // FETCH AGENT LIST DATA
  const fetchAgentsData = () => {
    setLoading(true)
    let params = {
      size: 50
    }
    if (agentSearch) {
      params.search = agentSearch ? agentSearch.trim() : ''
    }
    // CALLING AGENT LIST API
    getAgentListingData(params).then(response => {

      if (response?.data && response?.data?.length > 0) {
        let agents = []
        response?.data?.forEach((agent) => {
          agents.push({ label: agent?.firstName + agent?.lastName, value: agent?.id })
        })
        setAgentList(agents ?? [])
      } else {
        setAgentList([])
      }

      setLoading(false)

    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  // GET AGENT DROPDOWN DATA
  useEffect(() => {
    fetchAgentsData()
  }, [agentSearch])

  // HANDLE PROPERTY REPORT DOWNLOAD
  const handleReportDownload = (values, actions) => {
    console.log(values)
    setLoading(true)
    // CALL API HERE TO GET FILE DATA
    const params = {
      startDate: startDate ?? "",
      endDate: endDate ?? "",

    }
    if (values?.agent && values?.agent !== '') {
      params.agentId = values?.agent
    }
    if (values?.governorate && values?.governorate !== '') {
      params.governorateId = values?.governorate
    }
    if (values?.village && values?.village !== '') {
      params.villageId = values?.village
    }

    propertyReportDownload(params).then((response) => {
      if (response?.data) {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const blobUrl = window.URL.createObjectURL(blob);

        const tempLink = document.createElement('a');
        tempLink.href = blobUrl;
        tempLink.setAttribute('download', 'Property.xlsx');

        // Append the link to the document body before clicking it
        document.body.appendChild(tempLink);

        tempLink.click();

        // Clean up by revoking the Blob URL
        window.URL.revokeObjectURL(blobUrl);

        // Remove the link from the document body after clicking
        document.body.removeChild(tempLink);
      } else {
        throw new Error('Response data is empty.');
      }
    }).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    }).finally(() => {
      setLoading(false)
    })
  }


  return (
    <div className="p-3 d-flex flex-column h-100 ">
      <Formik
        enableReinitialize={true}
        initialValues={{
          governorate: null,
          village: null,
          agent: null
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false)
          handleReportDownload(values, actions)
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors
        }) => (
          <Form className="d-flex flex-column flex-grow-1 theme-from">
            <div className='row'>
              <div className="col-xl-4">
                {/* GOVERNORATE SELECT */}
                <AgencySelect
                  showSearch={true}
                  filterOption={false}
                  label={t("GOVERNORATE")}
                  id="governorate"
                  placeholder={t("SELECT")}
                  name="governorate"
                  enableScrollPagination={true}
                  handleChange={(value) => {
                    setFieldValue("governorate", value);
                  }}
                  handleSearch={(value) => {
                    setGovernorateSearch(value)
                  }}
                  handleBlur={handleBlur}
                  value={values?.governorate}
                  className={`selectpicker form-control ${touched?.governorate &&
                    errors?.governorate
                    ? "error"
                    : ""
                    }`}
                  options={governorateList ?? []}
                />
              </div>
              {/* VILLAGE SELECT */}
              <div className="col-xl-4">
                <AgencySelect
                  showSearch={true}
                  filterOption={false}
                  label={t("VILLAGE")}
                  id="village"
                  placeholder={t("SELECT")}
                  name="village"
                  enableScrollPagination={true}
                  handleChange={(value) => {
                    setFieldValue("village", value);
                  }}
                  handleSearch={(value) => {
                    setVillageSearch(value)
                  }}
                  handleBlur={handleBlur}
                  value={values?.village}
                  className={`selectpicker form-control ${touched?.village &&
                    errors?.village
                    ? "error"
                    : ""
                    }`}
                  options={villageList ?? []}
                />
              </div>
              {/* AGENT LIST */}
              <div className="col-xl-4">
                <AgencySelect
                  showSearch={true}
                  filterOption={false}
                  label={t("AGENT")}
                  id="agent"
                  placeholder={t("SELECT")}
                  name="agent"
                  enableScrollPagination={true}
                  handleChange={(value) => {
                    setFieldValue("agent", value);
                  }}
                  handleSearch={(value) => {
                    setAgentSearch(value)
                  }}
                  handleBlur={handleBlur}
                  value={values?.agent}
                  className={`selectpicker form-control ${touched?.agent &&
                    errors?.agent
                    ? "error"
                    : ""
                    }`}
                  options={agentList ?? []}
                />
              </div>
            </div>
            <div className="my-3">
              {/* DOWNLOAD PROPERTY REPORT BUTTON */}
              <Button
                onClick={handleSubmit}
                className="ud-btn btn-dark"
                type="submit"
                disabled={loading ?? false}
              >   <IoMdDownload size={20} />{t("PROPERTY REPORT")}</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
export default PropertyReport