import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { agencyStatsApi } from 'services/dashboardService'
import { LuBuilding2 } from "react-icons/lu";
import { BsBuildingCheck, BsBuildingExclamation } from "react-icons/bs"
import { useTranslation } from 'react-i18next';
import MainStatsComponent from '../MainStatsComponent';
const AgencyStats = ({ startDate, endDate }) => {

  const { t } = useTranslation()

  const [mainStatsData, setMainStatsData] = useState({})
  const [loading, setLoading] = useState(true)

  // FETCH STATS FOR AGENCY
  const getAgencyStats = () => {
    setLoading(true)
    let params = {
      startDate: startDate,
      endDate: endDate
    }
    agencyStatsApi(params).then((response) => {
      setMainStatsData(response)
    }).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    }).finally(() => {
      setLoading(false)
    })
  }

  // CALL FUNCTION ON COMPONENT RENDER
  useEffect(() => {
    getAgencyStats()
  }, [startDate, endDate])


  return (
    <div className="row">
      {/* TOTAL PROPERTIES */}
      <MainStatsComponent title={t("TOTAL PROPERTIES")} count={mainStatsData?.totalProperties ?? 0} icon={<LuBuilding2 />} />
      {/* NEW PROPERTIES */}
      <MainStatsComponent title={t("NEW PROPERTIES")} count={mainStatsData?.newProperties ?? 0} icon={<LuBuilding2 />} />

      {/* ACTIVE PROPERTIES */}
      <MainStatsComponent title={t("ACTIVE PROPERTIES")} count={mainStatsData?.activeProperties ?? 0} icon={<BsBuildingCheck />} />

      {/* TOTAL PROPERTIES VIEWS*/}
      <MainStatsComponent title={t("PROPERTY VIEWS")} count={mainStatsData?.totalPropertyViews ?? 0} icon={<i className="flaticon-search-chart" />} />

      {/* TOTAL VIEWS */}
      <MainStatsComponent title={t("AGENCY PROFILE VIEWS")} count={mainStatsData?.totalViews ?? 0} icon={<i className="flaticon-search-chart" />} />

      {/* AGING PROPERTIES */}
      <MainStatsComponent title={t("AGING PROPERTIES")} count={mainStatsData?.agingProperties ?? 0} icon={<BsBuildingExclamation />} />

    </div>
  )
}

export default AgencyStats