import React, { useEffect, useState } from 'react'
import defaultImg from "../../assets/images/default-image.jpg";
import { Popconfirm, Upload } from 'antd';
import { toast } from 'react-toastify';
import { deleteAgencyLogo, uploadAgencyLogo } from 'services/agencyService';
import LoaderMu from 'components/common/LoaderMu';
import { useTranslation } from 'react-i18next';
function UploadLogo({ agencyLogo, getAgencyData }) {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)

  const [logoUrl, setLogoUrl] = useState(agencyLogo ?? "")

  useEffect(() => {
    setLogoUrl(agencyLogo)
  }, [agencyLogo])

  // HANDLE LOGO UPLOAD
  const handleUpload = (fileData) => {
    setLoading(true);
    const allowedFileTypes = ["image/jpeg", "image/png"];
    const isAllowedType = allowedFileTypes.includes(fileData?.file?.type);

    const allowedFileSize = 1024 * 1024 * 10
    const isAllowedSize = fileData?.file?.size <= allowedFileSize
    if (!isAllowedType) {
      setLoading(false);
      toast.error("You can only upload JPG or PNG files!");
    } else if (!isAllowedSize) {
      setLoading(false);
      toast.error("You can upload file up to 10mb!");
    } else {
      let formData = new FormData();
      formData.append("logo", fileData?.file);
      uploadAgencyLogo(formData)
        .then((response) => {
          setLoading(false);
          setLogoUrl(response?.logo)
          getAgencyData()
          toast.success(response?.data?.message?? "Logo updated!");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            error?.response?.data?.error_description
              ? error?.response?.data?.error_description
              : error?.response?.data?.message ?? error?.message
          );
        });
    }
  };
  // HANDLE LOGO DELETE
  const handleDelete = () => {
    setLoading(true);
    deleteAgencyLogo()
      .then((response) => {
        setLoading(false);
        getAgencyData()
        setLogoUrl("")
        toast.success(response?.data?.message ?? "Logo Removed!");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });
  };
  return (
    <div className="col-xl-7">
    {loading ? <LoaderMu loading={loading}/> : ""}
      <div className="profile-box position-relative d-md-flex align-items-end mb50">
        {
          logoUrl ?
            <div className="profile-img position-relative overflow-hidden bdrs12 mb20-sm">
              <img
                src={logoUrl ?? defaultImg}
                className="mxh-250"
                alt="Properties For You"
              />
              <Popconfirm
                title={t("DELETE THE IMAGE")}
                description={t("ARE YOU SURE TO DELETE PROFILE PICTURE?")}
                onConfirm={handleDelete}
                okText={t("YES")}
                cancelText={t("NO")}
              >
                <button className="tag-del border-none">
                  <span className="fas fa-trash-can" />
                </button>
              </Popconfirm>
            </div> : <div className="profile-img position-relative overflow-hidden bdrs12 mb20-sm">
              <img
                src={defaultImg}
                className="mxh-250"
                alt="Properties For You"
              />
            </div>
        }
        <div className="profile-content ml30 ml0-sm">
          <Upload
            name="profilePicture"
            onChange={handleUpload}
            beforeUpload={() => false}
            showUploadList={false}
            accept=".jpg, .jpeg, .png"
          >
            <button className="btn ud-btn btn-white2 mb30">
              {t("UPLOAD AGENCY LOGO")}<i className="fal fa-arrow-right-long" />
            </button>
          </Upload>
          {/* <a href="" className="ud-btn btn-white2 mb30">
          Upload Agency Logo
          <i className="fal fa-arrow-right-long" />
        </a> */}
          <p className="text">{t("JPEG OR PNG FORMAT")}</p>
        </div>
      </div>
    </div>
  )
}

export default UploadLogo
