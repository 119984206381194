import { createSlice } from "@reduxjs/toolkit";
import storage from "../helpers/storage";
import { currentPackageApi } from "services/packageService";
let initalToken = storage.getToken();



// An asynchronous function to fetch user data
const fetchUserPackageDetail = async () => {
  try {
    if (initalToken) {
      const response = await currentPackageApi();
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};
// Fetch user package detail and number of access
const userPackageDetail = await fetchUserPackageDetail();
const userPackageDetailSlice = createSlice({
  name: "userPackage",
  initialState: userPackageDetail,
  reducers: {
    setCurrentPackage: (state, action) => {
      if (action?.payload?.userPackage) {
        return action.payload.userPackage;
      }
    },
    cancelCurrentPackage: (state, action) => {
      state = null
      return null;
    },
    setAutoRenewToggle: (state, action) => {
      if (action?.payload?.autoRenew) {
        state.autoRenew = action?.payload?.autoRenew
      }
    }
  },
});

export const { setCurrentPackage, cancelCurrentPackage, setAutoRenewToggle } = userPackageDetailSlice?.actions;
export default userPackageDetailSlice?.reducer;
