import { Link } from "react-router-dom";
import dashboardagentimg from "../../assets/images/team/agent-2.jpg";
import moment from "moment";
import { CiMail } from "react-icons/ci";
import { updateAgentStatus } from "services/agentService";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";



export const AgentNameTemplate = (row) => {

	const { i18n } = useTranslation()
	const cookies = new Cookies()

	const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

  return <div className="listing-style1 dashboard-style d-xxl-flex align-items-center mb-0">
    <div className="list-thumb">
      <div className="bdrs24 default-box-shadow1 mb30-sm">
        {
          row?.imageUrl
            && row?.imageUrl
            !== null ? <img
            className="bdrs24 w-100"
            src={row?.imageUrl
            }
            alt=" Logo"
          /> : <img
            className="bdrs24 w-100"
            src={dashboardagentimg}
            alt=" Logo"
          />
        }

      </div>
    </div>
    <div className={`list-content py-0 p-0 mt-2 mt-xxl-0 ps-xxl-4 ${currentLanguage==='ar' ? 'text-right' :  'text-start'}`}>
      <div className="h6 list-title">
        <Link to="#">{row?.firstName ?? '' + " " + row?.lastName ?? ''}</Link>
      </div>
      <Link className="text fz16" to="#">
        <CiMail className="pe-1 fs-3" />
        {row?.email ?? ''}
        {/* <i className="fal fa-mail pe-1" /> */}
        {/* +(962) 79 123 4567 */}
      </Link>
    </div>
  </div>
}

export const ActionTemplate = ({ row, getData }) => {
  const { t } = useTranslation()

  const handleStatusUpdate = (agentId, status) => {
    updateAgentStatus(agentId, { status: status }).then((response) => {
      if (response?.status == '200') {
        toast.success(response?.message)
        getData()
      } else {
        toast.error(response?.error_description
          ? response?.error_description
          : response?.message)
      }

    }).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  return (
    <div className="d-flex justify-content-end">
      <div className="text-center text-lg-end header_right_widgets">
        <ul className="mb0 d-flex justify-content-center justify-content-sm-end p-0">
          <li className="user_setting">
            <div className="dropdown">
              <Link to="#" className="btn px5" data-bs-toggle="dropdown">
                <span className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu w-auto actions">
                <div className="user_setting_content">
                  <p className="fz14 fw400 ff-heading text-gray mb-1 bb1">
                    {t("ACTIONS")}
                  </p>
                  <Link className="dropdown-item p10 lh0 mb5" to={`/agent/edit/${row?.id}`}>
                    {t("EDIT")}
                  </Link>
                  {
                    row?.status === 'PENDING' || row?.status === 'DISABLED' ? <button className="btn dropdown-item p10 mb5" onClick={() => handleStatusUpdate(row?.id, 'ENABLED')}>
                      {t("ENABLE")}
                    </button> : <button className="btn dropdown-item p10 mb5" onClick={() => handleStatusUpdate(row?.id, "DISABLED")}>
                      {t("DISABLE")}
                    </button>
                  }
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export const statusTemplate = (row) => {
  return <span className={`pending-style fw-bold ${row?.status === 'ENABLED' ? 'style2' : 'style3'}`}>{row?.status}</span>;
};
export const startedAtTemplate = (row) => {
  return <span>{row?.createdAt ? moment(row?.createdAt).format('MMMM DD,YYYY') : ""}</span>
}
export const typeTemplate = (row) => {
  return <span>{row?.partialAgent === true ? 'Partial' : 'Full'}</span>
}
