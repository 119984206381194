import React, { useEffect, useState } from 'react'
import pricingIcon1 from "../../assets/images/icon/pricing-icon-1.svg"
import LoaderMu from "components/common/LoaderMu";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import RangeSlider from 'components/common/RangeSlider';
import { nameBasedProtectedRoutes } from 'router/protected';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { modifyPlanApi, upgradePlanApi } from 'services/packageService';
import { setCurrentPackage } from '../../redux/UserPackageSlice';
import { Popconfirm } from 'antd';


function PlanCard({ planDetails }) {

    const { t } = useTranslation()
    const navigate = useNavigate();

    const location = useLocation()

    const params = new URLSearchParams(location?.search)

    const isModifyPlan = params.get("modify") === 'true' ? true : false
    const isUpgradePlan = params.get("upgrade") === 'true' ? true : false

    const currentPlan = useSelector((state) => state?.userPackage)

    const dispatch = useDispatch()

    const [loading, setLoading] = useState(false);

    const [planPrice, setPlanPrice] = useState(0);
    const [featuredProp, setFeaturedProp] = useState(planDetails?.noOfActiveFeaturedProperty || 0)
    const [virtualTourCount, setVirtualTourCount] = useState(planDetails?.noOf3dViewUpload || 0)

    // CURRENT ACTIVE PACKAGE
    const currentPackage = useSelector((state) => state?.userPackage)

    useEffect(() => {
        setPlanPrice(planDetails?.price)
        setFeaturedProp(planDetails?.noOfActiveFeaturedProperty)
        setVirtualTourCount(planDetails?.noOf3dViewUpload)
    }, [planDetails])

    // HANDLE VIRTUAL TOUR CHANGE
    const handleVirtualTourChange = (event) => {

      
        const newValue = parseInt(event.target.value, 10);
        console.log({"NEW VALUE" :newValue })
        let priceVariation = planDetails?.additionalCostPer3dViewUpload || 0;

        if (currentPackage?.status === "ACTIVE") {
            priceVariation = currentPackage?.planData?.additionalCostPer3dViewUpload;
        }

        // Calculate the difference from the previous value
        const difference = newValue - virtualTourCount;

        if (isModifyPlan === true) {
            if (newValue >= currentPlan?.planData?.customNoOf3dViewUpload) {
                // Update the virtual tour count
                setVirtualTourCount(newValue);

                // Update the plan price
                setPlanPrice((prevPrice) => {
                    const updatedPrice = parseFloat(prevPrice) + (difference * priceVariation);
                    return updatedPrice.toFixed(2);
                });
            } else {
                toast.warn(t('YOU CANNOT DECREASE THE NUMBER OF VIRTUAL TOURS'), {
                    toastId: 'virtualTourWarning',  // Unique ID to prevent duplicate toasts
                });
            }

        } else {

            // Update the virtual tour count
            setVirtualTourCount(newValue);

            // Update the plan price
            setPlanPrice((prevPrice) => {
                const updatedPrice = parseFloat(prevPrice) + (difference * priceVariation);
                return updatedPrice.toFixed(2);
            });
        }


    };
    // HANDLE FEATURE PROPERTY CHANGE
    const handleFeaturePropertyChange = (event) => {
        const newValue = parseInt(event.target.value, 10);
        let priceVariation = planDetails?.additionalCostPerFeaturedProperty || 0;

        if (currentPackage?.status === "ACTIVE") {
            priceVariation = currentPackage?.planData?.additionalCostPerFeaturedProperty;
        }

        // Calculate the difference from the previous value
        const difference = newValue - featuredProp;


        if (isModifyPlan === true) {
            if (newValue >= currentPlan?.planData?.customNoOfActiveFeaturedProperty) {
                // Update the virtual tour count
                setFeaturedProp(newValue);

                // Update the plan price
                setPlanPrice((prevPrice) => {
                    const updatedPrice = parseFloat(prevPrice) + (difference * priceVariation);
                    return updatedPrice.toFixed(2);
                });
            } else {
                toast.warn(t('YOU CANNOT DECREASE THE NUMBER OF FEATURED PROPERTY'), {
                    toastId: 'virtualTourWarning',  // Unique ID to prevent duplicate toasts
                });
            }
        } else {
            // Update the featured property count
            setFeaturedProp(newValue);

            // Update the plan price
            // Update the plan price and fix it to 2 decimal places
            setPlanPrice((prevPrice) => {
                const updatedPrice = parseFloat(prevPrice) + (difference * priceVariation);
                return updatedPrice.toFixed(2);
            });
        }
    };

    useEffect(() => {
        if (isModifyPlan === true && currentPlan && currentPlan !== null) {
            setPlanPrice(currentPlan?.planData?.planAmount)
            setFeaturedProp(currentPlan?.planData?.customNoOfActiveFeaturedProperty)
            setVirtualTourCount(currentPlan?.planData?.customNoOf3dViewUpload)
        } else {
            setPlanPrice(planDetails?.price)
            setFeaturedProp(planDetails?.noOfActiveFeaturedProperty)
            setVirtualTourCount(planDetails?.noOf3dViewUpload)
        }
    }, [isModifyPlan, planDetails])

    const handlePayButtonClick = () => {
        //  IF PLAN MODIFY THEN VALUE MUST BE MORE THAN CURRENT PLAN AND IT WILL DIRECTLY PAY FROM HERE
        if (isModifyPlan === true) {
            setLoading(true)
            if (virtualTourCount > currentPlan?.planData?.customNoOf3dViewUpload || featuredProp > currentPlan?.planData?.customNoOfActiveFeaturedProperty) {
                const modifyData = {
                    customNoOf3dViewUpload: virtualTourCount,
                    customNoOfActiveFeaturedProperty: featuredProp
                }
                modifyPlanApi(currentPlan?.id, modifyData).then((response) => {
                    dispatch(setCurrentPackage({ userPackage: response }))
                    navigate(nameBasedProtectedRoutes?.package?.path)
                    if (response?.paymentResult === "SUCCESS") {
                        toast.success(response?.currentOrder?.paymentNote)
                    } else {
                        toast.error(response?.currentOrder?.paymentNote)
                    }
                })
                    .catch((error) => {
                        setLoading(false);
                        toast.error(
                            error?.response?.data?.error_description
                                ? error?.response?.data?.error_description
                                : error?.response?.data?.message ?? error?.message
                        );
                    }).finally(() => {
                        setLoading(false)
                    });
            } else {
                setLoading(false)
                toast.error(t("PLEASE MODIFY FEATURED PROPERTY OR VIRTUAL TOUR BEFORE PAYMENT"))
            }
        }
        else if (isUpgradePlan === true) {
            const upgradeData = {
                planId: planDetails?.id,
                validityType: planDetails?.validityType,
                customNoOf3dViewUpload: virtualTourCount,
                customNoOfActiveFeaturedProperty: featuredProp
            }
            upgradePlanApi(currentPlan?.id, upgradeData).then((response) => {
                dispatch(setCurrentPackage({ userPackage: response }))
                navigate(nameBasedProtectedRoutes?.package?.path)
                toast.success(response?.currentOrder?.paymentNote)
            })
                .catch((error) => {
                    setLoading(false);
                    toast.error(
                        error?.response?.data?.error_description
                            ? error?.response?.data?.error_description
                            : error?.response?.data?.message ?? error?.message
                    );
                }).finally(() => {
                    setLoading(false)
                });
        }
        else {
            setLoading(false)
            navigate(nameBasedProtectedRoutes?.payment?.path + "/" + planDetails?.id, { state: { price: planPrice, noOf3dViewUpload: virtualTourCount ?? 0, noOfFeaturedProperty: featuredProp ?? 0, validityType: planDetails?.validityType } })
        }
    }
    return (
        <div className="pricing_packages active">
            <LoaderMu loading={loading} />
            <div className="heading row mb20">
                <div className='col'>
                    {/* <small>(Your Plan)</small> */}
                    <h4 className="package_title">{planDetails?.planName} </h4>
                    <h1 className="text2" >{planPrice ?? "Free"}  {t('CURRENCY SYMBOL')} </h1>
                    <p className="text">{planDetails?.validityType === 'YEARLY' ? t('PER YEAR') : t('PER MONTH')}</p>
                </div>
                <div className='col-auto'>
                    <img src={pricingIcon1} className="" alt="Package Icon" />
                </div>
            </div>
            <div className="details">
                <p className="text mb35">{t("STANDARD LISTING SUBMISSION, ACTIVE FOR 30 DAYS")}</p>
                <div className="list-style1 mb40">
                    <ul>
                        <li><i className="far fa-check text-white bgc-dark fz15"></i>{t("ALL OPERATING SUPPORTED")}</li>
                        <li><i className="far fa-check text-white bgc-dark fz15"></i>
                            {planDetails?.noOfActiveAgent} {t("ACTIVE AGENT")}</li>
                        <li><i className="far fa-check text-white bgc-dark fz15"></i>
                            {planDetails?.noOfActiveProperty} {t("ACTIVE PROPERTIES")}</li>
                        <li><i className="far fa-check text-white bgc-dark fz15"></i>
                            {planDetails?.noOfActiveFeaturedProperty} {t("FEATURED PROPERTY")}</li>
                        <li><i className="far fa-check text-white bgc-dark fz15"></i>
                            {planDetails?.noOf3dViewUpload} {t("VIRTUAL TOUR")}</li>
                        <li><i className="far fa-check text-white bgc-dark fz15"></i>{t("VIEW PROPERTY STATISTICS")}</li>
                        <li><i className="far fa-check text-white bgc-dark fz15"></i>{t("NOTIFICATIONS")}</li>
                        <li><i className="far fa-check text-white bgc-dark fz15"></i>{t("24/7 FULL SUPPORT")}</li>
                    </ul>
                </div>
                {
                    planDetails?.maxNoOfActiveFeaturedProperty && planDetails?.maxNoOfActiveFeaturedProperty !== planDetails?.noOfActiveFeaturedProperty ?
                        <div>
                            <RangeSlider
                                label="Featured Property"
                                name="featureProperty"
                                id="featureProperty"
                                handleChange={handleFeaturePropertyChange}
                                value={featuredProp}
                                min={planDetails?.noOfActiveFeaturedProperty}
                                max={planDetails?.maxNoOfActiveFeaturedProperty}
                                additionalCost={currentPackage?.planData?.additionalCostPerFeaturedProperty}
                            />
                        </div>
                        : ""
                }
                {
                    planDetails?.maxNoOf3dViewUpload && planDetails?.maxNoOf3dViewUpload !== planDetails?.noOf3dViewUpload ?
                        <div>
                            <RangeSlider
                                label="Virtual Tour"
                                name="virtualTour"
                                id="virtualTour"
                                handleChange={handleVirtualTourChange}
                                value={virtualTourCount}
                                min={planDetails?.noOf3dViewUpload}
                                max={planDetails?.maxNoOf3dViewUpload}
                                additionalCost={currentPackage?.planData?.additionalCostPer3dViewUpload}
                            />
                        </div>
                        : ""
                }
                <div className="d-grid">
                    {
                        isModifyPlan === true ?
                            <Popconfirm
                                title="Modify plan"
                                description={<span>{t("ARE YOU SURE YOU WANT TO MODIFY THIS PLAN?")}</span>}
                                onConfirm={handlePayButtonClick}
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            >
                                <button className="ud-btn btn-thm-border text-thm" >{t("PAY")} <i className="fal fa-arrow-right-long"></i></button>
                            </Popconfirm> :

                            isUpgradePlan === true ?
                                <Popconfirm
                                    title="Upgrade plan"
                                    description={<span>{t("ARE YOU SURE YOU WANT TO UPGRADE THIS PLAN?")}</span>}
                                    onConfirm={handlePayButtonClick}
                                    // onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <button className="ud-btn btn-thm-border text-thm" >{t("PAY")} <i className="fal fa-arrow-right-long"></i></button>
                                </Popconfirm> :
                                <button onClick={handlePayButtonClick} className="ud-btn btn-thm-border text-thm" >{t("PAY")} <i className="fal fa-arrow-right-long"></i></button>
                    }
                </div>
            </div>
        </div>
    )
}
export default PlanCard
