import { Modal } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'

const PropertyConfirmationModal = ({ confirmModal, setConfirmModal, setUnderConstruction, handleCancel }) => {

  const { t } = useTranslation()

  const { i18n } = useTranslation()

  const cookies = new Cookies()

  const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

  // HANDLE PROCEED BUTTON
  const handleProceed = () => {
    setConfirmModal(false)
    setUnderConstruction(false)
  }
  // HANDLE UNDER CONSTRUCTION BUTTON
  const handleUnderConstruction = () => {
    setConfirmModal(false)
    setUnderConstruction(true)
  }

  return (
    <Modal
      title=<p className="fw-bold text-thm2">{t("DO YOU HAVE THE TITLE DEED FOR YOUR PROPERTY?")}</p>
      open={confirmModal}
      maskClosable={false}
      onOk={() => setConfirmModal(false)}
      footer={false}
      className={`${currentLanguage === 'ar' ? 'direction-rtl' : ''}`}
      onCancel={handleCancel}>
      <div dir={currentLanguage === "ar" ? 'rtl' : 'ltr'}>
        <ol>
          <li>
            <p className="fw500 fs-6">1. {t("IF YES, PLEASE PROCEED.")}</p></li>
          <li>
            <p className="fw500 fs-6">2. {t("IF NO, PLEASE NOTE THAT YOUR PROPERTY IS STILL UNDER CONSTRUCTION.")}</p>
          </li>
        </ol>
        <div>
          <button className="ud-btn btn-dark me-2" onClick={handleProceed}>
            {t("PROCEED")}
          </button>
          <button className="ud-btn btn-thm" onClick={handleUnderConstruction}>
            {t("UNDER CONSTRUCTION")}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default PropertyConfirmationModal