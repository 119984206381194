import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Pagination from "@mui/material/Pagination";
import { toast } from 'react-toastify';
import { subscriptionListApi, } from 'services/packageService';
import {  PackageEndDateTemplate, PackageNameTemplate, PackageStartDateTemplate, PackageStatusTemplate, PackageTypeTemplate, SubscriptionId } from '../../components/package/RowTemplates';
import { useLocation, useNavigate } from 'react-router-dom';
import { nameBasedProtectedRoutes } from 'router/protected';
import { useSelector } from 'react-redux';


const SubscriptionList = () => {

  const { t } = useTranslation()

  const location = useLocation();
  const params = new URLSearchParams(location?.search)
  const search = params.get("search") ?? ''
  const [currentPage, setCurrentPage] = useState(params.get("page") ? parseInt(params.get("page")) : 1)

  const currentPackage = useSelector((state)=> state?.userPackage)

  const navigate = useNavigate()

  // const [sortValue, setSortValue] = useState("")
  const [loading, setLoading] = useState(false)
  const [subscriptionListData, setSubscriptionListData] = useState([])
  
  // PAGINATION STATES
  const [pageSize, setPageSize] = useState(5)
  const [totalRowCount, setTotalRowCount] = useState(0)
  
  const pageOffset = (parseInt(currentPage) - 1) * pageSize + 1
// HANDLE PAGE CHAGE
const handlePageChange = (event, value) => {
  setCurrentPage(value)
  navigate(`${nameBasedProtectedRoutes?.package?.path}?page=${value}${search !== '' ? '&search=' + search : ''}`)
}


  // FETCH PACKAGE LISTING DATA
  const fetchListingData = () => {
    setLoading(true)
    let params = {
      size: pageSize,
      sort : 'createdAt,desc'
    }

    if (currentPage) {
      params.page = currentPage - 1
    }
    // GET TRANSACTION LIST // ALL PREVIOUS PACKAGES
    subscriptionListApi(params).then(response => {
      setSubscriptionListData(response?.data ?? [])
      setTotalRowCount(response?.headers["x-total-count"])
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(error?.response?.data?.error_description
        ? error?.response?.data?.error_description
        : error?.response?.data?.message)
    })

  }

  // CAL FUNCTION ON COMPONENT RENDER OR CURRENT PAGE CHANGE
  useEffect(() => {
    fetchListingData()
  }, [currentPage , currentPackage])


  // HANDLE UNSUBSCRIBE
  // const handleUnsubscribe = (subscriptionId) => {
  //   setLoading(true)
  //   // CALLING UNSUBSCRIBE API 
  //   unsubscribedApi(subscriptionId).then((response) => {
  //     setLoading(false)
  //     toast.success(t("UNSUBSCRIBE SUCCESSFUL"))
  //     fetchListingData() // CALLING LISTING DATA FOR UPDATING LIST
  //   }).catch((error) => {
  //     setLoading(false)
  //     toast.error(
  //       error?.response?.data?.error_description
  //         ? error?.response?.data?.error_description
  //         : error?.response?.data?.message ?? error?.message
  //     );
  //   })
  // }
  return (
    <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
      <div className="packages_table table-responsive">
        <div className="packages_table table-responsive">
          {/* RENDER PRIME REACT DATATABLE */}
          <DataTable
            value={subscriptionListData ?? []}
            tableClassName="table-style3 table at-savesearch"
            cellClassName="vam"
          >
          {/* SUBSCRIPTION REF ID */}
           <Column
              field="subscriptionRefId"
              header={t("REFERENCE ID")}
              body ={(row)=> <SubscriptionId row={row}/>}
            ></Column>
            {/* CURRENT PACKAGE */}
            <Column
              field="planName"
              header={t("CURRENT PACKAGE")}
              body ={(row)=> <PackageNameTemplate row={row}/>}
            ></Column>
            {/* VALIDITY TYPE */}
            <Column
              field="validityType"
              header={t("TYPE")}
              body={(row) => <PackageTypeTemplate row={row} />}
            ></Column>
            {/* PACKAGE  STATUS*/}
            <Column
              field="active"
              header={t("MY PACKAGE")}
              body={(row) => <PackageStatusTemplate row={row} />}
            ></Column>
            {/* PACKAGE AMOUNT */}
            <Column field="price" header={t("AMOUNT")}
              //body={{(row) => <span> row.price +' '+ t('CURRENCY SYMBOL')}</span>}
              body={(row) => <div className="packageStatusCss"><span>{row?.planData?.planAmount && (row?.planData?.planAmount).toFixed(2) + ' ' + t('CURRENCY SYMBOL')}</span></div>}
            ></Column>
            {/* SUBSCRIPTION START DATE */}
            <Column
              field="subscriptionStartDate"
              header={t("DATE STARTED")}
              body={(row) => <PackageStartDateTemplate row={row} />}
            ></Column>
            {/* SUBSCRIPTION END DATE */}
            <Column
              field="subscriptionEndDate"
              header={t("EXPIRY DATE")}
              body={(row) => <PackageEndDateTemplate row={row} />}
            ></Column>
            {/* SUBSCRIPTION CANCEL DATE */}
            {/* <Column
              field="subscriptionCancelDate"
              header={t("CANCEL DATE")}
              body={(row) => <CancelDateTemplate row={row} />}
            ></Column> */}
            {/* UNSUBSCRIBE */}
            {/* <Column
              field="id"
              body={(row) => row.active == true ? <UnSubscriptionTemplate row={row} unsubscribe={handleUnsubscribe} /> : ''}
            ></Column> */}
          </DataTable>
          {/* PAGINATION */}
          <div className="mbp_pagination text-center mt30 dataTable_pagination">
            <Pagination count={Math.ceil(totalRowCount / pageSize)} siblingCount={1} page={currentPage} className="d-inline-block" size="large" onChange={(event, value) => handlePageChange(event, value)} />
            {
              totalRowCount < pageSize ?
                <p className="mt10 pagination_page_count text-center">
                  {pageOffset} – {totalRowCount} {t("OF")} {totalRowCount} {t("PACKAGE AVAILABLE")}
                </p> : <p className="mt10 pagination_page_count text-center">
                  {pageOffset} – {currentPage * pageSize} {t("OF")} {totalRowCount}+ {t("PACKAGE AVAILABLE")}
                </p>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionList