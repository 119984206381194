import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "./AgencySlice";
import userPackageReducer from "./UserPackageSlice";

const rootReducer = combineReducers({
  user: userReducer,
  userPackage: userPackageReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
