export const SIGNUP_API = `/v1/user/sign-up`;
export const LOGIN_API = `/v1/sign-in`;
export const VERIFICATION_EMAIL_API = `/v1/account/agent/activate`;
export const VERIFICATION_ACCOUNT_API = `/v1/account/activate`;
export const FORGOT_API = `/v1/account/reset-password/init`;
export const RESET_API = `/v1/account/reset-password/finish`;
export const REFRESH_TOKEN = `/auth/refresh-tokens`;
export const VERIFICATION = `/auth/mfa`;
export const LOGOUT = `/auth/logout`;
export const RESEND_CODE = `/auth/mfa-resend`;
export const SOCIAL_SIGNUP_API = `/v1/user/social-sign-up`
export const SOCIAL_SIGNIN_API = `/v1/user/social-sign-in`


export const AGENCY_SUMMARY_LIST_API = `/v1/agency/agency-summary-list`;
export const GET_DASHBOARD_COUNT_API = `/dashboard/get-dashboard-count`;
export const GET_CMS_LIST_API = `/dashboard/cms/list`;
export const ADD_CMS_API = `/dashboard/cms/submit`;
export const VIEW_CMS_API = `/dashboard/cms/view`;
export const GET_CONTACTUS_LIST_API = `/dashboard/contact/list`;
export const REPLY_CONTACTUS_API = `/dashboard/contact/reply`;
export const VIEW_CONTACTUS_API = `/dashboard/contact/view`;
export const GET_QOUTES_LIST_API = `/dashboard/qoutes/list`;
export const IMAGE_UPLOAD_API = `/user/image/upload`
export const VIDEO_UPLOAD_API = `/user/video/upload`

export const CHANGE_PASSWORD_API = `/v1/account/change-password`

export const UPDATE_USER_PROFILE_API = `/v1/agency/agent/edit-profile`
export const GET_USER_DATA_API = `/v1/agency/agent/profile` // GET DATA FOR AGENT OR AGENCY [LOGGED IN]
export const UPLOAD_USER_PROFILE_PIC = `/v1/agency/agent/upload-profile`
export const DELETE_USER_PROFILE_PIC = `/v1/agency/agent/delete-profile-picture`
export const AGENT_SIGNUP_API = `/v1/agency/agent-sign-up`
export const AGENCY_SIGNUP_API = `/v1/agency/agency-sign-up`

export const GENERAL_INFO_API = '/v1/user/general-info'

export const AGENT_CREATE_API = `/v1/agency/agent/add`
export const AGENT_LISTING_API = `/v1/agency/agent/list`
export const UPDATE_AGENT_STATUS = `/v1/agency/agent/status`
export const EDIT_AGENT = `/v1/agency/agent/edit`


// AGENCY PROFILE

export const GET_AGENCY_DATA = `/v1/agency/account`
export const UPDATE_AGENCY_PROFILE_SOCIAL_INFO = `/v1/agency/social-info`
export const UPLOAD_AGENCY_LOGO = `/v1/agency/upload-logo`
export const DELETE_AGENCY_LOGO = `/v1/agency/delete-logo`
export const UPDATE_AGENCY_PROFILE = `/v1/agency/account`
export const GET_AGENT_BY_ID = `/v1/agency/agent`
export const DELETE_AGENT_PROFILE_IMG = `/v1/agency/agent/delete-profile-picture/`
export const AGENCY_VERIFICATION_EMAIL = `/v1/agency/send-verification-email`
export const WATERMARK_CONFIGURATION = `/v1/agency/watermark-configuration`


// ADD PROPERTY

// LIST
export const PROPERTY_LIST_API = `/v1/property/agency/property/list`
export const PROPERTY_FEATURED_MARK_API = `/v1/property/agency/property/featured`
export const TOGGLE_EXACT_LOCATION_API = `/v1/property/agency/property/hide-exact-location`

// IDENTIFICATION
export const GOVERNORATE_LIST_API = `/v1/property/governorate`
export const DIRECTORATE_LIST_API = `/v1/property/directorate`
export const VILLAGE_LIST_API = `/v1/property/village/`
export const GET_COORDINATES_API = `/v1/property/agency/property/get-coordinate`
export const PROPERTY_TYPE_LIST_API = `/v1/property/agency/property/property-type-list`
export const PROPERTY_INFO_API = `/v1/property/agency/property/info`
export const SAVE_IDENTIFICATION_API = `/v1/property/agency/property/save-identification`
export const GET_PROPERTY_BY_ID_API = `/v1/property/agency/property`

// UNDER CONSTRUCTION PROPERTY IDENTIFICATION
export const UC_PROPERTY_IDENTIFICATION = `/v1/property/agency/property/save-uc-identification`

// DESCRIPTION AND MEDIA APIS
export const SAVE_DESCRIPTION_DATA_API = `/v1/property/agency/property/save-description`
export const SAVE_MEDIA_DATA_API = `/v1/property/agency/property/save-media`
export const SAVE_FLOOR_PLAN = `/v1/property/agency/property/save-floor-plan`
export const DELETE_FLOOR_PLAN = `/v1/property/agency/property/delete-floor-plan`

// EDIT DESCRIPTION

export const EDIT_DESCRIPTION_API = `/v1/property/agency/property/edit-description`

// PROPERTY DETAILS API
export const PROPERTY_DETAILS_FIELD = `/v1/property/agency/property/property-type`
export const SAVE_PROPERTY_DETAILS = `/v1/property/agency/property/save-detail`

// AMENITIES APIS
export const AMENITIES_LIST_API = `/v1/property/agency/property/amenity`
export const SAVE_AMENITIES_API = `/v1/property/agency/property/amenity`
export const ALL_PLANS_API = `/v1/agency/plan/fetch-all-plan`

export const DELETE_MEDIA = `/v1/property/agency/property/delete-media`
export const MARK_MAIN_IMAGE = `/v1/property/agency/property/mark-main-photo`

// PAYMENT Master Card Section
export const GET_SESSION_ID_API = `/v1/payment/session/`
export const CHANGE_STATUS_API = `/v1/property/agency/property/change-status`
export const GET_PAYMENT_RESPONSE_API = `/v1/payment/result`

// COMMENT API'S
export const ADD_COMMENT_API = `/v1/property/agency/property/add-comment`
export const COMMENT_LIST_API = `/v1/property/agency/property/comment-list`

// PAYMENT API'S
export const GET_PAYMENT_TRANSACTION_API = `/v1/payment/transactions`
export const GET_PAYMENT_SUBSCRIPTION_LIST_API = `/v1/package/subscribed-list`
export const GET_UNSUBSCRIBED_API = `/v1/package/unsubscribe` // v1/package/unsubscribed/{subscribedId}
export const CARD_PAYMENT = `/v1/payment/session` // v1/package/unsubscribed/{subscribedId}
export const PACKAGE_DETAIL_API = `/v1/user/activated-package/detail`
export const PACKAGE_PLAN_LIMIT_API = `/v1/user/package-plan/limit`

// PAYMENT CARD
export const ADD_PAYMENT_SOURCE = `/v1/payment/add-source`
export const PAYMENT_SOURCE_LIST = `/v1/payment/source-list`
export const DELETE_PAYMENT_SOURCE = `/v1/payment/delete-source`
export const MARK_SOURCE_DEFAULT = `/v1/payment/mark-source-as-default`


// CURRENT SUBSCRIPTION
export const CURRENT_SUBSCRIPTION = `/v1/package/current-subscription`
// NEW PAYMENT
export const NEW_SUBSCRIPTION = `/v1/package/subscribe-new`
export const PAY_CIT = `/v1/package/pay-cit`
// MODIFY PLAN
export const MODIFY_PLAN = `/v1/package/modify-new`
// UPGRADE PLAN 
export const UPGRADE_PLAN = `/v1/package/upgrade-new`
// CANCEL PLAN 
export const CANCEL_PLAN = '/v1/package/cancel'
// RETRY PAYMENT 
export const RETRY_PAYMENT = `/v1/package/retry-payment-new`
// SUBSCRIPTION LIST 
export const SUBSCRIPTION_LIST = `/v1/package/subscription-list-new`
// ORDER LIST
export const ORDER_LIST = `/v1/package/subscription-order-list-new`
// AUTO RENEW TOGGLE
export const AUTO_RENEW_TOGGLE = `/v1/package/subscription-auto-renew-toggle`

// SEND OTP ON MOBILE
export const SEND_OTP = `/v1/account/phone/send-otp`

// VERIFY OTP
export const VERIFY_OTP = `/v1/account/phone/verify-otp`

//AGENT PROFILE EMAIL VERIFICATION API
export const EMAIL_VERIFY_API = `/v1/agency/agent/send-verification-email`

// DASHBOARD API'S
export const RECENT_ACTIVITIES_API = `/v1/agency/activity-log/activity-list`
export const AUDIT_TRAIL_API = `/v1/agency/activity-log/audit-trail-list`

// AGENCY DASHBOARD

export const AGENCY_STATS_API = `/v1/agency/stat/main`
export const PACKAGE_STATS_API = `/v1/agency/stat/package-usage`
export const MONTH_WISE_PROP_AGENCY = `/v1/agency/stat/month-wise-property`
export const AGENCY_GOVT_API = `/v1/agency/stat/governorate-property`
export const AGENCY_PROPERTY_TYPE = `/v1/agency/stat/property-type`
export const TOP_TEN_AGENT_CHART = `/v1/agency/stat/top-ten-agents`
export const MOST_VIEWED_PROPERTY_AGENCY = `/v1/agency/stat/most-viewed-property`
export const AGENT_WISE_PROPERTY = `/v1/agency/stat/agent-wise-property`

export const PROPERTY_TYPE_VIEW_AGENCY = `/v1/agency/stat/property-type-view`
// AGENT DASHBOARD

export const AGENT_STATS_API = `/v1/agency/stat/agent/main`
export const MONTH_WISE_PROP_AGENT = `/v1/agency/stat/agent/month-wise-property`
export const AGENT_GOVT_API = `/v1/agency/stat/agent/governorate-property`
export const AGENT_PROPERTY_TYPE = `/v1/agency/stat/agent/property-type`
export const MOST_VIEWED_PROPERTY_AGENT = `/v1/agency/stat/agent/most-viewed-property`
export const PROPERTY_TYPE_VIEW_AGENT = `/v1/agency/stat/agent/property-type-view`


// AGENCY REPORT 
export const AGENT_REPORT_DOWNLOAD = `/v1/agency/report/download-agent`
export const LOCATION_REPORT_DOWNLOAD = `/v1/agency/report/download-location`
export const PROPERTY_REPORT_DOWNLOAD = `/v1/agency/report/download-property`

export const VILLAGE_SEARCH_API = `/v1/property/village-search`

