import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

function ScrollToHome() {

    const [scrollTop, setScrollTop] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 100) {
            setScrollTop(true);
          } else {
            setScrollTop(false);
          }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
    
    const handleScrollToTop = (event) => {
        event.preventDefault();
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      };
    
  return (
    <Link
    className={`scrollToHome ${scrollTop ? "show" : ""}`}
    to="#"
    onClick={(e) => {
      handleScrollToTop(e);
    }}
  >
    <i className="fas fa-angle-up" />
  </Link>
  )
}

export default ScrollToHome
