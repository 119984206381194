import React, { useEffect, useState } from "react";
import AgencyInputText from "../../common/AgencyInputText";
import AgencyTextArea from "../../common/AgencyTextArea";
import AgencySelect from "../../common/AgencySelect";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { descriptionFormSchema } from "validations/ValidationSchema";
import { PropertyInfoApi, editDescriptionData } from "services/propertyService";
import LoaderMu from "components/common/LoaderMu";
import { toast } from "react-toastify";
import MediaForm from "../addproperty/Description_media/MediaForm";
import FloorPlansForm from "../floorPlans";
import { Link, useNavigate } from "react-router-dom";
import { MdRemoveRedEye } from "react-icons/md";
import AgencyFileUpload from "components/common/AgencyFileUpload";
import { Radio } from "antd";
import VideoDisplayModal from "components/common/VideoDisplayModal";
export const Description_media = ({ property_id, propertyData, getPropertyData }) => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [videoSrcOptions, setVideoSrcOptions] = useState([])
  const [isVdoModalOpen, setVideoModalOpn] = useState(false)
  const [isFullVdoModalOpen, setFullVideoModalOpn] = useState(false)
  const [initialValues, setInitialValues] = useState({
    // DESCRIPTION & MEDIA
    title_en: '',
    title_ar: '',
    description_en: '',
    description_ar: '',
    embeddedVideoSource: '',
    embeddedVideoUrl: '',
    embeddedVideoSourceFull: '',
    embeddedVideoUrlFull: '',
    virtualTourUrl: '',
    browseVideo: '',
    browseVideoUrl:'',
    browseVideoFull: '',
    browseVideoFullUrl : '',
    videoSource: 'NONE',
    videoSourceFull: '',

  })

  // SUBMIT PROPERTY MEDIA FORM 
  const handleSubmit = (values) => {
    setLoading(true)
    // CHECK IF IMAGES ARE UPLOADED OR NOT 
    if (propertyData?.propertyImages && propertyData?.propertyImages?.length < 1) {
      toast.error(t('AT LEAST ONE MEDIA IS REQUIRED'))
    } else {
      // const formData = {
      //   id: property_id,
      //   title: {
      //     en: values?.title_en,
      //     ar: values?.title_ar
      //   },
      //   description: {
      //     en: values?.description_en,
      //     ar: values?.description_ar
      //   },
      //   embeddedVideoSource: values?.embeddedVideoSource !== '' ? values?.embeddedVideoSource : null,
      //   embeddedVideoUrl: values?.embeddedVideoUrl !== '' ? values?.embeddedVideoUrl : null,
      //   virtualTourUrl: values?.virtualTourUrl !== '' ? values?.virtualTourUrl : null
      // }
      const formData = new FormData();

      // Append individual properties
      formData.append('id', property_id);

      // Handle nested 'title' object
      formData.append('title[\'en\']', values?.title_en || '');
      formData.append('title[\'ar\']', values?.title_ar || '');

      // Handle nested 'description' object
      formData.append('description[\'en\']', values?.description_en || '');
      formData.append('description[\'ar\']', values?.description_ar || '');


      if (values?.virtualTourUrl && values?.virtualTourUrl !== '') {
        formData.append('virtualTourUrl', values?.virtualTourUrl);
      }


      // VIDEO SOURCES
      if (values?.videoSource && values?.videoSource !== 'NONE') {
        formData.append('videoSource', values?.videoSource)
      }

      if (values?.videoSourceFull && values?.videoSourceFull !== 'NONE') {
        formData.append('videoSourceFull', values?.videoSourceFull)
      }




      // IF EMBEDDED VIDEOS URL'S INSERTED BY USER
      if (values?.embeddedVideoSource && values?.embeddedVideoSource !== '') {
        formData.append('embeddedVideoSource', values?.embeddedVideoSource);
      }
      if (values?.embeddedVideoUrl && values?.embeddedVideoUrl !== '') {
        formData.append('embeddedVideoUrl', values?.embeddedVideoUrl);
      }
      if (values?.embeddedVideoSourceFull && values?.embeddedVideoSourceFull !== '') {
        formData.append('embeddedVideoSourceFull', values?.embeddedVideoSourceFull);
      }
      if (values?.embeddedVideoUrlFull && values?.embeddedVideoUrlFull !== '') {
        formData.append('embeddedVideoUrlFull', values?.embeddedVideoUrlFull);
      }


      //  IF VIDEO FILES UPLOADED BY USER
      if (values?.browseVideo && values?.browseVideo !== "") {
        formData.append('browseVideo', values?.browseVideo);
      }
      if (values?.browseVideoFull && values?.browseVideoFull !== "") {
        formData.append('browseVideoFull', values?.browseVideoFull);
      }

      // CALL API FOR EDIT DESCRIPTION DATA
      editDescriptionData(formData).then((response) => {
        getPropertyData()
        // navigate(nameBasedProtectedRoutes?.propertyList?.path)
        // NAVIGATE BACK TO PREVIOUS ROUTE
        navigate(-1)
        toast.success(response?.message ?? 'Description Data saved')
      }).catch((error) => {
        toast.error(error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message)
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    if (property_id) {
      setInitialValues({
        // DESCRIPTION & MEDIA
        title_en: propertyData?.title?.en ?? '',
        title_ar: propertyData?.title?.ar ?? '',
        description_en: propertyData?.description?.en ?? '',
        description_ar: propertyData?.description?.ar ?? '',
        embeddedVideoSource: propertyData?.embeddedVideoSource ?? '',
        embeddedVideoUrl: propertyData?.embeddedVideoUrl ?? '',
        embeddedVideoSourceFull: propertyData?.embeddedVideoSourceFull ?? '',
        embeddedVideoUrlFull: propertyData?.embeddedVideoUrlFull ?? '',
        virtualTourUrl: propertyData?.virtualTourUrl ?? '',
        browseVideo: '',
        browseVideoUrl: propertyData?.video,
        browseVideoFull: '',
        browseVideoFullUrl: propertyData?.videoFull,
        videoSource: propertyData?.videoSource && propertyData?.videoSource !== '' ? propertyData?.videoSource : 'NONE',
        videoSourceFull: propertyData?.videoSourceFull && propertyData?.videoSourceFull !== '' ? propertyData?.videoSourceFull : 'NONE',
        virtualTourUrl: propertyData?.virtualTourUrl ?? null,
      })
    }
  }, [propertyData, property_id])


  // FUNCTION FOR FETCH PROPERTY INFO
  const getPropertyInfo = () => {
    setLoading(true)
    PropertyInfoApi().then(response => {
      const videoSource = Object.keys(response?.videoSource ?? {}).map((key) => {
        return { value: key, label: response?.videoSource[key] }
      });
      setVideoSrcOptions(videoSource)
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(error?.response?.data?.error_description
        ? error?.response?.data?.error_description
        : error?.response?.data?.message)
    })
  }

  useEffect(() => {
    getPropertyInfo()
  }, [])

  return (
    <div>
      <LoaderMu loading={loading} />
      <div className="ps-widget bgc-white bdrs12 p30 overflow-hidden position-relative">
        <h4 className="title fz17 mb30">{t('PROPERTY DESCRIPTION')}</h4>
        <Formik
          validationSchema={descriptionFormSchema}
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            handleSubmit(values);
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            setFieldValue,
            setFieldError,
            touched,
            isValid,
            errors,
          }) => (
            <Form>
              <div>
                <div className="form-style1">
                  <div className="row">
                    <div className="col-sm-12">
                      <AgencyInputText
                        type="text"
                        label={t('PROPERTY TITLE (ENGLISH)')}
                        id="title_en"
                        placeholder={t('PROPERTY TITLE (ENGLISH)')}
                        name="title_en"
                        multiline={true}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values?.title_en ?? ''}
                        className={`form-control ${touched?.title_en &&
                          errors?.title_en
                          ? "error"
                          : ""
                          }`}
                      />
                    </div>
                    <div className="col-sm-12">
                      <AgencyInputText
                        type="text"
                        label={t('PROPERTY TITLE (ARABIC)')}
                        id="title_ar"
                        placeholder={t('PROPERTY TITLE (ARABIC)')}
                        name="title_ar"
                        multiline={true}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values?.title_ar ?? ''}
                        dir={"rtl"}
                        className={`form-control ${touched?.title_ar &&
                          errors?.title_ar
                          ? "error"
                          : ""
                          }`}
                      />
                    </div>
                    <div className="col-sm-12">
                      <AgencyTextArea
                        type="text"
                        rows={4}
                        cols={30}
                        label={t("DESCRIPTION (ENGLISH)")}
                        id="description_en"
                        placeholder={t('THERE ARE MANY VARIATIONS OF PASSAGES.')}
                        name="description_en"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values?.description_en ?? ''}
                        className={`${touched?.description_en &&
                          errors?.description_en
                          ? "error"
                          : ""
                          }`}
                      />
                    </div>
                    <div className="col-sm-12">
                      <AgencyTextArea
                        type="text"
                        rows={4}
                        cols={30}
                        label={t("DESCRIPTION (ARABIC)")}
                        id="description_ar"
                        placeholder={t('THERE ARE MANY VARIATIONS OF PASSAGES.')}
                        name="description_ar"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values?.description_ar ?? ''}
                        dir={"rtl"}
                        className={`${touched?.description_ar &&
                          errors?.description_ar
                          ? "error"
                          : ""
                          }`}
                      />
                    </div>
                  </div>
                </div>
                {/* FORM FOR UPLOADING MEDIA */}
                <MediaForm property_id={property_id} propertyMedia={propertyData?.propertyImages} getPropertyData={getPropertyData} />
                <div className="form-style1">
                  <h4 className="title fz17 mb30">{t("VIDEO OPTION")}</h4>
                  <div className="row mb20">
                    {/* INTRO VIDEO */}
                    <div className="col-md-6 border p-4">
                      <h6> {t("INTRO VIDEO")}</h6>
                      <div className="row">
                        <div className="col-md-12 mb20">
                          <Radio.Group
                            value={values?.videoSource && values?.videoSource !== '' ? values?.videoSource : 'NONE'}
                            id="videoSource"
                            name="videoSource"
                            onChange={(event) => setFieldValue("videoSource", event?.target?.value)}
                            className={` radioButton ${touched?.title_ar &&
                              errors?.title_ar
                              ? "error"
                              : ""
                              }`}
                          >
                            <Radio
                              key={'none'}
                              value={"NONE"}>
                              None
                            </Radio>
                            {
                              videoSrcOptions && videoSrcOptions?.length > 0 ?
                                videoSrcOptions?.map((src, index) => (
                                  <Radio
                                    key={index + 1}
                                    value={src?.value}>
                                    {src?.label}
                                  </Radio>
                                )) : ""
                            }
                          </Radio.Group>
                        </div>
                      </div>
                      {
                        values?.videoSource === "EMBEDDED" ?
                          <div className="row">
                            <div className="col-12">
                              <AgencySelect
                                label={t("INTRO VIDEO SOURCE")}
                                id="embeddedVideoSource"
                                placeholder={t("SELECT")}
                                name="embeddedVideoSource"
                                handleChange={(value) => setFieldValue("embeddedVideoSource", value)}
                                handleBlur={handleBlur}
                                value={values?.embeddedVideoSource}
                                className={`selectpicker form-control ${touched?.embeddedVideoSource &&
                                  errors?.embeddedVideoSource
                                  ? "error"
                                  : ""
                                  }`}
                                options={[
                                  { value: '', label: 'Select' },
                                  {
                                    value: "YOUTUBE",
                                    label: "Youtube",
                                  },
                                  {
                                    value: "FACEBOOK",
                                    label: "Facebook",
                                  },
                                  {
                                    value: "VIMEO",
                                    label: "Vimeo",
                                  },
                                ]}
                              />
                            </div>
                            <div className="col-12">
                              <AgencyInputText
                                type="text"
                                label={t("INTRO VIDEO URL")}
                                id="embeddedVideoUrl"
                                placeholder={t('INTRO VIDEO URL')}
                                name="embeddedVideoUrl"
                                multiline={true}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values?.embeddedVideoUrl}
                                className={`form-control ${touched?.embeddedVideoUrl && errors?.embeddedVideoUrl
                                  ? "error"
                                  : ""
                                  }`}
                              />
                            </div>
                          </div>
                          : <div className="row">
                            <div className="col-md-12">
                              <AgencyFileUpload
                                id="browseVideo"
                                label={t("INTRO VIDEO")}
                                accept=".mkv,.mp4" // This restricts file selection to video files only.
                                name="browseVideo"
                                labelMargin="mb20"
                                handleChange={(event) => {
                                  const uploadedFile = event?.target?.files[0];

                                  // Define the allowed video MIME types
                                  const allowedFileTypes = [
                                    "video/mp4",    // MP4 videos
                                    "video/mkv",   // mkv videos
                                    "video/x-matroska"//mkv videos
                                  ];

                                  // Check if the uploaded file's type is in the allowedFileTypes array
                                  const isAllowedType = allowedFileTypes.includes(uploadedFile?.type);

                                  // Check if the file size is less than or equal to 100 MB
                                  const maxSizeInBytes = 100 * 1024 * 1024; // 100 MB in bytes
                                  const isFileSizeValid = uploadedFile?.size <= maxSizeInBytes;

                                  // Handle file validation
                                  if (!isAllowedType) {
                                    setFieldError("browseVideo", "You can only upload video files (MP4, MKV)!");
                                  } else if (!isFileSizeValid) {
                                    setFieldError("browseVideo", "The video size must be 100 MB or less!");
                                  } else {
                                    setFieldValue("browseVideo", uploadedFile); // Set the field value if file type and size are valid
                                  }
                                }}
                                error={errors?.browseVideo ?? null}
                                fileName={values?.browseVideo}
                                className={`form-control p15 pl30 ud-btn2 ${touched?.browseVideo && errors?.browseVideo ? "error" : ""}`}
                              />
                              {
                                propertyData?.video ? <button type="button" onClick={() => setVideoModalOpn(true)} className="btn-white px-4 py-2 ud-btn mb20" >
                                  <MdRemoveRedEye size={22} /> {t("INTRO VIDEO")}
                                </button> : ""
                              }
                            </div>
                          </div>
                      }

                    </div>
                    {/* FULL VIDEO */}
                    <div className="col-md-6 border p-4">
                      <h6>{t("FULL VIDEO")}</h6>
                      <div className="row">
                        <div className="col-md-12 mb20">
                          <Radio.Group className="radioButton"
                            value={values?.videoSourceFull && values?.videoSourceFull !== '' ? values?.videoSourceFull : 'NONE'}
                            id="videoSourceFull"
                            name="videoSourceFull"
                            onChange={(event) => setFieldValue("videoSourceFull", event?.target?.value)} >
                            <Radio
                              key={'none'}
                              value={"NONE"}>
                              None
                            </Radio>
                            {
                              videoSrcOptions && videoSrcOptions?.length > 0 ?
                                videoSrcOptions?.map((src, index) => (
                                  <Radio
                                    key={index + 1}
                                    value={src?.value}>
                                    {src?.label}
                                  </Radio>
                                )) : ""
                            }
                          </Radio.Group>
                        </div>
                      </div>
                      {
                        values?.videoSourceFull === "EMBEDDED" ?
                          <div className="row">
                            <div className="col-12">
                              <AgencySelect
                                label={t("FULL VIDEO SOURCE")}
                                id="embeddedVideoSourceFull"
                                placeholder={t("SELECT")}
                                name="embeddedVideoSourceFull"
                                handleChange={(value) => setFieldValue("embeddedVideoSourceFull", value)}
                                handleBlur={handleBlur}
                                value={values?.embeddedVideoSourceFull}
                                className={`selectpicker form-control ${touched?.embeddedVideoSourceFull &&
                                  errors?.embeddedVideoSourceFull
                                  ? "error"
                                  : ""
                                  }`}
                                options={[
                                  { value: '', label: 'Select' },
                                  {
                                    value: "YOUTUBE",
                                    label: t("YOUTUBE"),
                                  },
                                  {
                                    value: "FACEBOOK",
                                    label: t("FACEBOOK"),
                                  },
                                  {
                                    value: "VIMEO",
                                    label: t("VIMEO"),
                                  },
                                ]}
                              />
                            </div>
                            <div className="col-12">
                              <AgencyInputText
                                type="text"
                                label={t("FULL VIDEO URL")}
                                id="embeddedVideoUrlFull"
                                placeholder={t('FULL VIDEO URL')}
                                name="embeddedVideoUrlFull"
                                multiline={true}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values?.embeddedVideoUrlFull}
                                className={`form-control ${touched?.embeddedVideoUrlFull && errors?.embeddedVideoUrlFull
                                  ? "error"
                                  : ""
                                  }`}
                              />
                            </div>
                          </div> : <div className="col-md-12">
                            <AgencyFileUpload
                              id="browseVideoFull"
                              label={t("INTRO VIDEO")}
                              accept=".mkv, .mp4" // This restricts file selection to video files only.
                              name="browseVideoFull"
                              labelMargin="mb20"
                              handleChange={(event) => {
                                const uploadedFile = event?.target?.files[0];

                                // Define the allowed video MIME types
                                const allowedFileTypes = [
                                  "video/mp4",  // MP4 videos
                                  "video/mkv", // MKV videos
                                  "video/x-matroska" //MKV VIDEOS
                                ];

                                // Check if the uploaded file's type is in the allowedFileTypes array
                                const isAllowedType = allowedFileTypes.includes(uploadedFile?.type);

                                // Check if the file size is less than or equal to 100 MB
                                const maxSizeInBytes = 100 * 1024 * 1024; // 100 MB in bytes
                                const isFileSizeValid = uploadedFile?.size <= maxSizeInBytes;

                                // If the file type is not allowed or the size is too large, set an error
                                if (!isAllowedType) {
                                  setFieldError("browseVideoFull", t("VIDEO VALIDATION"));
                                } else if (!isFileSizeValid) {
                                  setFieldError("browseVideoFull", t("VIDEO SIZE"));
                                } else {
                                  setFieldValue("browseVideoFull", uploadedFile); // Set the field value if file type and size are valid
                                }
                              }}
                              error={errors?.browseVideoFull ?? null}
                              fileName={values?.browseVideoFull}
                              className={`form-control p15 pl30 ud-btn2 ${touched?.browseVideoFull && errors?.browseVideoFull ? "error" : ""}`}
                            />

                            {
                              propertyData?.videoFull ?
                                <button type="button" onClick={() => setFullVideoModalOpn(true)} className="btn-white px-4 py-2 ud-btn mb20">
                                  <MdRemoveRedEye size={22} /> {t("FULL VIDEO")}
                                </button> : ""
                            }
                          </div>
                      }
                    </div>
                  </div>
                  <div className="row">
                    <h4 className="title fz17 mb30">{t("VIRTUAL TOUR")}</h4>
                    <div className="col-sm-6 col-xl-12">
                      <AgencyInputText
                        type="text"
                        label={t("VIRTUAL TOUR")}
                        id="virtualTourUrl"
                        placeholder={t("VIRTUAL TOUR")}
                        name="virtualTourUrl"
                        multiline={true}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values?.virtualTourUrl ?? ''}
                        className={`form-control ${touched?.virtualTourUrl &&
                          errors?.virtualTourUrl
                          ? "error"
                          : ""
                          }`}
                      />
                    </div>
                    <div className="col-md-12">
                      <div className="d-sm-flex justify-content-between">
                        <Link
                          to={-1}
                          className="ud-btn btn-white"
                        >
                          {t("CANCEL")}

                        </Link>
                        <button
                          type="submit"
                          className={`ud-btn btn-dark `}
                          onClick={handleSubmit}
                          disabled={loading ?? false}
                        >
                          {t('SAVE')}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>)}
        </Formik>
      </div>
      {/* FLOOR PLANS */}
      <div className="ps-widget bgc-white bdrs12 p30 overflow-hidden position-relative mt-3">
        <FloorPlansForm propertyFloorImages={propertyData?.propertyFloorImages} propertyId={property_id} />
      </div>
      <VideoDisplayModal filePath={propertyData?.video} isFileOpen={isVdoModalOpen} setIsFileOpen={setVideoModalOpn} title="Intro Video Preview" />
      <VideoDisplayModal filePath={propertyData?.videoFull} isFileOpen={isFullVdoModalOpen} setIsFileOpen={setFullVideoModalOpn} title="Full Video Preview" />
    </div>
  );
}

