import React, { useState } from "react";
import defaultImage from "../../assets/images/default-image.jpg";
import { Upload, Popconfirm } from "antd";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { deleteUserProfilePic, uploadUserProfilePic } from "services/userService";
import { removeUserImage, updateUserImage } from "../../redux/AgencySlice";
import LoaderMu from "components/common/LoaderMu";
import { useTranslation } from "react-i18next";

function UploadProfilePic() {

  const {t} = useTranslation()

  const userData = useSelector((state) => state?.user?.userData);


  const dispatch = useDispatch()
  const [loading ,setLoading] = useState(false)
  // HANDLE IMAGE UPLOAD
  const handleUpload = (fileData) => {

    setLoading(true);
    const allowedFileTypes = ["image/jpeg", "image/png"];
    const isAllowedType = allowedFileTypes.includes(fileData?.file?.type);
    const allowedFileSize = 1024 * 1024 * 10
    const isAllowedSize = fileData?.file?.size <= allowedFileSize
    if (!isAllowedType) {
      setLoading(false);
      toast.error("You can only upload JPG or PNG files!");
    } else if (!isAllowedSize) {
      setLoading(false);
      toast.error("You can upload file up to 10mb!");
    } else {
      let formData = new FormData();
      formData.append("profilePicture", fileData?.file);
      uploadUserProfilePic(formData)
        .then((response) => {
          setLoading(false);
          dispatch(updateUserImage({ imageUrl: response?.imageUrl }));
          toast.success("Profile image updated!");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(
            error?.response?.data?.error_description
              ? error?.response?.data?.error_description
              : error?.response?.data?.message ?? error?.message
          );
        });
    }
  };
  // ON PROFILE DELETE
  const handleDelete = () => {
    setLoading(true);
    deleteUserProfilePic()
      .then((response) => {
        setLoading(false);
        dispatch(removeUserImage());
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error.response.data.error_description
            ? error.response.data.error_description
            : error.response.data?.message
        );
      });
  };
  return (
    <div className="col-xl-7">
    <LoaderMu loading={loading}/>
      <div className="profile-box position-relative d-md-flex align-items-end mb50">
        {userData?.imageUrl && userData?.imageUrl !== "" ? (
          <div className="profile-img position-relative overflow-hidden bdrs12 mb20-sm ">
            <img
              src={userData?.imageUrl}
              className="mxh-250"
              alt="Profile"
            />
            {/* w-100 */}
            <Popconfirm
              title={t("DELETE THE IMAGE")}
              description={t("ARE YOU SURE TO DELETE PROFILE PICTURE?")}
              onConfirm={handleDelete}
              okText={t("YES")}
              cancelText={t("NO")}
            >
              <button className="tag-del border-none">
                <span className="fas fa-trash-can" />
              </button>
            </Popconfirm>
          </div>
        ) : (
          <img
            src={defaultImage}
            className="mxh-250"
            alt="Profile"
          />
        )}
        <div className="profile-content ml30 ml0-sm">
          <Upload
            name="profilePicture"
            onChange={handleUpload}
            beforeUpload={() => false}
            showUploadList={false}
            accept=".jpg, .jpeg, .png"
          >
            <button className="btn ud-btn btn-white2 mb30">
              {t("UPLOAD PROFILE PICTURE")} <i className="fal fa-arrow-right-long" />
            </button>
          </Upload>
          <p className="text">{t("JPEG OR PNG FORMAT")}</p>
        </div>
      </div>
    </div>
  );
}

export default UploadProfilePic;
