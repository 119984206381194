import { Modal } from 'antd';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import InputPhoneCode from '../common/InputPhoneCode';
import { phoneNoValidationSchema } from '../../validations/ValidationSchema';
import { sendOTPapi, verifyOTPapi } from '../../services/userService';
import { toast } from 'react-toastify';
import Timer from '../timer/Timer';

import { useDispatch } from 'react-redux';
import AgencyInputText from 'components/common/AgencyInputText';
import { updateUserPhoneNO } from '../../redux/AgencySlice';
import Cookies from 'universal-cookie';

const PhoneVerifyModal = ({ openModal, setOpenModal, otpEntityType, fetchVerifiedData }) => {

	// const [isOtpSend]

	const { t } = useTranslation()

	const { i18n } = useTranslation()
	const cookies = new Cookies()

	const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

	const [isOTPSend, setIsOTPSend] = useState(false)

	const [isTimerRunning, setIsTimerRunning] = useState(false)

	const dispatch = useDispatch()

	const [data, setData] = useState({
		phoneNumber: '',
		countryCode: '+962',
	})

	// const handleOk = () => {
	// 	setOpenModal(false);
	// };
	const handleCancel = () => {
		setOpenModal(false);
	};

	// HANDLE SEND OTP FORM
	const handleSendOtp = (values, actions) => {

		if (isOTPSend && values?.otp) {
			// CALL VERIFY OTP HERE
			const formData = {
				countryCode: "+" + values?.countryCode,
				phoneNumber: values?.phoneNumber,
				otpEntityType: otpEntityType ?? "USER",
				otp: values?.otp
			}
			verifyOTPapi(formData).then((response) => {
				toast.success(response?.message)
				actions.resetForm()
				if (otpEntityType === "USER") {
					dispatch(updateUserPhoneNO({ countryCode: values?.countryCode, mobileNumber: values?.phoneNumber, mobileNumberVerified: true }))
				} else {

					if (fetchVerifiedData !== undefined) {
						fetchVerifiedData()
					}
				}

				setOpenModal(false)
				setIsOTPSend(false)
			}).catch((error) => {
				toast.error(
					error?.response?.data?.error_description
						? error?.response?.data?.error_description
						: error?.response?.data?.message ?? error?.message
				);
			})
		} else {
			// CALL SEND OTP HERE
			setData({
				phoneNumber: values?.phoneNumber,
				countryCode: values?.countryCode,
				otp: values?.otp
			})
			const formData = {
				countryCode: "+" + values?.countryCode,
				phoneNumber: values?.phoneNumber,
				otpEntityType: otpEntityType ?? "USER"
			}

			sendOTPapi(formData).then((response) => {
				toast.success(response?.message)
				setIsOTPSend(true)
				setIsTimerRunning(true)
			}).catch((error) => {
				toast.error(
					error?.response?.data?.error_description
						? error?.response?.data?.error_description
						: error?.response?.data?.message ?? error?.message
				);
			})
		}



	}

	useEffect(() => {
		if (isTimerRunning === false) {
			setIsOTPSend(false)
		}
	}, [isTimerRunning])


	return (
		<Modal title="Verify Phone Number"
			open={openModal}
			onCancel={handleCancel}
			footer={false}
			className={`${currentLanguage === 'ar' ? 'direction-rtl' : ''}`}
		>
			{/* SEND OTP FORM */}
			<Formik
				initialValues={{
					phoneNumber: data?.phoneNumber ?? '',
					countryCode: data?.countryCode ?? '+962',
					isOtpSend: isOTPSend ?? false,
					otp: data?.otp ?? ''
				}
				}
				enableReinitialize={true}
				validationSchema={phoneNoValidationSchema}
				onSubmit={(values, actions) => {
					actions.setSubmitting(false);
					handleSendOtp(values, actions);
					// if(values?.countryCode == "972"){
					// 	toast.error("This country code is not allowed!")
					// }else{
					// 	handleSendOtp(values, actions);
					// }

				}}
			>
				{({
					isSubmitting,
					handleSubmit,
					handleChange,
					handleBlur,
					values,
					setFieldValue,
					setFieldError,
					touched,
					isValid,
					errors,
				}) => (
					<Form className="form-style1">
						<div className="row mt-4">
							<div className="col-md-4 col-xl-4">
								<InputPhoneCode
									id="countryCode"
									name="countryCode"
									type="number"
									country="jo"
									label={t("CODE")}
									placeholder={t("CODE")}
									handleChange={(code) => {
										setFieldValue("countryCode", code)
									}
									}
									// handleBlur={handleBlur}
									value={values?.countryCode}
									className={`${touched?.countryCode && errors?.countryCode ? "error" : ""
										}`}
								/>
							</div>
							<div className="col-md-8 col-xl-8">
								<AgencyInputText
									id="phoneNumber"
									label={t("PHONE NUMBER")}
									placeholder={t("YOUR PHONE NUMBER")}
									value={values?.phoneNumber}
									name="phoneNumber"
									handleChange={handleChange}
									handleBlur={handleBlur}
									className={`form-control ${touched?.phoneNumber && errors?.phoneNumber ? "error" : ""
										}`} />
							</div>
						</div>
						{
							isOTPSend === true ?
								<div className="row mt-4">
									<div className="col">
										<AgencyInputText
											type="number"
											id="otp"
											label={t("ENTER 4 DIGIT OTP RECEIVED ON YOUR PHONE")}
											placeholder={t("ENTER OTP")}
											value={values?.otp}
											name="otp"
											handleChange={handleChange}
											handleBlur={handleBlur}
											className={`form-control ${touched?.otp && errors?.otp ? "error" : ""
												}`} />
									</div>
								</div> : ""
						}
						<div className="row">
							<div className="col w-100">
								{
									!isOTPSend ? <button disabled={isSubmitting} type="submit" className="ud-btn btn-dark mb20 w-100" >Send OTP</button> :
										<>
											<button disabled={isSubmitting} type="submit" className="ud-btn btn-dark mb20 w-100" onClick={handleSubmit}>
												Verify OTP
											</button>

											<div className="text-center">
												{
													isTimerRunning ? <Timer isTimerRunning={isTimerRunning} setIsTimerRunning={setIsTimerRunning} text={"Didn't Received OTP : "} /> : ""
												}
											</div>
										</>
								}
							</div>
						</div>

					</Form>
				)}
			</Formik>
		</Modal>
	)
}

export default PhoneVerifyModal