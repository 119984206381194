import AgencyInputText from "components/common/AgencyInputText";
import LoaderMu from "components/common/LoaderMu";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { changePassword } from "services/userService";
import { profileChangePassword } from "validations/ValidationSchema";

function ChangePassword() {
  const {t} = useTranslation()
  const [loading,setLoading] = useState(false)
  const handleSubmit = (values, actions) => {
    setLoading(true)
    const oldPassword = values?.oldPassword;
    const newPassword = values?.newPassword;
    changePassword({
      currentPassword: oldPassword.toString(),
      newPassword: newPassword.toString(),
    })
      .then((response) => {
        setLoading(false);
        actions.resetForm()
        toast.success("Password updated!");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });
  };
  
  return (
    <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
    <LoaderMu loading ={loading}/>
      <h4 className="title fz17 mb30">{t("CHANGE PASSWORD")}</h4>
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={profileChangePassword}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="form-style1">
            <div className="row">
              <div className="col-sm-6 col-xl-4">
                <AgencyInputText
                  type="password"
                  label={t("OLD PASSWORD")}
                  id="oldPassword"
                  placeholder={t("OLD PASSWORD")}
                  name="oldPassword"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.oldPassword}
                  className={`form-control ${
                    touched?.oldPassword && errors?.oldPassword ? "error" : ""
                  }`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-xl-4">
                <AgencyInputText
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  label={t("NEW PASSWORD")}
                  placeholder={t("NEW PASSWORD")}
                  // controlId={"newPassword"}
                  value={values?.newPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  className={`form-control ${
                    touched?.newPassword && errors?.newPassword ? "error" : ""
                  }`}
                />
              </div>
              <div className="col-sm-6 col-xl-4">
                <AgencyInputText
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  label={t("CONFIRM NEW PASSWORD")}
                  placeholder={t("CONFIRM NEW PASSWORD")}
                  // controlId={"confirmPassword"}
                  value={values?.confirmPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  className="form-control"
                />
              </div>
              <div className="col-md-12">
                <div className="text-end">
                  <button type="submit" onClick={handleSubmit} className="ud-btn btn-dark">
                    {t('CHANGE PASSWORD')}
                    <i className="fal fa-arrow-right-long" />
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ChangePassword;
