export const propertyData = [
  {
    id: "1",
    property_title: "",
    details: "",
    price: "",
    publish_date: "",
    status: "",
    view: "",
  },  
];
export const agentData = [
  {
    id: "1",
    name: "Randa Maher",
    phone: "+(962) 79 123 4567",
    date_started: "December 31, 2022",
    status: "Published",
    view: "December 31, 2022",
  },
  {
    id: "2",
    name: "Randa Maher",
    phone: "+(962) 79 123 4567",
    date_started: "December 31, 2022",
    status: "Published",
    view: "December 31, 2022",
  },
  {
    id: "3",
    name: "Randa Maher",
    phone: "+(962) 79 123 4567",
    date_started: "December 31, 2022",
    status: "Published",
    view: "December 31, 2022",
  },
  {
    id: "4",
    name: "Randa Maher",
    phone: "+(962) 79 123 4567",
    date_started: "December 31, 2022",
    status: "Published",
    view: "December 31, 2022",
  },
  {
    id: "5",
    name: "Randa Maher",
    phone: "+(962) 79 123 4567",
    date_started: "December 31, 2022",
    status: "Published",
    view: "December 31, 2022",
  },
  
];
export const governorateOption = [

  {
    value: "amman",
    label: "Amman",
  },
  {
    value: "irbid",
    label: "Irbid",
  },
  {
    value: "zarqa",
    label: "Zarqa",
  },
  {
    value: "jerash",
    label: "Jerash",
  },
  {
    value: "ajloun",
    label: "Ajloun",
  },
  {
    value: "madaba",
    label: "Madaba",
  },
  {
    value: "al_Salt",
    label: "Al Salt",
  },
  {
    value: "aqaba",
    label: "Aqaba",
  },
  {
    value: "fuheis",
    label: "Fuheis",
  },
];
export const amenitiesOptions = [
  {
    value: "terrace",
    label: "Terrace",
  },
  {
    value: "installed_kitchen",
    label: "Installed Kitchen",
  },
  {
    value: "electic_shutters",
    label: "Electric Shutters",
  },
  {
    value: "independent_kitchen",
    label: "Independent Garage",
  },
  {
    value: "laundary_room",
    label: "Laundary Room",
  },
  {
    value: "water_well",
    label: "Water well",
  },
  {
    value: "independent_entrance",
    label: "Independent Entrance",
  },
  {
    value: "maid_room",
    label: "Maid Room",
  },
  {
    value: "solar_heater",
    label: "Solar Heater",
  },
  {
    value: "roof",
    label: "Roof",
  },
  {
    value: "storage_room",
    label: "Storage Room",
  },
  {
    value: "building_janitor",
    label: "Building Janitor",
  },
  {
    value: "central_heating",
    label: "Central Heating",
  },
  {
    value: "walk_in_closets",
    label: "Walk In Closets",
  },
  {
    value: "serviced",
    label: "Serviced",
  },
  {
    value: "air_conditioning",
    label: "Air Conditioning",
  },
  {
    value: "fireplace",
    label: "Fireplace",
  },
  {
    value: "wi_fi",
    label: "Wi-Fi",
  },
  {
    value: "elevator",
    label: "Elevator",
  },
  {
    value: "double_glazed",
    label: "Double Glazed",
  },
  {
    value: "barbeque",
    label: "Barbeque",
  },
];
