import { AGENCY_GOVT_API, AGENCY_PROPERTY_TYPE, AGENCY_STATS_API, AGENT_GOVT_API, AGENT_PROPERTY_TYPE, AGENT_STATS_API, AGENT_WISE_PROPERTY, AUDIT_TRAIL_API, MONTH_WISE_PROP_AGENCY, MONTH_WISE_PROP_AGENT, MOST_VIEWED_PROPERTY_AGENCY, MOST_VIEWED_PROPERTY_AGENT, PACKAGE_STATS_API, PROPERTY_TYPE_VIEW_AGENCY, PROPERTY_TYPE_VIEW_AGENT, RECENT_ACTIVITIES_API, TOP_TEN_AGENT_CHART } from "constants/paths"
import { axios } from "./axios"
import { t } from "i18next"

// RECENT ACTIVITY SERVICE
export const recentActivityApi = async (params) => {
  const response = await axios.get(RECENT_ACTIVITIES_API, { params })
  return response
}

// AUDIT TRAIL SERVICE
export const auditTrailApi = async (params) => {
  const response = await axios.get(AUDIT_TRAIL_API, { params })
  return response
}

// AGENCY DASHBOARD API'S
// AGENCY STATS
export const agencyStatsApi = async (params) => {
  const response = await axios.get(AGENCY_STATS_API, { params })
  return response?.data
}

// PACKAGE USAGE STATS API 
export const packageUsageStats = async () => {
  const response = await axios.get(PACKAGE_STATS_API)
  return response?.data
}
// MONTH WISE PROPERTY CHART
export const monthWisePropAgency = async(params)=>{
  const response = await axios.get(MONTH_WISE_PROP_AGENCY,{params})
  return response?.data
}
// GOVERNORATE CHART API
export const governorateChartAgency = async(params)=>{
  const response = await axios.get(AGENCY_GOVT_API,{params})
  return response?.data
}
// PROPERTY TYPE CHART API
export const propertyTypeChartAgency = async(params)=>{
  const response = await axios.get(AGENCY_PROPERTY_TYPE,{params})
  return response?.data
}
// TOP 10 AGENT CHART
export const topTenAgentsChart = async(params)=>{
  const response = await axios.get(TOP_TEN_AGENT_CHART,{params})
  return response?.data
}
// MOST VIEWED PROPERTIES AGENCY
export const mostViewedPropertiesAgency = async(params)=>{
  const response = await axios.get(MOST_VIEWED_PROPERTY_AGENCY,{params})
  return response?.data
}
// AGENCY WISE PROPERTY DETAILS
export const agentWiseProperties = async(params)=>{
  const response = await axios.get(AGENT_WISE_PROPERTY,{params})
  return response?.data
}
// PROPERTY TYPE VIEW AGENCY
export const propertyTypeViewAgency = async(params)=>{
  const response = await axios.get(PROPERTY_TYPE_VIEW_AGENCY,{params})
  return response?.data
}


// AGENT DASHBOARD API'S
// AGENT STATS
export const agentStatsApi = async (params) => {
  const response = await axios.get(AGENT_STATS_API, { params })
  return response?.data
}
// MONTH WISE PROPERTY CHART
export const monthWisePropAgent = async(params)=>{
  const response = await axios.get(MONTH_WISE_PROP_AGENT,{params})
  return response?.data
}
// GOVERNORATE CHART API
export const governorateChartAgent = async(params)=>{
  const response = await axios.get(AGENT_GOVT_API,{params})
  return response?.data
}
// PROPERTY TYPE CHART API
export const propertyTypeChartAgent = async(params)=>{
  const response = await axios.get(AGENT_PROPERTY_TYPE,{params})
  return response?.data
}

// MOST VIEWED PROPERTIES AGENT
export const mostViewedPropertiesAgent = async(params)=>{
  const response = await axios.get(MOST_VIEWED_PROPERTY_AGENT,{params})
  return response?.data
}
// PROPERTY TYPE VIEW AGENT
export const propertyTypeViewAgent = async(params)=>{
  const response = await axios.get(PROPERTY_TYPE_VIEW_AGENT,{params})
  return response?.data
}



// COMPONENT SERVICE
export const capitalizeAndSeparate = (header) =>{
  // Separate words where uppercase letters are found
  const separated = header.replace(/([a-z])([A-Z])/g, '$1 $2');
  // Capitalize each word
  const converted =  separated.split(" ").map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(" ");
 return t(converted.toUpperCase())
}

// CREATE CSV DATA FORMAT

// User
export const convertToCSV = (data , currentLanguage) => {
  if (!data || data.length === 0) {
    return '';
  }

  // Extracting keys from the first object to use as CSV headers
  // const headers = Object.keys(data[0]);

  const headers = Object.keys(data[0]).map(header => capitalizeAndSeparate(header));
  // console.log(headers)

  const dataHeaders = Object.keys(data[0]);

  // Generating rows for each object
  const rows = data.map(obj => {
    return dataHeaders.map(key => {
      // If the value is an object, convert it to a string


      if (typeof obj[key] === 'object') {
        if(currentLanguage === 'ar'){
          return obj[key].ar || '';
        }else{
          return obj[key].en || '';
        }
       
      }
      return obj[key];
    }).join(',');
  });

  // Joining headers and rows into a CSV string
  return [headers.join(','), ...rows].join('\n');
};

// THIS FUNCTION IS CREATED FOR BOTH ENGLISH AND ARABIC DATA IN SINGLE FILE
// export const convertToCSV = (data) => {
//   if (!data || data.length === 0) {
//     return '';
//   }

//   // Extracting keys from the first object to use as CSV headers
//   const headers = [];
//   const dataHeaders = Object.keys(data[0]);

//   // Generating headers for each object property
//   dataHeaders.forEach(key => {
//     // Check if the value is an object with 'ar' and 'en' keys
//     if (typeof data[0][key] === 'object' && 'ar' in data[0][key] && 'en' in data[0][key]) {
//       headers.push(`${capitalizeAndSeparate(key)}_ar`, `${capitalizeAndSeparate(key)}_en`);
//     } else {
//       headers.push(capitalizeAndSeparate(key));
//     }
//   });

//   // Generating rows for each object
//   const rows = data.map(obj => {
//     return dataHeaders.flatMap(key => {
//       if (typeof obj[key] === 'object' && 'ar' in obj[key] && 'en' in obj[key]) {
//         return [obj[key].ar || '', obj[key].en || ''];
//       }
//       return [obj[key]];
//     }).join(',');
//   });

//   // Joining headers and rows into a CSV string
//   return [headers.join(','), ...rows].join('\n');
// };
