import React from 'react'
import Form from "react-bootstrap/Form"
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

/**
 * Custom Common Select Field
 * @date 5/5/2023 - 12:34:21 PM
 *
 * @param {{ label: any; options: any; value: any; onChange: any; minWidth: any; }} { label, options, value, onChange, minWidth }
 * @returns {*}
 */

export const CustomSelect = ({ label, options, value, onChange, minWidth, name, errorsField, touched, disabled, isHtmlLabel }) => {
  
  return (
    <FormControl variant="standard" sx={{ minWidth: minWidth ? minWidth : '100%' }}>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        labelId="select-label"
        id="select-box"
        value={value}
        onChange={onChange}
        label={label}
        fullWidth
        name={name}
        error={errorsField && touched}
        helperText={errorsField && touched ? errorsField : ""}
        disabled={disabled ? true : false}
        isHtmlLabel={isHtmlLabel ? true : false}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <Form.Control.Feedback type="invalid">
          {errorsField}
        </Form.Control.Feedback>
      
    </FormControl>
  );
}