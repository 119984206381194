
import { Link, useLocation } from "react-router-dom"
import { AgencyNavItems, AgentNavItems } from "./NavItems"
import "./sidebar.scss"
import { useSelector } from "react-redux"
import i18n from "language/i18"
import Cookies from "universal-cookie"


export const Sidebar = () => {

  const cookies = new Cookies()

  const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en';
  const userData = useSelector((state) => state?.user?.userData);

  const location = useLocation();

  const matchPath = (path) => {
    return path === location.pathname;
  }

  // const matchPath = (path) =>{
  //     if(path==location.pathname){
  //       return true
  //     }else{
  //       return false
  //     }
  // }

  return (
    <div className="dashboard__sidebar d-none d-lg-block">
      <div className="dashboard_sidebar_list">
        {
          userData?.authorities?.includes('ROLE_AGENCY_ADMIN') ?
            AgencyNavItems[currentLanguage]?.map((item, index) => {
              return <div key={index + 1} className="sidebar_list_item mb-1">
                {
                // item?.isLink ? <Link to={item?.path} className={`items-center ${item?.isActive ? '-is-active' : ''}`}>
                  item?.isLink ? <Link to={item?.path} 
                  className={`${
                      matchPath(item?.path)
                        ? "-is-active"
                        : ""
                    } items-center`}
                  >
                    {item?.menuIcon}
                    {item?.menuName}
                  </Link> : <p className="fz15 fw400 ff-heading mt30">{item?.menuName}</p>
                }
              </div>
            }) : AgentNavItems?.[currentLanguage]?.map((item, index) => {
              return <div key={index + 1} className="sidebar_list_item mb-1">
                {
                  // item?.isLink ? <Link to={item?.path} className={`items-center ${item?.isActive ? '-is-active' : ''}`}>
                  item?.isLink ? <Link to={item?.path} 
                  className={`${
                      matchPath(item?.path)
                      ? "-is-active"
                      : ""
                  } items-center`}
                 >
                    {item?.menuIcon}
                    {item?.menuName}
                  </Link> : <p className="fz15 fw400 ff-heading mt30">{item?.menuName}</p>
                }
              </div>
          })
        }
      </div>
    </div>
  )
}
