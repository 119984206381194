import { Modal } from 'antd'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'

function VideoDisplayModal({ filePath, isFileOpen, setIsFileOpen, title }) {

    const { i18n } = useTranslation()
    const cookies = new Cookies()
  
    const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'
    
    const videoRef = useRef(null);

    const handleModalClose = () => {
        // Pause the video and reset its current time
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
        setIsFileOpen(false);
    };
    return (
        <Modal
            title={title}   
            open={isFileOpen}
            onCancel={handleModalClose}
            footer={false}
            width={"800px"}
            className={`${currentLanguage === 'ar' ? 'direction-rtl' :''}`}
        >
            <video ref={videoRef} width="750" height="auto" controls>
                <source src={filePath} />
            </video>

        </Modal>
    )
}

export default VideoDisplayModal
