import { Result } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setCurrentPackage } from '../../redux/UserPackageSlice'
import { nameBasedProtectedRoutes } from 'router/protected'
import { currentPackageApi } from 'services/packageService'
import { useTranslation } from 'react-i18next'

const PaymentResponse = () => {

  const [responseData, setResponseData] = useState({})
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const {t} = useTranslation()

  const getCurrentPackage = () => {
    currentPackageApi().then((response) => {
      setResponseData(response)
      dispatch(setCurrentPackage({ userPackage: response }))
    }).catch((error) => {
      if(error?.response?.data?.status === 400){
        navigate(nameBasedProtectedRoutes?.package?.path)
      }
      toast.error(
        error.response.data.error_description
          ? error.response.data.error_description
          : error.response.data?.message
      );


    }).finally(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    getCurrentPackage();
  }, [])

  return (
    <div className="dashboard__content property-page bgc-f7">
      <div className="row align-items-center pb20">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>{t("PAYMENT RESPONSE")}</h2>
          </div>
        </div>
      </div>
      <div className="row py-5">
        <div className="col-md-12">
          {
            responseData && Object.values(responseData).length > 0 ? <> {
              responseData?.paymentResult === "SUCCESS" ?
                <Result
                  status="success"
                  title={t("PAYMENT SUCCESS")}
                  subTitle={responseData?.currentOrder?.paymentNote}
                  extra={[
                    <div>
                      <p>{responseData?.currentOrder?.description}</p>
                      <Link to={nameBasedProtectedRoutes?.package?.path} className='ud-btn btn-thm'>{t("VIEW PACKAGE")}</Link>
                    </div>
                  ]}
                /> :
                responseData?.paymentResult === "FAILURE" ?
                  <Result
                    status="error"
                    title="PAYMENT FAILED"
                    subTitle={responseData?.currentOrder?.paymentNote}
                    extra={[
                      <Link className='ud-btn btn-dark' to={nameBasedProtectedRoutes?.retryPayment?.path}>{t("TRY AGAIN")}</Link>,
                    ]}
                  /> : responseData?.paymentResult === "PENDING" ?
                    <Result
                      status="warning"
                      title="PAYMENT PENDING"
                      subTitle={responseData?.currentOrder?.paymentNote}
                      extra={[
                        <div>
                          <Link className='ud-btn btn-dark' to={nameBasedProtectedRoutes?.retryPayment?.path}>{t("TRY AGAIN")}</Link>,
                        </div>

                      ]}
                    /> : <Result
                      title={"PAYMENT" + responseData?.status}
                      subTitle={responseData?.currentOrder?.paymentNote}
                      extra={
                        <Link to={nameBasedProtectedRoutes?.package?.path} className='ud-btn btn-thm'>{t("VIEW PACKAGE")}</Link>
                      }
                    />
            }
            </> : ""}

        </div>
      </div>
    </div>
  )
}

export default PaymentResponse