import React from 'react'

const MainStatsComponent = ({ title, count, icon }) => {
    return (
        <div className="col-sm-6 col-md-4 col-lg-3 col-xl">
            <div className="statistics_funfact">
                <div className="details">
                    <div className="text fz25"> {title}</div>
                    <div className='d-flex justify-content-between align-items-center mt-2'>
                        <div className="title">{count ?? 0}</div>
                        <div className="icon text-center">
                            {icon}
                            {/* <i className="flaticon-search-chart" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainStatsComponent