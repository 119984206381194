export const Uielement = () => {
  return (
    <div className="wrapper ovh">
      {/* <div className="preloader" />*/}
      {/* Main Header Nav */}
      <header className="header-nav nav-innerpage-style bdrb1 style2 main-menu">
        {/* Ace Responsive Menu */}
        <nav className="posr">
          <div className="container posr menu_bdrt1">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="logos">
                    <a className="header-logo logo2" href="index.html">
                      <img src="images/Amaken-logo2.svg" alt="Header Logo" />
                    </a>
                  </div>
                  {/* Responsive Menu Structure*/}
                  <ul
                    id="respMenu"
                    className="ace-responsive-menu mx40 mx10-lg"
                    data-menu-style="horizontal"
                  >
                    <li className="visible_list">
                      <a className="list-item" href="index.html">
                        <span className="title">Home</span>
                      </a>
                    </li>
                    <li className="megamenu_style">
                      {" "}
                      <a className="list-item" href="search-by-amaken-map.html">
                        <span className="title">Search by Amaken Map</span>
                      </a>
                    </li>
                    <li className="visible_list">
                      {" "}
                      <a className="list-item" href="page-agency.html">
                        <span className="title">Agencies</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-auto">
                <div className="d-flex align-items-center">
                  <a
                    className="login-info d-flex align-items-center"
                    data-bs-toggle="modal"
                    href="#exampleModalToggle"
                    role="button"
                  >
                    <i className="far fa-user-circle fz16 me-2" />{" "}
                    <span>Sign In / Sign Up</span>
                  </a>
                  <a
                    className="ud-btn btn-transparent add-property bdrs60 mx-2 mx-xl-4"
                    href="page-login.html"
                  >
                    Agencies Login
                    <i className="fal fa-arrow-right-long" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <div className="body_content">
        {/* UI Elements Sections */}
        <section className="breadcumb-section pt60 pb30">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="breadcumb-style1">
                  <h2 className="title">UI Elements Page (For Development Team)</h2>
                  <div className="breadcumb-list">
                    <a href="">Home</a>
                    <a href="">UI Elements Page</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* UI Elements Sections */}
        <section className="pt0 pb90 our-ui-elements">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="ui-content">
                  <h4 className="title">Accordions</h4>
                  <div className="accordion-style1 mb-4 mb-lg-5">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Can a home depreciate in value?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            It doesn’t matter how organized you are — a surplus of
                            toys will always ensure your house
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Is an older home as good a value as a new home?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            It doesn’t matter how organized you are — a surplus of
                            toys will always ensure your house
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item active">
                        <h2 className="accordion-header" id="headingThree">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            What is a broker?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingThree"
                          data-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            It doesn’t matter how organized you are — a surplus of
                            toys will always ensure your house
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFour">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour"
                          >
                            Can I pay my own taxes and insurance?
                          </button>
                        </h2>
                        <div
                          id="collapseFour"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            It doesn’t matter how organized you are — a surplus of
                            toys will always ensure your house
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ui-content">
                  <h4 className="title">Tabs</h4>
                  <div className="navpill-style1 mb70">
                    <ul
                      className="nav nav-pills mb30"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active fw500 dark-color"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          Home Improvement
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link fw500 dark-color"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          Life &amp; Style
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link fw500 dark-color"
                          id="pills-contact-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-contact"
                          type="button"
                          role="tab"
                          aria-controls="pills-contact"
                          aria-selected="false"
                        >
                          Finance
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade fz15 show active"
                        id="pills-home"
                        role="tabpanel"
                        aria-labelledby="pills-home-tab"
                      >
                        Pharetra nulla ullamcorper sit lectus. Fermentum mauris
                        pellentesque nec nibh sed et, vel diam, massa. Placerat quis
                        vel fames interdum urna lobortis sagittis sed pretium.
                        Aliquam eget posuere sit enim elementum.
                      </div>
                      <div
                        className="tab-pane fade fz15"
                        id="pills-profile"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab"
                      >
                        Pharetra nulla ullamcorper sit lectus. Fermentum mauris
                        pellentesque nec nibh sed et, vel diam, massa. Placerat quis
                        vel fames interdum urna lobortis sagittis sed pretium.
                        Aliquam eget posuere sit enim elementum.
                      </div>
                      <div
                        className="tab-pane fade fz15"
                        id="pills-contact"
                        role="tabpanel"
                        aria-labelledby="pills-contact-tab"
                      >
                        Pharetra nulla ullamcorper sit lectus. Fermentum mauris
                        pellentesque nec nibh sed et, vel diam, massa. Placerat quis
                        vel fames interdum urna lobortis sagittis sed pretium.
                        Aliquam eget posuere sit enim elementum.
                      </div>
                    </div>
                  </div>
                  <div className="navtab-style1 mb-4 mb-lg-5 mt50">
                    <nav>
                      <div
                        className="nav nav-tabs mb20"
                        id="nav-tab2"
                        role="tablist"
                      >
                        <button
                          className="nav-link active fw600"
                          id="nav-item1-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-item1"
                          type="button"
                          role="tab"
                          aria-controls="nav-item1"
                          aria-selected="true"
                        >
                          Items 1
                        </button>
                        <button
                          className="nav-link fw600"
                          id="nav-item2-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-item2"
                          type="button"
                          role="tab"
                          aria-controls="nav-item2"
                          aria-selected="false"
                        >
                          Items 2
                        </button>
                        <button
                          className="nav-link fw600"
                          id="nav-item3-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-item3"
                          type="button"
                          role="tab"
                          aria-controls="nav-item3"
                          aria-selected="false"
                        >
                          Items 3
                        </button>
                      </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade show active fz15"
                        id="nav-item1"
                        role="tabpanel"
                        aria-labelledby="nav-item1-tab"
                      >
                        Pharetra nulla ullamcorper sit lectus. Fermentum mauris
                        pellentesque nec nibh sed et, vel diam, massa. Placerat quis
                        vel fames interdum urna lobortis sagittis sed pretium.
                        Aliquam eget posuere sit enim elementum.
                      </div>
                      <div
                        className="tab-pane fade fz15"
                        id="nav-item2"
                        role="tabpanel"
                        aria-labelledby="nav-item2-tab"
                      >
                        Pharetra nulla ullamcorper sit lectus. Fermentum mauris
                        pellentesque nec nibh sed et, vel diam, massa. Placerat quis
                        vel fames interdum urna lobortis sagittis sed pretium.
                        Aliquam eget posuere sit enim elementum.
                      </div>
                      <div
                        className="tab-pane fade fz15"
                        id="nav-item3"
                        role="tabpanel"
                        aria-labelledby="nav-item3-tab"
                      >
                        Pharetra nulla ullamcorper sit lectus. Fermentum mauris
                        pellentesque nec nibh sed et, vel diam, massa. Placerat quis
                        vel fames interdum urna lobortis sagittis sed pretium.
                        Aliquam eget posuere sit enim elementum.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="ui-content">
                  <h4 className="title">Table</h4>
                  <div className="table-style1 table-responsive mb-4 mb-lg-5">
                    <table className="table table-borderless">
                      <thead className="thead-light">
                        <tr>
                          <th className="fz15 fw500" scope="col">
                            Description
                          </th>
                          <th className="fz15 fw500" scope="col">
                            Hour
                          </th>
                          <th className="fz15 fw500" scope="col">
                            Quantity
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Design UX and UI</td>
                          <td>14</td>
                          <td>3</td>
                        </tr>
                        <tr>
                          <td>Design UX and UI</td>
                          <td>14</td>
                          <td>3</td>
                        </tr>
                        <tr>
                          <td>Design UX and UI</td>
                          <td>14</td>
                          <td>3</td>
                        </tr>
                        <tr>
                          <td>Design UX and UI</td>
                          <td>14</td>
                          <td>3</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ui-content">
                  <h4 className="title">Message Boxes</h4>
                  <div className="message-alart-style1">
                    <div
                      className="alert alart_style_one alert-dismissible fade show mb20"
                      role="alert"
                    >
                      Info: User pending action
                      <i
                        className="far fa-xmark btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      />
                    </div>
                    <div
                      className="alert alart_style_two alert-dismissible fade show mb20"
                      role="alert"
                    >
                      Warning: User has to be admin
                      <i
                        className="far fa-xmark btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      />
                    </div>
                    <div
                      className="alert alart_style_three alert-dismissible fade show mb20"
                      role="alert"
                    >
                      Error: Internal Server Error
                      <i
                        className="far fa-xmark btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      />
                    </div>
                    <div
                      className="alert alart_style_four alert-dismissible fade show mb20"
                      role="alert"
                    >
                      Success: Updated members status
                      <i
                        className="far fa-xmark btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="ui-content">
                  <h4 className="title">Buttons</h4>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ui-content mb-4">
                  <div className="button-list">
                    <a href="" className="ud-btn btn-dark mb25 me-4">
                      Button Primary
                      <i className="fal fa-arrow-right-long" />
                    </a>
                    <a href="" className="ud-btn btn-thm mb25">
                      Button Primary Hover
                      <i className="fal fa-arrow-right-long" />
                    </a>
                    <a href="" className="ud-btn btn-white mb25 me-4">
                      Button Third
                      <i className="fal fa-arrow-right-long" />
                    </a>
                    <a href="" className="ud-btn btn-light-gray mb25">
                      Button Third Hover
                      <i className="fal fa-arrow-right-long" />
                    </a>
                    <a
                      href=""
                      className="ud-btn btn-light-gray mb25 filter-btn-left"
                    >
                      LeftSide HiddenBar
                      <i className="fal fa-arrow-right-long" />
                    </a>
                    <a
                      href=""
                      className="ud-btn btn-light-gray mb25 filter-btn-right"
                    >
                      RightSide HiddenBar
                      <i className="fal fa-arrow-right-long" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ui-content mb-4">
                  <div className="button-list">
                    <a href="" className="ud-btn btn-thm2 mb25 me-4">
                      Button Secondary
                      <i className="fal fa-arrow-right-long" />
                    </a>
                    <a href="" className="ud-btn btn-thm mb25">
                      Button Secondary Hover
                      <i className="fal fa-arrow-right-long" />
                    </a>
                    <a href="" className="ud-btn btn-thm-border mb25 me-4">
                      Button Third
                      <i className="fal fa-arrow-right-long" />
                    </a>
                    <a href="" className="ud-btn btn-light-gray mb25">
                      Button Third Hover
                      <i className="fal fa-arrow-right-long" />
                    </a>
                    <a href="" className="ud-btn btn-white2 mb25 me-4">
                      Button Third
                      <i className="fal fa-arrow-right-long" />
                    </a>
                    <a href="" className="ud-btn btn-transparent mb25 me-4">
                      Button Fourth
                      <i className="fal fa-arrow-right-long" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="ui-content mt10">
                  <h4 className="title">Form</h4>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ui-content mb40">
                  <div className="form-style1">
                    <label className="form-label fw600 dark-color">Input</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Your Name"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ui-content mb40">
                  <div className="form-style1">
                    <label className="form-label fw600 dark-color">
                      Input Focus
                    </label>
                    <input
                      type="text"
                      className="form-control active"
                      placeholder="Your Name"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ui-content mb40">
                  <div className="form-style1">
                    <label className="form-label fw600 dark-color">
                      Search Select boxes, Hover
                    </label>
                    <div className="bootselect-multiselect">
                      <select
                        className="selectpicker"
                        data-live-search="true"
                        data-width="100%"
                      >
                        <option>Search</option>
                        <option data-tokens="Banking">Banking</option>
                        <option data-tokens="Digital&Creative">
                          Digital &amp; Creative
                        </option>
                        <option data-tokens="Retail">Retail</option>
                        <option data-tokens="Designer">Designer</option>
                        <option data-tokens="Developer">Developer</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ui-content mb40">
                  <div className="form-style1">
                    <label className="form-label fw600 dark-color">
                      Search Select boxes, Hover
                    </label>
                    <div className="bootselect-multiselect">
                      <select className="selectpicker" multiple="">
                        <option>Search</option>
                        <option>Banking</option>
                        <option>Digital &amp; Creative</option>
                        <option>Retail</option>
                        <option>Designer</option>
                        <option>Developer</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="ui-content mb40">
                  <div className="form-style1">
                    <label className="form-label fw600 dark-color">Textarea</label>
                    <textarea
                      name="text"
                      id="textarea1"
                      cols={30}
                      rows={7}
                      defaultValue={"There are many variations of passages."}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="ui-content">
                  <h4 className="title fz15 mb20 mt50">Radiobox</h4>
                  <div className="radiobox-style1">
                    <div className="radio-element">
                      <div className="form-check d-flex align-items-center mb15">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          Items
                        </label>
                      </div>
                      <div className="form-check d-flex align-items-center mb15">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          Items
                        </label>
                      </div>
                      <div className="form-check d-flex align-items-center mb15">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          defaultChecked="checked"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault3"
                        >
                          Items
                        </label>
                      </div>
                      <div className="form-check d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault4"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault4"
                        >
                          Items
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="ui-content">
                  <h4 className="title fz15 mb20 mt50">Checkbox</h4>
                  <div className="checkbox-style1">
                    <label className="custom_checkbox">
                      Items
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                    <label className="custom_checkbox">
                      Items
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                    <label className="custom_checkbox">
                      Items
                      <input type="checkbox" defaultChecked="checked" />
                      <span className="checkmark" />
                    </label>
                    <label className="custom_checkbox">
                      Items
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="ui-content">
                  <h4 className="title fz15 mb20 mt50">Switch</h4>
                  <div className="switch-style1">
                    <div className="form-check form-switch mb20">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        Items
                      </label>
                    </div>
                  </div>
                  <div className="switch-style1">
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault2"
                        defaultChecked="checked"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckDefault2"
                      >
                        Items
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="ui-content">
                  <h4 className="title">Tooltips</h4>
                  <div className="tooltip-list">
                    <button
                      type="button"
                      className="ud-btn btn-gray me-2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Tooltip on top"
                    >
                      Top
                    </button>
                    <button
                      type="button"
                      className="ud-btn btn-gray me-2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Tooltip on bottom"
                    >
                      Bottom
                    </button>
                    <button
                      type="button"
                      className="ud-btn btn-gray me-2"
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      title="Tooltip on right"
                    >
                      Right
                    </button>
                    <button
                      type="button"
                      className="ud-btn btn-gray"
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title="Tooltip on left"
                    >
                      Left
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ui-content">
                  <h4 className="title">Progress bars</h4>
                  <div className="progressbar-style1">
                    <div className="progressbar-bg" />
                    <div className="progressd-bar" style={{ width: "90%" }}>
                      <span>90%</span>
                    </div>
                  </div>
                  <div className="progressbar-style1 mt50">
                    <div className="progressbar-bg" />
                    <div className="progressd-bar" style={{ width: "50%" }}>
                      <span>50%</span>
                    </div>
                  </div>
                  <div className="progressbar-style1 mt50">
                    <div className="progressbar-bg" />
                    <div className="progressd-bar" style={{ width: "40%" }}>
                      <span>40%</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="ui-content">
                  <h4 className="title">Range Slider</h4>
                  {/* Range Slider Desktop Version */}
                  <div className="range-slider-style1 mb-4 mb-lg-5">
                    <div className="range-wrapper">
                      <div className="slider-range mt35 mb20" />
                      <div className="text-center">
                        <input
                          type="text"
                          className="amount"
                          placeholder="JOD 20"
                        />
                        <span className="fa-sharp fa-solid fa-minus mx-2 dark-color" />
                        <input
                          type="text"
                          className="amount2"
                          placeholder="JOD 70987"
                        />
                      </div>
                    </div>
                  </div>
                  {/* Range Slider Mobile Version */}
                  <div className="range-slider-style2">
                    <div className="range-wrapper">
                      <div className="mb30" id="slider" />
                      <div className="d-flex align-items-center">
                        <span id="slider-range-value1" />
                        <i className="fa-sharp fa-solid fa-minus mx-2 dark-color icon" />
                        <span id="slider-range-value2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <div className="ui-content">
                  <h4 className="title">Typography</h4>
                  <h4>1. Reduce the clutter</h4>
                  <p className="mb25 ff-heading">
                    It doesn’t matter how organized you are — a surplus of toys will
                    always ensure your house is a mess waiting to happen.
                    Fortunately, getting kids on board with the idea of ditching
                    their stuff is a lot easier than it sounds.
                  </p>
                  <p className="ff-heading">
                    The trick is to make it an opportunity for them to define
                    themselves and their interests. Encourage kids to make a pile of
                    ”baby toys” to donate, and have them set aside any toys that no
                    longer interest them, such as action figures from a forgotten TV
                    show. Separating these toys will help them appreciate how much
                    they’ve grown and rediscover the toys they love.
                  </p>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="blockquote-style1 mb-4 mb-lg-5">
                  <blockquote className="blockquote">
                    <p className="fst-italic fz15 fw500 ff-heading">
                      Aliquam hendrerit sollicitudin purus, quis rutrum mi accumsan
                      nec. Quisque bibendum orci ac nibh facilisis, at malesuada
                      orci congue.
                    </p>
                    <h6 className="quote-title">Luis Pickford</h6>
                  </blockquote>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-8">
                <div className="ui-content mt15">
                  <h4 className="title">2. Choose toys wisely</h4>
                </div>
                <div className="row">
                  <div className="col-auto">
                    <div className="ui-content">
                      <div className="list-style1">
                        <ul>
                          <li>
                            <i className="far fa-check text-thm3 bgc-thm3-light" />
                            Become a UI/UX designer.
                          </li>
                          <li>
                            <i className="far fa-check text-thm3 bgc-thm3-light" />
                            You will be able to start earning money Figma skills.
                          </li>
                          <li>
                            <i className="far fa-check text-thm3 bgc-thm3-light" />
                            Build a UI project from beginning to end.
                          </li>
                          <li>
                            <i className="far fa-check text-thm3 bgc-thm3-light" />
                            Work with colors &amp; fonts.
                          </li>
                          <li>
                            <i className="far fa-check text-thm3 bgc-thm3-light" />
                            You will create your own UI Kit.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="ui-content">
                      <div className="list-style1">
                        <ul>
                          <li>
                            <i className="far fa-check text-thm3 bgc-thm3-light" />
                            Build &amp; test a complete mobile app.
                          </li>
                          <li>
                            <i className="far fa-check text-thm3 bgc-thm3-light" />
                            Learn to design mobile apps &amp; websites.
                          </li>
                          <li>
                            <i className="far fa-check text-thm3 bgc-thm3-light" />
                            Design 3 different logos.
                          </li>
                          <li>
                            <i className="far fa-check text-thm3 bgc-thm3-light" />
                            Create low-fidelity wireframe.
                          </li>
                          <li>
                            <i className="far fa-check text-thm3 bgc-thm3-light" />
                            Downloadable exercise files.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="advance-search-field position-relative">
                      <form
                        action="#"
                        method="get"
                        className="form-search form-style1 position-relative"
                        acceptCharset="utf-8"
                      >
                        <div className="box-search">
                          <span className="icon flaticon-home-1" />
                          <input
                            className="form-control bdrs12"
                            type="text"
                            name="search"
                            placeholder="Search products…"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Our Footer */}
        <section className="footer-style1 at-home4 pt60 pb-0">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <div className="footer-widget light-style mb-4 mb-lg-5">
                  <a className="footer-logo" href="index.html">
                    <img className="mb40" src="images/Amaken-logo2.svg" alt="" />
                  </a>
                  <div className="contact-info mb25">
                    <p className="text mb5">Need Support?</p>
                    <h6 className="info-phone">
                      <a href="+(962)-6-123-4567">+(962) 6 123 4567</a>
                    </h6>
                    <h6 className="info-mail">
                      <a href="mailto:info@amakenplus.com">info@amakenplus.com</a>
                    </h6>
                  </div>
                  <div className="footer-widget mb-4 mb-lg-5">
                    <div className="light-style mb-4">
                      <h6 className="mb20">Discover Jordan Cities</h6>
                      <div className="ps-0">
                        <a className="px5" href="">
                          Amman
                        </a>
                        /
                        <a className="px5" href="">
                          Irbid
                        </a>
                        /
                        <a className="px5" href="">
                          Jerash
                        </a>
                        /
                        <a className="px5" href="">
                          Fuhais
                        </a>
                        /
                        <a className="px5" href="">
                          Madaba
                        </a>
                        /
                        <a className="px5" href="">
                          Ajloun
                        </a>
                        /
                        <a className="px5" href="">
                          Al Salt
                        </a>
                        /
                        <a className="px5" href="">
                          Zarqa
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="link-style1 light-style mb30">
                  <h6 className="mb25">Popular Search</h6>
                  <div className="link-list">
                    <a href="">Apartment for Sale</a>
                    <a href="">Apartment for Rent</a>
                    <a href="">Offices for Rent</a>
                    <a href="">Villa In Amman</a>
                    <a href="">Commercial Land</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="footer-widget mb-4 mb-lg-5 ps-0 ps-lg-5">
                  <div className="link-style1 light-style mb-3">
                    <h6 className="mb25">Quick Links</h6>
                    <ul className="ps-0">
                      <li>
                        <a href="">About Amaken</a>
                      </li>
                      <li>
                        <a href="">Our Services</a>
                      </li>
                      <li>
                        <a href="">Terms of Use</a>
                      </li>
                      <li>
                        <a href="">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="">FAQs</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="footer-widget mb-4 mb-lg-5">
                  <div className="app-widget">
                    <h5 className="title mb10">Download Amaken App</h5>
                    <div className="row">
                      <div className="col-auto">
                        <a href="">
                          <div className="app-info light-style d-flex align-items-center mb10">
                            <div className="flex-shrink-0">
                              <i className="fab fa-apple fz30 text-white" />
                            </div>
                            <div className="flex-grow-1 ml20">
                              <p className="app-text fz13 mb0">Download on the</p>
                              <h6 className="app-title text-white fz14">
                                Apple Store
                              </h6>
                            </div>
                          </div>
                        </a>
                      </div>
                      <div className="col-auto">
                        <a href="">
                          <div className="app-info light-style d-flex align-items-center mb10">
                            <div className="flex-shrink-0">
                              <i className="fab fa-google-play fz30 text-white" />
                            </div>
                            <div className="flex-grow-1 ml20">
                              <p className="app-text fz13 mb0">Get in on</p>
                              <h6 className="app-title text-white fz14">
                                Google Play
                              </h6>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container gray-bdrt1 py-4">
            <div className="row">
              <div className="col-sm-6">
                <div className="text-center text-lg-start">
                  <p className="copyright-text ff-heading mb-0">
                    © Amaken - All rights reserved
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="social-widget text-center text-sm-end">
                  <div className="social-style1 light-style">
                    <a className="me-2 fw600 fz15" href="">
                      Follow us
                    </a>
                    <a href="">
                      <i className="fab fa-facebook-f list-inline-item" />
                    </a>
                    <a href="">
                      <i className="fab fa-twitter list-inline-item" />
                    </a>
                    <a href="">
                      <i className="fab fa-instagram list-inline-item" />
                    </a>
                    <a href="">
                      <i className="fab fa-linkedin-in list-inline-item" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <a className="scrollToHome" href="#">
          <i className="fas fa-angle-up" />
        </a>
      </div>
    </div>
  )
}
