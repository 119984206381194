import { ErrorMessage } from "formik";
import { Calendar } from "primereact/calendar";
import React from "react";
import { FormLabel } from "react-bootstrap";

function AgencyDatePicker({
  label,
  disabled,
  placeholder,
  handleChange,
  handleBlur,
  value,
  minDate,
  name,
  className,
}) {
  return (
    <div className="mb20">
      <FormLabel className="heading-color ff-heading fw600 mb10">
        {label}
      </FormLabel>
      <div>
        <Calendar
          placeholder={placeholder}
          id={name}
          name={name}
          onChange={handleChange}
          minDate={minDate}
          // onBlur={handleBlur}
          value={value}
          dateFormat="dd/mm/yy"
          className={className}
          disabled={disabled}
        />
        <ErrorMessage name={name} component="div" className="text-danger" />
      </div>

    </div>
  );
}

export default AgencyDatePicker;
