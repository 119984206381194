import { DIRECTORATE_LIST_API, GOVERNORATE_LIST_API, VILLAGE_LIST_API, GET_COORDINATES_API, PROPERTY_TYPE_LIST_API, PROPERTY_INFO_API, SAVE_IDENTIFICATION_API, GET_PROPERTY_BY_ID_API, SAVE_DESCRIPTION_DATA_API, SAVE_MEDIA_DATA_API, AMENITIES_LIST_API, SAVE_AMENITIES_API, DELETE_MEDIA, MARK_MAIN_IMAGE, PROPERTY_DETAILS_FIELD, SAVE_PROPERTY_DETAILS, PROPERTY_LIST_API, EDIT_DESCRIPTION_API, CHANGE_STATUS_API, ADD_COMMENT_API, COMMENT_LIST_API, PROPERTY_FEATURED_MARK_API, TOGGLE_EXACT_LOCATION_API, UC_PROPERTY_IDENTIFICATION, SAVE_FLOOR_PLAN, DELETE_FLOOR_PLAN } from "constants/paths"
import { axios } from "./axios"


// PROPERTY LIST DATA API

export const MyPropertyListApi = async (params) => {

  let queryString = `?size=${params.size}&page=${params.page}`;

  // Add sorting to the query string
  if (params.sort) {
    queryString += `&sort=${params.sort}`;
  }

  // Add search parameter if exists
  if (params.search) {
    queryString += `&search=${encodeURIComponent(params.search)}`;
  }

  const response = await axios.get(PROPERTY_LIST_API + queryString)
  return response
}

// GET GOVERNORATE LIST FOR DROPDOWN

export const GovernorateListApi = async (params) => {
  const response = await axios.get(GOVERNORATE_LIST_API, { params })
  return response
}

// GET DIRECTORATE LIST FOR DROPDOWN BY GOVERNORATE ID

export const DirectorateListApi = async (gId, params) => {
  const response = await axios.get(`${DIRECTORATE_LIST_API}/${gId}`, { params })
  return response
}

// GET VILLAGE LIST FOR DROPDOWN BY DIRECTORATE ID

export const VillageListApi = async (dId, params) => {
  const response = await axios.get(`${VILLAGE_LIST_API}${dId}`, { params })
  return response
}

// GET COORDINATES FOR ADD PROPERTY
export const GetCoordinatesApi = async (data) => {
  const response = await axios.post(`${GET_COORDINATES_API}`, data)
  return response?.data
}
//GET PROPERTY LIST API
export const PropertyListApi = async () => {
  const response = await axios.get(PROPERTY_TYPE_LIST_API)
  return response?.data
}
//GET PROPERTY INFO API
export const PropertyInfoApi = async () => {
  const response = await axios.get(PROPERTY_INFO_API)
  return response?.data
}
//SAVE IDENTIFICATION DATA API
export const SaveIdentifcationFormApi = async (data) => {
  const response = await axios.post(`${SAVE_IDENTIFICATION_API}`, data)
  return response?.data
}

//SAVE UNDER CONSTRUCTION PROPERTY IDENTIFICATION DATA API
export const SaveUCIdentificationData = async (data) => {
  const response = await axios.post(UC_PROPERTY_IDENTIFICATION, data)
  return response?.data
}


//GET IDENTIFICATION FORM DATA API
export const GetPropertyData = async (id) => {
  const response = await axios.get(`${GET_PROPERTY_BY_ID_API}/${id}`)
  return response?.data
}
// SAVE DESCRIPTION FORM DATA API
export const saveDescriptionDataApi = async (data) => {
  const response = await axios.post(SAVE_DESCRIPTION_DATA_API, data)
  return response?.data
}
// SAVE MEDIA FORM DATA API
export const saveMediaDataApi = async (data) => {
  const response = await axios.post(SAVE_MEDIA_DATA_API, data)
  return response?.data
}
// AMENITIES LIST FETCH API
export const amenitiesListApi = async (id) => {
  const response = await axios.get(`${AMENITIES_LIST_API}/${id}`)
  return response?.data
}

// SAVE AMENITIES LIST API
export const saveAmenitiesApi = async (propertyId, data) => {
  const response = await axios.post(`${SAVE_AMENITIES_API}/${propertyId}`, data)
  return response?.data
}

// DELETE MEDIA
export const deleteMediaApi = async (id) => {
  const response = await axios.delete(`${DELETE_MEDIA}/${id}`)
  return response?.data
}

// MARK MAIN IMAGE API
export const markMainImageApi = async (id) => {
  const response = await axios.patch(`${MARK_MAIN_IMAGE}/${id}`)
  return response?.data
}

// MARK MAIN IMAGE API
export const markFeaturedProperty = async (id, status) => {
  const response = await axios.put(`${PROPERTY_FEATURED_MARK_API}/${id}?featured=${status}`)
  return response?.data
}
// TOGGLE HIDE OR SHOW EXACT LOCATION 
export const toggleExactLocationApi = async (id, status) => {
  const response = await axios.put(`${TOGGLE_EXACT_LOCATION_API}/${id}?hideExactLocation=${status}`)
  return response?.data
}

// PROPERTY DETAILS FIELDS API 
export const getPropertyDetailsFields = async (id) => {
  const response = await axios.get(`${PROPERTY_DETAILS_FIELD}/${id}`)
  return response?.data
}

// SAVE PROPERTY DETAILS DATA
export const savePropertyDetailsApi = async (data) => {
  const response = await axios.post(SAVE_PROPERTY_DETAILS, data)
  return response?.data
}

// EDIT DESCRIPTION 

export const editDescriptionData = async (data) => {
  const response = await axios.put(EDIT_DESCRIPTION_API, data)
  return response?.data
}

// CHANGE PROPERTY STATUS API
export const changePropertyStatus = async (id, status) => {
  const response = await axios.put(`${CHANGE_STATUS_API}/${id}?status=${status}`)
  return response?.data
}

// CHECK FILE VALIDATION
export const validateFile = (file) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      const image = new Image();
      image.onload = () => {
        const minWidth = 800;
        const minHeight = 800;
        const maxSizeInBytes = 1024 * 1024 * 10; // 10MB
        // Validate image dimensions
        if (image.naturalWidth < minWidth || image.naturalWidth < minHeight) {
          reject('Image dimensions do not meet the specified criteria.');
        } else if (file.size > maxSizeInBytes) {
          reject('File size exceeds the specified limit.');
        } else {
          resolve({ status: true, message: 'File is valid.' });
        }
      };

      image.src = e.target.result;
    };

    // Read the file as data URL
    reader.readAsDataURL(file);
  });
}

// COMMENT API's

// ADD COMMENT
export const addCommentApi = async (data) => {
  const response = await axios.post(ADD_COMMENT_API, data)
  return response?.data
}
// COMMENT LIST
export const getCommentList = async (id, params) => {
  const response = await axios.get(`${COMMENT_LIST_API}/${id}`, { params })
  return response
}
// SAVE FLOOR PLAN
export const saveFloorPlan = async (id, data) => {
  const response = await axios.post(`${SAVE_FLOOR_PLAN}/${id}`, data)
  return response?.data
}

// DELETE FLOOR PLAN
export const deleteFloorPlan = async (id) => {
  const response = await axios.delete(`${DELETE_FLOOR_PLAN}/${id}`)
  return response?.data
}