import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { agentStatsApi } from 'services/dashboardService'
import { LuBuilding2 } from "react-icons/lu";
import { BsBuildingCheck, BsBuildingExclamation } from "react-icons/bs"
import MainStatsComponent from '../MainStatsComponent';
import { useTranslation } from 'react-i18next';
const AgentStats = ({ startDate, endDate }) => {

  const [mainStatsData, setMainStatsData] = useState({})
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation()

  // GET AGENT STATS FROM SERVER
  const getAgentStats = () => {
    setLoading(true)
    // GET START AND END DATE FROM HIGHER COMPONENT
    let params = {
      startDate: startDate,
      endDate: endDate
    }
    // CALLING AGENT STATS API
    agentStatsApi(params).then((response) => {
      setMainStatsData(response)
    }).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    }).finally(() => {
      setLoading(false)
    })
  }
  // CALL ON COMPONENT RENDER
  useEffect(() => {
    getAgentStats()
  }, [startDate, endDate])

  return (
    <div className="row">
      {/* TOTAL PROPERTIES */}
      <MainStatsComponent title={t("TOTAL PROPERTIES")} count={mainStatsData?.totalProperties ?? 0} icon={<LuBuilding2 />} />
      {/* NEW PROPERTIES */}
      <MainStatsComponent title={t("NEW PROPERTIES")} count={mainStatsData?.newProperties ?? 0} icon={<LuBuilding2 />} />
      {/* ACTIVE PROPERTIES */}
      <MainStatsComponent title={t("ACTIVE PROPERTIES")} count={mainStatsData?.activeProperties ?? 0} icon={<BsBuildingCheck />} />
      {/* PROPERTY VIEW */}
      <MainStatsComponent title={t("PROPERTY VIEWS")} count={mainStatsData?.totalPropertyViews ?? 0} icon={<i className="flaticon-search-chart" />} />
      {/* AGENT PROFILE VIEWS */}
      <MainStatsComponent title={t("AGENT PROFILE VIEWS")} count={mainStatsData?.totalViews ?? 0} icon={<i className="flaticon-search-chart" />} />

      {/* AGING PROPERTIES */}
      <MainStatsComponent title={t("AGING PROPERTIES")} count={mainStatsData?.agingProperties ?? 0} icon={<BsBuildingExclamation />} />
    </div>
  )
}

export default AgentStats