import AgencyInputText from "components/common/AgencyInputText";
import AgencySelect from "components/common/AgencySelect";
import InputPhoneCode from "components/common/InputPhoneCode";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setUser } from "../../redux/AgencySlice";
import { getUserDataApi, updateProfileApi, verifyAgentEmailAccount } from "services/userService";
import { profileUpdateSchema } from "validations/ValidationSchema";
import AgencyTextArea from "components/common/AgencyTextArea";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import PhoneVerifyModal from "./PhoneVerifyModal";

function UpdateProfileForm() {
  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const cookies = new Cookies()
  const userData = useSelector(state => state?.user?.userData)

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const [verificationMailSent, setVerificationMailSent] = useState(false)
  const [openPhoneVerifyModal, setOpenPhoneVerifyModal] = useState(false)

  const handleSubmit = (values) => {
    setLoading(true)
    let formData = {
      id: userData?.id,
      firstName: values?.firstName,
      lastName: values?.lastName,
      address: values?.address,
      mobileNumber: values?.phoneNo,
      phoneCode: values?.phoneCode,
      langKey: values?.langKey,
      facebookUrl: values?.facebook_url,
      instagramUrl: values?.instagram_url,
      linkedInUrl: values?.linkedin_url,
      about: { "en": values?.about_en, "ar": values?.about_ar }
      // "about['en']" :values?.about_en,
      // "about['ar']" :values?.about_ar,
    };
    updateProfileApi(formData)
      .then((response) => {
        setLoading(false);
        toast.success(response?.message ?? "Profile updated!");
        // GET USER UPDATE DATA
        getUserProfileData()
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });
  }

  // GET USER PROFILE DATA AND UPDATE REDUX STATE
  const getUserProfileData = () => {
    // REDUX STORE UPDATE WITH UPDATED PROFILE DATA
    getUserDataApi()
      .then((userDataResponse) => {
        cookies.remove("langKey")
        cookies.set("langKey", userDataResponse?.langKey)
        i18n.changeLanguage(userDataResponse?.langKey)
        setLoading(false);
        dispatch(setUser({ userData: userDataResponse }));
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error.response.data.error_description
            ? error.response.data.error_description
            : error.response.data?.message
        );
      });
  }

  // HANDLE EMAIL VERIFY
  const handleEmailVerify = () => {
    verifyAgentEmailAccount().then((response) => {
      setVerificationMailSent(true)
      getUserProfileData()
      toast.success(response?.message)
    }).catch(error => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  return (
    <div className="col-lg-12">
      <Formik
        validationSchema={profileUpdateSchema}
        initialValues={{
          firstName: userData?.firstName ?? "",
          lastName: userData?.lastName ?? "",
          email: userData?.email ?? "",
          phoneNo: userData?.mobileNumber ?? "",
          phoneCode: userData?.countryCode ?? "",
          langKey: userData?.langKey ?? "",
          address: userData?.address ?? "",
          facebook_url: userData?.facebookUrl ?? "",
          instagram_url: userData?.instagramUrl ?? "",
          linkedin_url: userData?.linkedInUrl ?? "",
          about_en: userData?.about?.en ?? "",
          about_ar: userData?.about?.ar ?? "",
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          handleSubmit(values)
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="form-style1">
            <div className="row align-items-end">
              <div className="col-sm-6 col-xl">
                <AgencyInputText
                  id="firstName"
                  name="firstName"
                  type="text"
                  label={t("FIRST NAME")}
                  placeholder={t("FIRST NAME")}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.firstName}
                  className={`form-control ${touched?.firstName && errors?.firstName ? "error" : ""
                    }`}
                />
              </div>
              <div className="col-sm-6 col-xl">
                <AgencyInputText
                  id="lastName"
                  name="lastName"
                  type="text"
                  label={t("LAST NAME")}
                  placeholder={t("LAST NAME")}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.lastName}
                  className={`form-control ${touched?.lastName && errors?.lastName ? "error" : ""
                    }`}
                />
              </div>
              <div className="col-sm-6 col-xl">
                <AgencyInputText
                  id="email"
                  name="email"
                  type="email"
                  label={t("EMAIL")}
                  readOnly={true}
                  placeholder={t("EMAIL")}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.email}
                  className={`form-control ${touched?.email && errors?.email ? "error" : ""
                    }`}
                />
              </div>
              {
                userData?.isEmailVerified === false && verificationMailSent === false ? <div className="col-sm-6 col-xl-auto">
                  <div className="text-end">
                    <button type="button" onClick={handleEmailVerify} className="ud-btn btn-thm mb20">Verify Email</button>
                  </div>
                </div> : ""
              }
            </div>
            <div className="row">
              <div className="col-sm-6 col-xl-4">
                <div className="row">
                  <div className="col-md-4 col-xl-4">
                    <InputPhoneCode
                      id="phoneCode"
                      name="phoneCode"
                      type="number"
                      label={t("CODE")}
                      disabled={true}
                      country="jo"
                      placeholder={t("CODE")}
                      handleChange={(code) => { setFieldValue("phoneCode", code) }}
                      handleBlur={handleBlur}
                      value={values?.phoneCode}
                      className={` ${touched?.phoneCode && errors?.phoneCode ? "error" : ""
                        }`}
                    />
                  </div>
                  <div className="col md-8 col-xl-8">
                    <AgencyInputText
                      id="phoneNo"
                      name="phoneNo"
                      type="text"
                      label={t("PHONE NO.")}
                      disabled={true}
                      placeholder={t("YOUR PHONE NUMBER")}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values?.phoneNo}
                      className={`form-control ${touched?.phoneNo && errors?.phoneNo ? "error" : ""
                        }`}
                    />
                  </div>
                  {/* <div className="col-md col-xl-3">
                    <button type="button" className="ud-btn btn-thm mb20" onClick={() => {
                      setOpenPhoneVerifyModal(true)
                    }}>{userData?.mobileNumberVerified ? 'Edit' :
                      'Add'} & Verify</button>
                  </div> */}
                </div>
                <button type="button" className="ud-btn btn-thm mb20" onClick={() => {
                  setOpenPhoneVerifyModal(true)
                }}>{userData?.isMobileNumberVerified ? t('EDIT') :
                  t('ADD')} & {t('VERIFY')}</button>
              </div>
              <div className="col-sm-6 col-xl-4">
                <AgencySelect
                  id="langKey"
                  name="langKey"
                  label={t("PREFERRED LANGUAGE")}
                  placeholder={t("NOTHING SELECTED")}
                  handleChange={(value) => {
                    setFieldValue("langKey", value);
                  }}
                  handleBlur={handleBlur}
                  value={values?.langKey}
                  options={[
                    {
                      value: "en",
                      label: t("ENGLISH"),
                    },
                    {
                      value: "ar",
                      label: t("ARABIC"),
                    },
                  ]}
                  className={`form-control ${touched?.langKey && errors?.langKey
                    }`}
                />
              </div>
              <div className="col-sm-6 col-xl-4">
                <AgencyInputText
                  id="address"
                  name="address"
                  type="text"
                  label={t("ADDRESS")}
                  placeholder={t("YOUR ADDRESS")}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.address}
                  className={`form-control ${touched?.address && errors?.address ? "error" : ""
                    }`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-xl-4">
                <AgencyInputText
                  type="url"
                  label={t("FACEBOOK URL")}
                  id="facebook_url"
                  placeholder={t("FACEBOOK URL")}
                  name="facebook_url"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.facebook_url}
                  className={`form-control ${touched?.facebook_url && errors?.facebook_url
                    ? "error"
                    : ""
                    }`}
                />
              </div>
              <div className="col-sm-6 col-xl-4">
                <AgencyInputText
                  type="url"
                  label={t("INSTAGRAM URL")}
                  id="instagram_url"
                  placeholder={t("INSTAGRAM URL")}
                  name="instagram_url"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.instagram_url}
                  className={`form-control ${touched?.instagram_url && errors?.instagram_url
                    ? "error"
                    : ""
                    }`}
                />
              </div>
              <div className="col-sm-6 col-xl-4">
                <AgencyInputText
                  type="url"
                  label={t("LINKEDIN URL")}
                  id="linkedin_url"
                  placeholder={t("LINKEDIN URL")}
                  name="linkedin_url"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.linkedin_url}
                  className={`form-control ${touched?.linkedin_url && errors?.linkedin_url
                    ? "error"
                    : ""
                    }`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AgencyTextArea
                  rows={4}
                  cols={30}
                  label={t("ABOUT AGENT (ENGLISH)")}
                  id="about_en"
                  placeholder={t("ABOUT AGENT")}
                  name="about_en"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.about_en}
                  className={` ${touched?.about_en && errors?.about_en
                    ? "error"
                    : ""
                    }`}
                />

              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AgencyTextArea
                  rows={4}
                  cols={30}
                  dir={'rtl'}
                  label={t("ABOUT AGENT (ARABIC)")}
                  id="about_ar"
                  placeholder={t("ABOUT AGENT")}
                  name="about_ar"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.about_ar}
                  className={` ${touched?.about_ar && errors?.about_ar
                    ? "error"
                    : ""
                    }`}
                />

              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="text-end">
                  <button disabled={loading} type="submit" className="ud-btn btn-dark" onClick={handleSubmit}>
                    {t("UPDATE PROFILE")}
                    <i className="fal fa-arrow-right-long" />
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <PhoneVerifyModal openModal={openPhoneVerifyModal} setOpenModal={setOpenPhoneVerifyModal} otpEntityType={"USER"} fetchVerifiedData={getUserProfileData} />
    </div>
  );
}

export default UpdateProfileForm;
