import { Modal } from 'antd'
import AgencyTextArea from 'components/common/AgencyTextArea';
import LoaderMu from 'components/common/LoaderMu';
import { Form, Formik } from 'formik';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { IoMdSend } from "react-icons/io";
import { toast } from 'react-toastify';
import { addCommentApi } from 'services/propertyService';
import { addCommentValidationSchema } from 'validations/ValidationSchema';
import CommentList from './CommentList';
import { Link } from 'react-router-dom';
import { nameBasedProtectedRoutes } from 'router/protected';
import Cookies from 'universal-cookie';
const CommentModal = ({ modalData, modalStatus, setModalStatus }) => {

	const { t } = useTranslation()

	const { i18n } = useTranslation()
	const cookies = new Cookies()

	const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

	const [loading, setLoading] = useState(false)
	const [listApiCall, setListApiCall] = useState(true)

	const handleOk = () => {
		setModalStatus(false);
	};
	const handleCancel = () => {
		setModalStatus(false);
	};

	const handleSendMessage = (values, actions) => {
		const formData = {
			propertyId: modalData?.id,
			commentText: values?.comment
		}
		addCommentApi(formData).then((response) => {
			toast.success(response?.message)
			// setModalStatus(false)
			setListApiCall(!listApiCall)
			actions.resetForm()
		}).catch((error) => {
			toast.error(
				error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message
			);
		})
	}
	return (
		<div>
			<LoaderMu loading={loading} />
			<Modal
				title={<div>
					<Link to={`${nameBasedProtectedRoutes?.propertyEdit?.path}/${modalData?.id}`} className='text-decoration-underline mb-2' >{modalData?.title}</Link>
					<p> {t("AMAKEN ID")} :  ({modalData?.amakenId})</p></div>}
				open={modalStatus}
				onOk={handleOk}
				onCancel={handleCancel}
				width={"800px"}
				footer={false}
				className={`${currentLanguage === 'ar' ? 'direction-rtl' : ''}`}
				style={{
					top: 20,
				}}>
				<Formik
					initialValues={{
						comment: ""
					}}
					validationSchema={addCommentValidationSchema}
					onSubmit={(values, actions) => {
						actions.setSubmitting(false);
						handleSendMessage(values, actions);
					}}
				>
					{
						({ handleSubmit,
							handleChange,
							handleBlur,
							values,
							setFieldValue,
							setFieldError,
							touched,
							isValid,
							errors, }) => (
							<Form>
								<div className="row">
									<div className="col-12">
										<AgencyTextArea
											rows={2}
											cols={30}
											// label={t("ADD COMMENT")}
											id="comment"
											placeholder={t("ADD COMMENT")}
											name="comment"
											handleChange={handleChange}
											handleBlur={handleBlur}
											value={values?.comment}
											className={` ${touched?.comment && errors?.comment
												? "error"
												: ""
												}`}
										/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="d-flex justify-content-end">
										<button
											type="submit"
											className="ud-btn btn-dark"
											onClick={handleSubmit}
											disabled={loading}
										>
											{t("SEND")}
											<IoMdSend className='ms-2' size={22} />

										</button>
									</div>
								</div>
							</Form>
						)
					}
				</Formik>
				<CommentList propertyId={modalData?.id} listApiCall={listApiCall} />
			</Modal>
		</div>
	)
}

export default CommentModal
