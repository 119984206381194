
import logoImg2 from "../../assets/images/Amaken-logo2.svg"
import RegisterBg from "../../assets/images/icon/register-page-icon.svg"
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import AgencyModal from "./AgencyModal";
import AgentModal from "./AgentModal";
import { useTranslation } from "react-i18next";
import LoaderMu from "components/common/LoaderMu";
import { TabPanel, TabView } from "primereact/tabview";
import Cookies from "universal-cookie";

export const Register = () => {

  const { t } = useTranslation()

  const { i18n } = useTranslation()

  const cookies = new Cookies();

  const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

  const [loading] = useState()


  return (
    <div className="d-flex flex-column bgc-f7 pageContainer p-3 h-100 overflow-auto">
      <LoaderMu loading={loading} />
      <div className="wrapper ovh">
        <div className="body_content">
          <section className="our-compare pt60 pb60">
            <img className="login-bg-icon wow fadeInLeft position-fixed" src={RegisterBg} alt="Register Bg" />
            <div className="container">
              <div className="row wow fadeInRight" data-wow-delay="300ms">
                <div dir={currentLanguage === 'ar' ? 'rtl' : ''} className="col-lg-6">
                  <div className="log-reg-form signup-modal form-style1 bgc-white p50 p30-sm default-box-shadow2 bdrs12">
                    <div className="header-logo text-center mb40">
                      {/* TO WEBSITE MAIN PAGE */}
                      <a href={process.env.REACT_APP_AGENCY_WEB_URL}>
                        <img className="mb25" src={logoImg2} alt="Header Logo" />
                      </a>
                      <h2>{t("SIGN UP")}</h2>
                      <p className="text">{t("SIGN IN TO YOUR ACCOUNT WITH YOUR CREDENTIALS.")}</p>
                    </div>
                    {/* PRIME REACT TAB COMPONENT */}
                    <TabView className="pr_tabView registerTab">
                      {/* AGENCY TAB*/}
                      <TabPanel header={t("AGENCY")} key="tab1" headerClassName="pr_tabHeader me-2">
                        <AgencyModal />
                      </TabPanel>
                      {/* AGENT TAB */}
                      <TabPanel header={t("AGENT")} key="tab2" headerClassName="pr_tabHeader me-2">
                        <AgentModal />
                      </TabPanel>
                    </TabView>
                    <p className="dark-color text-center mb0 mt10">
                    {t("ALREADY SIGNED UP")}{" "}
                    <Link className="dark-color fw600" to="/login">
                      {t("SIGN IN")}
                    </Link>
                  </p>
                  </div>
                </div>
                {/* BOOTSTRAP TAB COMPONENT */}
                {/* <nav>
                          <div
                            className="nav nav-tabs mb30 justify-content-center"
                            id="nav-tab"
                            role="tablist"
                          >
                            <button
                              className="nav-link active fz18 fw600 mx-2 px-4"
                              id="nav-home-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-home"
                              type="button"
                              role="tab"
                              aria-controls="nav-home"
                              aria-selected="true"
                            >
                              {t("AGENCY")}
                            </button>
                            <button
                              className="nav-link fz18 fw600 mx-2 px-4"
                              id="nav-profile-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#nav-profile"
                              type="button"
                              role="tab"
                              aria-controls="nav-profile"
                              aria-selected="false"
                            >
                              {t("AGENT")}
                            </button>
                          </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent2">
                        
                          <div
                            className="tab-pane fade show active fz15"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                          >
                            <AgencyModal />
                          </div>
                        
                          <div
                            className="tab-pane fade fz15"
                            id="nav-profile"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                          >
                            <AgentModal />
                          </div>
                        </div> */}


              </div>
            </div>
          </section>
          <a className="scrollToHome" href="#">
            <i className="fas fa-angle-up" />
          </a>
        </div>
      </div>
    </div>

  )
}
