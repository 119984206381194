import { Timeline } from 'primereact/timeline';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import { recentActivityApi } from 'services/dashboardService';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { nameBasedProtectedRoutes } from 'router/protected';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';

const ActivityLogs = () => {

    const { t } = useTranslation()

    const { i18n } = useTranslation()

    const [activityList, setActivityList] = useState([])

    const [loading, setLoading] = useState(true)

    const [hasMore, setHasMore] = useState(true);


    const [currentPage, setCurrentPage] = useState(0)

    const cookies = new Cookies()

    const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'


    // FETCH RECENT ACTIVITIES LIST
    const fetchRecentActivities = () => {

        setLoading(true)
        let params = {
            sort: 'createdAt,DESC',
            size: 10,
            page: currentPage
        }

        recentActivityApi(params).then((response) => {
            const newData = response?.data
            setActivityList(prevData => [...prevData, ...response?.data])
            setCurrentPage(prevPage => prevPage + 1);
            setHasMore(newData?.length > 0);
        }).catch((error) => {
            toast.error(
                error?.response?.data?.error_description
                  ? error?.response?.data?.error_description
                  : error?.response?.data?.message ?? error?.message
              );
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {

        if (activityList?.length === 0) {
            fetchRecentActivities()
        }

    },[])



    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading || !hasMore) return;
            fetchRecentActivities();
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [currentPage, loading]); // Listen for changes in currentPage and loading

    const customizedContent = (item) => {
        let activityTitle = ""
        let redirectLink = ""
        if (item?.entityName === "Property") {
            const entityData = JSON.parse(item?.entityData)
            activityTitle = item?.entityTitle[currentLanguage]
            redirectLink=<Link to={`${nameBasedProtectedRoutes?.propertyList?.path}?search=${entityData?.amakenId}`} className="fw600 text-thm2">{activityTitle}</Link>
        } else {
            const entityData = JSON.parse(item?.entityData)
            activityTitle = entityData?.property?.title[currentLanguage]
            redirectLink=<a target="_blank" href={`${process.env.REACT_APP_WEBSITE_WEB_URL}/property-details/${entityData?.property?.id}`} className="fw600 text-thm2">{activityTitle}</a>
        }

        return (
            <div className='timeline-card'>
                <p className='mb-0'>{item?.message[currentLanguage]}</p>
                    {redirectLink}
                <p className='fs-14 text-secondary'>{moment(item?.createdAt).fromNow()}</p>
            </div>
        );
    };

    const customizedMarker = (item) => {

        if (item?.actionType === 'PROPERTY_FAVOURITE_TOGGLE') {
            return <span className="timeline-icon">
                <span class="icon flaticon-like flex-shrink-0"></span>
            </span>
        } else {
            return <span className="timeline-icon">
                <span className="icon flaticon-review flex-shrink-0" />
            </span>
        }

    };
  
    return (
        <div className="dashboard__content bgc-f7">
            <div className="row align-items-center pb20">
                <div className="col-lg-12">
                    <div className="dashboard_title_area">
                        <h2>{t("ACTIVITIES")}</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div className="col-md-12">
                    <Timeline value={activityList} align='alternate' content={customizedContent} marker={customizedMarker} />
                </div>

                {loading && <div className='text-center'><Spin />Loading...</div>}
                {/* {!loading && !hasMore && <div>End of data</div>} */}
            </div>
        </div>
    )
}

export default ActivityLogs