import DashHeader from "components/dashboard/DashHeader";
import moment from "moment";
import { useEffect, useState } from "react";
import AgencyDashboard from "components/dashboard/Agency";
import { useSelector } from "react-redux";
import AgentDashboard from "components/dashboard/Agent";
// import { useSelector } from "react-redux";

export const Dashboard = () => {

  const userData = useSelector((state) => state?.user?.userData)

  const dateFormat = "YYYY-MM-DD";
  // First date of the year
  const firstDateOfYear = moment().startOf('year').format(dateFormat);

  // Last date of the year
  const lastDateOfYear = moment().format(dateFormat);
  // const lastDateOfYear = moment().format(dateFormat)


  const [startDate, setStartDate] = useState(firstDateOfYear)
  const [endDate, setEndDate] = useState(lastDateOfYear)



  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, [])


  return (
    <div className="dashboard__content bgc-f7">
      <DashHeader startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
      {
        userData?.authorities?.includes("ROLE_AGENCY_ADMIN") ?

          <AgencyDashboard startDate={startDate} endDate={endDate} /> :
          <AgentDashboard startDate={startDate} endDate={endDate} />
      }
    </div>

  )
}
