import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { IoMdDownload } from 'react-icons/io'
import { toast } from 'react-toastify'
import { locationReportDownload } from 'services/reportsServices'

const LocationReport = ({ startDate, endDate }) => {

  const [loading, setLoading] = useState(false)

  const {t} = useTranslation()

  // LOCATION REPORT DOWNLOAD
  const handleReportDownload = () => {
    const params = {
      startDate: startDate,
      endDate: endDate
    }
    // CALL API HERE TO GET FILE DATA
    locationReportDownload(params).then((response) => {
      setLoading(true)
      if (response?.data) {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const blobUrl = window.URL.createObjectURL(blob);

        const tempLink = document.createElement('a');
        tempLink.href = blobUrl;
        tempLink.setAttribute('download', 'Location.xlsx');

        // Append the link to the document body before clicking it
        document.body.appendChild(tempLink);

        tempLink.click();

        // Clean up by revoking the Blob URL
        window.URL.revokeObjectURL(blobUrl);

        // Remove the link from the document body after clicking
        document.body.removeChild(tempLink);
      } else {
        throw new Error('Response data is empty.');
      }
    }).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    }).finally(() => {
      setLoading(false)
    })
  }
  return (
    <div>
    {/* DOWNLOAD LOCATION REPORT BUTTON */}
      <Button
        className="ud-btn btn-dark"
        type="submit"
        onClick={handleReportDownload} disabled={loading ?? false}>
        <IoMdDownload size={20} /> {t("LOCATION REPORT")}
      </Button>
    </div>
  )
}

export default LocationReport