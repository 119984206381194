import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { payCitApi } from 'services/packageService';

const AuthenticatePaymentResponse = () => {

  const location = useLocation()

  const params = new URLSearchParams(location?.search)

  // PROPERTY ID
  const orderId = params.get('orderId');
  const transactionId = params.get('transactionId');
  const subscriptionId = params.get("subscriptionId")
  const retryPayment = params.get('retryPayment') ?? false

  const dispatch = useDispatch()

  const [responseData, setResponseData] = useState({})
  // const agreementId = params.get("agreementId")

  useEffect(() => {
    const payCIT = async () => {
      const params = {
        orderId: orderId,
        transactionId: transactionId,
        subscriptionId: subscriptionId,
        retryPayment : retryPayment
      }
      payCitApi(params).then(response => {
        window.top.location.href = `/${process.env.REACT_APP_BASE_URL}payment/response`;
        setResponseData(response?.paymentResult)
      }).catch((error) => {
        console.log(error)
      })
    };

    payCIT();
  }, []); // Empty dependency array ensures this runs only once on mount

  return (
    <div className='py-5'>
      <p className='text-center'>Please wait payment processing....</p>
    </div>
  )
}

export default AuthenticatePaymentResponse