import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import moment from "moment";
import { Modal, Popconfirm } from 'antd';



export const UnSubscriptionTemplate = ({ row, unsubscribe }) => {
  const { t } = useTranslation()
  return (
    <div className="text-center text-lg-end header_right_widgets">
      <ul className="mb0 d-flex justify-content-center justify-content-sm-end p-0">
        <li className="user_setting">
          <div className="dropdown">
            <Link to="#" className="btn px5" data-bs-toggle="dropdown">
              <span className="fas fa-ellipsis-v" />
            </Link>
            <div className="dropdown-menu w-auto actions">
              <div className="user_setting_content">
                <p className="fz14 fw400 ff-heading text-gray mb-1 bb1">
                  {t("ACTIONS")}
                </p>
                {/* CONFIRM BEFORE SUBSCRIBE PLAN */}
                <Popconfirm
                  title={t("UNSUBSCRIBE")}
                  description={t("ARE YOU SURE TO UN-SUBSCRIBE")}
                  onConfirm={() => unsubscribe(row?.id)} // IF USER WANTS TO BLOCK
                  okText={t("YES")}
                  cancelText={t("NO")}
                >
                  <Link className="dropdown-item p10 lh0 mb5">
                    {t("UNSUBSCRIBE")}
                  </Link>
                </Popconfirm>

              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

// PACKAGE TYPE COLUMN  TEMPLATE
export const PackageTypeTemplate = ({ row }) => {
  return <span className="pending-style style2"> {row?.validityType}</span>;
};

// PACKAGE STATUS COLUMN TEMPLATE
export const PackageStatusTemplate = ({ row }) => {
  const { t } = useTranslation()
  return row?.active == true ?
    <div className="packageStatusCss"><span className="pending-style style4"> {t("ACTIVE")}</span></div>
    : <div className="packageStatusCss"><span className="pending-style style3"> {t("INACTIVE")}</span></div>

};

// PACKAGE START DATE TEMPLATE
export const PackageStartDateTemplate = ({ row }) => {
  return <div className="w-120"><span>{moment(row?.subscriptionStartDate).format('DD MMM YYYY')}</span></div>
}

// PACKAGE END DATE TEMPLATE
export const PackageEndDateTemplate = ({ row }) => {
  return <div className="w-120"><span>{moment(row?.subscriptionEndDate).format('DD MMM YYYY')}</span></div>
}
// PACKAGE CANCEL DATE TEMPLATE
export const CancelDateTemplate = ({ row }) => {
  return <div className="w-120"><span>{row?.unSubscribedDate != null ? moment(row?.unSubscribedDate).format('DD MMM YYYY') : '-'}</span></div>
}
