import Button from 'react-bootstrap/Button';
import logoImg2 from "../../../assets/images/Amaken-logo2.svg"
import forgotBg from "../../../assets/images/icon/login-page-icon.svg"
import React, { useState } from 'react'
import InputText from "../../../components/common/InputText"
import { ForgotPasswordSchema } from '../../../validations/ValidationSchema';
import { forgotPasswordAPI } from '../../../services/userService';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from "formik"
import { toast } from "react-toastify";
import { useTranslation } from 'react-i18next';
import LoaderMu from 'components/common/LoaderMu';
import { nameBasedRoutes } from 'router/public';
import Cookies from 'universal-cookie';

export const ForgotPassword = () => {

  const { t } = useTranslation()

  const { i18n } = useTranslation()

  const cookies = new Cookies();

  const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState()
  const navigate = useNavigate();

  // Submit onboarding handleChange
  const handleSubmit = async (value, actions) => {
    submitForm(value)
  }
  const submitForm = async (value) => {
    const email = value.email
    forgotPasswordAPI({
      email: email.toString(),
      panel: 'AGENCY',
    })
      .then(response => {
        setLoading(false)
        toast.success(response?.message)
      })
      .catch(error => {
        setLoading(false)
        toast.error(error.response.data.error_description ? error.response.data.error_description : error?.response?.data?.message)
      })
  }

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <LoaderMu loading={loading} />
      <div className="wrapper ovh">
        <div className="body_content">
          <section className="our-compare pt60 pb60">
            <img className="login-bg-icon wow fadeInLeft" src={forgotBg} alt="Forgot bg" />
            <div className="container">
              <div className="row wow fadeInRight" data-wow-delay="300ms">
                <div dir={currentLanguage === 'ar' ? 'rtl' : ''} className="col-lg-6">
                  <div className="log-reg-form signup-modal form-style1 bgc-white p50 p30-sm default-box-shadow2 bdrs12">
                    <div className="header-logo text-center mb40">
                      {/* REDIRECT TO AGENCY MAIN PAGE */}
                      <a href={process.env.REACT_APP_AGENCY_WEB_URL}>
                        <img className="mb25" src={logoImg2} alt="forgot logo" />
                      </a>
                      <h2>{t("FORGET PASSWORD")}</h2>
                      <p className="text">
                        {t('ENTER YOUR EMAIL ADDRESS TO RESET YOUR PASSWORD.')}
                      </p>
                    </div>
                    <Formik
                      validationSchema={ForgotPasswordSchema}
                      initialValues={
                        {
                          email: data.email ? data.email : "",
                        }
                      }
                      onSubmit={(values, actions) => {
                        actions.setSubmitting(false)
                        handleSubmit(values, actions)
                      }}
                    >
                      {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        setFieldValue,
                        touched,
                        isValid,
                        errors
                      }) => (
                        <Form>
                          {/* ENTER EMAIL */}
                          <div className="mb5">
                            <InputText
                              controlId="email"
                              label={t("ENTER EMAIL")}
                              value={values.email}
                              name="email"
                              maxLength="300"
                              errorsField={errors.email}
                              touched={touched.email}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              className="form-control"
                            />
                            <span className="form-label fz12 mb0 fw600 mx10 text-danger" />
                          </div>
                          {/* HANDLE SUBMIT */}
                          <div className="d-grid mb20">
                            <Button
                              variant="primary"
                              onClick={handleSubmit}
                              className="ud-btn btn-thm"
                              type="submit"
                              disabled={loading}
                            >
                              {t("GET RESET LINK")}
                              <i className="fal fa-arrow-right-long" />
                            </Button>
                          </div>
                          {/* BACK TO LOGIN PAGE */}
                          <div className="d-grid mb20">
                            <Link
                              className="ud-btn btn-white"
                              type="button"
                              to={nameBasedRoutes?.login?.path}
                            >
                              {t("BACK")} <i className="fal fa-arrow-left-long" />
                            </Link>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <a className="scrollToHome" href="#">
            <i className="fas fa-angle-up" />
          </a>
        </div>
      </div>
    </div>
  )
}
