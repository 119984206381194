import React from 'react'
import RecentActivities from '../RecentActivities'
import AgencyMainStats from "./AgencyMainStats";
import PackageUsage from "./PackageUsage";
import MonthWiseProperty from '../CommonCharts/MonthWiseProperty';
import GovernorateChart from '../CommonCharts/GovernorateChart';
import PropertyTypesChart from '../CommonCharts/PropertyTypesChart';
import TopAgents from './TopAgents';
import MostViewedProps from '../CommonCharts/MostViewedProps';
import AgentWisePropertyStatus from './AgentWisePropertyStatus';
import AgentWisePropertyTypes from './AgentWisePropertyTypes';
import PropertyTypeViewChart from '../CommonCharts/PropertyTypeViewChart';
import { useSelector } from 'react-redux';
import NoActiveSubscription from 'components/package/NoActiveSubscription';
const AgencyDashboard = ({ startDate, endDate }) => {

  const currentPackage = useSelector((state) => state?.userPackage)

  return (
    <div>
      {/* AGENCY STATS */}
      <AgencyMainStats startDate={startDate} endDate={endDate} />
      <div className="row">
        <div className="col-xl-12">
          {/* PACKAGE USAGE */}
          {
            currentPackage ?
              currentPackage?.status === "ACTIVE" || currentPackage?.status === "INACTIVE" ?
                <PackageUsage />
                :
                "" :
              <NoActiveSubscription />
          }
        </div>
      </div>
      <div className="row">
        {/* GOVERNORATE CHART */}
        <div className="col-xl-6">
          <GovernorateChart startDate={startDate} endDate={endDate} currentRole={"AGENCY"} />
        </div>
        {/* PROPERTY TYPES CHART */}
        <div className="col-xl-6">
          <PropertyTypesChart startDate={startDate} endDate={endDate} currentRole={"AGENCY"} />
        </div>
      </div>
      {/* MONTH WISE PROPERTY */}
      <div className='row'>
        <div className="col-xl-6">
          <MonthWiseProperty currentRole={"AGENCY"} />
        </div>
        <div className="col-xl-6">
          <PropertyTypeViewChart currentRole={"AGENCY"} startDate={startDate} endDate={endDate} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6">
          <AgentWisePropertyStatus startDate={startDate} endDate={endDate} />
        </div>
        <div className="col-xl-6">
          <AgentWisePropertyTypes startDate={startDate} endDate={endDate} />
        </div>
      </div>
      <div className="row">
        {/* TOP AGENTS */}
        <div className='col-lg-12 col-xl-3'>
          <TopAgents startDate={startDate} endDate={endDate} />
        </div>
        {/* MOST VIEWED PROPERTIES */}
        <div className='col-lg-12 col-xl-5'>
          <MostViewedProps currentRole={"AGENCY"} startDate={startDate} endDate={endDate} />
        </div>
        {/* RECENT ACTIVITIES */}
        <div className="col-lg-12 col-xl-4">
          <RecentActivities />
        </div>
      </div>
    </div>
  )
}

export default AgencyDashboard