import React from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { BiExport } from "react-icons/bi";
// COMMON CSV EXPORT BUTTON
const DownloadCSVButton = ({ csvData ,filename}) => {

    const {t} = useTranslation()

    return (
        <CSVLink data={csvData} filename={filename ?? 'data.csv'} className='ud-btn btn-white px-3 py-2 fw-bold'>
            <BiExport size={23} className='me-2 mb-1'/>
            {t("EXPORT CSV")}
        </CSVLink>
    );
};

export default DownloadCSVButton;
