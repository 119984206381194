import LoaderMu from 'components/common/LoaderMu'
import ActiveSubscriptionPlan from 'components/package/ActiveSubscriptionPlan'
import NoActiveSubscription from 'components/package/NoActiveSubscription'
import SubscriptionList from 'components/package/SubscriptionList'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { nameBasedProtectedRoutes } from 'router/protected'
const Package = () => {

  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const packageData = useSelector((state) => state?.userPackage)

  return (
    <div className="dashboard__content bgc-f7">
      <LoaderMu loading={loading} />
      <div className="row align-items-center pb20">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>{t("MY PACKAGE")}</h2>
          </div>
        </div>
      </div>
      {
        packageData && packageData !== "" ?
          <ActiveSubscriptionPlan /> :
          <div className="row">
            <div className="col-md-12">
              <NoActiveSubscription />
            </div>
          </div>
      }
      <div className="row mt30 justify-content-between align-items-center">
        <div className="col">
          <h4>{t("PLAN HISTORY")}</h4>
        </div>
        <div className="col-auto">
          <Link to={nameBasedProtectedRoutes?.orders?.path} className='ud-btn btn-thm'>{t('VIEW ORDERS')} <i className="fal fa-arrow-right-long" /></Link>
        </div>
        <div className="col-xl-12">
          <SubscriptionList />
        </div>
      </div>
    </div>
  )
}
export default Package