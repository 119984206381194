import React from "react";
import { FormLabel } from "react-bootstrap";
import { ErrorMessage } from "formik";
import { Select } from "antd";
import "./ant_select.scss";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
function AgencySelect({
  controlId,
  label,
  options,
  disabled,
  placeholder,
  handleChange,
  handleBlur,
  value,
  rightIcon,
  size,
  name,
  className,
  mode,
  showSearch,
  enableScrollPagination,
  handleSearch,
  handleScroll,
  filterOption,
  ...rest
}) {

  const { i18n } = useTranslation()
  const cookies = new Cookies()

  const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'


  return (
    <div className="mb20">
      <FormLabel className="heading-color ff-heading fw600 mb10">
        {label}
      </FormLabel>
      <div className="location-area bootstrap-select user-select">
        <Select
          // ref={selectRef}
          virtual={false}
          filterOption={filterOption}
          showSearch={showSearch}
          placeholder={placeholder}
          id={name}
          name={name}
          onChange={handleChange}
          onSearch={handleSearch}
          onBlur={handleBlur}
          onPopupScroll={handleScroll}
          mode={mode}
          value={value}
          className={className}
          disabled={disabled}
          dropdownRender={(menu) => (
            <div className={`custom-antd-dropdown ${currentLanguage === 'ar' ? 'direction-rtl' :''}`}>
              {menu}
            </div>
          )}
          options={options}
        />
        <ErrorMessage name={name} component="div" className="text-danger" />
      </div>
    </div>
  );
}

export default AgencySelect;
