import logoImg2 from "../../../assets/images/Amaken-logo2.svg";
import forgotBg from "../../../assets/images/icon/login-page-icon.svg";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { ForgotPasswordSchema } from "../../../validations/ValidationSchema";
import { getEmailVerificationAPI } from "../../../services/userService";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useTranslation } from "react-i18next";

export const EmailVerify = () => {
  const [data] = useState([]);

  const {t} = useTranslation()
  const [loading, setLoading] = useState();
  const location = useLocation();
  const [received, setReceived] = useState("");

  const params = new URLSearchParams(location.search);
  const verificationKey = params.get("key");


  // Get list details
  const fetchData = (params) => {
    getEmailVerificationAPI({ ...params })
      .then((response) => {
        setReceived(t('THANK YOU FOR VERIFICATION.'))
        setLoading(false);
        toast.success(t('THANK YOU FOR VERIFICATION.'));
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error.response.data.error_description
            ? error.response.data.error_description
            : error.response.data?.message
        );
        setReceived(error.response.data.error_description
          ? error.response.data.error_description
          : error.response.data?.message)
      });
  };

  // Get onboarding
  useEffect(() => {
    setLoading(true);
    fetchData({
      key: verificationKey,
    });
  }, [verificationKey]);

  return (
    <>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">

          <div className="body_content">
            {/* Our Compare Area */}
            <section className="our-compare pt60 pb60">
              <img
                className="login-bg-icon wow fadeInLeft"
                src={forgotBg}
                alt="Forgot bg"
              />
              <div className="container">
                <div
                  className="row wow fadeInRight"
                  data-wow-delay="300ms"
                >
                  <div className="col-lg-6">
                    <div className="log-reg-form signup-modal form-style1 bgc-white p50 p30-sm default-box-shadow2 bdrs12">
                      <div className="header-logo text-center mb40">
                        <a href={process.env.REACT_APP_AGENCY_WEB_URL}>
                          <img
                            className="mb25"
                            src={logoImg2}
                            alt="forgot logo"
                          />
                        </a>
                        <h2>{t("EMAIL VERIFICATION")}</h2>
                        <p className="text">
                          {received}
                        </p>
                      </div>

                      <div className="d-grid mb20">
                        <a
                          className="ud-btn btn-thm"
                          type="button"
                          href="login"
                        >
                          {t("BACK")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <a className="scrollToHome" href="#">
              <i className="fas fa-angle-up" />
            </a>
          </div>

        </div>
      )}
    </>
  );
};
