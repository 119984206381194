import React from "react";
import { FormLabel } from "react-bootstrap";
import { Field, ErrorMessage } from "formik";

function AgencyInputText({
  type,
  label,
  disabled,
  placeholder,
  handleChange,
  handleBlur,
  value,
  name,
  className,
  maxLength,
  max,
  multiline,
  readOnly,
  autoComplete,
  dir,
  ...rest
}) {
  return (
    <div className="mb20">
      <FormLabel className="heading-color ff-heading fw600 mb10">
        {label}
      </FormLabel>
      <Field
        type={type ? type : 'text'}
        placeholder={placeholder}
        dir={dir}
        id={name}
        readOnly={readOnly}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        className={className}
        disabled={disabled}
        autoComplete={autoComplete}
       {...rest}
      />
      <ErrorMessage name={name} component="div" className="text-danger"/>
    </div>
  );
}

export default AgencyInputText;
