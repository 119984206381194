import { useLocation, useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  ListingTitleTemplate,
  ViewDateTemplate,
  StatusTemplate,
  UpdatedDateTemplate,
} from "./RowTemplates";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { useTranslation } from "react-i18next";
import { MyPropertyListApi } from "services/propertyService";
import { toast } from "react-toastify";
import LoaderMu from "components/common/LoaderMu";
import { useSelector } from "react-redux";
import { Select } from "antd";
import { FaCheck } from "react-icons/fa";
import { ActionRowTemplate } from "./ActionRowTemplate";
import CommentModal from "components/property/propertylist/CommentModal";
import { nameBasedProtectedRoutes } from "router/protected";
import Cookies from "universal-cookie";

export const Properties = () => {

  const userData = useSelector((state) => state?.user?.userData)

  const { t } = useTranslation()

  const { i18n } = useTranslation()
  const cookies = new Cookies()

  const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

  const navigate = useNavigate()

  // GET DATA FROM URL PARAMS
  const location = useLocation();
  const params = new URLSearchParams(location?.search)
  const search = params.get("search") ?? ''
  const [currentPage, setCurrentPage] = useState(params.get("page") ?? 1)
  const [searchQuery, setSearchQuery] = useState(search ?? "")
  // SORTING VALUE STATE
  const [sortValue, setSortValue] = useState("new")
  const [loading, setLoading] = useState(false)

  // PAGINATIN STATES
  const [pageSize, setPageSize] = useState(5)
  const [totalRowCount, setTotalRowCount] = useState(0)
  const pageOffset = (parseInt(currentPage) - 1) * pageSize + 1

  // LIST STATE
  const [propertyListData, setPropertyListData] = useState([])

  // MODAL SELECTION STATE
  const [selectedRow, setSelectedRow] = useState({})
  const [commentModalOpen, setCommentModalOpen] = useState(false)

  // const ActiveUserPackage = useSelector((state) => state?.userPackage)

  // NAVIGATE CHECK PACKAGE VALIDATION
  const navigateForPackageValidation = () => {
    navigate(nameBasedProtectedRoutes?.propertyAdd?.path)
    // setLoading(true)
  }
  // HANDLE PAGE CHANGE
  const handlePageChange = (event, value) => {
    setCurrentPage(value)
    // navigate(`${nameBasedProtectedRoutes?.propertyList?.path }?page=${value}`)
    navigate(`${nameBasedProtectedRoutes?.propertyList?.path}?page=${value}${search !== '' ? '&search=' + search : ''}`)
  }

  // FETCH PROPERTY LISTING DATA FROM API
  const fetchListingData = () => {

    console.log("calling")
    setLoading(true)
    // Initialize parameters with default values
    let params = {
      size: pageSize
    };

    // Add search query if provided
    if (searchQuery) {
      params.search = searchQuery.trim();
    }

    // Set current page for pagination
    if (currentPage) {
      params.page = currentPage - 1;
    }

    // Manage sorting parameters
    if (sortValue && sortValue !== '') {
      // Initialize an array to hold sort parameters
      let sortParams = [];

      // Add sorting based on user selection
      switch (sortValue) {
        case "new":
          sortParams.push('listingDate,DESC');
          break;
        case "price_high":
          sortParams.push('priceInJod,DESC');
          break;
        case "price_low":
          sortParams.push('priceInJod,ASC');
          break;
      }

      // Always add sorting by `id` in descending order
      sortParams.push('id,DESC');

      // Convert sort parameters to query string format without indexing
      params.sort = sortParams.join('&sort=');
    }
    //CALLING PROPERTY LIST API
    MyPropertyListApi(params).then(response => {
      setPropertyListData(response?.data ?? [])
      setTotalRowCount(response?.headers["x-total-count"])
      // IF TOTAL ROW COUNT ARE LESS THAN CURRENT PAGE SIZE THEN NAVIGATE TO PAGE 1
      if (response?.headers["x-total-count"] < pageSize) {
        navigate(`${nameBasedProtectedRoutes?.propertyList?.path}?page=${1}${search !== '' ? '&search=' + search : ''}`)
        setCurrentPage(1)
      }
      setLoading(false)
    }).catch((error) => {
      toast.error(
        error.response.data.error_description
          ? error.response.data.error_description
          : error.response.data?.message ?? error?.message,
        {
          toastId: "error"
        }
      );
      setLoading(false)
    })
  }

  // THIS IS FOR SEARCHING
  const handleValueChange = (value) => {
    setSearchQuery(value)
    if (value !== "") {
      navigate(`${nameBasedProtectedRoutes?.propertyList?.path}?page=${currentPage}&search=${encodeURIComponent(value)}`)
    } else {
      navigate(`${nameBasedProtectedRoutes?.propertyList?.path}?page=${currentPage}`)
    }
  };

  // CALL FUNCTION COMPONENT RENDER , SEARCH , CURRENT PAGE , SORT
  useEffect(() => {
    fetchListingData()
  }, [searchQuery, currentPage, sortValue])
  return (
    <div className="dashboard__content property-page bgc-f7">
      <LoaderMu loading={loading} />
      <div className="row align-items-center pb20">
        <div className="col-xxl-3">
          <div className="dashboard_title_area">
            <h2>{t('MY PROPERTIES')}</h2>
          </div>
        </div>
        <div className="col-xxl-9">
          <div className="dashboard_search_meta d-md-flex align-items-center justify-content-xxl-end">
            {/* SEARCH */}
            <div className="item1 mb15-sm">
              <div className="search_area me-2">
                <InputText
                  value={searchQuery}
                  onChange={(event) => { handleValueChange(event?.target?.value) }}
                  placeholder={t("SEARCH")}
                  className="form-control bdrs12 "
                />
                <label>
                  <span className="flaticon-search" />
                </label>
              </div>
            </div>
            {/* SORTING */}
            <div className="page_control_shorting bdr1 bdrs12 ps-3 pe-2 mx-1 mx-xxl-3 bgc-white mb15-sm sort-dropdown">
              <span className="text-nowrap d-block text-dark">{t("SORT BY")} :</span>
              <Select
                id="property_sort"
                placeholder={t("SELECT")}
                name="property_sort"
                value={sortValue}
                menuItemSelectedIcon={<FaCheck className="text-dark" />}
                onChange={(value) => {
                  setSortValue(value);
                }}
                popupClassName={`${currentLanguage === 'ar' ? 'direction-rtl' : ''}`}
                className={`w-100 selectpicker`}
                options={[{
                  label: t("NEW"),
                  value: "new"
                },
                {
                  label: t("PRICE HIGH"),
                  value: "price_high"
                },
                {
                  label: t("PRICE LOW"),
                  value: "price_low"
                }]}
              />
            </div>

            {/* ADD NEW PROPERTY AND CHECK USER HAS VALID PACKAGE OR NOT */}
            <div onClick={navigateForPackageValidation} className="ud-btn btn-thm cursorPointer">
              {t("ADD NEW PROPERTY")}
              <i className="fal fa-arrow-right-long" />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30  position-relative">
            <div className="packages_table table-responsive">
              {/* DATA TABLE */}
              <DataTable
                scrollable
                value={propertyListData ?? []}
                tableClassName="table-style3 table at-savesearch"
                cellClassName="vam"

              >
                {/* TITLE COLUMN */}
                <Column
                  field="title"
                  header={t("LISTING TITLE")}
                  style={{ minWidth: '450px' }}
                  body={(row) => <ListingTitleTemplate row={row} />}
                ></Column>

                {/* LISTING DATE */}
                <Column field="listingDate"
                  header={t('LISTING DATE')}
                  style={{ minWidth: '50px' }}
                  body={(row) => <ViewDateTemplate row={row} />}></Column>
                {/* EXACT LOCATION NOT DELIVERABLE FOR NOW*/}
                {/* <Column field="exactLocation"
                  header={t('Exact Location')}
                  style={{ minWidth: '180px' }}
                  body={(row) => <ShowLocationTemplate row={row} />}></Column> */}
                {/* STATUS COLUMN */}
                <Column
                  field="status"
                  header={t("STATUS")}
                  style={{ minWidth: '50px' }}
                  body={(row) => <StatusTemplate row={row} />}
                ></Column>
                {/* PROPERTY UPDATED DATE COLUMN */}
                <Column field="updatedAt"
                  header={t("UPDATED DATE")}
                  style={{ minWidth: '50px' }}
                  body={(row) => <UpdatedDateTemplate row={row} />} > </Column>

                {/* IF AGENCY LOGGED IN THEN SHOW AGENT COLUMN */}
                {/* {
                  userData?.authorities?.includes("ROLE_AGENCY_ADMIN") ? <Column field="Agent"
                    header={t('AGENT')}
                    style={{ minWidth: '50px' }}
                    body={(row) => <AgentTemplate row={row} />}></Column> : ""
                } */}
                {/* ACTION COLUMN */}
                <Column
                  field="action"
                  header={t("ACTION")}
                  style={{ minWidth: '50px' }}
                  body={(row) => <ActionRowTemplate row={row} getPropertyData={fetchListingData} setRowData={setSelectedRow} setCommentModalOpen={setCommentModalOpen} />}
                ></Column>
              </DataTable>

              {/* PAGINATION FROM MATERIAL UI */}
              <div className="mbp_pagination text-center mt30 dataTable_pagination">
                <Pagination count={Math.ceil(totalRowCount / pageSize)} siblingCount={1} page={parseInt(currentPage)} className="d-inline-block" size="large" onChange={(event, value) => handlePageChange(event, value)} />
                {
                  totalRowCount < pageSize ?
                    <p className="mt10 pagination_page_count text-center">
                      {(pageOffset)} – {totalRowCount} {t("OF")} {totalRowCount} {t("PROPERTIES AVAILABLE")}
                    </p> : <p className="mt10 pagination_page_count text-center">
                      {pageOffset} – {currentPage * pageSize} {t("OF")} {totalRowCount}+ {t("PROPERTIES AVAILABLE")}
                    </p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* COMMENT MODAL */}
      <CommentModal modalData={selectedRow} modalStatus={commentModalOpen} setModalStatus={setCommentModalOpen} />
    </div>
  );
};
