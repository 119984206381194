import { allPlansApi } from "services/packageService";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import PlanCard from "components/package/PlanCard";

import { Empty, Radio, Switch } from "antd";
import LoaderMu from "components/common/LoaderMu";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "router/protected";

export const Plan = () => {

  const [loading, setLoading] = useState(false)
  const [customExist, setCustomExist] = useState(false)
  const [planList, setPlanList] = useState()
  const [isYearlyPlan, setIsYearlyPlan] = useState(false)

  
  const location = useLocation()

  const params = new URLSearchParams(location?.search)

  const isModifyPlan = params.get("modify") === 'true' ? true : false
  const isUpgradePlan = params.get("upgrade") === 'true' ? true : false

  const currentPlan = useSelector((state) => state?.userPackage)

  
  const [planScope, setPlanScope] = useState("GENERAL")


  const navigate = useNavigate()

  const { t } = useTranslation()

  // FUNCTION FOR FETCH ALL PLANS

  const fetchAllPlans = () => {
    setLoading(true)
    const params = {
      all: false,
      validityType: isYearlyPlan ? "YEARLY" : "MONTHLY",
      planScope: planScope ?? "GENERAL"
    }

    if(isModifyPlan === true){
      params.planScope = currentPlan?.planData?.planScope
    }

    // CALLING PLAN LIST API
    allPlansApi(params).then((response) => {
      setLoading(false)
      let planListing = response
      if (currentPlan && currentPlan !== null && isModifyPlan === true) {
        planListing = planListing?.filter((element) => element?.id === currentPlan?.planData?.planId)
        setPlanList(planListing)
      }
      else if (currentPlan && currentPlan !== null && isUpgradePlan === true) {
        planListing = planListing?.filter((element) => element?.price > currentPlan?.planData?.planAmount)
        setPlanList(planListing)
      }
      else {
        setPlanList(planListing)
      }
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  // Check custom plan exist for this user or not
  const checkCustomePlansExist = () => {
    setLoading(true)
    const params = {
      validityType: isYearlyPlan ? "YEARLY" : "MONTHLY",
      all: true,
      planScope: "CUSTOM"
    }

    allPlansApi(params).then((response) => {
      if (response.length > 0) {
        setCustomExist(true);
      }
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
    })
  }

  // CALL FUNCTION ON COMPONENT RENDER
  useEffect(() => {
    fetchAllPlans();
    // CHECK IF CUSTOM PLAN EXISTS FOR THIS USER OR NOT
    checkCustomePlansExist();
  }, [isYearlyPlan, planScope])


// USER CAN NOT NAVIGATE TO THIS PAGE
 useEffect(()=>{
  if(currentPlan?.status === "ACTIVE" && isModifyPlan !== true && isUpgradePlan!== true){
    navigate(nameBasedProtectedRoutes?.package?.path)
  }
 },[currentPlan,isModifyPlan,isUpgradePlan])


//  useEffect(()=>{
//   setPlanScope(currentPlan?.planData?.planScope ?? 'GENERAL')
//  },[currentPlan])


  return (
    <div className="dashboard__content bgc-f7">
      <LoaderMu loading={loading} />
      <div className="row align-items-center pb20">
        <div className="col-lg-12">
          <div className="main-title text-center mb30">
            <h2 className="text-center">{t("MEMBERSHIP PLANS")}</h2>
            <p>{t("JOIN AMAKEN NOW, LET'S GROW TOGETHER")}</p>
          </div>
        </div>
      </div>
      {
        isModifyPlan !== true &&  isUpgradePlan !== true ? <div className="row wow fadeInUp" data-wow-delay="200ms" >
          <div className="col-lg-12">
            {
              isUpgradePlan !== true ? <div className="pricing_packages_top d-flex align-items-center justify-content-center ">
                <div className="toggle-btn mb20">
                  <span className="pricing_save1 ff-heading">{t("BILLED MONTHLY")}</span>
                  <Switch checked={isYearlyPlan} className='fw-bold plan-check' onChange={(value) => setIsYearlyPlan(value)} checkedChildren={t("YEARLY")} unCheckedChildren={t("MONTHLY")} />
                  <span className="pricing_save2 ff-heading">{t("BILLED YEARLY")}</span>
                </div>
              </div> : ""
            }
            <div className="text-center mb20">
              <Radio.Group defaultValue="GENERAL" className="radioButton"
                onChange={(event) => { setPlanScope(event.target.value) }}>
                <Radio.Button value="GENERAL">{t("GENERAL")}</Radio.Button>
                {customExist && <Radio.Button value="CUSTOM">{t("CUSTOM")}</Radio.Button>}
              </Radio.Group>
            </div>
          </div>
        </div> : ""
      }
      <div className="row wow fadeInUp" data-wow-delay="300ms" >
        {
          planList && planList?.length > 0 ?
            planList?.map((plan, index) => {
              return <div key={index + 1} className="col-md-6 col-xl-4 ">
                <PlanCard planDetails={plan} />
              </div>
            })
            : <Empty description={
              <span>
                {t("NO PLANS AVAILABLE")}
              </span>
            } />
        }
      </div>
    </div>
  )
};
