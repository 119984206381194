import { Form, Formik } from "formik";
import Addagentimg from "../../assets/images/team/agent-6.jpg";
import AgencyFileUpload from "components/common/AgencyFileUpload";
import AgencyInputText from "components/common/AgencyInputText";
import AgencyTextArea from "components/common/AgencyTextArea";
import AgencySelect from "components/common/AgencySelect";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { deleteAgentProfilePic, editAgent, getAgentById } from "services/agentService";
import { toast } from "react-toastify";
import LoaderMu from "components/common/LoaderMu";
import { editAgentSchema } from "validations/ValidationSchema";
import { useTranslation } from "react-i18next";

function EditAgent() {

  const { t } = useTranslation()
  // GET AGENT ID FROM URL PARAMS
  const { agentId } = useParams()
  const [loading, setLoading] = useState(false)
  const [profilePicUrl, setProfilePicUrl] = useState("")
  const navigate = useNavigate()

  // INITIAL DATA FOR FORM
  const [initialData, setIntitalData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    agency: "",
    type: "",
    address: "",
    facebook_url: "",
    instagram_url: "",
    linkedin_url: "",
    about_en: "",
    about_ar: "",
    password: "",
    confirm_password: ""
  })


  // SUBMIT FUNCTION FOR EDIT AGENT
  const handleSubmit = (values) => {
    setLoading(true)
    const formdata = new FormData()
    formdata.append("firstName", values?.firstName)
    formdata.append("lastName", values?.lastName)
    formdata.append("email", values?.email)
    formdata.append("address", values?.address)
    formdata.append("id", agentId)
    formdata.append("facebookUrl", values?.facebook_url)
    formdata.append("instagramUrl", values?.instagram_url)
    formdata.append("linkedInUrl", values?.linkedin_url)
    formdata.append("about['en']", values?.about_en)
    formdata.append("about['ar']", values?.about_ar)
    formdata.append("password", values?.password)
    formdata.append("partialAgent", values?.type === null || values?.type === "" ? false : values?.type)
    if (values?.profile_picture) {
      formdata.append("profilePicture", values?.profile_picture)
    }

    // CALLING API FOR EDIT AGENT
    editAgent(formdata).then((response) => {
      setLoading(false);
      getAgentData()
      // navigate(nameBasedProtectedRoutes?.agentListing?.path)
      // GO BACK TO PREVIOUS PAGE
      navigate(-1)
      toast.success(response?.message ?? 'Agent Updated!')
    }).catch((error) => {
      setLoading(false);
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
// GET AGENT DETAILS FORM FORM INITIAL DATA
  const getAgentData = () => {
    getAgentById(agentId).then((response) => {
      // SET PROFILE PIC URL
      setProfilePicUrl(response?.imageUrl ?? '')
      // UPDATE INITIAL DATA WITH RESPONSE DATA
      setIntitalData({
        firstName: response?.firstName ?? "",
        lastName: response?.lastName ?? "",
        email: response?.email ?? "",
        agency: response?.agency?.name,
        type: response?.partialAgent ?? "",
        address: response?.address ?? "",
        facebook_url: response?.facebookUrl
          ?? "",
        instagram_url: response?.instagramUrl ?? "",
        linkedin_url: response?.linkedInUrl ?? "",
        about_en: response?.about?.en ?? "",
        about_ar: response?.about?.ar ?? "",
        password: "",
        confirm_password: ""
      })
    }).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  // HANDLE PROFILE PICTURE DELETE
  const handleDelete = (e) => {
    e.preventDefault()
    setLoading(true);
    // CALL API FOR DELETE AGENT PROFILE PICTURE
    deleteAgentProfilePic(agentId)
      .then((response) => {
        setProfilePicUrl("")
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });
  };

  // GET AGENT DETAILS ON COMPONENT RENDER
  useEffect(() => {
    getAgentData()
  }, [agentId])

  return (
    <div className="dashboard__content property-page bgc-f7">
      <LoaderMu loading={loading} />
      <div className="row align-items-center pb20">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>{t("EDIT AGENT")}</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <Formik
              initialValues={initialData}
              onSubmit={(values, actions) => {
                actions.setSubmitting(false);
                handleSubmit(values);
              }}
              validationSchema={editAgentSchema}
              enableReinitialize={true}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                setFieldValue,
                touched,
                isValid,
                errors,
                setFieldError
              }) => (
                <Form>
                  <div className="col-lg-12">
                    <div className="form-style1">
                      <div className="row">
                        <div className="col-xl-7">
                        {/* AGENT PROFILE PICTURE */}
                          <div className="profile-box position-relative d-md-flex align-items-end mb50">
                            {
                              profilePicUrl && profilePicUrl !== "" ? <div className="profile-img position-relative overflow-hidden bdrs12 mb20-sm">
                                <img className="w-100" src={profilePicUrl} alt="Agent" />
                                {/* PROFILE PICTURE DELETE BUTTON */}
                                <span type="button" className="border-none tag-del" onClick={(e) => handleDelete(e)}>
                                  <span className="fas fa-trash-can" />
                                </span> :
                              </div> : <div className="profile-img position-relative overflow-hidden bdrs12 mb20-sm">
                                <img className="w-100" src={Addagentimg} alt="Agent" />
                              </div>
                            }

                            {/* PROFILE UPLOAD  */}
                            <div className="profile-content ml30 ml0-sm">
                              <AgencyFileUpload
                                id="title_deed"
                                accept=".jpg, .jpeg, .png"
                                name="profile_picture"
                                // ON CHANGE PROFILE PICTURE CHECK IMAGE IS VALID OR NOT
                                handleChange={(event) => {
                                  const uploadedFile = event?.target?.files[0]
                                  const allowedFileTypes = ["image/jpeg", "image/png"];
                                  const isAllowedType = allowedFileTypes.includes(uploadedFile?.type);
                                  if (!isAllowedType) {
                                    setFieldError("profile_picture", "You can only upload JPG or PNG files!")
                                  } else {
                                    setFieldValue("profile_picture", event?.target?.files[0])
                                  }
                                }}
                                fileName={values?.profile_picture}
                                className={`form-control p15 pl30 ud-btn2 ${touched?.profile_picture &&
                                  errors?.profile_picture
                                  ? "error"
                                  : ""
                                  }`}
                              />

                              <p className="text">{t("PHOTOS MUST BE JPEG OR PNG FORMAT")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                      {/* AGENT FIRST NAME */}
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                            type="text"
                            label={t("FIRST NAME")}
                            id="firstName"
                            placeholder={t("FIRST NAME")}
                            name="firstName"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.firstName}
                            className={`form-control ${touched?.firstName && errors?.firstName
                              ? "error"
                              : ""
                              }`}
                          />
                        </div>
                        {/* AGENT LAST NAME */}
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                            type="text"
                            label={t("LAST NAME")}
                            id="lastName"
                            placeholder={t("LAST NAME")}
                            name="lastName"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.lastName}
                            className={`form-control ${touched?.lastName && errors?.lastName
                              ? "error"
                              : ""
                              }`}
                          />
                        </div>
                        {/* AGENT EMAIL */}
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                            type="email"
                            label={t("EMAIL")}
                            id="email"
                            placeholder={t("EMAIL")}
                            name="email"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.email}
                            className={`form-control ${touched?.email && errors?.email ? "error" : ""
                              }`}
                          />
                        </div>
                        {/* AGENT TYPE FULL OR PARTIAL */}
                        <div className="col-sm-6 col-xl-4">
                          <AgencySelect
                            type="text"
                            label={t("TYPE")}
                            id="type"
                            placeholder={t("SELECT")}
                            name="type"
                            handleChange={(value) => setFieldValue("type", value)}
                            handleBlur={handleBlur}
                            value={values?.type}
                            options={[
                              { value: '', label: 'Select' },
                              { value: false, label: "Full" },
                              { value: true, label: 'partial' }]}
                            className={`form-control ${touched?.type && errors?.type ? "error" : ""
                              }`}
                          />
                        </div>
                        {/* AGENCY READ ONLY INPUT */}
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                            type="text"
                            label={t("AGENCY")}
                            id="agency"
                            readOnly={true}
                            placeholder={t("AGENCY")}
                            name="agency"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.agency}
                            className={`form-control ${touched?.agency && errors?.agency ? "error" : ""
                              }`}
                          />
                        </div>
                        {/* AGENT ADDRESS */}
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                            type="text"
                            label={t("ADDRESS")}
                            id="address"
                            placeholder={t("ADDRESS")}
                            name="address"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.address}
                            className={`form-control ${touched?.address && errors?.address ? "error" : ""
                              }`}
                          />
                        </div>
                        {/* AGENT FACEBOOK */}
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                            type="url"
                            label={t("FACEBOOK URL")}
                            id="facebook_url"
                            placeholder={t("FACEBOOK URL")}
                            name="facebook_url"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.facebook_url}
                            className={`form-control ${touched?.facebook_url && errors?.facebook_url
                              ? "error"
                              : ""
                              }`}
                          />
                        </div>
                        {/* AGENT INSTAGRAM */}
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                            type="url"
                            label={t("INSTAGRAM URL")}
                            id="instagram_url"
                            placeholder={t("INSTAGRAM URL")}
                            name="instagram_url"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.instagram_url}
                            className={`form-control ${touched?.instagram_url && errors?.instagram_url
                              ? "error"
                              : ""
                              }`}
                          />
                        </div>
                        {/* AGENT LINKEDIN */}
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                            type="url"
                            label={t("LINKEDIN URL")}
                            id="linkedin_url"
                            placeholder={t("LINKEDIN URL")}
                            name="linkedin_url"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.linkedin_url}
                            className={`form-control ${touched?.linkedin_url && errors?.linkedin_url
                              ? "error"
                              : ""
                              }`}
                          />
                        </div>
                        {/* AGENT ABOUT IN ENGLISH */}
                        <div className="col-md-12">
                          <AgencyTextArea
                            rows={4}
                            cols={30}
                            label={t("ABOUT AGENT (ENGLISH)")}
                            id="about_en"
                            placeholder={t("ABOUT AGENT")}
                            name="about_en"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.about_en}
                            className={` ${touched?.about_en && errors?.about_en
                              ? "error"
                              : ""
                              }`}
                          />
                        </div>
                        {/* AGENT ABOUT IN ARABIC */}
                        <div className="col-md-12">
                          <AgencyTextArea
                            rows={4}
                            cols={30}
                            dir={'rtl'}
                            label={t("ABOUT AGENT (ARABIC)")}
                            id="about_ar"
                            placeholder={t("ABOUT AGENT (ARABIC)")}
                            name="about_ar"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.about_ar}
                            className={` ${touched?.about_ar && errors?.about_ar
                              ? "error"
                              : ""
                              }`}
                          />

                        </div>
                        {/* CHANGE AGENT PASSWORD  HERE*/}
                        <h4 className="title fz17 mb30 mt30">
                          {t("CHANGE PASSWORD")}
                        </h4>
                        <div className="row">
                        {/* CREATE NEW PASSWORD */}
                          <div className="col-sm-6 col-xl-4">
                            <AgencyInputText
                              type="password"
                              label={t("CHOOSE PASSWORD")}
                              id="password"
                              placeholder={t("PASSWORD")}
                              name="password"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values?.password}
                              className={`form-control ${touched?.password && errors?.password
                                ? "error"
                                : ""
                                }`}
                            />
                          </div>
                          {/* CONFIRM PASSWORD */}
                          <div className="col-sm-6 col-xl-4">
                            <AgencyInputText
                              type="password"
                              label={t("CONFIRM PASSWORD")}
                              id="confirm_password"
                              placeholder={t("CONFIRM PASSWORD")}
                              name="confirm_password"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              value={values?.confirm_password}
                              className={`form-control ${touched?.confirm_password && errors?.confirm_password
                                ? "error"
                                : ""
                                }`}
                            />

                          </div>
                        </div>
                        {/* CANCEL BUTTON FOR GO BACK TO PREVIOUS PAGE */}
                        <div className="col-md-12">
                          <div className="d-sm-flex justify-content-between">
                            <Link
                              to={-1}
                              className="ud-btn btn-white"
                            >
                              {t("CANCEL")}
                            </Link>
                            {/* UPDATE AGENT SUBMIT BUTTON */}
                            <button
                              type="submit"
                              className="ud-btn btn-dark"
                              onClick={handleSubmit}
                              disabled={loading ?? false}
                            >
                              {t("UPDATE AGENT")}
                              <i className="fal fa-arrow-right-long" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditAgent
