import React from 'react'
import { useTranslation } from 'react-i18next'

function Footer() {
  const {t} = useTranslation()
  return (
    <footer className="dashboard_footer pt30 pb10">
        <div className="container">
          <div className="row items-center justify-content-center justify-content-md-between">
            <div className="col-auto">
              <div className="copyright-widget">
                <p className="text">© {t("AMAKEN - ALL RIGHTS RESERVED")}</p>
              </div>
            </div>
            <div className="col-auto">
              <div className="footer_bottom_right_widgets text-center text-lg-end">
                <p>
                  {/* <Link to="#">{t("PRIVACY")}</Link> · <Link to="#">{t("TERMS")}</Link> ·{" "} */}
                  {/* <Link to="#">{t("SITEMAP")}</Link> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer
