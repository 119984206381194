import React, { useState } from "react";
import InputText from "../../components/common/InputText";
import { AgencySingupSchema } from "../../validations/ValidationSchema";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { FaCircleInfo } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import TermsAndConditionModal from "components/modal/TermsAndConditionModal";
import LoaderMu from "components/common/LoaderMu";
import { agencySignUp } from "services/userService";
import { nameBasedRoutes } from "router/public";
import { Checkbox } from "antd";

const AgencyModal = () => {

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false);
  const [imageFile, setImageFile] = useState();
  const navigate = useNavigate();
  // STATE FOR PASSWORD VISIBLE TOGGLE
  const [showEye, setShowEye] = useState(false);
  const [showEyeConfirmPass, setShowEyeConfirmPass] = useState(false);
  const [isOpenTermsCondition, setIsOpenTermsCondition] = useState(false)

  // const AGENCY_SIGNUP_API =
  //   process.env.REACT_APP_API_URL + "/v1/agency/agency-sign-up";

  // Submit onboarding handleChange
  const handleSubmit = async (values, actions) => {
    setLoading(true);

    const formData = new FormData();

    // Apply authorization header to formData using the interceptor function
    // const config = authRequestInterceptor({
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //   },
    // });

    // // setFieldValue('image','blank')

    // axios.interceptors.request.use(authRequestInterceptor);

    formData.append("commercialRegistration", imageFile);

    formData.append("agencyName", values?.agencyName);
    formData.append("contactName", values?.contactName);
    formData.append("agencyAddress", values?.agencyAddress);
    formData.append("city", values?.area.toString());
    formData.append("area", values?.area);
    formData.append("street", values?.street);
    formData.append("agencyEmail", values?.agencyEmail);
    formData.append("companyNationalId", values?.companyNationalId);
    formData.append("firstName", values?.firstName);
    formData.append("lastName", values?.lastName);
    formData.append("email", values?.email);
    formData.append("password", values?.password);
    agencySignUp(formData).then((response) => {
      toast.success(response?.message);
      navigate(nameBasedRoutes?.login?.path);
    }).catch((error) => {
      toast.error(error?.response?.data?.error_description
        ? error?.response?.data?.error_description
        : "Something went wrong. Please try again after some time.");
    }).finally(() => {
      setLoading(false)
    })

    // try {
    //   const response = await axios.post(AGENCY_SIGNUP_API, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });
    //   if (response.data.status) {
    //     toast.success(response?.data?.message);
    //     setLoading(false);
    //     navigate("/login");
    //   }
    //   else {
    //     toast.error(response?.data?.error_description);
    //     setLoading(false);
    //   }
    // } catch (error) {
    //   setLoading(false);
    //   toast.error(error?.response?.data?.error_description
    //     ? error?.response?.data?.error_description
    //     : "Something went wrong. Please try again after some time.");
    // }
  };

  // TOGGLE EYE BUTTON FOR PASSWORD
  const showToggle = (event) => {
    setShowEye(event);
  };

  // TOGGLE EYE BUTTON FOR PASSWORD
  const showToggleConfirmPass = (event) => {
    setShowEyeConfirmPass(event);
  };

  // Start Form submit
  // const ImageUplodUrl =
  //   process.env.REACT_APP_API_URL + "/v1/user/social-sign-up";

  // // add header
  // function authRequestInterceptor(config) {
  //   const token = storage.getToken();
  //   if (token) {
  //     config.headers.authorization = `Bearer ${token}`;
  //   }
  //   config.headers.Accept = "application/json";
  //   return config;
  // }


  // file upload with restriction
  const agencyRegistration = async (event, setFieldValue) => {
    const allowedFormats = ['jpg', 'jpeg', 'png', 'pdf'];
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileExtension = fileName.split('.').pop().toLowerCase();

      if (allowedFormats.includes(fileExtension)) {
        setFieldValue("commercialRegistration", fileName);
        setImageFile(selectedFile);
      } else {
        toast.error("Invalid file format. Please choose a file with jpg, jpeg, png, or pdf format.");
        // You may want to provide feedback to the user about the invalid format.
      }
    } else {
      toast.error("No file selected");
      // You may want to provide feedback to the user about not selecting a file.
    }
  };

  // End

  return (

    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      <LoaderMu loading={loading} />
      <Formik
        validationSchema={AgencySingupSchema}
        initialValues={{
          i_agree: false,
          agencyName: "",
          contactName: "",
          agencyAddress: "",
          agencyEmail: "",
          area: "",
          street: "",
          city: "",
          commercialRegistration: "",
          companyNationalId: "",
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirm_password: "",
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="form-style1">
            {/* AGENCY NAME */}
            <div className="d-flex">
              <div className="col-md-6 px-1">
                <InputText
                  controlId="agencyName"
                  label={t('AGENCY NAME')}
                  value={values?.agencyName}
                  name="agencyName"
                  maxLength="300"
                  className="form-control"
                  errorsField={errors.agencyName}
                  touched={touched.agencyName}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
              {/* AGENCY ADDRESS */}
              <div className="col-md-6 px-1">
                <InputText
                  controlId="agencyAddress"
                  label={t("AGENCY ADDRESS")}
                  value={values?.agencyAddress}
                  name="agencyAddress"
                  maxLength="300"
                  className="form-control"
                  errorsField={errors.agencyAddress}
                  touched={touched.agencyAddress}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
            </div>
            {/* MAIN CONTACT */}
            <div className="d-flex">
              <div className="col-12 px-1">
                <InputText
                  controlId="contactName"
                  label={t("MAIN CONTACT")}
                  value={values?.contactName}
                  name="contactName"
                  maxLength="300"
                  className="form-control"
                  errorsField={errors.contactName}
                  touched={touched.contactName}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
            </div>
            {/* EMAIL ADDRESS */}
            <div className="d-flex">
              <div className="col-12 px-1">
                <InputText
                  controlId="agencyEmail"
                  label={t("EMAIL ADDRESS")}
                  value={values?.agencyEmail}
                  name="agencyEmail"
                  maxLength="300"
                  className="form-control"
                  errorsField={errors.agencyEmail}
                  touched={touched.agencyEmail}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
            </div>
            <div className="d-flex mt10">
              <div className="col-12 px-1">
                <div className="ui-content mb15">
                  <div className="form-style1">
                    <div className="bootselect-multiselect">
                      {/* SELECT CITY */}
                      <div className="dropdown bootstrap-select show-tick">
                        <InputText
                          controlId="city"
                          label={t("SELECT CITY")}
                          value={values?.city}
                          name="city"
                          maxLength="300"
                          className="form-control"
                          errorsField={errors.city}
                          touched={touched.city}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              {/* AREA */}
              <div className="col-12 px-1">
                <InputText
                  controlId="area"
                  label={t("AREA")}
                  value={values?.area}
                  name="area"
                  maxLength="300"
                  className="form-control"
                  errorsField={errors.area}
                  touched={touched.area}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
            </div>
            {/* STREET */}
            <div className="d-flex">
              <div className="col-12 px-1 mt5">
                <InputText
                  controlId="street"
                  label={t("STREET")}
                  value={values?.street}
                  name="street"
                  maxLength="300"
                  className="form-control"
                  errorsField={errors.street}
                  touched={touched.street}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
            </div>
            {/* COMMERCIAL REGISTRATION */}
            <div className="d-flex">
              <div className="col-12 px-1 mt10">
                <label className="heading-color ff-heading fw600 mb10">
                  {t("COMMERCIAL REGISTRATION")}
                </label>
                <InputText
                  controlId="commercialRegistration"
                  // value={values?.commercialRegistration ? values?.commercialRegistration : ''}
                  name="commercialRegistration"
                  maxLength="300"
                  type="file"
                  className="form-control"
                  errorsField={errors.commercialRegistration}
                  touched={touched.commercialRegistration}
                  // handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleChange={(e) => {
                    agencyRegistration(e, setFieldValue);
                  }}
                />
              </div>
            </div>
            {/* COMPANY NATIONAL ID */}
            <div className="d-flex">
              <div className="col-12 px-1">
                <InputText
                  controlId="companyNationalId"
                  label={t("COMPANY NATIONAL ID")}
                  value={values?.companyNationalId}
                  name="companyNationalId"
                  maxLength="300"
                  className="form-control"
                  errorsField={errors.companyNationalId}
                  touched={touched.companyNationalId}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
            </div>
            {/* ADMIN EMAIL */}
            <div>
              <div className="col-12 px-1">
                <InputText
                  controlId="email"
                  label={t("ADMIN EMAIL")}
                  value={values?.email}
                  name="email"
                  maxLength="300"
                  className="form-control"
                  errorsField={errors.email}
                  touched={touched.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />

              </div>
              <p className="text-primary mt-2 mb-0"><FaCircleInfo className="mb-1 me-2" />{t("THIS EMAIL WILL BE USED FOR ADMINISTRATOR ACCESS.")}</p>
            </div>
            {/* ADMIN FIRST NAME */}
            <div className="d-flex">
              <div className="col-md-6 px-1">
                <InputText
                  controlId="firstName"
                  label={t("ADMIN FIRST NAME")}
                  value={values?.firstName}
                  name="firstName"
                  maxLength="300"
                  className="form-control"
                  errorsField={errors.firstName}
                  touched={touched.firstName}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
              {/* ADMIN LAST NAME */}
              <div className="col-md-6 px-1">
                <InputText
                  controlId="lastName"
                  label={t("ADMIN LAST NAME")}
                  value={values?.lastName}
                  name="lastName"
                  maxLength="300"
                  className="form-control"
                  errorsField={errors.lastName}
                  touched={touched.lastName}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
            </div>
            {/* CREATE PASSWORD */}
            <div className="d-flex">
              <div className="col-12 px-1">
                <div
                  className={`position-relative ${showEye ? "form-right-icon" : ""
                    }`}
                >
                  <InputText
                    controlId="password"
                    label={t("CREATE PASSWORD")}
                    value={values?.password}
                    name="password"
                    maxLength="300"
                    type={showEye ? "text" : "password"}
                    className="form-control"
                    errorsField={errors.password}
                    touched={touched.password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />

                  <div onClick={() => setShowEye(!showEye)}>
                    {showEye ? (
                      <FaEye
                        size="22"
                        className="eyePosition"
                        onClick={() => showToggle(true)}
                      />
                    ) : (
                      <FaEyeSlash
                        size="22"
                        className="eyePosition"
                        onClick={() => showToggle(true)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              {/* CONFIRM PASSWORD */}
              <div className="col-12 px-1 mt20">
                <div
                  className={`position-relative ${showEyeConfirmPass ? "form-right-icon" : ""
                    }`}
                >
                  <InputText
                    controlId="confirm_password"
                    label={t("CONFIRM PASSWORD")}
                    name="confirm_password"
                    maxLength="300"
                    type={showEyeConfirmPass ? "text" : "password"}
                    value={values?.confirm_password}
                    className="form-control"
                    errorsField={errors.confirm_password}
                    touched={touched.confirm_password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <div onClick={() => setShowEyeConfirmPass(!showEyeConfirmPass)}>
                    {showEyeConfirmPass ? (
                      <FaEye
                        size="22"
                        className="eyePosition"
                        onClick={() => showToggleConfirmPass(true)}
                      />
                    ) : (
                      <FaEyeSlash
                        size="22"
                        className="eyePosition"
                        onClick={() => showToggleConfirmPass(true)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* AGREE CHECKBOX */}
            <div className="checkbox-style1 px10 ">
              <div className="d-flex align-items-end">
                <Checkbox name="i_agree" checked={values?.i_agree ?? false} onChange={(e) => setFieldValue("i_agree", e?.target?.checked)}> {t("I AGREE")}</Checkbox>
                {/* <label className="custom_checkbox fz14 ff-heading mt25">
                  {t("I AGREE")}
                  <input
                    name="i_agree"
                    onChange={(e) => {
                      setFieldValue("i_agree", e?.target?.checked);
                    }}
                    type="checkbox"
                    checked={values?.i_agree}
                    error={touched?.i_agree && errors?.i_agree}
                  />
                  <span className="checkmark mt-1 " />
                </label> */}
                <button type="button" className="fw600 btn" onClick={() => setIsOpenTermsCondition(true)}>
                  {t("TERMS AND CONDITIONS")}.
                </button>
              </div>

              <span className="text-danger answer-type-error fs-11">
                {errors?.i_agree && touched?.i_agree ? errors?.i_agree : ""}
              </span>
            </div>
            <span className="form-label fz12 px10 fw600 text-danger" />
            <div className="d-grid mb20 mt5">
              <button
                variant="primary"
                onClick={handleSubmit}
                className="ud-btn btn-thm"
                type="submit"
                disabled={loading ?? false}
              >
                {t("SUBMIT")} <i className="fal fa-arrow-right-long" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {/* TERMS AND CONDITION MODAL */}
      <TermsAndConditionModal isModalOpen={isOpenTermsCondition} setIsModalOpen={setIsOpenTermsCondition} />
    </div>

  );
};

export default AgencyModal;
