export const FloorNoDropdownList = [
    {
        id: 1,
        value: -1,
        label: {
            en: 'Basement',
            ar: 'الطابق السفلي'
        }
    },
    {
        id: 2,
        value: 0,
        label: {
            en: 'Ground Floor',
            ar: 'الطابق الأرضي'
        }
    },
    {
        id: 3,
        value: 1,
        label: {
            en: '1st Floor',
            ar: 'الطابق الأول'
        }
    },
    {
        id: 4,
        value: 2,
        label: {
            en: '2nd Floor',
            ar: 'الطابق الثاني'
        }
    },
    {
        id: 5,
        value: 3,
        label: {
            en: '3rd Floor',
            ar: 'الطابق الثالث'
        }
    },
    {
        id: 6,
        value: 4,
        label: {
            en: '4th Floor',
            ar: 'الطابق الرابع'
        }
    },
    {
        id: 7,
        value: 5,
        label: {
            en: '5th Floor',
            ar: 'الطابق الخامس'
        }
    },
    {
        id: 8,
        value: 6,
        label: {
            en: '6th Floor',
            ar: 'الطابق السادس'
        }
    },
    {
        id: 9,
        value: 7,
        label: {
            en: '7th Floor',
            ar: 'الطابق السابع'
        }
    },
    {
        id: 10,
        value: 8,
        label: {
            en: '8th Floor',
            ar: 'الطابق الثامن'
        }
    },
    {
        id: 11,
        value: 9,
        label: {
            en: '9th Floor',
            ar: 'الطابق التاسع'
        }
    },
    {
        id: 12,
        value: 10,
        label: {
            en: '10th Floor',
            ar: 'الطابق العاشر'
        }
    },
    {
        id: 13,
        value: 11,
        label: {
            en: '11th Floor',
            ar: 'الطابق الحادي عشر'
        }
    },
    {
        id: 14,
        value: 12,
        label: {
            en: '12th Floor',
            ar: 'الطابق الثاني عشر'
        }
    },
    {
        id: 15,
        value: 13,
        label: {
            en: '13th Floor',
            ar: 'الطابق الثالث عشر'
        }
    },
    {
        id: 16,
        value: 14,
        label: {
            en: '14th Floor',
            ar: 'الطابق الرابع عشر'
        }
    },
    {
        id: 17,
        value: 15,
        label: {
            en: '15th Floor',
            ar: 'الطابق الخامس عشر'
        }
    },
    {
        id: 18,
        value: 16,
        label: {
            en: '16th Floor',
            ar: 'الطابق السادس عشر'
        }
    },
    {
        id: 19,
        value: 17,
        label: {
            en: '17th Floor',
            ar: 'الطابق السابع عشر'
        }
    },
    {
        id: 20,
        value: 18,
        label: {
            en: '18th Floor',
            ar: 'الطابق الثامن عشر'
        }
    },
    {
        id: 21,
        value: 19,
        label: {
            en: '19th Floor',
            ar: 'الطابق التاسع عشر'
        }
    },
    {
        id: 22,
        value: 20,
        label: {
            en: '20th Floor',
            ar: 'الطابق العشرون'
        }
    },
    {
        id: 23,
        value: 21,
        label: {
            en: '21st Floor',
            ar: 'الطابق الحادي والعشرون'
        }
    },
    {
        id: 24,
        value: 22,
        label: {
            en: '22nd Floor',
            ar: 'الطابق الثاني والعشرون'
        }
    },
    {
        id: 25,
        value: 23,
        label: {
            en: '23rd Floor',
            ar: 'الطابق الثالث والعشرون'
        }
    },
    {
        id: 26,
        value: 24,
        label: {
            en: '24th Floor',
            ar: 'الطابق الرابع والعشرون'
        }
    },
    {
        id: 27,
        value: 25,
        label: {
            en: '25th Floor',
            ar: 'الطابق الخامس والعشرون'
        }
    },
    {
        id: 28,
        value: 26,
        label: {
            en: '26th Floor',
            ar: 'الطابق السادس والعشرون'
        }
    },
    {
        id: 29,
        value: 27,
        label: {
            en: '27th Floor',
            ar: 'الطابق السابع والعشرون'
        }
    },
    {
        id: 30,
        value: 28,
        label: {
            en: '28th Floor',
            ar: 'الطابق الثامن والعشرون'
        }
    },
    {
        id: 31,
        value: 29,
        label: {
            en: '29th Floor',
            ar: 'الطابق التاسع والعشرون'
        }
    },
    {
        id: 32,
        value: 30,
        label: {
            en: '30th Floor',
            ar: 'الطابق الثلاثون'
        }
    },
    {
        id: 33,
        value: 31,
        label: {
            en: '31st Floor',
            ar: 'الطابق الحادي والثلاثون'
        }
    },
    {
        id: 34,
        value: 32,
        label: {
            en: '32nd Floor',
            ar: 'الطابق الثاني والثلاثون'
        }
    },
    {
        id: 35,
        value: 33,
        label: {
            en: '33rd Floor',
            ar: 'الطابق الثالث والثلاثون'
        }
    },
    {
        id: 36,
        value: 34,
        label: {
            en: '34th Floor',
            ar: 'الطابق الرابع والثلاثون'
        }
    },
    {
        id: 37,
        value: 35,
        label: {
            en: '35th Floor',
            ar: 'الطابق الخامس والثلاثون'
        }
    },
    {
        id: 38,
        value: 36,
        label: {
            en: '36th Floor',
            ar: 'الطابق السادس والثلاثون'
        }
    },
    {
        id: 39,
        value: 37,
        label: {
            en: '37th Floor',
            ar: 'الطابق السابع والثلاثون'
        }
    },
    {
        id: 40,
        value: 38,
        label: {
            en: '38th Floor',
            ar: 'الطابق الثامن والثلاثون'
        }
    },
    {
        id: 41,
        value: 39,
        label: {
            en: '39th Floor',
            ar: 'الطابق التاسع والثلاثون'
        }
    },
    {
        id: 42,
        value: 40,
        label: {
            en: '40th Floor',
            ar: 'الطابق الأربعون'
        }
    },

    {
        id: 43,
        value: 41,
        label: {
            en: '41st Floor',
            ar: 'الطابق الحادي والأربعون'
        }
    },
    {
        id: 44,
        value: 42,
        label: {
            en: '42nd Floor',
            ar: 'الطابق الثاني والأربعون'
        }
    },
    {
        id: 45,
        value: 43,
        label: {
            en: '43rd Floor',
            ar: 'الطابق الثالث والأربعون'
        }
    },
    {
        id: 46,
        value: 44,
        label: {
            en: '44th Floor',
            ar: 'الطابق الرابع والأربعون'
        }
    },
    {
        id: 47,
        value: 45,
        label: {
            en: '45th Floor',
            ar: 'الطابق الخامس والأربعون'
        }
    },
    {
        id: 48,
        value: 46,
        label: {
            en: '46th Floor',
            ar: 'الطابق السادس والأربعون'
        }
    },
    {
        id: 49,
        value: 47,
        label: {
            en: '47th Floor',
            ar: 'الطابق السابع والأربعون'
        }
    },
    {
        id: 50,
        value: 48,
        label: {
            en: '48th Floor',
            ar: 'الطابق الثامن والأربعون'
        }
    },
    {
        id: 51,
        value: 49,
        label: {
            en: '49th Floor',
            ar: 'الطابق التاسع والأربعون'
        }
    },
    {
        id: 52,
        value: 50,
        label: {
            en: '50th Floor',
            ar: 'الطابق الخمسون'
        }
    }
]

export const FloorNoDropdownList20 = [
    {
        id: 1,
        value: -1,
        label: {
            en: 'Basement',
            ar: 'الطابق السفلي'
        }
    },
    {
        id: 2,
        value: 0,
        label: {
            en: 'Ground Floor',
            ar: 'الطابق الأرضي'
        }
    },
    {
        id: 3,
        value: 1,
        label: {
            en: '1st Floor',
            ar: 'الطابق الأول'
        }
    },
    {
        id: 4,
        value: 2,
        label: {
            en: '2nd Floor',
            ar: 'الطابق الثاني'
        }
    },
    {
        id: 5,
        value: 3,
        label: {
            en: '3rd Floor',
            ar: 'الطابق الثالث'
        }
    },
    {
        id: 6,
        value: 4,
        label: {
            en: '4th Floor',
            ar: 'الطابق الرابع'
        }
    },
    {
        id: 7,
        value: 5,
        label: {
            en: '5th Floor',
            ar: 'الطابق الخامس'
        }
    },
    {
        id: 8,
        value: 6,
        label: {
            en: '6th Floor',
            ar: 'الطابق السادس'
        }
    },
    {
        id: 9,
        value: 7,
        label: {
            en: '7th Floor',
            ar: 'الطابق السابع'
        }
    },
    {
        id: 10,
        value: 8,
        label: {
            en: '8th Floor',
            ar: 'الطابق الثامن'
        }
    },
    {
        id: 11,
        value: 9,
        label: {
            en: '9th Floor',
            ar: 'الطابق التاسع'
        }
    },
    {
        id: 12,
        value: 10,
        label: {
            en: '10th Floor',
            ar: 'الطابق العاشر'
        }
    },
    {
        id: 13,
        value: 11,
        label: {
            en: '11th Floor',
            ar: 'الطابق الحادي عشر'
        }
    },
    {
        id: 14,
        value: 12,
        label: {
            en: '12th Floor',
            ar: 'الطابق الثاني عشر'
        }
    },
    {
        id: 15,
        value: 13,
        label: {
            en: '13th Floor',
            ar: 'الطابق الثالث عشر'
        }
    },
    {
        id: 16,
        value: 14,
        label: {
            en: '14th Floor',
            ar: 'الطابق الرابع عشر'
        }
    },
    {
        id: 17,
        value: 15,
        label: {
            en: '15th Floor',
            ar: 'الطابق الخامس عشر'
        }
    }
]