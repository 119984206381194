import { Form, Formik } from "formik";

import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import Addagentimg from "../../assets/images/team/agent-6.jpg";
import AgencyFileUpload from "components/common/AgencyFileUpload";
import AgencyInputText from "components/common/AgencyInputText";
import { addAgentSchema } from "validations/ValidationSchema";
import { useState } from "react";
import Loader from "components/common/Loader";
import { CustomSelect } from "../../components/common/CustomSelect";

export const BulkUpload = () => {

  const [loading, setLoading] = useState(false)

  const initialValues = {
    profile_picture: "",
    agent_name: "",
    phoneCode: "+962",
    phone: "",
    mobile: "",
    email: "",
    agency: "",
    address: "",
    facebook_url: "",
    instagram_url: "",
    linkedin_url: "",
    about_agent: "",
    username: "",
    password: "",
    confirm_password: ""
  }

  const handleSubmit = (values,actions) => {
    setLoading(true)
  }

  return (
    <div className="dashboard__content property-page bgc-f7">
      <div className="row align-items-center pb20">
        <div className="col-lg-3">
          <div className="dashboard_title_area">
            <h2>Bulk Upload</h2>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="dashboard_search_meta d-md-flex align-items-center justify-content-xxl-end">
            
            <Link to="/addagent" className="ud-btn btn-dark">
              Cancel
              <i className="fal fa-arrow-right-long" />
            </Link>

            <Link to="/addagent" className="ud-btn btn-thm">
              Add agent
              <i className="fal fa-arrow-right-long" />
            </Link>
          </div>
        </div>
      </div>
      {/* top box */}
          <div class="row">
            <div class="col-xl-12">
              <div class="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
                <div class="packages_table table-responsive">
                  <table class="table-style3 table at-savesearch">
                    <thead class="t-head">
                      <tr>
                        <th scope="col">Format Name</th>
                        <th scope="col">Username</th>
                        <th scope="col">Date Published</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody class="t-body">
                      <tr>
                        <th scope="row">
                          <div class="listing-style1 dashboard-style d-flex justify-content-start align-items-center m-0">
                            {/* <input type="checkbox" class="mx-3 card-subtitle"> */}
                           
                            <div class="fs17 mb-8"><a href="">15 New Listing Nov 2023</a></div>
                          </div>
                        </th>
                        <td class="vam">Tariq Hussain</td>
                        <td class="vam">Sunday, November 12 2023 12:30PM</td>
                        <td class="vam"><span class="pending-style style4">Published</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="d-sm-flex justify-content-start">
                      <a class="ud-btn btn-thm mx-1" href="">Add</a>
                      <a class="ud-btn btn-dark mx-1" href="">Import</a>
                      <a class="ud-btn btn-dark mx-1" href="">Export</a>
                      <a class="ud-btn btn-dark mx-1" href="">Delete</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      {/* middle box */}
      <div className="row">
        {
          loading ? <Loader /> : <div className="col-xl-12">
            <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
              <Formik
                initialValues={initialValues}
                validationSchema={addAgentSchema}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false);
                  handleSubmit(values, actions);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  setFieldValue,
                  setFieldError,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <Form>
                    <div className="col-lg-12 form-style1">
                      
                      <div className="row">
                        <div className="col-sm-4 col-xl-4">
                          <AgencyInputText
                            type="text"
                            label=" Agent Name"
                            id="agent_name"
                            placeholder="Agent Name"
                            name="agent_name"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.agent_name}
                            className={`form-control ${touched?.agent_name && errors?.agent_name
                              ? "error"
                              : ""
                              }`}
                          />
                        </div>
                        <div className="col-sm-6 col-xl-8">
                          <AgencyInputText
                            
                            label="Discription"
                            id="discription"
                            placeholder="Discription"
                            name="discription"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.mobile}
                            className={`form-control ${touched?.phone && errors?.mobile ? "error" : ""
                              }`}
                          />
                        </div>
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                           
                            label="Skip Header Lines"
                            id="email"
                            placeholder="Skip Header Lines"
                            name="email"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.email}
                            className={`form-control ${touched?.email && errors?.email ? "error" : ""
                              }`}
                          />
                        </div>
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                           
                            label="Creation Date"
                            id="Creation Date"
                            placeholder="Creation Date"
                            name="agency"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.agency}
                            className={`form-control ${touched?.agency && errors?.agency ? "error" : ""
                              }`}
                          />
                        </div>
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                            type="text"
                            label="Address"
                            id="address"
                            placeholder="Address"
                            name="address"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.address}
                            className={`form-control ${touched?.address && errors?.address ? "error" : ""
                              }`}
                          />
                        </div>
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                            
                            label="Ignore Characters:"
                            id="facebook_url"
                            placeholder=" Ignore Characters:"
                            name="facebook_url"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.facebook_url}
                            className={`form-control ${touched?.facebook_url && errors?.facebook_url
                              ? "error"
                              : ""
                              }`}
                          />
                        </div>
                        <div className="col-sm-6 col-xl-4">
                          <AgencyInputText
                          
                            label="Last Updated"
                            id="instagram_url"
                            placeholder="Last Updated"
                            name="instagram_url"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            value={values?.instagram_url}
                            className={`form-control ${touched?.instagram_url && errors?.instagram_url
                              ? "error"
                              : ""
                              }`}
                          />
                        </div>
                        <div className="col-sm-6 col-xl-4">
                          
                        </div>
                        <div className="col-sm-12 col-xl-12">
                            <div className="col-sm-6 col-xl-4">
                                <CustomSelect
                                  options={[
                                    { label: "A", value: "1" },
                                    { label: "B", value: "2" },
                                    { label: "C", value: "3" },
                                  ]}
                                  className="selectpicker"
                                  controlId="Record Delimiter"
                                  label="Record Delimiter"
                                  //value={values.category_id}
                                  name="city"
                                  errorsField={errors.city}
                                  touched={touched.city}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-12 mt25">
                            <div className="col-sm-6 col-xl-4">
                                <CustomSelect
                                  options={[
                                    { label: "A", value: "1" },
                                    { label: "B", value: "2" },
                                    { label: "C", value: "3" },
                                  ]}
                                  className="selectpicker"
                                  controlId="Field Delimiter"
                                  label="Field Delimiter"
                                  //value={values.category_id}
                                  name="city"
                                  errorsField={errors.city}
                                  touched={touched.city}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                            </div>
                        </div>

                        
                        <div className="row mt25">
                          <div className="row">
                            <div className="col-sm-6 col-xl-4">
                              <AgencyInputText
                                type="text"
                               
                                label="Name"
                                id="username"
                                placeholder="Name"
                                name="username"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values?.email}
                                className={`form-control ${touched?.username && errors?.username
                                  ? "error"
                                  : ""
                                  }`}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 col-xl-4">
                              <AgencyInputText
                                type="number"
                               
                                label="Size"
                                id="Size"
                                placeholder="Size"
                                name="username"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values?.email}
                                className={`form-control ${touched?.username && errors?.username
                                  ? "error"
                                  : ""
                                  }`}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 col-xl-4">
                              <AgencyInputText
                                type="number"
                               label="Position"
                                id="username"
                                placeholder="Position"
                                name="username"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                value={values?.email}
                                className={`form-control ${touched?.username && errors?.username
                                  ? "error"
                                  : ""
                                  }`}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6 col-xl-4">
                                <CustomSelect
                                  options={[
                                    { label: "A", value: "1" },
                                    { label: "B", value: "2" },
                                    { label: "C", value: "3" },
                                  ]}
                                  className="selectpicker"
                                  controlId="Type"
                                  label="Type"
                                  //value={values.category_id}
                                  name="city"
                                  errorsField={errors.city}
                                  touched={touched.city}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  onChange={handleChange}
                                />
                            </div>
                          </div>
                          
                        </div>
                        <div className="col-md-12">
                          <div className="text-end">
                            <button
                              type="submit"
                              className="ud-btn btn-dark"
                              onClick={handleSubmit}
                            >
                              Save
                              <i className="fal fa-arrow-right-long" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        }

      </div>
    </div>
  );
};
