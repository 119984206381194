import { AGENT_REPORT_DOWNLOAD, LOCATION_REPORT_DOWNLOAD, PROPERTY_REPORT_DOWNLOAD, VILLAGE_SEARCH_API } from "constants/paths";
import { axios } from "./axios";

// AGENT REPORT DOWNLOAD
export const agentReportDownload = async(params)=>{
    const response = await axios.get(AGENT_REPORT_DOWNLOAD,{ params, responseType: 'arraybuffer' })
    return response
}

// LOCATION REPORT DOWNLOAD
export const locationReportDownload = async(params)=>{
    const response = await axios.get(LOCATION_REPORT_DOWNLOAD, { params, responseType: 'arraybuffer' })
    return response
}

// PROPERTY REPORT DOWNLOAD
export const propertyReportDownload = async(params)=>{
    const response = await axios.get(PROPERTY_REPORT_DOWNLOAD, { params, responseType: 'arraybuffer' })
    return response
}

export const villageSearchApi = async(params)=>{
    const response = await axios.get(VILLAGE_SEARCH_API, {params})
    return response
}