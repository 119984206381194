import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { convertToCSV, monthWisePropAgency, monthWisePropAgent } from 'services/dashboardService';
import { toast } from 'react-toastify';
import DownloadCSVButton from 'components/common/DownloadCSVButton';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const MonthWiseProperty = ({ currentRole }) => {

    const { t } = useTranslation()


    const { i18n } = useTranslation()

    const cookies = new Cookies()

    // GET CURRENT LANGUAGE FROM i18 OR COOKIES
    const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

    // CURRENT YEAR
    const currentYear = moment().year()

    const [selectedYear, setSelectedYear] = useState(currentYear)
    const [chartData, setChartData] = useState([])
    const [loading, setLoading] = useState(true)
    const monthNames = {
        en: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        ar: ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر']
    };

    //  FETCH PROPERTY CHART DATA
    const fetchPropertyChartData = () => {
        setLoading(true)
        const params = {
            year: selectedYear
        }
        if (currentRole === 'AGENCY') {
            monthWisePropAgency(params)
                .then((response) => {
                    // CONVERTING MONTH VALUE FROM NO TO MONTH NAME
                    const convertMonthWithName = response?.map((element) => (
                        { ...element, month: monthNames[currentLanguage][element?.month - 1] }))

                    setChartData(convertMonthWithName)

                })
                .catch(error => {
                    toast.error(
                        error?.response?.data?.error_description
                          ? error?.response?.data?.error_description
                          : error?.response?.data?.message ?? error?.message
                      );
                }).finally(() => {
                    setLoading(false)
                })
        } else {
            monthWisePropAgent(params)
                .then((response) => {
                    setChartData(response)
                })
                .catch(error => {
                    toast.error(
                        error?.response?.data?.error_description
                          ? error?.response?.data?.error_description
                          : error?.response?.data?.message ?? error?.message
                      );
                }).finally(() => {
                    setLoading(false)
                })
        }
    }
    // CALL FUNCTION ON COMPONENT RENDER
    useEffect(() => {
        fetchPropertyChartData()
    }, [selectedYear])


    // CHART OPTIONS
    const options = {
        plugins: {
            title: {
                display: true,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: false,
                grid: {
                    display: false
                }
            },
            y: {
                stacked: false,
                grid: {
                    display: false
                }
            },
        },
    };
    // CHART LABELS
    // Mapping month numbers to month names

    const chart_labels = chartData.map(item => item?.month);
    // CHART DATA
    const data = {
        labels: chart_labels,
        datasets: [
            {
                label: t("TOTAL PROPERTIES"),
                data: chartData.map(item => item?.totalProperties),
                backgroundColor: 'rgba(33, 150, 243, 0.6)', // Red
                borderColor: 'rgba(33, 150, 243, 0.6)',
                borderWidth: 1.5,
                strokeColor: "#ffb88c",
                // fill : true 

            },
            {
                label: t("ACTIVE PROPERTIES"),
                data: chartData.map(item => item?.activeProperties),
                backgroundColor: 'rgba(233, 30, 99, 1)', // Blue
                borderColor: 'rgba(233, 30, 99, 1)',
                borderWidth: 1.5
                // fill : true

            }
        ]
    };



    // GENERATE CSV DATA
    const csvData = convertToCSV(chartData);

    return (
        <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <div className="navtab-style1">
                <div className="d-sm-flex align-items-center justify-content-between">
                    <h4 className="title fz17 mb20">{t("PROPERTY CHART")}</h4>
                    <ul
                        className="nav nav-tabs border-bottom-0 mb30"
                    >
                        <li className={'nav-item'}>
                            <button
                                className={`nav-link fw600 ${selectedYear === currentYear - 2 ? 'active' : ''}`}
                                onClick={() => { setSelectedYear(currentYear - 2) }}
                            >
                                {currentYear - 2}
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link fw600 ${selectedYear === currentYear - 1 ? 'active' : ''}`}
                                onClick={() => { setSelectedYear(currentYear - 1) }}
                            >
                                {currentYear - 1}
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`nav-link fw600 ${selectedYear === currentYear ? 'active' : ''}`}
                                onClick={() => { setSelectedYear(currentYear) }}
                            >
                                {currentYear}
                            </button>
                        </li>
                    </ul>
                </div>
                <div className='text-end'>
                    {/* CSV EXPORT */}
                    {
                        csvData && csvData?.length > 0 ? <div>
                            <DownloadCSVButton csvData={csvData} filename={'properties.csv'} />
                        </div> : ""
                    }
                </div>
                {/* LINE CHART */}
                <div className="tab-content" >
                    <Line options={options} data={data} />
                </div>
            </div>
        </div>
    )
}

export default MonthWiseProperty