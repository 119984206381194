import React from 'react'
import { useSelector } from "react-redux";

import DateFilter from 'components/dateFilter/DateFilter';


const DashHeader = ({ startDate, endDate, setStartDate, setEndDate }) => {

  // GET USER DATA FROM REDUX
  const userData = useSelector((state) => state?.user?.userData)
  return (
    <div className="row pb20">
      <div className="col-lg-auto">
        <div className="dashboard_title_area">
          <h2>Hello, {userData?.firstName}</h2>
        </div>
      </div>

      {/* DATE FILTER */}
      <div className='col-lg'>
        <div className="col-12">
          <DateFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
        </div>
      </div>
    </div>
  )
}

export default DashHeader