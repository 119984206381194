import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdClear } from "react-icons/md";


const DragNdDrop = ({ onFilesSelected, width, height , files , setFiles }) => {
  // const [files, setFiles] = useState([]);
  const {t} = useTranslation()

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const newFiles = Array.from(selectedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    onFilesSelected(files);
  }, [files]);

  return (
    <section className="drag-drop" style={{ width: width, height: height }}>
      <div
        className={`document-uploader ${files.length > 0 ? "upload-box active" : "upload-box"
          }`}
        onDrop={handleDrop}
        onDragOver={(event) => {
          event.preventDefault();
          event.stopPropagation(); // Prevent default and propagation
        }}
        aria-label="File Upload Area"
      >
        <div className="upload-info">
          <h4 className="title fz17 mb10">{t("UPLOAD PHOTOS OF YOUR PROPERTY")}</h4>
          <p className="text  mb-0">{t("PHOTOS MUST BE JPEG OR PNG FORMAT")}</p>
          <p className="text  mb-0"><span className='fw-bold'>Minimum Dimensions: </span> Each image must be at least 800 pixels in width and 800 pixels in height.</p>
          <p className="text mb25"><span className='fw-bold'>Maximum Number of Images:</span> You can upload up to 10 images per property listing.</p>
          <div className="icon mb30">
						<span className="flaticon-upload" />
					</div>
          {/* <AiOutlineCloudUpload size={20} /> */}
          <div>
            <p>Drag and drop your files here</p>
            {/* <p>
              Limit 15MB per file. Supported files: .PDF, .DOCX, .PPTX, .TXT,
              .XLSX
            </p> */}
          </div>
        </div>

        <input
          type="file"
          hidden
          id="browse"
          onChange={handleFileChange}
          accept="image/*"
          // accept=".pdf,.docx,.pptx,.txt,.xlsx"
          multiple
          aria-label="File Upload Input"
        />
        <label htmlFor="browse" className="browse-btn">
          Browse files
        </label>

        {files.length > 0 && (
          <div className="file-list">
            <div className="file-list__container">
              {files.map((file, index) => (
                <div className="file-item" key={index}>
                  <div className="file-info">
                    <p>{file.name}</p>
                  </div>
                  <div className="file-actions">
                    <MdClear
                      onClick={() => handleRemoveFile(index)}
                      aria-label={`Remove ${file.name}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {files.length > 0 && (
          <div className="success-file">
            <AiOutlineCheckCircle style={{ color: "#6DC24B", marginRight: 1 }} />
            <p>{files.length} file(s) selected</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default DragNdDrop;
