import SocialMediaForm from "components/agencyprofile/SocialMediaForm";
import UpdateProfileInfo from "components/agencyprofile/UpdateProfileInfo";
import UploadLogo from "components/agencyprofile/UploadLogo";
import WatermarkForm from "components/agencyprofile/WatermarkForm";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { getAgencyProfileData } from "services/agencyService";

export const Profileagency = () => {

  const { t } = useTranslation()

  const [agencyData, setAgencyData] = useState({})

  // FETCH AGENCY PROFILE DATA
  const fetchAgencyData = () => {
    getAgencyProfileData().then((response) => {
      setAgencyData(response)
    }).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  
  // FETCH AGENCY PROFILE DATA
  useEffect(() => {
    fetchAgencyData()
  }, [])

  return (
    <div className="dashboard__content property-page bgc-f7">
      <div className="row align-items-center pb20">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>{t("AGENCY PROFILE")}</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <UploadLogo agencyLogo={agencyData?.logo} getAgencyData={fetchAgencyData} />
            <UpdateProfileInfo agencyInfo={agencyData} getAgencyData={fetchAgencyData} />
          </div>
          <SocialMediaForm agencyInfo={agencyData} getAgencyData={fetchAgencyData} />
          <WatermarkForm agencyInfo={agencyData} getAgencyData={fetchAgencyData} />
          {/* <ChangePassword/> */}
        </div>
      </div>
    </div>
  );
};
