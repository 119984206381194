import { Modal } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'

function ViewFileModal({filePath, isFileOpen, setIsFileOpen,title }) {

    const { i18n } = useTranslation()
    const cookies = new Cookies()

    const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'
    const handleCancel = () => {
        setIsFileOpen(false);
    };

    return (
        <Modal
            title={title}
            open={isFileOpen}
            onCancel={handleCancel}
            footer={false}
            width={"70%"}
            className={`${currentLanguage === 'ar' ? 'direction-rtl' : ''}`}
            >
            <iframe src={filePath}
                className=' h-60vh' frameborder="0"></iframe>
        </Modal>
    )
}

export default ViewFileModal
