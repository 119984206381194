import AgencyInputText from "components/common/AgencyInputText";
import AgencySelect from "components/common/AgencySelect";
import AgencyTextArea from "components/common/AgencyTextArea";
import InputPhoneCode from "components/common/InputPhoneCode";
import LoaderMu from "components/common/LoaderMu";
import ViewFileModal from "components/common/ViewFileModal";
import PhoneVerifyModal from "components/profile/PhoneVerifyModal";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FiEye } from "react-icons/fi";
import { toast } from "react-toastify";
import { updateAgencyProfile, verifyAgencyEmailAccount } from "services/agencyService";
// import { verifyAgentEmailAccount } from "services/userService";
import { agencyProfileSchema } from "validations/ValidationSchema";

function UpdateProfileInfo({ agencyInfo, getAgencyData }) {

  const { t } = useTranslation()

  const [loading, setLoading] = useState(false)

  // const [verificationMailSent, setVerificationMailSent] = useState(false)
  const [openPhoneVerifyModal, setOpenPhoneVerifyModal] = useState(false)
  const [verificationMailSent, setVerificationMailSent] = useState(false)

  const [viewFile, setViewFile] = useState(false) // TITLE DEED PREV FILE VIEW MODAL

  const [initialData, setInitialData] = useState({
    name: agencyInfo?.name ?? "",
    address: agencyInfo?.address ?? "",
    contactName: agencyInfo?.contactName ?? "",
    phoneNo: agencyInfo?.mobileNumber ?? "",
    email: agencyInfo?.email ?? "",
    // cities: "",
    comm_registration: "",
    nationalId: agencyInfo?.companyNationalId ?? "",
    about_en: agencyInfo?.about?.en ?? "",
    about_ar: agencyInfo?.about?.ar ?? ""
  })

  // HANDLE EMAIL VERIFY

  // const handleEmailVerify = () => {
  //   verifyAgentEmailAccount().then((response) => {
  //     setVerificationMailSent(true)
  //     toast.success(response?.message)
  //   }).catch(error => {
  //     toast.error(
  //       error?.response?.data?.error_description
  //         ? error?.response?.data?.error_description
  //         : error?.response?.data?.message ?? error?.message
  //     );
  //   })
  // }

  useEffect(() => {
    setInitialData({
      name: agencyInfo?.name ?? "",
      address: agencyInfo?.address ?? "",
      contactName: agencyInfo?.contactName ?? "",
      phoneCode: agencyInfo?.countryCode,
      phoneNo: agencyInfo?.mobileNumber ?? "",
      email: agencyInfo?.email ?? "",
      // cities: "",
      comm_registration: "",
      nationalId: agencyInfo?.companyNationalId ?? "",
      about_en: agencyInfo?.about?.en ?? "",
      about_ar: agencyInfo?.about?.ar ?? ""
    })
  }, [agencyInfo])

  const handleSubmit = (values) => {
    setLoading(true)
    const formdata = {
      name: values?.name ?? "",
      contactName: values?.contactName ?? "",
      address: values?.address ?? "",
      about: {
        en: values?.about_en ?? "",
        ar: values?.about_ar ?? ""
      }
    }
    updateAgencyProfile(formdata).then((response) => {
      setLoading(false);
      getAgencyData()
      toast.success(response?.data?.message ?? 'Profile Info Updated!')
    }).catch((error) => {
      setLoading(false);
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }

  // HANDLE EMAIL VERIFY
  const handleEmailVerify = () => {
    verifyAgencyEmailAccount().then((response) => {
      setVerificationMailSent(true)
      getAgencyData()
      toast.success(response?.message)
    }).catch(error => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }




  return (
    <div className="col-lg-12">
      {loading ? <LoaderMu loading={loading} /> : ""}
      <Formik
        initialValues={initialData}
        enableReinitialize={true}
        validationSchema={agencyProfileSchema}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          handleSubmit(values)
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="form-style1">
            <div className="row align-items-end">
              <div className="col-sm-6 col-xl">
                <AgencyInputText
                  type="text"
                  label={t("AGENCY NAME")}
                  id="name"
                  placeholder={t("AGENCY NAME")}
                  name="name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.name}
                  className={`form-control ${touched?.name && errors?.name ? "error" : ""
                    }`}
                />
              </div>
              <div className="col-sm-6 col-xl">
                <AgencyInputText
                  type="text"
                  label={t("AGENCY ADDRESS")}
                  id="address"
                  placeholder={t("AGENCY ADDRESS")}
                  name="address"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.address}
                  className={`form-control ${touched?.address && errors?.address
                    ? "error"
                    : ""
                    }`}
                />
              </div>
              <div className="col-sm-6 col-xl">
                <AgencyInputText
                  type="text"
                  label={t("MAIN CONTACT NAME")}
                  id="contactName"
                  placeholder={t("MAIN CONTACT NAME")}
                  name="contactName"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.contactName}
                  className={`form-control ${touched?.contactName && errors?.contactName ? "error" : ""
                    }`}
                />
              </div>
              {/* {
                agencyInfo?.isEmailVerified === false && verificationMailSent === false ? <div className="col-sm-6 col-xl-auto">
                  <div className="text-end">
                    <button type="button" onClick={handleEmailVerify} className="ud-btn btn-thm mb20">Verify Email</button>
                  </div>
                </div> : ""
              } */}
            </div>
            <div className="row">
              <div className="col-sm-6 col-xl-4">
                <div className="row">
                  <div className="col-md-4 col-xl-4">
                    <InputPhoneCode
                      id="phoneCode"
                      name="phoneCode"
                      type="number"
                      label={t("CODE")}
                      country="jo"
                      placeholder={t("CODE")}
                      handleChange={(code) => { setFieldValue("phoneCode", code) }}
                      handleBlur={handleBlur}
                      value={values?.phoneCode}
                      className={` ${touched?.phoneCode && errors?.phoneCode ? "error" : ""
                        }`}
                      disabled={true}
                    />
                  </div>
                  <div className="col md-8 col-xl-8">
                    <AgencyInputText
                      id="phoneNo"
                      name="phoneNo"
                      type="text"
                      label={t("PHONE NO.")}
                      placeholder={t("YOUR PHONE NUMBER")}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      value={values?.phoneNo}
                      className={`form-control ${touched?.phoneNo && errors?.phoneNo ? "error" : ""
                        }`}
                      disabled={true}
                    />
                  </div>
                </div>
                <button type="button" className="ud-btn btn-thm mb20" onClick={() => {
                  setOpenPhoneVerifyModal(true)
                }}>{agencyInfo?.isMobileNumberVerified ? t('EDIT') :
                  t('ADD')} & {("VERIFY")}</button>
              </div>
              <div className="col-sm-6 col-xl-4">
                <AgencyInputText
                  type="email"
                  label={t("EMAIL")}
                  id="email"
                  placeholder={t("EMAIL")}
                  name="email"
                  disabled={true}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.email}
                  className={`form-control ${touched?.email && errors?.email ? "error" : ""
                    }`}
                />
                {
                  agencyInfo?.isEmailVerified === false && verificationMailSent === false ? <div className="col-sm-6 col-xl-auto">
                    <div>
                      <button type="button" onClick={handleEmailVerify} className="ud-btn btn-thm mb20">{t("VERIFY")} {t("EMAIL")}</button>
                    </div>
                  </div> : ""
                }
              </div>
              <div className="col-sm-6 col-xl-4">
                <AgencyInputText
                  type="text"
                  label={t("COMPANY NATIONAL ID")}
                  id="nationalId"
                  readOnly={true}
                  placeholder={t("COMPANY NATIONAL ID")}
                  name="nationalId"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.nationalId}
                  className={`form-control ${touched?.nationalId && errors?.nationalId ? "error" : ""
                    }`}
                />
              </div>
              {/* <div className="col-sm-6 col-xl-4">
                <AgencySelect
                  label={t("SELECT CITIES")}
                  id="cities"
                  placeholder={t("SELECT")}
                  name="cities"
                  handleChange={(value) => {
                    setFieldValue("cities", value);
                  }}
                  handleBlur={handleBlur}
                  value={values?.cities}
                  showSearch={true}
                  className={`selectpicker form-control ${touched?.cities && errors?.cities ? "error" : ""
                    }`}
                  options={[
                    {
                      value: "",
                      label: "Select",
                    },
                    { value: "Amman", label: "Amman" },
                    { value: "Irbid", label: "Irbid" },
                    { value: "Zarqa", label: "Zarqa" },
                    { value: "Jerash", label: "Jerash" },
                    { value: "Ajloun", label: "Ajloun" },
                    { value: "Madaba", label: "Madaba" },
                    { value: "Al Salt", label: "Al Salt" },
                    { value: "Aqaba", label: "Aqaba" },
                    { value: "Fuheis", label: "Fuheis" },
                  ]}
                />
              </div> */}
            </div>
            <div className="row align-items-end">
              <div className="col-sm-6 col-xl-4">
                {/* <AgencyFileUpload
                  id="comm_registration"
                  label={t("COMMERCIAL REGISTRATION")}
                  fileName={values?.comm_registration}
								 name="comm_registration"
                  handleChange={(event) =>{
                  console.log(event?.target?.files[0]?.name)
                    setFieldValue("comm_registration", event?.target?.files[0])}
                  }
                  className={`form-control p15 pl30 ud-btn2 ${touched?.comm_registration && errors?.comm_registration ? "error" : ""
                    }`}
                /> */}


                {agencyInfo?.commercialRegistration && agencyInfo?.commercialRegistration !== null ?
                  <button type="button" className=" mb-3 ud-btn btn-white" onClick={() => setViewFile(true)}>
                    <FiEye className="me-2" />
                    {t("COMMERCIAL REGISTRATION")}</button> : ""
                }
              </div>

            </div>
            <div className="row">
              <div className="col-md-12">
                <AgencyTextArea
                  type="text"
                  rows={4}
                  cols={30}
                  label={t("AGENCY OVERVIEW (ENGLISH)")}
                  id="about_en"
                  placeholder={t("AGENCY OVERVIEW")}
                  name="about_en"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.about_en}
                  className={`${touched?.about_en &&
                    errors?.about_en
                    ? "error"
                    : ""
                    }`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <AgencyTextArea
                  type="text"
                  rows={4}
                  cols={30}
                  label={t("AGENCY OVERVIEW (ARABIC)")}
                  id="about_ar"
                  placeholder={t("AGENCY OVERVIEW")}
                  name="about_ar"
                  dir="rtl"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.about_ar}
                  className={`${touched?.about_ar &&
                    errors?.about_ar
                    ? "error"
                    : ""
                    }`}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="text-end">
                  <button disabled={loading} type="submit" className="ud-btn btn-dark" onClick={handleSubmit}>
                    {t("UPDATE PROFILE")}
                    <i className="fal fa-arrow-right-long" />
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <ViewFileModal filePath={agencyInfo?.commercialRegistration} isFileOpen={viewFile} setIsFileOpen={setViewFile} title={"Commercial Registration"} />
      <PhoneVerifyModal openModal={openPhoneVerifyModal} setOpenModal={setOpenPhoneVerifyModal} otpEntityType={"AGENCY"} fetchVerifiedData={getAgencyData} />
    </div>
  );
}

export default UpdateProfileInfo;
