import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import { convertToCSV, propertyTypeChartAgency, propertyTypeChartAgent } from 'services/dashboardService';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import DownloadCSVButton from 'components/common/DownloadCSVButton';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const PropertyTypesChart = ({ startDate, endDate, currentRole }) => {

    const { t } = useTranslation()

    const { i18n } = useTranslation()

    const cookies = new Cookies()

    // GET CURRENT LANGUAGE FROM i18 OR COOKIES
    const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

    const [chartData, setChartData] = useState([])
    const [loading, setLoading] = useState(true)

    // FETCH PROPERTY TYPES CHART DATA

    const fetchPropertyTypeChartData = () => {
        setLoading(true)

        const params = {
            startDate: startDate,
            endDate: endDate
        }

        if (currentRole === 'AGENCY') {
            propertyTypeChartAgency(params)
                .then((response) => {
                    const responseData = response?.map((element)=>{
                        const {id , ...rest} = element
                        return {...rest}
                    })

                    setChartData(responseData)
                })
                .catch(error => {
                    toast.error(
                        error?.response?.data?.error_description
                          ? error?.response?.data?.error_description
                          : error?.response?.data?.message ?? error?.message
                      );
                }).finally(() => {
                    setLoading(false)
                })
        } else {
            propertyTypeChartAgent(params)
                .then((response) => {
                    const responseData = response?.map((element)=>{
                        const {id , ...rest} = element
                        return {...rest}
                    })

                    setChartData(responseData)
                })
                .catch(error => {
                    toast.error(
                        error?.response?.data?.error_description
                          ? error?.response?.data?.error_description
                          : error?.response?.data?.message ?? error?.message
                      );
                }).finally(() => {
                    setLoading(false)
                })
        }

    }

    // CALL FUNCTION OF COMPONENT RENDER
    useEffect(() => {
        fetchPropertyTypeChartData()
    }, [startDate, endDate])

    // CHART OPTIONS
    const options = {
        plugins: {
            title: {
                display: true,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false
                }
            },
        },
    };
    // CHART LABELS
    // Mapping month numbers to month names

    const chart_labels = chartData.map(item => item?.title[currentLanguage]);
    // CHART DATA
    const data = {
        labels: chart_labels,
        datasets: [
            {
                label: t("TOTAL PROPERTIES"),
                data: chartData.map(item => item?.totalProperties),
                fill: true,
                backgroundColor: gradientFill('rgba(79, 181, 181, 0.8)', 'rgba(255, 255, 255, 0.1)'),
                borderColor: 'rgba(79, 181, 181, 1)',
                cubicInterpolationMode: 'monotone',
                order: 1,
                pointRadius: 5,
                pointBackgroundColor: 'rgba(79, 181, 181, 1)'
            },
            {
                label: t("ACTIVE PROPERTIES"),
                data: chartData.map(item => item?.activeProperties),
                fill: true,
                backgroundColor: gradientFill('rgba(103, 58, 183,0.8)', 'rgba(255, 255, 255, 0.1)'),
                borderColor: 'rgba(103, 58, 183,1)',
                cubicInterpolationMode: 'monotone',
                pointRadius: 5,
                pointBackgroundColor: 'rgba(103, 58, 183,1)'
            }
        ]
    };

    // FILL BACKGROUND OF LINE CHART WITH GRADIANT
    function gradientFill(primaryColor, secondaryColor) {
        const ctx = document.createElement('canvas').getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 290);
        gradient.addColorStop(0, primaryColor);
        gradient.addColorStop(1, secondaryColor);
        return gradient;
    }

    // GENERATE CSV DATA
    const csvData = convertToCSV(chartData, currentLanguage);


    return (
        <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <div className="navtab-style1">
                <div className="d-sm-flex align-items-center justify-content-between">
                    <h4 className="title fz17 mb20">{t("PROPERTY TYPES")}</h4>

                    {/* CSV EXPORT  */}
                    {
                        csvData && csvData?.length > 0 ? <div>
                            <DownloadCSVButton csvData={csvData} filename={'propertyTypes.csv'} />
                        </div> : ""
                    }
                </div>
                {/* LINE CHART */}
                <div className="tab-content" >
                    <Line options={options} data={data} />
                </div>
            </div>
        </div>
    )
}

export default PropertyTypesChart