import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { protectedRoutes } from "./protected";
import { publicRoutes } from './public'
import { useDispatch, useSelector } from "react-redux";
import storage from "helpers/storage";
import { getUserDataApi } from "services/userService";
import { setUser } from "../redux/AgencySlice";
import { useEffect } from "react";
import { toast } from "react-toastify";

export default function AppRoutes() {

    //  IF ADMIN LOGIN AS AGENCY
    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const currentUser = useSelector((state) => state?.user?.userData)



    const params = new URLSearchParams(location.search);
    const userToken = params.get('t');

    // CHECK IF ADMIN IS LOGIN AS AGENCY
    useEffect(() => {



        if (userToken) {
            //  IF ALREADY USER EXISTS THEN REMOVE PREVIOUS ONE
            if (currentUser) {
                storage.clearToken()
                localStorage.removeItem('userType');
                localStorage.removeItem('AG_token');
                localStorage.removeItem('user-info');
                localStorage.removeItem('refresh-token');
            }


            storage.setToken(userToken)
            storage.setRefreshToken(userToken)

            storage.setData("refresh-token", userToken)
            getUserDataApi()
                .then((user) => {
                    storage.setData("user-info", user)
                    storage.setData("userType", 'AGENCY')
                    dispatch(
                        setUser({ userData: user, token: userToken })
                    );
                    navigate('/dashboard')
                })
                .catch((error) => {
                    toast.error(
                        error?.response?.data?.error_description
                            ? error?.response?.data?.error_description
                            : error.response.data?.message
                    );
                });
        }

    }, [userToken])


    const token = userToken ?? window.localStorage.getItem('AG_token');
    const mainRoutes = token != null ? protectedRoutes : [];
    const element = useRoutes([...mainRoutes, ...publicRoutes]);
    return element;
}
