import React from 'react'
import RecentActivities from '../RecentActivities'
import AgentMainStats from './AgentMainStats'
import GovernorateChart from '../CommonCharts/GovernorateChart'
import PropertyTypesChart from '../CommonCharts/PropertyTypesChart'
import MonthWiseProperty from '../CommonCharts/MonthWiseProperty'
import MostViewedProps from '../CommonCharts/MostViewedProps'
import PropertyTypeViewChart from '../CommonCharts/PropertyTypeViewChart'

const AgentDashboard = ({ startDate, endDate }) => {
    return (
        <div>
            {/* AGENT MAIN STATS */}
            <AgentMainStats startDate={startDate} endDate={endDate} />
            <div className="row">
                <div className="col-xl-6">
                    {/* GOVERNORATE / CITY / LOCATION CHART */}
                    <GovernorateChart startDate={startDate} endDate={endDate} currentRole={"AGENT"} />
                </div>
                <div className="col-xl-6">
                    {/* PROPERTY TYPES CHART */}
                    <PropertyTypesChart startDate={startDate} endDate={endDate} currentRole={"AGENT"} />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-6">
                {/* MONTH WISE PROPERTIES */}
                    <MonthWiseProperty currentRole={"AGENT"} />
                </div>
                <div className="col-xl-6">
                    <PropertyTypeViewChart  startDate={startDate} endDate={endDate} currentRole={"AGENT"} />
                </div>
            </div>
            <div className="row">
                <div className="col-xl-7">
                    <MostViewedProps currentRole={"AGENT"} startDate={startDate} endDate={endDate} />
                </div>
                <div className="col-xl-5">
                    <RecentActivities />
                </div>
            </div>
        </div>
    )
}

export default AgentDashboard