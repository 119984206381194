import React, { useEffect } from "react"
import "react-toastify/dist/ReactToastify.css"
import "./App.scss"
import Loader from "./components/common/Loader"
import { BrowserRouter as Router } from "react-router-dom"
import AppRoutes from "./router"
import { ToastContainer } from "react-toastify"
import { I18nextProvider, useTranslation } from "react-i18next"
import i18nConfig from "language/i18"
import Cookies from "universal-cookie"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import CssBaseline from '@mui/material/CssBaseline';
import { useDispatch, useSelector } from "react-redux"
import { currentPackageApi } from "services/packageService"
import { setCurrentPackage } from "./redux/UserPackageSlice"

//  MATERIAL UI THEME
// const customTheme = createTheme({
//   typography: {
//     fontFamily: '"DM Sans", sans-serif',
//     htmlFontSize: 17,
//   },
//   palette: {
//     primary: {
//       main: '#17B0B2',
//     },
//     text: {
//       primary: '#000000',
//       secondary: '#777777',
//     }
//   }
// });

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});
  
function App() {

  const { i18n } = useTranslation()

  const cookies = new Cookies()

  const dispatch = useDispatch()

  const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'
  
  const currentUser = useSelector((state)=> state?.user?.token)


  const baseUrl = process.env.REACT_APP_BASE_URL
  useEffect(() => {

    // Function to add RTL CSS files dynamically
    const addRTLStyles = () => {

      const bootstrapLink = document.createElement('link');
      bootstrapLink.href = `${process.env.REACT_APP_BASE_URL}/assets/rtl/css/bootstrap.rtl.min.css`
      bootstrapLink.rel = 'stylesheet';
      bootstrapLink.dataset.dynamicCss = true; // Add custom attribute for cleanup
      document.head.appendChild(bootstrapLink);

      const customSpacingLink = document.createElement('link');
      customSpacingLink.href = `${process.env.REACT_APP_BASE_URL}/assets/rtl/css/ud-custom-spacing.css`
      customSpacingLink.rel = 'stylesheet';
      customSpacingLink.dataset.dynamicCss = true;
      document.head.appendChild(customSpacingLink);

      const styleLink = document.createElement('link');
      styleLink.href = `${process.env.REACT_APP_BASE_URL}/assets/rtl/css/style.css`;
      styleLink.rel = 'stylesheet';
      styleLink.dataset.dynamicCss = true;
      document.head.appendChild(styleLink);
    };

    // Function to remove dynamically added RTL CSS files
    const removeRTLStyles = () => {
      const dynamicCssLinks = document.querySelectorAll('[data-dynamic-css]');
      dynamicCssLinks.forEach(link => link.remove());
    };

    // Add or remove RTL CSS files based on user's language
    if (currentLanguage === 'ar') {
      addRTLStyles();
    } else {
      removeRTLStyles();
    }

    // Clean up when the component unmounts or language changes
    return () => {
      removeRTLStyles();
    };

  }, [currentLanguage]); // Trigger useEffect when language changes


  // GET CURRENT PACKAGE DETAIL ON LOGIN

  const currentPackageDetails = ()=>{
    currentPackageApi().then((response)=>{
      dispatch(setCurrentPackage({ userPackage: response }))
    }).catch((error)=>{
      console.log({"ERROR IN CURRENT PACKAGE API":error})
    })
  }


  useEffect(()=>{
    currentPackageDetails()
  },[currentUser])

  return (
    <React.Suspense fallback={<Loader isLoading={true} />}>
      <I18nextProvider i18n={i18nConfig}>
        {
          currentLanguage === "ar" ?
            <CacheProvider value={cacheRtl}>
              <CssBaseline />
              <Router basename={baseUrl}>
                <AppRoutes />
                {/* FOR ALL TOAST MESSAGES */}
                <ToastContainer autoClose={5000} />
              </Router>
            </CacheProvider> :
            <Router basename={baseUrl}>
              <AppRoutes />
              {/* FOR ALL TOAST MESSAGES */}
              <ToastContainer autoClose={5000} />
            </Router>
        }

      </I18nextProvider>
    </React.Suspense>
  )
}

export default App
