import { AGENCY_VERIFICATION_EMAIL, DELETE_AGENCY_LOGO, GET_AGENCY_DATA, UPDATE_AGENCY_PROFILE, UPDATE_AGENCY_PROFILE_SOCIAL_INFO, UPLOAD_AGENCY_LOGO, WATERMARK_CONFIGURATION } from "constants/paths";
import { axios } from "./axios";

// AGENCY PROFILE GET DATA
export const getAgencyProfileData = async()=>{
    const response = await axios.get(GET_AGENCY_DATA)
    return response?.data
}

// AGENCY PROFILE SOCIAL INFO
export const updateAgencySocialInfo = async(data)=>{
    const response = await axios.post(UPDATE_AGENCY_PROFILE_SOCIAL_INFO, data)
    return response?.data
}

// AGENCY PROFILE UPLOAD LOGO
export const uploadAgencyLogo = async(data)=>{
    const response = await axios.post(UPLOAD_AGENCY_LOGO, data)
    return response?.data
}

// AGENCY PROFILE DELETE LOGO
export const deleteAgencyLogo = async()=>{
    const response = await axios.delete(DELETE_AGENCY_LOGO)
    return response?.data
}

// AGENCY PROFILE INFO UPDATE
export const updateAgencyProfile = async(data)=>{
    const response = await axios.post(UPDATE_AGENCY_PROFILE,data)
    return response?.data
}

// AGENCY PROFILE EMAIL VERIFY
export const verifyAgencyEmailAccount = async()=>{
    const response = await axios.get(AGENCY_VERIFICATION_EMAIL)
    return response?.data
}

// AGENCY WATERMARK CONFIGURATION 
export const waterMarkConfiguration = async(data)=>{
    const response = await axios.post(WATERMARK_CONFIGURATION,data)
    return response?.data
}