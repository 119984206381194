export const englishLang = {
    "SELECT": "Select",
    "SUBMIT": "Submit",
    "USERNAME": "Username",
    "PASSWORD": "Password",
    "CHOOSE PASSWORD": "Choose Password",
    "CONFIRM PASSWORD": "Confirm Password",
    "FIRST NAME": "First Name",
    "LAST NAME": "Last Name",
    "EMAIL ADDRESS": "Email Address",
    "EMAIL": "Email",
    "AGENCY": "Agency",
    "ADDRESS": "Address",
    "FACEBOOK URL": "Facebook URL",
    "INSTAGRAM URL": "Instagram URL",
    "LINKEDIN URL": "LinkedIn URL",
    "WELCOME": "Welcome",
    "MANAGE ACCOUNT": "Manage Account",
    "MY PROFILE (AGENT)": "My Profile (Agent)",
    "MY PROFILE (AGENCY)": "My Profile (Agency)",
    "AGENCY PROFILE": "Agency Profile",
    "LOGOUT": "Sign out",
    "MENU": "Menu",
    "AMAKEN - ALL RIGHTS RESERVED": "Amaken - All Rights Reserved",
    "PRIVACY": "Privacy",
    "TERMS": "Terms",
    "SITEMAP": "Sitemap",
    "AGENCIES/AGENTS SIGN IN": "Agencies/Agents Sign In",
    "SIGN IN TO YOUR ACCOUNT WITH YOUR CREDENTIALS.": " Sign In to Your Account with Your Credentials.",
    "ENTER EMAIL": "Enter Email",
    "ENTER PASSWORD": "Enter Password",
    "REMEMBER ME": "Remember Me",
    "LOGIN": "Sign in",
    "NOT SIGNED UP": "Not Signed Up?",
    "CREATE AN ACCOUNT.": "Create an Account",
    "SIGN UP": "Sign Up",
    "SIGN UP WITH AMAKEN ACROSS THE FOLLOWING": "Sign Up with Amaken Across the Following",
    "AGENT": "Agent",
    "ALREADY SIGNED UP": "Already Signed Up?",
    "SIGN IN": "Sign In",
    "CREATE ACCOUNT": "Create Account",
    "OR": "Or",
    "AGENCY NAME": "Agency Name",
    "AGENCY ADDRESS": "Agency Address",
    "MAIN CONTACT": "Main Contact",
    "SELECT CITY": "Select City",
    "COMMERCIAL REGISTRATION": "Commercial Registration",
    "EMAIL FOR AGENT ROLE": "Email for Agent Role",
    "AGENT FIRST NAME": "Agent First Name",
    "AGENT LAST NAME": "Agent Last Name",
    "CREATE PASSWORD": "Create Password",
    "I AGREE": "I Agree",
    "TERMS AND CONDITIONS": "Terms and Conditions",
    "SELECT AGENCY": "Select Agency",
    "FORGET PASSWORD": "Forgot Password?",
    "ENTER YOUR EMAIL ADDRESS TO RESET YOUR PASSWORD.": "Enter Email",
    "GET RESET LINK": "Send Reset Link",
    "BACK": "Back",
    "RESET PASSWORD": "Reset Password",
    "ENTER CONFIRM PASSWORD": "Confirm Password",
    "IDENTIFICATION": "Identification",
    "DESCRIPTION & MEDIA": "Description & Media",
    "DETAILS": "Details",
    "AMENITIES": "Amenities",
    "PROPERTY IDENTIFICATION": "Property Identification",
    "TITLE DEED": "Title Deed",
    "GOVERNORATE": "Governorate",
    "PREV STEP": "Prev Step",
    "PROPERTY DESCRIPTION": "Property Description",
    "PROPERTY TITLE": "Property Title",
    "PROPERTY TITLE (ENGLISH)": "Property Title (English)",
    "PROPERTY TITLE (ARABIC)": "Property Title (Arabic)",
    "DESCRIPTION (ENGLISH)": "Description (English)",
    "DESCRIPTION (ARABIC)": "Description (Arabic)",
    "PROPERTY MEDIA": "Property Media",
    "UPLOAD PHOTOS OF YOUR PROPERTY": "Upload photos of your property",
    "PHOTOS MUST BE JPEG OR PNG FORMAT": "Photos must be JPEG or PNG format",
    "VIDEO OPTION": "Video Option",
    "VIDEO FROM": "Video from",
    "EMBED VIDEO URL": "Embed Video URL",
    "VIDEO URL": "Video URL",
    "VIRTUAL TOUR": "Virtual Tour",
    "THERE ARE MANY VARIATIONS OF PASSAGES.": " There are many variations of passages.",
    "LISTING LOCATION": "Listing Location",
    "AREA (SQ. METER)": "Area (Sq. meter)",
    "FURNISHED": "Furnished",
    "YEARS OLD": "Years Old",
    "NO. OF BEDROOMS": "Number of Bedrooms",
    "APARTMENT NUMBER": "Apartment Number",
    "GARDEN": "Garden",
    "GARDEN SIZE": "Garden Size",
    "AVAILABLE FROM": "Available From",
    "SELECT AMENITIES": "Select Amenities",
    "SUBMIT PROPERTY": "Submit Property",
    "MY PROPERTIES": "My Properties",
    "ADD NEW PROPERTY": "Add New Property",
    "LISTING TITLE": "Listing Title",
    "DATE PUBLISHED": "Date Published",
    "STATUS": "Purpose",
    "VIEW": "View",
    "ACTION": "Action",
    "OF": "of",
    "SEARCH": "Search",
    "PROPERTY AVAILABLE": "Property Available",
    "NEW": "New",
    "SORT BY": "Sort By",
    "PRICE HIGH": "Highest Price",
    "PRICE LOW": "Lowest Price",
    "FEATURED": "Featured",
    "OFF MARKET": "Off Market",
    "SOLD": "Sold",
    "EXPIRED": "Expired",
    "COMMENTS": "Comments",
    "AGENTS": "Agents",
    "ADD AGENT": "Add Agent",
    "AGENT NAME": "Agent Name",
    "DATE STARTED": "Date Started",
    "TYPE": "Package Type",
    "AGENTS AVAILABLE": "Agents Available",
    "ABOUT AGENT (ENGLISH)": "About Agent (English)",
    "ABOUT AGENT (ARABIC)": "About Agent (Arabic)",
    "ABOUT AGENT": "About Agent",
    "EDIT AGENT": "Edit Agent",
    "UPDATE AGENT": "Update Agent",
    "MY PACKAGE": "My Package",
    "CURRENT PACKAGE": "Current Package",
    "RENEWAL REMAINING": "Renewal Remaining",
    "EXPIRY DATE": "Expiry Date",
    "UPGRADE": "Upgrade",
    "AUDIT": "Audit",
    "ACTIVITY": "Activity",
    "MY PROFILE": "My Profile",
    "DELETE THE IMAGE": "Delete the Image",
    "ARE YOU SURE TO DELETE PROFILE PICTURE?": "Are you sure you want to delete the profile picture?",
    "YES": "Yes",
    "NO": "No",
    "UPLOAD PROFILE PICTURE": "Upload Profile Picture",
    "UPLOAD AGENCY LOGO": "Upload Agency Logo",
    "JPEG OR PNG FORMAT": "JPEG or PNG format",
    "MAIN CONTACT NAME": "Main Contact Name",
    "CODE": "Code",
    "PHONE NO.": "Phone No.",
    "YOUR PHONE NUMBER": "Your Phone Number",
    "SELECT CITIES": "Select Cities",
    "COMPANY NATIONAL ID": "Company National ID",
    "AGENCY OVERVIEW (ENGLISH)": "Agency Overview (English)",
    "AGENCY OVERVIEW": "Agency Overview",
    "AGENCY OVERVIEW (ARABIC)": "Agency Overview (Arabic)",
    "UPDATE PROFILE": "Update Profile",
    "UPDATE SOCIAL": "Update Social",
    "PREFERRED LANGUAGE": "Preferred Language",
    "NOTHING SELECTED": "Nothing Selected",
    "YOUR ADDRESS": "Your Address",
    "OLD PASSWORD": "Old Password",
    "NEW PASSWORD": "New Password",
    "CONFIRM NEW PASSWORD": "Confirm New Password",
    "CHANGE PASSWORD": "Change Password",
    "WEBSITE URL": "Website URL",
    "ADD COMMENT": "Add Comment",
    "ACTIONS": "Actions",
    "EDIT": "Edit",
    "COMMENT": "Comment",
    "APPROVE": "Approve",
    "REJECT": "Reject",
    "ENABLE": "Enable",
    "DISABLE": "Disable",
    "AREA": "Area",
    "STREET": "Street",
    "CITY": "City",
    "PROPERTIES AVAILABLE": "Properties available",
    "SEND": "Send",
    "FEATURED PROPERTY": "Featured Property",
    "ACTIVE AGENT": "Active Agent",
    "PACKAGE AVAILABLE": "Package Available",
    "AMOUNT": "Amount",
    "OK": "OK",
    "CANCEL": "Cancel",
    "UNSUBSCRIBE": "Unsubscribe",
    "ARE YOU SURE TO UN-SUBSCRIBE": "Unsubscribing will disable all property and agents, impacting all actions immediately.",
    "DEAR VALUED CUSTOMER": " Dear Valued Customer",
    "THANK YOU FOR CHOOSING AMAKEN": "Thank you for choosing Amaken",
    "UNFORTUNATELY, INCOMPLETE PROCESS": "INCOMPLETE PROCESS",
    "AN CONFIRMATION EMAIL HAVE BEEN SENT TO YOUR REGISTERED EMAIL ADDRESS": " A confirmation email has been sent to your registered email address",
    "ACTIVE": "Active",
    "INACTIVE": "Inactive",
    "UPDATED DATE": "Updated Date",
    "UNSUBSCRIBE SUCCESSFUL": "Subscription has been stopped",
    "PER YEAR": "Per Year",
    "PER MONTH": "Per Month",
    "PAY": "Pay",
    "CARD HOLDER": "Cardholder",
    "MONTH": "Expiry Month",
    "YEAR": "Expiry Year",
    "SECURITY CODE": "CVV",
    "CARD": "Card Number",
    "PLACEHOLDER CARD": "XXXX XXXX XXXX XXXX",
    "PLACEHOLDER MONTH": "MM",
    "PLACEHOLDER YEAR": "YY",
    "PLACEHOLDER SECURITY CODE": "***",
    "ACCEPT SUBSCRIPTION RECURRING": "By checking the box below, you acknowledge and agree to subscribe to our services. This subscription involves recurring monthly or yearly payments, depending on the selected plan. The specified amount will be automatically deducted from your chosen payment method.",
    "CURRENCY SYMBOL": "JOD",
    "CANCEL DATE": "Cancellation Date",
    "PLAN LIMIT": "You Reached your plan limit",
    "PLAN NOT PURCHASED": "Plan Not Purchased",
    "ACTIVITIES": "Activities",
    "EDIT PROPERTY": "Edit Property",
    "AT LEAST ONE MEDIA IS REQUIRED": "At least one media is required",
    "MINIMUM DIMENSIONS": "Minimum Dimensions",
    "MAXIMUM NUMBER OF IMAGES": "Maximum Number of Images",
    "MINIMUM_DIMENSION_TEXT": "Each image must be at least 800 pixels in width and 800 pixels in height.",
    "MAXIMUM_IMAGES_TEXT": "You can upload up to 30 images per property listing.",
    "NO IMAGE CHOOSEN": "No image choosen",
    "NO FILE CHOOSEN": "No File Choosen",
    "MARK COVER": "Mark cover",
    "UPLOAD IMAGES": "Upload images",
    "CHOOSE FILE": "Choose File",
    "Description Data saved": 'Description Data saved',
    "ADMIN EMAIL": "Admin Email",
    "ADMIN FIRST NAME": "Admin First Name",
    "ADMIN LAST NAME": "Admin Last Name",
    "THIS EMAIL WILL BE USED FOR ADMINISTRATOR ACCESS.": "This email will be used for administrator access.",
    "THIS YEAR": "This Year",
    "LAST YEAR": "Last Year",
    "LAST QUARTER": "Last Quarter",
    "LAST MONTH": "Last Month",
    "LAST WEEK": "Last Week",
    "DATES GREATER THAN TODAY ARE NOT PERMITTED.": "Dates greater than today are not permitted.",
    "TOTAL PROPERTIES": "Total Properties",
    "NEW PROPERTIES": "New Properties",
    "ACTIVE PROPERTIES": "Active Properties",
    "PROPERTY VIEWS": "Property Views",
    "AGENCY PROFILE VIEWS": "Agency Profile Views",
    "AGENT PROFILE VIEWS": "Agent Profile Views",
    "AGING PROPERTIES": "Aging Properties",
    "PACKAGE USAGE": "Package Usage",
    "ACTIVE AGENTS": "Active Agents",
    "FEATURED PROPERTIES": "Featured Properties",
    "3D VIEWS": "3D Views",
    "EXPORT CSV": "Export CSV",
    "PROPERTY TYPES": "Property Types",
    "LOCATION": 'Location',
    "PROPERTY CHART": "Property Chart",
    "RECENT ACTIVITIES": "Recent Activities",
    "VIEW MORE": "View More",
    "MOST VIEWED": "Most Viewed",
    "AMAKENID": "Amaken Ref.",
    "VIEWS": "VIEWS",
    "TOP AGENTS": "Top Agents",
    "PROPERTY TYPE VIEWS": "Property Type Views",
    "AGENT WISE PROPERTY TYPES": "Agent Wise Property Types",
    "AGENT WISE PROPERTY STATUS": "Agent Wise Property Status",
    "GOVERNORATE ID": "Governorate Id",
    "TITLE": "Title",
    "CAPITAL": "Capital",
    "START DATE": "Start Date",
    "END DATE": "End Date",
    "REPORTS": "Reports",
    "PROCEED": "Proceed",
    "ENSURE YOU'VE SAVED YOUR DATA BEFORE MOVING FORWARD. ARE YOU READY TO PROCEED?": "Ensure you've saved your data before moving forward. Are you ready to proceed?",
    "PLEASE COMPLETE IDENTIFICATION FORM BEFORE PROCEED": 'Please complete identification form before proceed',
    "DETAILS ARE NOT REQUIRED FOR THIS PROPERTY!": "Details are not required for this property!",
    "PLEASE COMPLETE DESCRIPTION & MEDIA FORM BEFORE PROCEED": "Please complete description & media form before proceed",
    "PLEASE COMPLETE DETAILS FORM BEFORE PROCEED": "Please complete details form before proceed",
    "DIRECTORATE": "Directorate",
    "VILLAGE": "Village",
    "BASIN": "Basin",
    "DISTRICT": "District",
    "ID NUMBER": "ID Number",
    "GENERATE DLS KEY": "Generate DLS Key",
    "LAND TYPE": "Land Type",
    "BUILDING NUMBER": "Building Number",
    "APARTMENT ID": "Apartment ID",
    "LOCATION ON THE MAP": "Location On The Map",
    "OFFERING TYPE": "Purpose",
    "PROPERTY CATEGORY": "Property Category",
    "PROPERTY TYPE": "Property Type",
    "PRICE IN JOD": "Price in JOD",
    "LISTING DATE": "Listing Date",
    "AMAKEN ID": "Amaken Ref.",
    "NEXT STEP": "Next Step",
    "AGENCY REFERENCE": "Agency Ref.",
    "WATERMARK CONFIGURATION": "Watermark Configuration",
    "ADD WATERMARK": "Add Watermark",
    "WATERMARK TEXT": "Watermark Text",
    "WATERMARK COLOR": "Watermark Color",
    "WATERMARK IMAGE": "Watermark Image",
    "WATERMARK POSITION": "Watermark Position",
    "TOP LEFT": "Top Left",
    "TOP RIGHT": "Top Right",
    "CENTER": "Center",
    "BOTTOM LEFT": "Bottom Left",
    "BOTTOM RIGHT": "Bottom Right",
    "SAVE": "Save",
    "FLOOR": "Floor",
    "FLOOR PLANS": "Floor Plans",
    "ADD FLOOR": "Add Floor",
    "FLOOR IMAGES": "Floor Images",
    "FLOOR PLAN": "Floor Plan",
    "FLOOR NUMBER": "Floor Number",
    "FLOOR PLAN DELETED SUCCESSFULLY.": "Floor plan deleted successfully",
    "ARE YOU SURE TO DELETE THIS FLOOR PLAN": "Are you sure you want to delete this floor plan",
    "FURTHER FLOOR ADDITIONS ARE NOT ALLOWED.": "Further floor additions are not allowed.",
    "INTRO VIDEO": "Intro Video",
    "FULL VIDEO": "Full Video",
    "FILE": "File",
    "EMBEDDED": "Embedded",
    "INTRO VIDEO SOURCE": "Intro video source",
    "INTRO VIDEO URL": "Intro video URL",
    "FULL VIDEO SOURCE": "Full video source",
    "FULL VIDEO URL": "Full video URL",
    "YOUTUBE": "Youtube",
    "FACEBOOK": "Facebook",
    "VIMEO": "Vimeo",
    "VIDEO VALIDATION": "You can only upload video files (MP4, WebM, Ogg, MKV)!",
    "VIDEO SIZE": "The video size must be 100 MB or less!",
    "SELECT DATE": "Select Date",
    "LONGITUDE": "Longitude",
    "LATITUDE": "Latitude",
    "DRAG MARKER AND SELECT A LOCATION FOR YOUR PROPERTY": "Drag marker and select a location for your property",
    "MARKER MUST REMAIN WITHIN THE BOUNDS OF JORDAN": "Marker must remain within the bounds of Jordan.",
    "ADD": "Add",
    "VERIFY": "Verify",
    "DO YOU HAVE THE TITLE DEED FOR YOUR PROPERTY?": "Do you have the title deed for your property?",
    "IF YES, PLEASE PROCEED.": "If Yes, please proceed.",
    "IF NO, PLEASE NOTE THAT YOUR PROPERTY IS STILL UNDER CONSTRUCTION.": "If No, please note that your property is still under construction.",
    "UNDER CONSTRUCTION": "Unverified",
    "CHOOSE YOUR PAYMENT METHOD": "Choose your payment method",
    "DEFAULT PAYMENT": "Default Payment",
    "SET AS DEFAULT": "Set as default",
    "ARE YOU SURE TO DELETE THIS CARD": "Are you sure you want to delete this card?",
    "DELETE CARD": "Delete Card",
    "VERIFY PHONE NUMBER": "Verify phone number",
    "SHOW EXACT LOCATION": "Show exact location",
    "DISABLED": "Disabled",
    "ENABLED": "Enabled",
    "NO COMMENTS": "No Comments",
    "ENGLISH": "English",
    "ARABIC": "Arabic",
    "SOCIAL MEDIA": "Social Media",
    "COVER IMAGE": "Cover Image",
    "ARE YOU SURE TO DELETE THE IMAGE": "Are you sure to delete this image?",
    "YOU CAN UPLOAD FILE UP TO 10MB": "You can upload file up to 10mb!",
    "MEMBERSHIP PLANS": "Membership Plans",
    "JOIN AMAKEN NOW, LET'S GROW TOGETHER": "Join amaken now, Let's grow together",
    "BILLED MONTHLY": "Billed Monthly",
    "BILLED YEARLY": "Billed Yearly",
    "GENERAL": "General",
    "CUSTOM": "Custom",
    "NO PLANS AVAILABLE": "No Plans available",
    "PAYMENT": "Payment",
    "STANDARD LISTING SUBMISSION, ACTIVE FOR 30 DAYS": "Standard listing submission, active for 30 days",
    "ALL OPERATING SUPPORTED": "All Operating Supported",
    "VIEW PROPERTY STATISTICS": "View property statistics",
    "NOTIFICATIONS": "Notifications",
    "24/7 FULL SUPPORT": "24/7 Full support",
    "YEARLY": "Yearly",
    "MONTHLY": "Monthly",
    "ENTITY NAME": "Entity Name",
    "DATE": "Date",
    "ACCOUNT ACTIVATION": "Account Activation",
    "EMAIL VERIFICATION": "Email Verification",
    "THANK YOU FOR VERIFICATION.": "Thank you for verification.",
    "AGENT REPORT": "Agent Report",
    "PROPERTY": "Property",
    "PROPERTY REPORT": "Property Report",
    "LOCATION REPORT": "Location Report",
    "MESSAGE": "Message",
    "AUDITS": "Audits",
    "AUDITS AVAILABLE": "Audits Available",
    "PUBLISH": "Publish",
    // NEW ADDED
    "REFERENCE ID": "Reference Id",
    "ORDERS": "Orders",
    "ORDER ID": "Order Id",
    "TRANSACTION ID": "Transaction Id",
    "SUBSCRIPTION ID": "Subscription Id",
    "DESCRIPTION": "Description",
    "ORDER STATUS": "Order Status",
    "ORDERS AVAILABLE": "Orders Available",
    "RETRY PAYMENT": "Retry Payment",

    "PLAN HISTORY": "Plan History",
    "VIEW ORDERS": "View Orders",
    "NO PLAN ACTIVE": "No plan active",
    "YOU DON'T HAVE ACTIVE SUBSCRIPTION": "You don't have active subscription",
    "SUBSCRIBE NOW": "Subscribe Now",
    "PLAN CANCELLED!": "Plan Cancelled!",
    "CURRENT PLAN": "Current Plan",
    "MODIFY": "Modify",
    "ARE YOU SURE YOU WANT TO CANCEL THIS PLAN?": "Are you sure you want to cancel this plan?",
    "ARE YOU SURE YOU WANT TO MODIFY THIS PLAN?": "Are you sure you want to modify this plan?",
    "ARE YOU SURE YOU WANT TO UPGRADE THIS PLAN?": "Are you sure you want to upgrade this plan?",
    "CANCELLING WILL OFF MARKET ALL YOUR PROPERTIES AND DISABLE YOUR AGENTS.": "Cancelling will 'off market' all your properties and 'disable' your agents.",
    "RETRY": "Retry",
    "CURRENT SUBSCRIPTION PLAN": "Current Subscription Plan",
    "PRICE": "Price",
    "STARTED AT": "Started at",
    "NEXT PAYMENT": "Next Payment",
    "ACTIVE PROPERTY": "Active Property",
    "RETRY ATTEMPTS": "Retry attempt",
    "PAYMENT FOR THE CURRENT SUBSCRIPTION PACKAGE FAILED.": "Payment for the current subscription package failed.",
    "ATTEMPTS LEFT": "Attempts Left",
    "YOU CANNOT DECREASE THE NUMBER OF VIRTUAL TOURS": "You cannot decrease the number of virtual tours.",
    "YOU CANNOT DECREASE THE NUMBER OF FEATURED PROPERTY": "You cannot decrease the number of featured property.",
    "PLEASE MODIFY FEATURED PROPERTY OR VIRTUAL TOUR BEFORE PAYMENT": "Please modify featured property or virtual tour before payment.",
    "PAYMENT RESPONSE": "Payment Response",
    "PAYMENT SUCCESS": "Payment Success",
    "VIEW PACKAGE": "View Package",
    "TRY AGAIN": "Try again",
    "PLAN LIMIT EXCEED": "Plan limit exceeded, please modify or upgrade to a new plan",
    "CURRENT_SUBSCRIPTION_NOT_FOUND": "Current subscription not found.",
    "PER": "per",
    "HIDE LOCATION TEXT": "Hiding will prevent your property from appearing in searches on the Amaken map.",
    "HIDE EXACT LOCATION": "Hide exact location",
    "ARE YOU SURE YOU WANT TO HIDE LOCATION": "Are you sure you want to hide the exact location?"
}