
import { Pagination } from '@mui/material'
import { Empty, Spin } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getCommentList } from 'services/propertyService'
import { FaRegComments } from "react-icons/fa";
import { useTranslation } from 'react-i18next'
const CommentList = ({ propertyId, listApiCall }) => {

	const [commentList, setCommentList] = useState([])

	const [currentPage, setCurrentPage] = useState(1)
	const [pageSize, setPageSize] = useState(5)
	const [totalRowCount, setTotalRowCount] = useState(0)
	const [loading, setLoading] = useState(false)

	const { t } = useTranslation()

	const fetchCommentListing = () => {
		setLoading(true)
		let params = {
			sort: 'commentedAt,DESC',
			size: pageSize
		}
		if (currentPage) {
			params.page = currentPage - 1
		}
		getCommentList(propertyId, params).then((response) => {
			setCommentList(response?.data)
			setTotalRowCount(response?.headers["x-total-count"])
			setLoading(false)
		}).catch((error) => {
			setLoading(false)
			toast.error(
				error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message
			);
		})
	}

	const handlePageChange = (event, value) => {
		setCurrentPage(value)
	}

	useEffect(() => {
		fetchCommentListing()
		setCommentList([])
	}, [propertyId, currentPage, listApiCall])

	useEffect(() => {
		setCurrentPage(1)
	}, [propertyId])

	return (
		<div className='mt-3'>
			<Card>
				<Card.Header>
					<p className='fw500 title fz17 mb-0'>{t("COMMENTS")} <FaRegComments size={22} className='mb-1' /></p>
				</Card.Header>
				<Card.Body className='overflow-auto max-h-60vh'>
					{loading ?
						<div className="text-center my-5">
							<Spin size="large" />
						</div> :
						commentList && commentList?.length > 0 ?


							<div>
								{
									commentList.map((element) => {
										return <div key={element?.id} className="comment-wrapper mb-3 border-bottom">
											<div className="row justify-content-between">
												<div className="col-auto">
													<p className="sub-heading fs-16">{element?.commentedByUser?.firstName ?? ''} {element?.commentedByUser?.lastName ?? ''}</p>
												</div>
												<div className="col-auto">
													{
														element?.commentedAt ? <span className='grey-color fs-14'>{moment(element?.commentedAt).fromNow()}</span> : ""
													}

												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<p className="text-justify grey-color fs-14">{element?.commentText ?? ''}</p>
												</div>
											</div>
										</div>
									})
								}
								<div className="mbp_pagination text-center mt30 dataTable_pagination">
									<Pagination count={Math.ceil(totalRowCount / pageSize)} siblingCount={1} page={currentPage} className="d-inline-block" size="large" onChange={(event, value) => handlePageChange(event, value)} />
								</div>
							</div>
							: <Empty description=<p className='fw500'>{t("NO COMMENTS")}!</p> />}
				</Card.Body>
			</Card>
		</div>
	)
}

export default CommentList
