import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { nameBasedProtectedRoutes } from 'router/protected'
import { recentActivityApi } from 'services/dashboardService'
import Cookies from 'universal-cookie'

const RecentActivities = () => {

	const {t} = useTranslation()

	const { i18n } = useTranslation()
	const cookies = new Cookies()

	const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'

	const [loading, setLoading] = useState(false)

	const [activityList, setActivityList] = useState([])

	// FETCH RECENT ACTIVITIES LIST
	const fetchRecentActivities = () => {
		setLoading(true)
		let params = {
			sort: 'createdAt,DESC',
			size: 6,
			page: 0
		}
		recentActivityApi(params).then((response) => {
			setActivityList(response?.data)
		}).catch((error) => {
			toast.error(
				error.response.data.error_description
					? error.response.data.error_description
					: error.response.data?.message ?? error?.message
			);
		})
	}

	useEffect(() => {
		fetchRecentActivities()
	}, [])

	const renderIcon = (actionType) => {
		if (actionType === 'PROPERTY_FAVOURITE_TOGGLE') {
			return <span className="icon me-3 flaticon-like flex-shrink-0"></span>
		} else {
			return <span className="icon me-3 flaticon-review flex-shrink-0" />
		}
	}

	return (
		<div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
			<h4 className="title fz17 mb25">{t("RECENT ACTIVITIES")}</h4>
			{
				activityList && activityList?.length > 0 ?
					activityList?.map((element, index) => {
						let activityTitle = ""
						let redirectLink = ""
						if (element?.entityName === "Property") {
							const entityData = JSON.parse(element?.entityData)
							activityTitle = element?.entityTitle[currentLanguage]
							redirectLink = <Link to={`${nameBasedProtectedRoutes?.propertyList?.path}?search=${entityData?.amakenId}`} className="fw600">{activityTitle}</Link>

						} else {
							const entityData = JSON.parse(element?.entityData)
							activityTitle = entityData?.property?.title[currentLanguage]
							redirectLink = <a target="_blank" href={`${process.env.REACT_APP_WEBSITE_WEB_URL}/property-details/${entityData?.property?.id}`} className="fw600">{activityTitle}</a>

						}
						return <div key={index + 1} className="recent-activity d-sm-flex align-items-center mb20">

							{renderIcon(element?.actionType)}
							<div>
								<p className="text mb-0 flex-grow-1">
									{element?.message[currentLanguage]}
									<br />
								</p>
								{redirectLink}
							</div>
						</div>
					}) : ""
			}
			<div className="d-grid">
				<Link to={nameBasedProtectedRoutes?.activityLogs?.path} className="ud-btn btn-white2">
					{t("VIEW MORE")}
					<i className="fal fa-arrow-right-long" />
				</Link>
			</div>
		</div>
	)
}

export default RecentActivities