import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { nameBasedProtectedRoutes } from 'router/protected'

const NoActiveSubscription = () => {
  const { t } = useTranslation()
  return (
    <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
      <div className='d-flex justify-content-between'>
        <div>
          <h2 className='text2 text-orange'>{t("NO PLAN ACTIVE")}</h2>
          <p className='text'>{t("YOU DON'T HAVE ACTIVE SUBSCRIPTION")}</p>
        </div>
      </div>
      <div>
        <Link to={nameBasedProtectedRoutes?.plans?.path} className="ud-btn btn-dark">{t('SUBSCRIBE NOW')} <i className="fal fa-arrow-right-long" /></Link>
      </div>
    </div>
  )
}

export default NoActiveSubscription