import Cookies from "universal-cookie";

// Function to translate text based on the user's language preference

function translateText(arabicText, englishText) {
const cookies = new Cookies()
const userLanguage = cookies.get('langKey') ?? 'en';
    return userLanguage === 'ar' ? arabicText : englishText;
}

export const USER_NAME_REQUIRED = translateText("اسم المستخدم مطلوب.", "Username is required.");
export const EMAIL_REQUIRED = translateText("عنوان البريد الإلكتروني مطلوب.", "Email address is required.");
export const CURRENT_PASSWORD_REQUIRED = translateText("كلمة المرور الحالية مطلوبة.", "Current password is required.");
export const NEW_PASSWORD_REQUIRED = translateText("كلمة المرور الجديدة مطلوبة.", "New password is required.");
export const PASSWORD_REQUIRED = translateText("كلمة المرور مطلوبة.", "Password is required.");
export const CONFIRM_PASSWORD_REQUIRED = translateText("تأكيد كلمة المرور مطلوب.", "Confirm password is required.");
export const CONFIRM_PASSWORD_MATCH = translateText("يجب أن تتطابق كلمة المرور المؤكدة مع كلمة المرور الجديدة.", "Confirm password must match the new password.");
export const MINIMUM_8_CHAR = translateText("يجب أن تكون على الأقل 8 أحرف.", "Must be at least 8 characters.");
export const MAXIMUM_30_CHAR = translateText("يجب ألا تزيد عن 14 حرفًا.", "Must be no more than 14 characters.");
export const MINIMUM_3_CHAR = translateText("يجب أن تكون على الأقل 3 أحرف.", "Must be at least 3 characters.");
export const MAXIMUM_255_CHAR = translateText("يجب ألا تزيد عن 255 حرفًا.", "Must be no more than 255 characters.");
export const MAXIMUM_1250_CHAR = translateText("يجب ألا تزيد عن 1250 حرفًا.", "Must be no more than 1250 characters.");
export const PASSWORD_REGEX = translateText("يجب أن تكون كلمة المرور بين 8-14 حرفًا وتحتوي على الأقل على حرف كبير واحد، وحرف صغير واحد، وحرف خاص واحد، ورقم واحد.", "Password must be 8-14 characters and include at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number.");
export const PASSWORD_MUST_MATCH = translateText("يجب أن تتطابق كلمة المرور المؤكدة مع كلمة المرور.", "Confirm password must match the password.");
export const VALID_EMAIL = translateText("يرجى إدخال عنوان بريد إلكتروني صحيح.", "Please enter a valid email address.");
export const SITE_CODE_REQUIRED = translateText("رمز الموقع مطلوب.", "Site code is required.");
export const SITE_NAME_REQUIRED = translateText("اسم الموقع مطلوب.", "Site name is required.");
export const CONTACT_FIRST_NAME_REQUIRED = translateText("الاسم الأول مطلوب.", "First name is required.");
export const CONTACT_LAST_NAME_REQUIRED = translateText("اسم العائلة مطلوب.", "Last name is required.");
export const PHONE_NO_REQUIRED = translateText("رقم الهاتف مطلوب.", "Phone number is required.");
export const MOBILE_REQUIRED = translateText("رقم الجوال مطلوب.", "Mobile number is required.");
export const CHAR_MAX_10 = translateText("يجب ألا يزيد عن 10 أحرف.", "Must not exceed 10 characters.");
export const CHAR_MAX_20 = translateText("يجب ألا يزيد عن 20 حرفًا.", "Must not exceed 20 characters.");
export const CHAR_MAX_50 = translateText("يجب ألا يزيد عن 50 حرفًا.", "Must not exceed 50 characters.");
export const CHAR_MAX_300 = translateText("يجب ألا يزيد عن 300 حرف.", "Must not exceed 300 characters.");
export const PHONE_NUM_LENGTH = translateText("يجب أن يتكون رقم الهاتف من 10 أحرف بالضبط.", "Phone number must be exactly 10 digits.");
export const FIRST_NAME_REQUIRED = translateText("الاسم الأول مطلوب.", "First name is required.");
export const LAST_NAME_REQUIRED = translateText("اسم العائلة مطلوب.", "Last name is required.");
export const MINIMUM_7_DIGIT = translateText("يجب أن يكون على الأقل 7 أرقام.", "Must be at least 7 digits.");
export const MAXIMUM_15_DIGIT = translateText("يجب ألا يزيد عن 15 رقمًا.", "Must not exceed 15 digits.");
export const LANG_KEY_REQUIRED = translateText("اللغة المفضلة مطلوبة.", "Preferred language is required.");

export const MINIMUM_3_DIGIT = translateText("يجب ألا يزيد عن 3 أرقام.", "Must be at least 3 digits.");
export const MAXIMUM_3_DIGIT = translateText("يجب أن يكون 3 أرقام بالضبط.", "Must be exactly 3 digits.");
export const MINIMUM_5_DIGIT = translateText("يجب أن يكون على الأقل 5 أرقام.", "Must be at least 5 digits.");
export const MAXIMUM_5_DIGIT = translateText("يجب ألا يزيد عن 5 أرقام.", "Must not exceed 5 digits.");
export const GOVERNORATE_REQUIRED = translateText("المحافظة مطلوبة.", "Governorate is required.");
export const DIRECTORATE_REQUIRED = translateText("المديرية مطلوبة.", "Directorate is required.");
export const VILLAGE_REQUIRED = translateText("القرية مطلوبة.", "Village is required.");
export const BASIN_REQUIRED = translateText("الحوض مطلوب.", "Basin is required.");
export const DISTRICT_REQUIRED = translateText("المنطقة مطلوبة.", "District is required.");
export const ID_NUMBER_REQUIRED = translateText("رقم الهوية مطلوب.", "Id number is required.");
export const BUILDING_NUMBER_REQUIRED = translateText("رقم المبنى مطلوب.", "Building Number is required.");
export const FLOOR_NUMBER_REQUIRED = translateText("رقم الطابق مطلوب.", "Floor number is required.");
export const APARTMENT_ID_REQUIRED = translateText("معرف الشقة مطلوب.", "Apartment ID is required");
export const LAND_TYPE_REQUIRED = translateText("نوع الأرض مطلوب.", "Land Type is required.");
export const OFFERING_TYPE_REQUIRED = translateText("نوع العرض مطلوب.", "Offering type is required.");
export const PROPERTY_TYPE_REQUIRED = translateText("نوع العقار مطلوب.", "Property type is required.");
export const PROPERTY_CATEGORY_REQUIRED = translateText("فئة العقار مطلوبة.", "Property category is required.");
export const AMAKEN_ID_REQUIRED = translateText("معرف الأماكن مطلوب.", "Amaken ID is required.");
export const TITLE_DEED_REQUIRED = translateText("سند الملكية مطلوب.", "Title deed is required.");
export const PRICE_REQUIRED = translateText("السعر مطلوب.", "Price is required.");
export const NO_NEGATIVE_VALUE = translateText("يجب ألا تكون القيمة أقل من 0.", "Value must not be less than 0.");

export const PROPERTY_TITLE_REQUIRED = translateText("عنوان العقار مطلوب.", "Property title is required.");
export const PROPERTY_DESCRIPTION_REQUIRED = translateText("وصف العقار مطلوب.", "Property description is required.");
export const VIDEO_SOURCE_REQUIRED = translateText("مصدر الفيديو مطلوب.", "Video source is required.");
export const VIDEO_URL_REQUIRED = translateText("رابط الفيديو مطلوب.", "Video URL is required.");
export const VIRTUAL_TOUR_REQUIRED = translateText("الجولة الافتراضية مطلوبة.", "Virtual tour is required.");
export const AGENCY_REQUIRED = translateText("الشركة العقارية مطلوبة.", "Agency is required.");
export const AGENT_NAME_REQUIRED = translateText("اسم الوكيل مطلوب.", "Agent name is required.");
export const ADDRESS_REQUIRED = translateText("العنوان مطلوب.", "Address is required.");
export const I_AGREE_REQUIRED = translateText("يجب قبول الشروط والأحكام.", "You must accept the terms and conditions.");
export const AGENCY_NAME_REQUIRED = translateText("اسم الشركة العقارية مطلوب.", "Agency name is required.");
export const CONTACT_NAME_REQUIRED = translateText("اسم جهة الاتصال مطلوب.", "Contact name is required.");
export const URL_REGEX = translateText("رابط غير صالح. يرجى استخدام رابط يبدأ بـ 'https'.", "Invalid URL. Please use a URL starting with 'https'.");
export const WEBSITE_URL = translateText("رابط غير صالح. يرجى استخدام رابط يبدأ بـ 'www'.", "Invalid URL. Please use a URL starting with 'www'.");
export const PHONE_NUMBER_VALIDATION = translateText("يرجى إدخال رقم هاتف صحيح.", "Please enter a valid phone number.");

export const AMENITIES_REQUIRED = translateText("مطلوب على الأقل واحدة من المرافق.", "At least one amenity is required.");
export const COMMENT_REQUIRED = translateText("التعليق مطلوب.", "Comment is required.");
export const CARD_NAME = translateText("اسم حامل البطاقة مطلوب.", "Cardholder name is required.");
export const CARD_NUMBER = translateText("رقم البطاقة مطلوب.", "Card number is required.");
export const CARD_MIN_LIMIT = translateText("يجب أن يكون على الأقل 8 أرقام.", "Must be at least 8 digits.");
export const CARD_MAX_LIMIT = translateText("يجب ألا يزيد عن 19 رقمًا.", "Must not exceed 19 digits.");
export const YEAR_REQUIRED = translateText("سنة انتهاء صلاحية البطاقة مطلوبة.", "Card expiration year is required.");
export const MONTH_REQUIRED = translateText("شهر انتهاء صلاحية البطاقة مطلوب.", "Card expiration month is required.");
export const CARD_CVV = translateText("الرمز الأمني مطلوب.", "Security code is required.");
export const CARD_CVV_LIMIT = translateText("يجب أن يكون الرمز الأمني مكونًا من 3 أرقام بالضبط.", "Security code must be exactly 3 digits.");
export const INVALID_FORMAT = translateText("تنسيق غير صالح.", "Invalid format.");
export const COUNTRY_CODE_REQUIRED = translateText("رمز البلد مطلوب.", "Country code is required.")
export const OTP_REQUIRED = translateText("يرجى إدخال رمز التحقق OTP.","Please enter the OTP.")

export const ONLY_ENGLISH_CHARACTERS = translateText("يُسمح فقط بالأحرف الإنجليزية.","Only English characters are allowed.")
export const ONLY_ARABIC_CHARACTERS = translateText("يُسمح فقط بالأحرف العربية.","Only Arabic characters are allowed.")

export const INVALID_COUNTRY_CODE = translateText("رمز البلد غير مسموح به","Country code not allowed!")

// NEW 
export const AVAILABLE_FROM_REQUIRED = translateText("متاح من التاريخ مطلوب","Available from date is required")
export const FILE_REQUIRED = translateText("مطلوب ملف الفيديو","Video file is required!")