export const arabicLang = {
    "SELECT": "اختيار",
    "SUBMIT": "إرسال",
    "USERNAME": "اسم المستخدم",
    "PASSWORD": "كلمة المرور",
    "CHOOSE PASSWORD": "اختر كلمة المرور",
    "CONFIRM PASSWORD": "تأكيد كلمة المرور",
    "FIRST NAME": "الاسم الأول",
    "LAST NAME": "اسم العائلة",
    "EMAIL": "البريد الإلكتروني",
    "EMAIL ADDRESS": "البريد الإلكتروني",
    "AGENCY": "الشركة العقارية",
    "ADDRESS": "العنوان",
    "FACEBOOK URL": "رابط فيسبوك",
    "INSTAGRAM URL": "رابط إنستجرام",
    "LINKEDIN URL": "رابط لينكد إن",
    "WELCOME": "مرحبًا",
    "MANAGE ACCOUNT": "إدارة الحساب",
    "MY PROFILE (AGENT)": "حسابي (المستشار العقاري)",
    "MY PROFILE (AGENCY)": "ملف الشركة",
    "AGENCY PROFILE": "صفحة الشركة",
    "LOGOUT": "خروج",
    "MENU": "القائمة",
    "AMAKEN - ALL RIGHTS RESERVED": "أماكن - جميع الحقوق محفوظة",
    "PRIVACY": "الخصوصية",
    "TERMS": "الشروط",
    "SITEMAP": "خريطة الموقع",
    "AGENCIES/AGENTS SIGN IN": "تسجيل دخول الشركات / المستشارون العقاريون",
    "SIGN IN TO YOUR ACCOUNT WITH YOUR CREDENTIALS.": "تسجيل الدخول إلى حسابك باستخدام معلومات الحساب",
    "ENTER EMAIL": "أدخل البريد الإلكتروني",
    "ENTER PASSWORD": "أدخل كلمة المرور",
    "REMEMBER ME": "تذكرني",
    "LOGIN": "تسجيل الدخول",
    "NOT SIGNED UP": "ليس لديك حساب؟",
    "CREATE AN ACCOUNT.": "حساب جديد",
    "SIGN UP": "اشترك",
    "SIGN UP WITH AMAKEN ACROSS THE FOLLOWING": "اشترك مع أماكن عبر التالي",
    "AGENT": "المستشار العقاري",
    "ALREADY SIGNED UP": "هل قمت بالتسجيل بالفعل؟",
    "SIGN IN": "تسجيل الدخول",
    "CREATE ACCOUNT": "إنشاء حساب",
    "OR": "أو",
    "AGENCY NAME": "اسم الشركة العقارية",
    "AGENCY ADDRESS": "عنوان الشركة العقارية",
    "MAIN CONTACT": "جهة الاتصال الرئيسية",
    "SELECT CITY": "اختر المدينة",
    "COMPANY NATIONAL ID": "الرقم الوطني للشركة",
    "EMAIL FOR AGENT ROLE": "البريد الإلكتروني لدور المستشار العقاري",
    "AGENT FIRST NAME": "الاسم الأول للمستشار العقاري",
    "AGENT LAST NAME": "اسم العائلة للمستشار العقاري",
    "CREATE PASSWORD": "إنشاء كلمة المرور",
    "I AGREE": "موافق",
    "TERMS AND CONDITIONS": "الشروط والأحكام",
    "SELECT AGENCY": "اختر الشركة",
    "FORGET PASSWORD": "نسيت كلمة المرور؟",
    "ENTER YOUR EMAIL ADDRESS TO RESET YOUR PASSWORD.": "أدخل البريد الإلكتروني",
    "GET RESET LINK": "إعادة تعيين كلمة المرور",
    "BACK": "العودة",
    "RESET PASSWORD": "إعادة تعيين كلمة المرور",
    "ENTER CONFIRM PASSWORD": "تأكيد كلمة المرور",
    "IDENTIFICATION": "التعريف",
    "DESCRIPTION & MEDIA": "الوصف والصور",
    "DETAILS": "التفاصيل",
    "AMENITIES": "وسائل الراحة",
    "PROPERTY IDENTIFICATION": "تعريف العقار",
    "TITLE DEED": "سند التسجيل",
    "GOVERNORATE": "المحافظة",
    "PREV STEP": "الخطوة السابقة",
    "PROPERTY DESCRIPTION": "وصف العقار",
    "PROPERTY TITLE": "مسمى العقار",
    "PROPERTY TITLE (ENGLISH)": "مسمى العقار (بالإنجليزية)",
    "PROPERTY TITLE (ARABIC)": "مسمى العقار (بالعربية)",
    "DESCRIPTION (ENGLISH)": "الوصف (بالإنجليزية)",
    "DESCRIPTION (ARABIC)": "الوصف (بالعربية)",
    "PROPERTY MEDIA": "الصور و الفيديو",
    "UPLOAD PHOTOS OF YOUR PROPERTY": "تحميل صور للعقار",
    "PHOTOS MUST BE JPEG OR PNG FORMAT": "يجب أن تكون الصور بصيغة JPEG أو PNG",
    "VIDEO OPTION": "خيار الفيديو",
    "VIDEO FROM": "فيديو من",
    "EMBED VIDEO URL": "رابط الفيديو",
    "VIDEO URL": "رابط الفيديو",
    "THERE ARE MANY VARIATIONS OF PASSAGES.": "هناك العديد من الاختلافات في المقاطع",
    "LISTING LOCATION": "موقع العقار",
    "AREA (SQ. METER)": "المساحة (بالمتر المربع)",
    "FURNISHED": "مفروشة",
    "YEARS OLD": "عمر البناء",
    "NO. OF BEDROOMS": "عدد غرف النوم",
    "APARTMENT NUMBER": "رقم الشقة",
    "GARDEN": "الحديقة",
    "GARDEN SIZE": "حجم الحديقة",
    "AVAILABLE FROM": "متوفرة من",
    "SELECT AMENITIES": "اختر وسائل الراحة",
    "SUBMIT PROPERTY": "إرسال العقار",
    "MY PROPERTIES": "عقاراتي",
    "ADD NEW PROPERTY": "إضافة عقار جديد",
    "LISTING TITLE": "مسمى العقار",
    "DATE PUBLISHED": "تاريخ النشر",
    "STATUS": "نوع العرض",
    "VIEW": "عرض",
    "ACTION": "الإجراء",
    "OF": "من",
    "SEARCH": "بحث",
    "PROPERTY AVAILABLE": "العقارات المتاحة",
    "NEW": "جديد",
    "SORT BY": "ترتيب حسب",
    "PRICE HIGH": "الأعلى سعراً",
    "PRICE LOW": "الأقل سعراً",
    "FEATURED": "مميز",
    "OFF MARKET": "غير معروض",
    "SOLD": "تم البيع",
    "EXPIRED": "منتهي الصلاحية",
    "COMMENTS": "التعليقات",
    "AGENTS": "المستشارون",
    "ADD AGENT": "إضافة مستشار",
    "AGENT NAME": "اسم المستشار",
    "DATE STARTED": "تاريخ البدء",
    "AGENTS AVAILABLE": "المستشارون العقاريون المتاحون",
    "ABOUT AGENT (ENGLISH)": "عن المستشار (بالإنجليزية)",
    "ABOUT AGENT (ARABIC)": "عن المستشار (بالعربية)",
    "ABOUT AGENT": "عن المستشار",
    "TYPE": "نوع الحزمة",
    "EDIT AGENT": "تعديل المستشار",
    "UPDATE AGENT": "تحديث المستشار",
    "MY PACKAGE": "الحزمة",
    "CURRENT PACKAGE": "الحزمة الحالية",
    "RENEWAL REMAINING": "التجديد المتبقي",
    "EXPIRY DATE": "تاريخ الانتهاء",
    "UPGRADE": "ترقية",
    "AUDIT": "التدقيق",
    "ACTIVITY": "الحركات",
    "MY PROFILE": "حسابي",
    "DELETE THE IMAGE": "حذف الصورة",
    "ARE YOU SURE TO DELETE PROFILE PICTURE?": "هل أنت متأكد من حذف الصورة",
    "YES": "نعم",
    "NO": "لا",
    "UPLOAD AGENCY LOGO": "تحميل شعار الشركة",
    "UPLOAD PROFILE PICTURE": "تحميل صورة",
    "JPEG OR PNG FORMAT": "صيغة JPEG أو PNG",
    "MAIN CONTACT NAME": "اسم جهة الاتصال الرئيسية",
    "CODE": "الرمز",
    "PHONE NO.": "رقم الهاتف",
    "YOUR PHONE NUMBER": "رقم هاتفك",
    "SELECT CITIES": "اختر المدن",
    "COMMERCIAL REGISTRATION": "السجل التجاري",
    "AGENCY OVERVIEW (ENGLISH)": "نظرة عامة على الشركة العقارية (بالإنجليزية)",
    "AGENCY OVERVIEW": "نظرة عامة على الشركة العقارية",
    "AGENCY OVERVIEW (ARABIC)": "نظرة عامة على الشركة العقارية (بالعربية)",
    "UPDATE PROFILE": "تحديث الملف الشخصي",
    "UPDATE SOCIAL": "تحديث شبكات التواصل",
    "PREFERRED LANGUAGE": "اللغة المفضلة",
    "NOTHING SELECTED": "لم يتم تحديد شيء",
    "YOUR ADDRESS": "عنوانك",
    "OLD PASSWORD": "كلمة المرور القديمة",
    "NEW PASSWORD": "كلمة المرور الجديدة",
    "CONFIRM NEW PASSWORD": "تأكيد كلمة المرور الجديدة",
    "CHANGE PASSWORD": "تغيير كلمة المرور",
    "WEBSITE URL": "رابط الموقع",
    "ADD COMMENT": "إضافة تعليق",
    "ACTIONS": "الإجراءات",
    "EDIT": "تعديل المستشار",
    "COMMENT": "تعليق",
    "APPROVE": "الموافقة",
    "REJECT": "الرفض",
    "ENABLE": "تفعيل",
    "DISABLE": "تعليق",
    "AREA": "المنطقة",
    "STREET": "الشارع",
    "CITY": "المدينة",
    "PROPERTIES AVAILABLE": "العقارات المتاحة",
    "SEND": "إرسال",
    "ACTIVE AGENT": "مستشار فعال",
    "PACKAGE AVAILABLE": "الحزمة المتاحة",
    "AMOUNT": "المبلغ",
    "CANCEL": "إلغاء",
    "OK": "نعم",
    "UNSUBSCRIBE": "إلغاء الاشتراك",
    "ARE YOU SURE TO UN-SUBSCRIBE": "هل أنت متأكد من إلغاء الاشتراك؟ إلغاء الاشتراك سيؤدي إلى تعليق جميع العقارات المستشارين، مما يؤثر على جميع الإجراءات على الفور.",
    "DEAR VALUED CUSTOMER": "عزيزي العميل",
    "THANK YOU FOR CHOOSING AMAKEN": "شكرًا لاختيارك أماكن",
    "UNFORTUNATELY, INCOMPLETE PROCESS": "عملية غير مكتملة",
    "AN CONFIRMATION EMAIL HAVE BEEN SENT TO YOUR REGISTERED EMAIL ADDRESS": "تم إرسال بريد إلكتروني للتأكيد إلى عنوان بريدك الإلكتروني المسجل",
    "ACTIVE": "فعال",
    "INACTIVE": "غير فعال",
    "UPDATED DATE": "تاريخ التحديث",
    "UNSUBSCRIBE SUCCESSFUL": "تم إيقاف الاشتراك",
    "PER YEAR": "سنويًا",
    "PER MONTH": "شهريًا",
    "PAY": "الدفع",
    "CARD HOLDER": "حامل البطاقة",
    "MONTH": "شهر الانتهاء",
    "YEAR": "سنة الانتهاء",
    "SECURITY CODE": "رمز التحقق من البطاقة (CVV)",
    "CARD": "رقم البطاقة",
    "PLACEHOLDER CARD": "XXXX XXXX XXXX XXXX",
    "PLACEHOLDER MONTH": "MM",
    "PLACEHOLDER YEAR": "YY",
    "PLACEHOLDER SECURITY CODE": "***",
    "ACCEPT SUBSCRIPTION RECURRING": "من خلال الموافقة ادناه، فإنك تقر وتوافق على الاشتراك في خدماتنا. يتضمن هذا الاشتراك دفعات شهرية أو سنوية متكررة، حسب الخطة المحددة. سيتم خصم المبلغ المحدد تلقائيًا من طريقة الدفع التي اخترتها.",
    "CURRENCY SYMBOL": "العقارات",
    "CANCEL DATE": "تاريخ الإلغاء",
    "PLAN LIMIT": "لقد وصلت الحد الأعلى للحزمة",
    "PLAN NOT PURCHASED": "الخطة غير مشتراة",
    "ACTIVITIES": "الحركات",
    "EDIT PROPERTY": "تعديل العقار",
    "AT LEAST ONE MEDIA IS REQUIRED": "مطلوب صورة واحدة على الأقل",
    "MINIMUM DIMENSIONS": "الأبعاد الدنيا",
    "MAXIMUM NUMBER OF IMAGES": "الحد الأقصى لعدد الصور",
    "MINIMUM_DIMENSION_TEXT": "يجب أن تكون كل صورة بعرض لا يقل عن 800 بكسل وارتفاع لا يقل عن 800 بكسل.",
    "MAXIMUM_IMAGES_TEXT": "يمكنك تحميل ما يصل إلى 30 صور لكل عقار",
    "NO IMAGE CHOOSEN": "لم يتم اختيار صورة",
    "NO FILE CHOOSEN": "لم تقم باختيار ملف",
    "UPLOAD IMAGES": "تحميل الصور",
    "CHOOSE FILE": "اختر ملف",
    "ADMIN EMAIL": "البريد الإلكتروني للمسؤول",
    "ADMIN FIRST NAME": "الاسم الأول للمسؤول",
    "ADMIN LAST NAME": "الاسم الأخير للمسؤول",
    "THIS EMAIL WILL BE USED FOR ADMINISTRATOR ACCESS.": "سيتم استخدام هذا البريد الإلكتروني للوصول إلى المسؤول.",
    "THIS YEAR": "هذا العام",
    "LAST YEAR": "العام الماضي",
    "LAST QUARTER": "الربع الأخير",
    "LAST MONTH": "الشهر الماضي",
    "LAST WEEK": "الأسبوع الماضي",
    "DATES GREATER THAN TODAY ARE NOT PERMITTED.": "التواريخ التي تزيد عن اليوم غير مسموح بها.",
    "TOTAL PROPERTIES": "إجمالي العقارات",
    "NEW PROPERTIES": "عقارات جديدة",
    "ACTIVE PROPERTIES": "العقارات الفعالة",
    "PROPERTY VIEWS": "مشاهدات العقارات",
    "AGENCY PROFILE VIEWS": "مشاهدات صفحة الشركة",
    "AGENT PROFILE VIEWS": "مشاهدات صفحة المستشار العقاري",
    "AGING PROPERTIES": "العقارات القديمة",
    "PACKAGE USAGE": "المستخدم من الحزمة",
    "ACTIVE AGENTS": "المستشارون الفعالون",
    "FEATURED PROPERTIES": "العقارات المميزة",
    "3D VIEWS": "الجولات ثلاثية الأبعاد",
    "EXPORT CSV": "تصدير CSV",
    "PROPERTY TYPES": "أنواع العقارات",
    "LOCATION": "الموقع",
    "PROPERTY CHART": "مخطط العقار",
    "RECENT ACTIVITIES": "الحركات الأخيرة",
    "VIEW MORE": "ععرض المزيد",
    "MOST VIEWED": "الأكثر مشاهدة",
    "AMAKENID": "الرقم المرجعي",
    "VIEWS": "المشاهدات",
    "TOP AGENTS": "أفضل المستشارين",
    "PROPERTY TYPE VIEWS": "عرض نوع العقار",
    "AGENT WISE PROPERTY TYPES": "أنواع العقارات حسب المستشار",
    "AGENT WISE PROPERTY STATUS": "حالة العقار حسب المستشار",
    "GOVERNORATE ID": "معرف المحافظة",
    "TITLE": "العنوان",
    "CAPITAL": "العاصمة",
    "START DATE": "تاريخ البدء",
    "END DATE": "تاريخ الانتهاء",
    "REPORTS": "التقارير",
    "PROCEED": "متابعة",
    "ENSURE YOU'VE SAVED YOUR DATA BEFORE MOVING FORWARD. ARE YOU READY TO PROCEED?":
        "تأكد من حفظ بياناتك قبل المتابعة. هل أنت مستعد للمتابعة؟",
    "DETAILS ARE NOT REQUIRED FOR THIS PROPERTY!": "لا توجد تفاصيل مطلوبة لهذا العقار",
    "PLEASE COMPLETE IDENTIFICATION FORM BEFORE PROCEED": "يرجى إكمال نموذج التعريف قبل المتابعة",
    "PLEASE COMPLETE DESCRIPTION & MEDIA FORM BEFORE PROCEED": "يرجى إكمال نموذج الوصف والوسائط قبل المتابعة",
    "PLEASE COMPLETE DETAILS FORM BEFORE PROCEED": "يرجى إكمال نموذج التفاصيل قبل المتابعة",
    "DIRECTORATE": "المديرية",
    "VILLAGE": "القرية",
    "BASIN": "حوض",
    "DISTRICT": "الحي",
    "ID NUMBER": "رقم القطعة",
    "GENERATE DLS KEY": "إنشاء مفتاح DLS",
    "LAND TYPE": "نوع الأرض",
    "BUILDING NUMBER": "رقم المبنى",
    "FLOOR NUMBER": "رقم الطابق",
    "APARTMENT ID": "رقم الشقة",
    "LOCATION ON THE MAP": "الموقع على الخريطة",
    "OFFERING TYPE": "نوع العرض",
    "PROPERTY CATEGORY": "فئة العقار",
    "PROPERTY TYPE": "نوع العقار",
    "PRICE IN JOD": "السعر بالدينار الأردني",
    "LISTING DATE": "تاريخ الإدراج",
    "AMAKEN ID": "الرقم المرجعي",
    "NEXT STEP": "الخطوة التالية",
    "ADD WATERMARK": "إضافة علامة مائية",
    "WATERMARK TEXT": "نص العلامة المائية",
    "WATERMARK COLOR": "لون العلامة المائية",
    "WATERMARK IMAGE": "صورة العلامة المائية",
    "WATERMARK POSITION": "موضع العلامة المائية",
    "TOP LEFT": "أعلى اليسار",
    "TOP RIGHT": "أعلى اليمين",
    "CENTER": "الوسط",
    "BOTTOM LEFT": "أسفل اليسار",
    "BOTTOM RIGHT": "أسفل اليمين",
    "SAVE": "يحفظ",
    "FLOOR": "الطابق",
    "FLOOR PLANS": "مخطط الطوابق",
    "ADD FLOOR": "إضافة طابق",
    "FLOOR IMAGES": "صور الطوابق",
    "FLOOR PLAN": "مخطط الطابق",
    "ARE YOU SURE TO DELETE THIS FLOOR PLAN": "هل أنت متأكد من حذف مخطط الطابق هذا",
    "FURTHER FLOOR ADDITIONS ARE NOT ALLOWED.": "لا يُسمح بإضافة المزيد من الطوابق.",
    "INTRO VIDEO": "فيديو تعريفي",
    "FULL VIDEO": "فيديو كامل",
    "FILE": "ملف",
    "EMBEDDED": "مضمن",
    "INTRO VIDEO SOURCE": "مصدر الفيديو التعريفي",
    "INTRO VIDEO URL": "رابط الفيديو التعريفي",
    "FULL VIDEO SOURCE": "مصدر الفيديو الكامل",
    "FULL VIDEO URL": "رابط الفيديو الكامل",
    "YOUTUBE": "يوتيوب",
    "FACEBOOK": "فيسبوك",
    "VIMEO": "فيميو",
    "VIDEO VALIDATION": "يمكنك فقط تحميل ملفات الفيديو (MP4، WebM، Ogg، MKV)!",
    "VIDEO SIZE": "يجب أن يكون حجم الفيديو 100 ميجابايت أو أقل!",
    "AGENCY REFERENCE": "مرجع الوكالة",
    "SELECT DATE": "اختر التاريخ",
    "LONGITUDE": "خط الطول",
    "LATITUDE": "خط العرض",
    "DRAG MARKER AND SELECT A LOCATION FOR YOUR PROPERTY": "اسحب المؤشر وحدد موقعًا لممتلكاتك",
    "MARKER MUST REMAIN WITHIN THE BOUNDS OF JORDAN": "يجب أن يبقى المؤشر داخل حدود الأردن.",
    "ADD": "أضف",
    "VERIFY": "تحقق",
    "DO YOU HAVE THE TITLE DEED FOR YOUR PROPERTY?": "هل لديك سند التسجيل لهذا العقار؟",
    "IF YES, PLEASE PROCEED.": "إذا كانت الإجابة نعم، يرجى المتابعة.",
    "IF NO, PLEASE NOTE THAT YOUR PROPERTY IS STILL UNDER CONSTRUCTION.": "إذا كانت الإجابة لا، يرجى ملاحظة أن العقار سيكون غير موثق",
    "UNDER CONSTRUCTION": "غير موثق",
    "CHOOSE YOUR PAYMENT METHOD": "اختر طريقة الدفع",
    "DEFAULT PAYMENT": "طريقة الدفع الرئيسية",
    "SET AS DEFAULT": "اجعلها طريقة الدفع الرئيسية",
    "ARE YOU SURE TO DELETE THIS CARD?": "هل أنت متأكد من حذف هذه البطاقة؟",
    "DELETE CARD": "حذف البطاقة",

    "VERIFY PHONE NUMBER": "التحقق من رقم الهاتف",
    "SHOW EXACT LOCATION": "عرض الموقع الدقيق",
    "DISABLED": "غير فعال",
    "ENABLED": "فعال",
    "NO COMMENTS": "بدون تعليقات",
    "ENGLISH": "إنجليزي",
    "ARABIC": "عربي",
    "SOCIAL MEDIA": "وسائل التواصل الاجتماعي",
    "COVER IMAGE": "صورة الغلاف",
    "MARK COVER": "غطاء العلامة",
    "ARE YOU SURE TO DELETE THE IMAGE": "هل أنت متأكد من حذف هذه الصورة؟",
    "YOU CAN UPLOAD FILE UP TO 10MB": "يمكنك تحميل ملف يصل حجمه إلى 10 ميجابايت",
    "MEMBERSHIP PLANS": "خطط العضوية",
    "JOIN AMAKEN NOW, LET'S GROW TOGETHER": "انضم إلى أماكن الآن، لننمو معًا",
    "BILLED MONTHLY": "فاتورة شهرية",
    "BILLED YEARLY": "فاتورة سنوية",
    "GENERAL": "عام",
    "CUSTOM": "مخصص",
    "NO PLANS AVAILABLE": "لا توجد خطط متاحة",
    "PAYMENT": "الدفع",
    "STANDARD LISTING SUBMISSION, ACTIVE FOR 30 DAYS": "إدراج قياسي، نشط لمدة 30 يومًا",
    "ALL OPERATING SUPPORTED": "جميع الأنظمة التشغيلية مدعومة",
    "VIEW PROPERTY STATISTICS": "عرض إحصائيات العقار",
    "NOTIFICATIONS": "الإشعارات",
    "24/7 FULL SUPPORT": "دعم كامل على مدار الساعة",
    "YEARLY": "سنوي",
    "MONTHLY": "شهريا",
    "ENTITY NAME": "اسم الكيان",
    "DATE": "تاريخ",
    "ACCOUNT ACTIVATION": "تفعيل الحساب",
    "EMAIL VERIFICATION": "تأكيد بواسطة البريد الالكتروني",
    "AGENT REPORT": "تقرير المستشار العقاري",
    "PROPERTY": "ملكية",
    "PROPERTY REPORT": "تقرير الملكية",
    "LOCATION REPORT": "تقرير الموقع",
    "MESSAGE": "رسالة",
    "AUDITS": "عمليات التدقيق",
    "AUDITS AVAILABLE": "عمليات التدقيق المتاحة",
    "PUBLISH": "نشر",


    // NEW
    "REFERENCE ID": "معرف المرجع",
    "ORDERS": "الطلبات",
    "ORDER ID": "معرف الطلب",
    "TRANSACTION ID": "معرف المعاملة",
    "SUBSCRIPTION ID": "معرف الاشتراك",
    "DESCRIPTION": "الوصف",
    "ORDER STATUS": "حالة الطلب",
    "ORDERS AVAILABLE": "الطلبات المتاحة",
    "RETRY PAYMENT": "إعادة محاولة الدفع",

    "PLAN HISTORY": "تاريخ الخطة",
    "VIEW ORDERS": "عرض الطلبات",
    "NO PLAN ACTIVE": "لا توجد خطة نشطة",
    "YOU DON'T HAVE ACTIVE SUBSCRIPTION": "ليس لديك اشتراك نشط",
    "SUBSCRIBE NOW": "اشترك الآن",
    "PLAN CANCELLED!": "تم إلغاء الخطة!",
    "CURRENT PLAN": "الخطة الحالية",
    "MODIFY": "تعديل",
    "ARE YOU SURE YOU WANT TO CANCEL THIS PLAN?": "هل أنت متأكد أنك تريد إلغاء هذه الخطة؟",
    "ARE YOU SURE YOU WANT TO MODIFY THIS PLAN?": "هل أنت متأكد أنك تريد تعديل هذه الخطة؟",
    "ARE YOU SURE YOU WANT TO UPGRADE THIS PLAN?": "هل أنت متأكد أنك تريد ترقية هذه الخطة؟",
    "CANCELLING WILL OFF MARKET ALL YOUR PROPERTIES AND DISABLE YOUR AGENTS.": "إلغاء الخطة سيزيل جميع عقاراتك من السوق ويعطل وكلائك.",
    "RETRY": "إعادة المحاولة",
    "CURRENT SUBSCRIPTION PLAN": "الخطة الحالية للاشتراك",
    "PRICE": "السعر",
    "STARTED AT": "بدأت في",
    "NEXT PAYMENT": "الدفع التالي",
    "ACTIVE PROPERTY": "عقار نشط",
    "FEATURED PROPERTY": "عقار مميز",
    "VIRTUAL TOUR": "جولة افتراضية",
    "RETRY ATTEMPTS": "محاولات إعادة المحاولة",
    "PAYMENT FOR THE CURRENT SUBSCRIPTION PACKAGE FAILED.": "فشل الدفع لحزمة الاشتراك الحالية.",
    "ATTEMPTS LEFT": "المحاولات المتبقية",
    "YOU CANNOT DECREASE THE NUMBER OF VIRTUAL TOURS": "لا يمكنك تقليل عدد الجولات الافتراضية.",
    "YOU CANNOT DECREASE THE NUMBER OF FEATURED PROPERTY": "لا يمكنك تقليل عدد العقارات المميزة.",
    "PLEASE MODIFY FEATURED PROPERTY OR VIRTUAL TOUR BEFORE PAYMENT": "يرجى تعديل العقار المميز أو الجولة الافتراضية قبل الدفع.",
    "PAYMENT RESPONSE": "استجابة الدفع",
    "PAYMENT SUCCESS": "تم الدفع بنجاح",
    "VIEW PACKAGE": "عرض الحزمة",
    "TRY AGAIN": "حاول مرة أخرى",
    "PLAN LIMIT EXCEED": "تم تجاوز حد الخطة، يرجى التعديل أو الترقية إلى خطة جديدة",
    "CURRENT_SUBSCRIPTION_NOT_FOUND": "لم يتم العثور على الاشتراك الحالي.",
    "PER": "لكل",
    "HIDE LOCATON TEXT": "اخفاء الموقع الفعلي يعني ان العقار لن يظهر للمستخدمين الذين يستخدمون البحث من خلال الخريطة، هل تريد الاستمرار؟",
    "HIDE EXACT LOCATION": "إخفاء الموقع الدقيق",
    "ARE YOU SURE YOU WANT TO HIDE LOCATION": "هل أنت متأكد أنك تريد إخفاء الموقع الدقيق؟"
}