import ChangePassword from "components/profile/ChangePassword";
import UploadProfilePic from "components/profile/UploadProfilePic";
import UpdateProfileForm from "components/profile/UpdateProfileForm";
import { useTranslation } from "react-i18next";

export const Profile = () => {

  const {t} = useTranslation()
  return (
    <div className="dashboard__content property-page bgc-f7">
      <div className="row align-items-center pb20">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>{t("MY PROFILE")}</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
          {/* UPLOAD PROFILE PICTURE COMPONENT */}
            <UploadProfilePic/>
          {/* UPDATE PROFILE DETAILS FORM */}
          <UpdateProfileForm/>
          </div>
          <ChangePassword/>
        </div>
      </div>
    </div>
  );
};
