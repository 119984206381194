import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  EntityNameTemplate,
  DateTemplate,
  MessageTemplate,
  NameTemplate,
} from "./RowTemplates";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { auditTrailApi } from "services/dashboardService";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination } from "@mui/material";
import { nameBasedProtectedRoutes } from "router/protected";
import LoaderMu from "components/common/LoaderMu";

export const Audit = () => {


  const { t } = useTranslation();
  const navigate = useNavigate();
  // GET PAGINATION DATA FROM URL PARAMS
  const location = useLocation();
  const params = new URLSearchParams(location?.search)
  const [currentPage, setCurrentPage] = useState(params.get("page") ?? 1)
  const [pageSize, setPageSize] = useState(5)
  const [totalRowCount, setTotalRowCount] = useState(0)
  const pageOffset = (parseInt(currentPage) - 1) * pageSize + 1
  const [loading, setLoading] = useState(false)

  // LIST STATE
  const [auditTrailList, setAuditTrailList] = useState([])

  // FETCH RECENT ACTIVITIES LIST
  const fetchAuditTrailList = () => {
    setLoading(true)
    let params = {
      sort: 'createdAt,DESC',
      size: 10,
      page: 0
    }
    auditTrailApi(params).then((response) => {
      setAuditTrailList(response?.data)
      setTotalRowCount(response?.headers["x-total-count"])
    }).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    }).finally(() => {
      setLoading(false)
    })
  }

  // FUNCTION CALL ON COMPONENT RENDER
  useEffect(() => {
    fetchAuditTrailList()
  }, [])

  // HANDLING NAVIGATION ON PAGE CHANGE
  const handlePageChange = (event, value) => {
    setCurrentPage(value)
    navigate(`${nameBasedProtectedRoutes?.audit?.path}?page=${value}`)
  }
  return (
    <div className="dashboard__content bgc-f7">
      <LoaderMu loading={loading} />
      <div className="row align-items-center pb20">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>{t("AUDIT")}</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <div className="packages_table table-responsive">
              <DataTable
                value={auditTrailList ?? []}
                tableClassName="table-style3 table at-savesearch"
                cellClassName="vam"
              >
                {/* MESSAGE */}
                <Column
                  field="message"
                  header={t("MESSAGE")}
                  body={(row) => <MessageTemplate row={row} />}
                ></Column>
                {/* USER NAME */}
                <Column
                  field="name"
                  header={t("USERNAME")}
                  body={(row) => <NameTemplate row={row} />}
                  style={{ minWidth: '200px' }}
                ></Column>

                {/* ENTITY NAME */}
                <Column
                  field="entityName"
                  header={t("ENTITY NAME")}
                  body={(row) => <EntityNameTemplate row={row} />}
                  style={{ minWidth: '200px' }}
                ></Column>

                {/* DATE */}
                <Column
                  field="createdAt"
                  header={t("DATE")}
                  body={(row) => <DateTemplate row={row} />}
                  style={{ minWidth: '250px' }}
                ></Column>
              </DataTable>
            </div>
            {/* PAGINATION */}
            <div className="mbp_pagination text-center mt30 dataTable_pagination">
              <Pagination count={Math.ceil(totalRowCount / pageSize)} siblingCount={1} page={parseInt(currentPage)} className="d-inline-block" size="large" onChange={(event, value) => handlePageChange(event, value)} />
              {
                totalRowCount < pageSize ?
                  <p className="mt10 pagination_page_count text-center">
                    {pageOffset} – {totalRowCount} {t("OF")} {totalRowCount} {t("AUDITS AVAILABLE")}
                  </p> : <p className="mt10 pagination_page_count text-center">
                    {pageOffset} – {currentPage * pageSize} {t("OF")} {totalRowCount}+ {t("AUDITS")}
                  </p>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
