import React, { useState } from 'react'
import moment from "moment"
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { nameBasedProtectedRoutes } from 'router/protected'
import { autoRenewToggleApi, cancelPlanApi } from 'services/packageService'
import { toast } from 'react-toastify'
import { cancelCurrentPackage, setCurrentPackage } from '../../redux/UserPackageSlice'
import { Popconfirm, Switch } from 'antd'
import { useTranslation } from 'react-i18next'

const ActiveSubscriptionPlan = () => {
  const currentPlanData = useSelector((state) => state?.userPackage)

  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()

  const dispatch = useDispatch()

  // HANDLE PLAN CANCELLATION
  const handlePlanCancel = () => {
    cancelPlanApi(currentPlanData?.id).then((response) => {
      dispatch(cancelCurrentPackage())
      toast.info(t("PLAN CANCELLED!"))
    })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      }).finally(() => {
        setLoading(false)
      });
  }

  // HANDLE AUTO RENEW TOGGLE
  const handleAutoRenewStatus = (status) => {
    autoRenewToggleApi(currentPlanData?.id, status).then((response) => {
      dispatch(setCurrentPackage({ userPackage: response }))
      toast.success(response?.message)
    })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      }).finally(() => {
        setLoading(false)
      });
  }
  return (
    <div className=''>
      <div className="row align-items-center">
        <div className="col-md">
          <h4 className=''>{t("CURRENT PLAN")}</h4>
        </div>
        {/* ACTIVE- UPGRADE, MODIFY & CANCEL
        PENDING- CANCEL & RETRY PAYMENT
        INACTIVE- CANCEL & RETRY PAYMENT */}
        {
          currentPlanData?.status === "ACTIVE" ?
            <div className='col-lg justify-content-end d-flex align-items-center'>
              <Popconfirm
                title="Cancel plan"
                description={<span>{t("ARE YOU SURE YOU WANT TO CANCEL THIS PLAN?")} <br />{t("CANCELLING WILL OFF MARKET ALL YOUR PROPERTIES AND DISABLE YOUR AGENTS.")}</span>}
                onConfirm={handlePlanCancel}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <button type="button" className='ud-btn px-4 py-2 btn-thm my-2' >{t("CANCEL")}</button>
              </Popconfirm>
              <Link to={`${nameBasedProtectedRoutes?.plans?.path}?upgrade=true`} className='ud-btn px-4 py-2 btn-dark ms-2 my-2'>{t("UPGRADE")}</Link>
              {
                currentPlanData?.planData?.noOfActiveFeaturedProperty !== currentPlanData?.planData?.maxNoOfActiveFeaturedProperty || currentPlanData?.planData?.noOf3dViewUpload !== currentPlanData?.planData?.maxNoOf3dViewUpload ?
                  <Link to={`${nameBasedProtectedRoutes?.plans?.path}?modify=true`} className='ud-btn px-4 py-2 btn-dark ms-2 my-2'>{t("MODIFY")}</Link> : ""
              }
            </div> :
            <div className='col-md justify-content-end d-flex align-items-center'>
              <Popconfirm
                title="Cancel plan"
                description={<span>{t("ARE YOU SURE YOU WANT TO CANCEL THIS PLAN?")} <br />{t("CANCELLING WILL OFF MARKET ALL YOUR PROPERTIES AND DISABLE YOUR AGENTS.")}</span>}
                onConfirm={handlePlanCancel}
                // onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <button type="button" className='ud-btn px-4 py-2 btn-thm my-2' >{t("CANCEL")}</button>
              </Popconfirm>
              <Link to={nameBasedProtectedRoutes?.retryPayment?.path} className='ud-btn px-4 py-2 btn-dark ms-2 my-2'>{t("RETRY")}</Link>
            </div>
        }
      </div>
      <div className="row">
        <div className="col-xl-12 my-2">
          <div className='ps-widget h-100 bgc-white bdrs12 default-box-shadow2 p30 overflow-hidden position-relative currentPlan-card'>
            <div className='row justify-content-between'>
              <div className='col-sm-6 col-md-4 col-lg-3'>
                <h4 >{currentPlanData?.planData?.planName}
                  {
                    currentPlanData?.status === "ACTIVE" ?
                      <span className='fs-12 pending-style style4 px-3 py-1 fw-bold ms-2'>{currentPlanData?.status}</span> :
                      currentPlanData?.status === "PENDING" ?
                        <span className='fs-12 pending-style style2 px-3 py-1 fw-bold ms-2'>{currentPlanData?.status}</span> :
                        currentPlanData?.status === "INACTIVE" ?
                          <span className='fs-12 pending-style style3 px-3 py-1 fw-bold ms-2'>{currentPlanData?.status}</span> : ""
                  }
                </h4>
                <p className='text text-muted'>{t("CURRENT SUBSCRIPTION PLAN")}</p>
              </div>
              <div className='col-sm-6 col-md-4 col-lg-3'>
                <h4 className='text2'>{currentPlanData?.planData?.planAmount && (currentPlanData?.planData?.planAmount).toFixed(2)} {t("CURRENCY SYMBOL")}
                  <span className='fs-14 text-muted'>{currentPlanData?.planData?.validityType === "MONTHLY" ? "/month" : "/year"}</span>
                </h4>
                <p className='text text-muted'>{t("PRICE")}</p>
              </div>
              <div className='col-sm-6 col-md-4 col-lg-3'>
                <p className='text fs-16 mb-0'>{moment(currentPlanData?.startDate).format('DD MMMM YYYY')}</p>
                <p className='text-muted mb-0'>{t("STARTED AT")}</p>
              </div>
              <div className='col-sm-6 col-md-4 col-lg-3'>
                <p className='text fs-16 mb-0'>{moment(currentPlanData?.endDate).format('DD MMMM YYYY')}</p>
                {
                  currentPlanData?.autoRenew === true ?
                    <p className='text-muted mb-0'>{t("NEXT PAYMENT")}</p>
                    : <p className='text-muted mb-0'>{t("END DATE")}</p>
                }

              </div>
            </div>
            <div className='row justify-content-between align-items-center my-2'>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <p className='text fs-16 mb-0 fw-bold'>{currentPlanData?.noOfActiveAgent}</p>
                <p className='text-muted mb-0'>{t("ACTIVE AGENT")}</p>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <p className='text fs-16 mb-0 fw-bold'>{currentPlanData?.noOfActiveProperty}</p>
                <p className='text-muted mb-0'>{t("ACTIVE PROPERTY")}</p>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <p className='text fs-16 mb-0 fw-bold'>{currentPlanData?.customNoOfActiveFeaturedProperty}</p>
                <p className='text-muted '>{t("FEATURED PROPERTY")}</p>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3">
                <p className='text fs-16 mb-0 fw-bold'>{currentPlanData?.customNoOf3dViewUpload}</p>
                <p className='text-muted '>{t("VIRTUAL TOUR")}</p>
              </div>
              {
                currentPlanData?.currentOrder?.status === "FAILED" ? <div className="col-xl-12">
                  <p className=' fw500  mt-3'> {t("RETRY ATTEMPTS")} {currentPlanData?.retryCount}  {t("PAYMENT FOR THE CURRENT SUBSCRIPTION PACKAGE FAILED.")} {3 - currentPlanData?.retryCount} {t("ATTEMPTS LEFT.")}</p>
                </div> : ""
              }
              {
                currentPlanData?.status === "ACTIVE" ?
                  <div className="col-xl-12 toggle-btn mb20 mt-4">
                    <p className="pricing_save1 ff-heading me-2">{t("AUTO RENEW")}</p>
                    <Switch checked={currentPlanData?.autoRenew} className='fw-bold plan-check' onChange={(value) => handleAutoRenewStatus(value)} checkedChildren={t("ON")} unCheckedChildren={t("OFF")} />
                  </div> : ""
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActiveSubscriptionPlan