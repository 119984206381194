import { Slider } from '@mui/material';
import React from 'react'
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const RangeSlider = ({ min, max, step, label,value,  handleChange , additionalCost }) => {

  const {t} = useTranslation()

  return (
    <div className="range-slider-custom">
      <Form.Label className="heading-color ff-heading fw600 fs-6 mb40">
        {label} {additionalCost ?  "(" + additionalCost + " " +  t("CURRENCY SYMBOL")+ "/" +  t("PER") +")" : ''}
      </Form.Label>
      <Slider
        aria-label="Custom marks"
        value={value}
        step={step ?? 1}
        min={min ?? 0}
        max={max ?? 100}
        onChange={handleChange}
        valueLabelDisplay="on"
        // marks={marks}
        marks={true}
        size={"large"}
      />
      <div className='d-flex justify-content-between mb10'>
        <span>{min}</span>
        <span>{max}</span>
      </div>
    </div>
  )
}

export default RangeSlider