import React, { useEffect, useRef } from 'react'

function MapMarker({ coordinateData }) {

    const mapElementRef = useRef(null);

    // console.log(coordinateData)
    
    useEffect(() => {
        const initMap = async () => {
            try {
                // Request needed libraries.
                const { Map } = await window.google.maps.importLibrary('maps');
                const { AdvancedMarkerElement } = await window.google.maps.importLibrary('marker');

                const map = new Map(mapElementRef.current, {
                    center: { lat: coordinateData?.y ? parseFloat(coordinateData?.y) : 37.7749, lng: coordinateData?.x ? parseFloat(coordinateData?.x) : -122.4194 },
                    zoom: 11,
                    mapId: '4504f8b37365c3d0',
                });

                // IF COORDINATE DATA AVAILABLE THEN ONLY SHOW MARKER
                if (coordinateData?.y && coordinateData?.x) {
                    const marker = new AdvancedMarkerElement({
                        map,
                        position: { lat: coordinateData?.y ? parseFloat(coordinateData?.y) : 37.7749, lng: coordinateData?.x ? parseFloat(coordinateData?.x) : -122.4194 },
                    });
                }
            } catch (error) {
                console.error('Error initializing map:', error);
            }
        };

        if (window.google) {
            initMap();
        }

    }, [coordinateData]);
    return (
        <div ref={mapElementRef} style={{ width: '100%', height: '400px' }} />
    )
}

export default MapMarker
