import { HiOutlineDocumentReport } from "react-icons/hi";
export const AgencyNavItems = {
  en: [
    {
      id: 1,
      menuName: "Dashboard",
      menuIcon: <i className="flaticon-discovery mr15" />,
      path: "/dashboard",
      isLink: true,
      //isActive: true,
    },
    {
      id : 2,
      menuName : "Reports",
      menuIcon: <HiOutlineDocumentReport size ={20} className="mr15"/>,
      path: "/reports",
      isLink: true,
    },
    {
      id: 3,
      menuName: "MANAGE LISTINGS",
      
    },
    {
      id: 4,
      menuName: "Add New Property",
      menuIcon: <i className="flaticon-new-tab mr15" />,
      path: "/addproperty",
      isLink: true,
      //isActive: true,
    },
    {
      id: 5,
      menuName: "My Properties",
      menuIcon: <i className="flaticon-home mr15" />,
      path: "/properties",
      isLink: true,
    },
    {
      id: 6,
      menuName: "Agents",
      menuIcon: <i className="fal fa-user-tie mr15" />,
      path: "/dashboardagents",
      isLink: true,
    },
    {
      id: 7,
      menuName: "My Package",
      menuIcon: <i className="flaticon-search-2 mr15" />,
      path: "/package",
      isLink: true,
    },
    {
      id: 8,
      menuName: "Audit",
      menuIcon: <i className="flaticon-clock mr15" />,
      path: "/audit",
      isLink: true,
    },
  ],
  ar: [
    {
      "id": 1,
      "menuName": "اللوحة الرئيسية",
      "menuIcon": <i className="flaticon-discovery mr15" />,
      "path": "/dashboard",
      "isLink": true,
      "isActive": true
    },
    {
      id : 2,
      menuName : "التقارير",
      menuIcon: <HiOutlineDocumentReport size ={20} className="mr15"/>,
      path: "/reports",
      isLink: true,
    },
    {
      "id": 3,
      "menuName": "إدارة القوائم"
    },
    {
      "id": 4,
      "menuName": "إضافة عقار جديد",
      "menuIcon": <i className="flaticon-new-tab mr15" />,
      "path": "/addproperty",
      "isLink": true
    },
    {
      "id": 5,
      "menuName": "عقاراتي",
      "menuIcon": <i className="flaticon-home mr15" />,
      "path": "/properties",
      "isLink": true
    },
    {
      "id": 6,
      "menuName": "المستشارون العقاريون",
      "menuIcon": <i className="fal fa-user-tie mr15" />,
      "path": "/dashboardagents",
      "isLink": true
    },
    {
      "id": 7,
      "menuName": "خطة الاشتراك",
      "menuIcon": <i className="flaticon-search-2 mr15" />,
      "path": "/package",
      "isLink": true
    },
    {
      "id": 8,
      "menuName": "التدقيق",
      "menuIcon": <i className="flaticon-clock mr15" />,
      "path": "/audit",
      "isLink": true
    }
  ]

};

export const AgentNavItems = {
  en: [
    {
      id: 1,
      menuName: "Dashboard",
      menuIcon: <i className="flaticon-discovery mr15" />,
      path: "/dashboard",
      isLink: true,
      isActive: true,
    },
    {
      id: 2,
      menuName: "MANAGE LISTINGS",
    },
    {
      id: 3,
      menuName: "Add New Property",
      menuIcon: <i className="flaticon-new-tab mr15" />,
      path: "/addproperty",
      isLink: true,
    },
    {
      id: 4,
      menuName: "My Properties",
      menuIcon: <i className="flaticon-home mr15" />,
      path: "/properties",
      isLink: true,
    },
  ],
  ar: [
    {
      "id": 1,
      "menuName": "اللوحة الرئيسية",
      "menuIcon": <i className="flaticon-discovery mr15" />,
      "path": "/dashboard",
      "isLink": true,
      "isActive": true
    },
    {
      "id": 2,
      "menuName": "إدارة القوائم"
    },
    {
      "id": 3,
      "menuName": "إضافة عقار جديد",
      "menuIcon": <i className="flaticon-new-tab mr15" />,
      "path": "/addproperty",
      "isLink": true
    },
    {
      "id": 4,
      "menuName": "عقاراتي",
      "menuIcon": <i className="flaticon-home mr15" />,
      "path": "/properties",
      "isLink": true
    }
  ]
}