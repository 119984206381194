import React, { useEffect, useState } from "react";
import { Checkbox } from "antd";
// import { amenitiesOptions } from "../../../assets/dummyData/DemoData";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { amenitiesListApi, saveAmenitiesApi } from "services/propertyService";
import LoaderMu from "components/common/LoaderMu";
import { useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "router/protected";

function Amenities({ property_id, handlePrevStep ,amenities,getPropertyData,showDetails}) {

  const navigate = useNavigate()

  const [amenitiesList, setAmenitiesList] = useState([])
  const [loading ,setLoading] = useState(false)
  const [initialValues , setInitialValues] = useState({amenities:amenities ??  []})
  const { t } = useTranslation()
  // SUBMIT
  const handleSubmit = (values) => {
    setLoading(true)
    const amenityList = values?.amenities
    saveAmenitiesApi(property_id, amenityList).then((response) => {
      toast.success(response?.message)
      getPropertyData()
      navigate(nameBasedProtectedRoutes?.propertyList?.path)
    }).catch((error) => {
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    }).finally(()=>{
      setLoading(false)
    })
  }

  // FETCH AMENITIES LIST FROM API
  const fetchAmenitiesDetails = () => {
    setLoading(true)
    amenitiesListApi(property_id).then((response) => {
      setLoading(false)
      setAmenitiesList(response)
    }).catch((error) => {
      setLoading(false)
      toast.error(error.response.data.error_description
        ? error.response.data.error_description
        : error.response.data?.message)
    })
  }

  useEffect(() => {
    if(property_id){
      fetchAmenitiesDetails()
    }
  }, [])


  useEffect(()=>{
    setInitialValues({amenities : amenities ?? []})
  },[amenities])

  return (
      <div className="ps-widget bgc-white bdrs12 p30 overflow-hidden position-relative">
        <LoaderMu loading={loading}/>
        <h4 className="title fz17 mb30">{t("SELECT AMENITIES")}</h4>
        {
          property_id ?   
        <Formik
          // validationSchema={amenitiesFormSchema}
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            actions.setSubmitting(false);
            handleSubmit(values);
          }}>
          {({
            handleSubmit,
            handleBlur,
            values,
            setFieldValue,
            errors,
          }) => (
            <Form>
              <div>
                <div className="row">
                  <div className="col-xxl-8">
                    <Checkbox.Group
                      name="amenities"
                      id="amenities"
                      onChange={(value) => {
                        setFieldValue("amenities", value);
                      }}
                      onBlur={handleBlur}
                      value={values?.amenities}
                      className="w-100"
                    >
                      <div className="row w-100">
                        {amenitiesList.map((option) => (
                          <div key={option?.id} className="col-sm-6 col-lg-6 col-xxl-4">
                            <div className="checkbox-style1">
                              <Checkbox  checked={amenities?.includes(option?.id) ?? false}
                                value={option?.id}>
                                {option?.amenityName}
                              </Checkbox>
                            </div>
                          </div>
                        ))}
                        <span className="text-danger">{errors?.amenities}</span>
                      </div>
                    </Checkbox.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mt30">
                    <div className="d-sm-flex justify-content-between">
                      <button
                        className="ud-btn btn-white"
                        onClick={() => {
                          if(showDetails === true){
                            handlePrevStep(1);
                          }else{
                            handlePrevStep(2);
                          }
                          
                        }}
                      >
                        {t("PREV STEP")}
                        <i className="fal fa-arrow-right-long" />
                      </button>
                      <button type="button" disabled={loading ?? false} onClick={handleSubmit} className="ud-btn btn-thm">
                        {t("SUBMIT PROPERTY")}
                        <i className="fal fa-arrow-right-long" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>)}
        </Formik> : <p className="text-danger"> Amenities not available</p>
        }
     
      </div>
  );
}

export default Amenities;
