import React from 'react'
import { Link } from 'react-router-dom'
import { AgencyNavItems, AgentNavItems } from "../Sidebar/NavItems"
import { useSelector } from 'react-redux'
function MobileMenu({ isMenuActive, setMobileMenu }) {
    const userData = useSelector((state) => state?.user?.userData)
    return (
        // id="menu"
        <nav className={`  bg-white mm-menu mm-menu--offcanvas mm-menu--position-left-front mm-menu--theme-light ${isMenuActive ? 'mm-menu--opened' : ''}`}>
            <div>
                <div className="mm-navbar"><a className="mm-navbar__title" tabIndex="-1" aria-hidden="true"><span className="">Menu</span></a></div>
                <div className="dashboard_sidebar_list p30 d-block d-lg-none">
                    {
                        userData?.authorities?.includes('ROLE_AGENCY_ADMIN') ?
                            AgencyNavItems?.en?.map((item, index) => {
                                return <div key={index + 1} className="sidebar_list_item mb-1">
                                    {
                                        item?.isLink ? <Link to={item?.path} className={`items-center ${item?.isActive ? '-is-active' : ''}`} onClick={() => { setMobileMenu(false) }}>
                                            {item?.menuIcon}
                                            {item?.menuName}
                                        </Link> : <p className="fz15 fw400 ff-heading mt30">{item?.menuName}</p>
                                    }

                                </div>
                            }) : AgentNavItems?.en?.map((item, index) => {
                                return <div key={index + 1} className="sidebar_list_item mb-1">
                                    {
                                        item?.isLink ? <Link to={item?.path} className={`items-center ${item?.isActive ? '-is-active' : ''}`}>
                                            {item?.menuIcon}
                                            {item?.menuName}
                                        </Link> : <p className="fz15 fw400 ff-heading mt30">{item?.menuName}</p>
                                    }

                                </div>
                            })}
                    {/* <div className="sidebar_list_item mb-1">
                        <a
                            href="page-dashboard"
                            className="items-center -is-active"
                        >
                            <i className="flaticon-discovery mr15" />
                            Dashboard
                        </a>
                    </div>
                    <p className="fz15 fw400 ff-heading mt30">MANAGE LISTINGS</p>
                    <div className="sidebar_list_item mb-1">
                        <a
                            href="page-dashboard-add-property"
                            className="items-center"
                        >
                            <i className="flaticon-new-tab mr15" />
                            Add New Property
                        </a>
                    </div>
                    <div className="sidebar_list_item mb-1">
                        <a
                            href="page-dashboard-properties"
                            className="items-center"
                        >
                            <i className="flaticon-home mr15" />
                            My Properties
                        </a>
                    </div>
                    <div className="sidebar_list_item mb-1">
                        <a href="page-dashboard-agents.html" className="items-center">
                            <i className="fal fa-user-tie mr15" />
                            Agents
                        </a>
                    </div>
                    <div className="sidebar_list_item mb-1">
                        <a href="page-dashboard-package.html" className="items-center">
                            <i className="flaticon-search-2 mr15" />
                            My Package
                        </a>
                    </div> */}
                </div>
            </div>
        </nav>
    )
}

export default MobileMenu
