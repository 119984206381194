import AgencyInputText from "components/common/AgencyInputText";
import LoaderMu from "components/common/LoaderMu";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { updateAgencySocialInfo } from "services/agencyService";
import { agencySocialInfoFormSchema } from "validations/ValidationSchema";

function SocialMediaForm({ agencyInfo, getAgencyData }) {

  const {t} = useTranslation()

  const [loading, setLoading] = useState(false)

  const [initialData, setIntitalData] = useState({
    facebook_url: agencyInfo?.facebookUrl ?? '',
    instagram_url: agencyInfo?.instagramUrl ?? '',
    linkedin_url: agencyInfo?.linkedinUrl ?? '',
    website_url: agencyInfo?.websiteUrl ?? ''
  })

  const handleSubmit = (values) => {
    setLoading(true)
    const formdata = {
      facebookUrl: values?.facebook_url,
      instagramUrl: values?.instagram_url,
      linkedinUrl: values?.linkedin_url,
      websiteUrl: values?.website_url
    }
    updateAgencySocialInfo(formdata).then((response) => {
      setLoading(false);
      getAgencyData()
      toast.success(response?.data?.message ?? 'Social Info Updated!')
    }).catch((error) => {
      setLoading(false);
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }

  useEffect(() => {
    setIntitalData({
      facebook_url: agencyInfo?.facebookUrl ?? '',
      instagram_url: agencyInfo?.instagramUrl ?? '',
      linkedin_url: agencyInfo?.linkedinUrl ?? '',
      website_url: agencyInfo?.websiteUrl ?? ''
    })
  }, [agencyInfo])


  return (
    <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
      {loading ? <LoaderMu loading={loading} /> : ""}
      <h4 className="title fz17 mb30">{t("SOCIAL MEDIA")}</h4>
      <Formik
        validationSchema={agencySocialInfoFormSchema}
        initialValues={initialData}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          handleSubmit(values)
        }}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="form-style1">
            <div className="row">
              <div className="col-sm-6 col-xl-4">
                <AgencyInputText
                  type="url"
                  label={t("FACEBOOK URL")}
                  id="facebook_url"
                  placeholder={t("FACEBOOK URL")}
                  name="facebook_url"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.facebook_url}
                  className={`form-control ${touched?.facebook_url && errors?.facebook_url ? "error" : ""
                    }`}
                />
              </div>
              <div className="col-sm-6 col-xl-4">
                <AgencyInputText
                  type="url"
                  label={t("LINKEDIN URL")}
                  id="linkedin_url"
                  placeholder={t("LINKEDIN URL")}
                  name="linkedin_url"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.linkedin_url}
                  className={`form-control ${touched?.linkedin_url && errors?.linkedin_url ? "error" : ""
                    }`}
                />
              </div>
              <div className="col-sm-6 col-xl-4">
                <AgencyInputText
                  type="url"
                  label={t("INSTAGRAM URL")}
                  id="instagram_url"
                  placeholder={t("INSTAGRAM URL")}
                  name="instagram_url"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.instagram_url}
                  className={`form-control ${touched?.instagram_url && errors?.instagram_url ? "error" : ""
                    }`}
                />
              </div>
              <div className="col-sm-6 col-xl-4">
                <AgencyInputText
                  type="text"
                  label={t("WEBSITE URL")}
                  id="website_url"
                  placeholder={t("WEBSITE URL")}
                  name="website_url"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values?.website_url}
                  className={`form-control ${touched?.website_url && errors?.website_url ? "error" : ""
                    }`}
                />
              </div>
              <div className="col-md-12">
                <div className="text-end">
                  <button type="submit" className="ud-btn btn-dark" onClick={handleSubmit}>
                    {t("UPDATE SOCIAL")}
                    <i className="fal fa-arrow-right-long" />
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default SocialMediaForm;
