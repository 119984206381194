import { Modal } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Cookies from 'universal-cookie'

const TermsAndConditionModal = ({ isModalOpen, setIsModalOpen }) => {

    const {t} = useTranslation()
    const { i18n } = useTranslation()

    const cookies = new Cookies()
    //   GET CURRENT LANGUAGE FROM i18n AND COOKIES
    const currentLanguage = i18n?.language ?? cookies.get("langKey") ?? 'en'
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <Modal title="Terms & Condition" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1000} className= {`${currentLanguage === 'ar' ? 'direction-rtl' :''} termsConditionModal`} style={{
            top: 20,
        }} footer={false}>
            <iframe src={`${process.env.REACT_APP_WEBSITE_WEB_URL}/terms-condition/web-view?lang=${currentLanguage}`} frameborder="0" height={"700px"} ></iframe>
        </Modal>
    )
}

export default TermsAndConditionModal