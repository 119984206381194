import { ErrorMessage } from 'formik'
import React from 'react'
import { FormLabel } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
function InputPhoneCode({
    label,
    disabled,
    placeholder,
    handleChange,
    handleBlur,
    value,
    name,
    className,
    maxLength,
    max,
    multiline,
    readOnly,
    country,
    ...rest
}) {
    return (
        <div className="mb20">
            <FormLabel className="heading-color ff-heading fw600 mb20">
                {label}
            </FormLabel>
            <PhoneInput
                name={name}
                id={name}
                country={country}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                className={className}
                disabled={disabled}
                excludeCountries={["il"]}
            />
            <ErrorMessage name={name} component="div" className="text-danger" />
        </div>
    )
}

export default InputPhoneCode
