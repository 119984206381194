import logoImg2 from "../../../assets/images/Amaken-logo2.svg";
import forgotBg from "../../../assets/images/icon/login-page-icon.svg";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getAccountVerificationAPI } from "../../../services/userService";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import { useTranslation } from "react-i18next";

export const AccountActivation = () => {
  const [loading, setLoading] = useState();
  const location = useLocation();
  const [received, setReceived] = useState("");

  const params = new URLSearchParams(location.search);
  const verificationKey = params.get("key");

  const { t } = useTranslation()

  // Get list details
  const fetchData = (params) => {
    getAccountVerificationAPI({ ...params })
      .then((response) => {
        setReceived(t('THANK YOU FOR VERIFICATION.'))
        setLoading(false);
        toast.success(t('THANK YOU FOR VERIFICATION.'));
        //navigate("/forgot-password");
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error.response.data.error_description
            ? error.response.data.error_description
            : error.response.data?.message
        );
        setReceived(error.response.data.error_description
          ? error.response.data.error_description
          : error.response.data?.message)
      });
  };

  // Get onboarding
  useEffect(() => {
    setLoading(true);
    fetchData({
      key: verificationKey,
    });
  }, [verificationKey]);

  return (

    <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="wrapper ovh">
          <div className="body_content">
            <section className="our-compare pt60 pb60">
              <img
                className="login-bg-icon wow fadeInLeft"
                src={forgotBg}
                alt="Forgot bg"
              />
              <div className="container">
                <div
                  className="row wow fadeInRight"
                  data-wow-delay="300ms"
                >
                  <div className="col-lg-6">
                    <div className="log-reg-form signup-modal form-style1 bgc-white p50 p30-sm default-box-shadow2 bdrs12">
                      <div className="header-logo text-center mb40">
                        <a href={process.env.REACT_APP_AGENCY_WEB_URL}>
                          <img
                            className="mb25"
                            src={logoImg2}
                            alt="forgot logo"
                          />
                        </a>
                        <h2>{t("ACCOUNT ACTIVATION")}</h2>
                        <p className="text">
                          {received}
                        </p>
                      </div>
                      {/* BACK TO LOGIN PAGE */}
                      <div className="d-grid mb20">
                        <Link
                          className="ud-btn btn-thm"
                          type="button"
                          to="/login"
                        >
                          {t("BACK")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};
