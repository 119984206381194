import { axios } from "./axios"
import { AGENT_CREATE_API, AGENT_LISTING_API, EDIT_AGENT, GET_AGENT_BY_ID, UPDATE_AGENT_STATUS,DELETE_AGENT_PROFILE_IMG} from "constants/paths"

// AGENT CREATE
export const createAgent = async(data) => {
    const response = await axios.post(AGENT_CREATE_API, data)
    return response?.data
}

// AGENT LISTING
export const getAgentListingData = async(params) => {
    const response = await axios.get(AGENT_LISTING_API,{params})
    return {data : response?.data , headers : response?.headers}
}

export const getAgentById = async(id)=>{
    const response = await axios.get(`${GET_AGENT_BY_ID}/${id}`)
    return response?.data
}

// AGENT EDIT
export const editAgent = async(data)=>{
    const response = await axios.put(EDIT_AGENT,data)
    return response?.data
}

// AGENT STATUS UPDATE
export const updateAgentStatus = async(id,params)=>{
    const response = await axios.put(`${UPDATE_AGENT_STATUS}/${id}?status=${params?.status}`)
    return response?.data
}
// AGENT PROFILE PICTURE DELETE

export const deleteAgentProfilePic = async(id)=>{
    const response = await axios.delete(`${DELETE_AGENT_PROFILE_IMG}${id}`)
    return response?.data
}
