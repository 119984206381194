import React, { useEffect, useState } from 'react'

import { topTenAgentsChart } from 'services/dashboardService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// TOP TEN AGENTS
const TopAgents = ({ startDate, endDate }) => {

    const {t} = useTranslation()

    const [gridData, setGridData] = useState([])
    const [loading, setLoading] = useState(true)

    // FETCH TOP TEN AGENTS
    const fetchTopAgentsData = () => {
        setLoading(true)
        const params = {
            startDate: startDate,
            endDate: endDate
        }
        topTenAgentsChart(params)
            .then((response) => {
                setGridData(response)
            })
            .catch(error => {
                toast.error(
                    error?.response?.data?.error_description
                      ? error?.response?.data?.error_description
                      : error?.response?.data?.message ?? error?.message
                  );
            }).finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchTopAgentsData()
    }, [startDate, endDate])



    return (
        <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <div className="navtab-style1">
                <div className="d-sm-flex align-items-center justify-content-between">
                    <h4 className="title fz17 mb20">{t("TOP AGENTS")}</h4>
                </div>
            </div>
            <div className="table-responsive-sm">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">{t("AGENT NAME")}</th>
                            <th scope="col "><p className='text-end fw-bold mb-0'>{t("PROPERTY VIEWS")}</p>    </th>
                        </tr>
                    </thead>
                    <tbody>{gridData && gridData?.length > 0 ? gridData.map((data, index) => (
                        <tr key={index + 1}>
                            <td className="text fz25">{data?.firstName + data?.lastName}</td>
                            <td className='text fz25 text-end text-theme fw-bold'>{data?.totalPropertyViews}</td>
                        </tr>
                    )) : null}</tbody>
                </table>
            </div>
        </div>
    )
}

export default TopAgents