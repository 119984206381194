import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getSubscribedListTransactionApi, unsubscribedApi } from "services/packageService";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import LoaderMu from "components/common/LoaderMu";
import {
  PackageTypeTemplate,
  UnSubscriptionTemplate,
  PackageStartDateTemplate,
  PackageEndDateTemplate,
  PackageStatusTemplate,
  CancelDateTemplate
} from "./RowTemplates";
import { getUserPackageDetailApi } from "../../services/userService";

export const PackageOld = () => {

  const { t } = useTranslation()

  // const [sortValue, setSortValue] = useState("")
  const [loading, setLoading] = useState(false)
  const [shouldShowDiv, setShouldShowDiv] = useState(false) // FOR PACKAGE UPGRADE BUTTON
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize] = useState(10)
  const [totalRowCount, setTotalRowCount] = useState(10)
  const [subscriptionListData, setSubscriptionListData] = useState([])

  // const ActiveUserPackage = useSelector((state) => state?.userPackage)

  const pageOffset = (currentPage - 1) * pageSize + 1

  const handlePageChange = (event, value) => {
    setCurrentPage(value)
  }

  // FETCH PACKAGE LISTING DATA
  const fetchListingData = () => {
    setLoading(true)
    let params = {
      size: pageSize
    }

    if (currentPage) {
      params.page = currentPage - 1
    }
    // GET TRANSACTION LIST // ALL PREVIOUS PACKAGES
    getSubscribedListTransactionApi(params).then(response => {
      setSubscriptionListData(response?.data ?? [])
      setTotalRowCount(response?.headers["x-total-count"])
      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(error?.response?.data?.error_description
        ? error?.response?.data?.error_description
        : error?.response?.data?.message)
    })

    getUserSubscriptionStatus()
  }

  // GET USER SUBSCRIPTION STATUS
  const getUserSubscriptionStatus = () => {
    setLoading(true)
    // GET USER PACKAGE DETAILS 
    getUserPackageDetailApi().then(response => {
      // IF ALREADY SUBSCRIBED THEN DO NOT SHOW UPGRADE BUTTON ELSE DISPLAY
      if (response.noOfActiveAgent == null) {
        setShouldShowDiv(true);
      }

      if (!response.noOfActiveAgent || response.noOfActiveAgent > 0) {
        //setShouldShowDiv(true);
      }

      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      toast.error(error?.response?.data?.error_description
        ? error?.response?.data?.error_description
        : error?.response?.data?.message)
    })
  }

  // HANDLE UNSUBSCRIBE
  const handleUnsubscribe = (subscriptionId) => {
    setLoading(true)
    // CALLING UNSUBSCRIBE API 
    unsubscribedApi(subscriptionId).then((response) => {
      setLoading(false)
      toast.success(t("UNSUBSCRIBE SUCCESSFUL"))
      fetchListingData() // CALLING LISTING DATA FOR UPDATING LIST
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }

  // CAL FUNCTION ON COMPONENT RENDER OR CURRENT PAGE CHANGE
  useEffect(() => {
    fetchListingData()
  }, [currentPage])



  return (
    <div className="dashboard__content bgc-f7">
      <LoaderMu loading={loading} />
      <div className="row align-items-center pb20">
        <div className="col-lg-12">
          <div className="dashboard_title_area">
            <h2>{t("MY PACKAGE")}</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
            <div className="packages_table table-responsive">
              {/* RENDER PRIME REACT DATATABLE */}
              <DataTable
                value={subscriptionListData ?? []}
                tableClassName="table-style3 table at-savesearch"
                cellClassName="vam"
              >
                {/* CURRENT PACKAGE */}
                <Column
                  field="planName"
                  header={t("CURRENT PACKAGE")}
                ></Column>
                {/* VALIDITY TYPE */}
                <Column
                  field="validityType"
                  header={t("TYPE")}
                  body={(row) => <PackageTypeTemplate row={row} />}
                ></Column>
                {/* PACKAGE  STATUS*/}
                <Column
                  field="active"
                  header={t("MY PACKAGE")}
                  body={(row) => <PackageStatusTemplate row={row} />}
                ></Column>
                {/* PACKAGE AMOUNT */}
                <Column field="price" header={t("AMOUNT")}
                  //body={{(row) => <span> row.price +' '+ t('CURRENCY SYMBOL')}</span>}
                  body={(row) => <div className="packageStatusCss"><span>{row.price + ' ' + t('CURRENCY SYMBOL')}</span></div>}
                ></Column>
                {/* SUBSCRIPTION START DATE */}
                <Column
                  field="subscriptionStartDate"
                  header={t("DATE STARTED")}
                  body={(row) => <PackageStartDateTemplate row={row} />}
                ></Column>
                {/* SUBSCRIPTION END DATE */}
                <Column
                  field="subscriptionEndDate"
                  header={t("EXPIRY DATE")}
                  body={(row) => <PackageEndDateTemplate row={row} />}
                ></Column>
                {/* SUBSCRIPTION CANCEL DATE */}
                <Column
                  field="subscriptionCancelDate"
                  header={t("CANCEL DATE")}
                  body={(row) => <CancelDateTemplate row={row} />}
                ></Column>
                {/* UNSUBSCRIBE */}
                <Column
                  field="id"
                  body={(row) => row.active == true ? <UnSubscriptionTemplate row={row} unsubscribe={handleUnsubscribe} /> : ''}
                ></Column>

              </DataTable>
              {/* PAGINATION */}
              <div className="mbp_pagination text-center mt30 dataTable_pagination">
                <Pagination count={Math.ceil(totalRowCount / pageSize)} siblingCount={1} page={currentPage} className="d-inline-block" size="large" onChange={(event, value) => handlePageChange(event, value)} />
                {
                  totalRowCount < pageSize ?
                    <p className="mt10 pagination_page_count text-center">
                      {pageOffset} – {totalRowCount} {t("OF")} {totalRowCount} {t("PACKAGE AVAILABLE")}
                    </p> : <p className="mt10 pagination_page_count text-center">
                      {pageOffset} – {currentPage * pageSize} {t("OF")} {totalRowCount}+ {t("PACKAGE AVAILABLE")}
                    </p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {shouldShowDiv && <div className="row" >
        <div className="col-md-12 text-end">
          <Link to="/plan" className="ud-btn btn-thm">
            {t("UPGRADE")}
            <i className="fal fa-arrow-right-long" />
          </Link>
        </div>
      </div>}
    </div>
  );
};
