import React, { useEffect, useRef } from 'react'
import { FormLabel } from 'react-bootstrap';
import markerImg from "../../assets/images/resource/map-marker-2.png"
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function MapMarkerDrag({ setMarkerCoordinates , markerCoordinates }) {

    const mapRef = useRef(null);
    const markerRef = useRef(null);

    const {t} = useTranslation()

    // Coordinates for the bounding box of Jordan
    const jordanBounds = {
        north: 33.3736, // Northernmost point
        south: 29.1836, // Southernmost point
        east: 39.3018,  // Easternmost point
        west: 34.9579   // Westernmost point
    };

    const jordanCenter = markerCoordinates ?? { lat: 30.5852, lng: 36.2384 }


    useEffect(() => {
        const initMap = async () => {

            const { Map } = await window.google.maps.importLibrary('maps');
            const { AdvancedMarkerElement } = await window.google.maps.importLibrary('marker');

            const map = new Map(mapRef.current, {
                minZoom: 6.8,
                center: { lat: 31.5, lng: 36.5 },
                restriction: {
                    latLngBounds: jordanBounds
                },
                mapId: '4504f8b37365c3d0',
            });

            const markerIcon = document.createElement("img");
            markerIcon.src = markerImg

            const markerOptions = {
                map,
                content: markerIcon,
                gmpDraggable: true,
                position: { lat: 30.5852, lng: 36.2384 },
            }

            const marker = new AdvancedMarkerElement(markerOptions);

            // EVENT ON MARKER DRAG END
            marker.addListener('dragend', (evt) => {
                const newPosition = evt.latLng;
                // Check if the new position is within bounds
                if (!bounds.contains(newPosition)) {
                    // Reset marker position to center of Jordan
                    marker.position = jordanCenter
                    toast.warning(t("MARKER MUST REMAIN WITHIN THE BOUNDS OF JORDAN"));
                } else {
                    setMarkerCoordinates({ lat: evt.latLng.lat().toFixed(3), lng: evt.latLng.lng().toFixed(3) })
                }
            });

            // Define the bounds of Jordan
            const bounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(jordanBounds.south, jordanBounds.west), // Southwest corner
                new window.google.maps.LatLng(jordanBounds.north, jordanBounds.east)  // Northeast corner
            );

            // Fit the map to the bounds of Jordan
            map.fitBounds(bounds);

            // Store marker reference for future use
            markerRef.current = marker;
        };
        if (window.google) {
            initMap();
        }

    }, []);

    return <div>
        <FormLabel className="heading-color ff-heading fw600 mb10">
           {t("DRAG MARKER AND SELECT A LOCATION FOR YOUR PROPERTY")}
        </FormLabel>
        <div id="map" ref={mapRef} style={{ height: '400px', width: '100%' }} />
    </div>
}

export default MapMarkerDrag
