import DateFilter from 'components/dateFilter/DateFilter'
import AgentReport from 'components/reports/AgentReport'
import LocationReport from 'components/reports/LocationReport'
import PropertyReport from 'components/reports/PropertyReport'
import moment from 'moment'
import { TabPanel, TabView } from 'primereact/tabview'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { nameBasedProtectedRoutes } from 'router/protected'

const Reports = () => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const location = useLocation()

	//GET ACTIVE TAB FROM URL PARAMS
	const params = new URLSearchParams(location?.search)

	const active_tab = params.get('active') ?? 1

	const [activeTab, setActiveTab] = useState(parseInt(active_tab) )

	const dateFormat = "YYYY-MM-DD";
	// First date of the year
	const firstDateOfYear = moment().startOf('year').format(dateFormat);

	// Last date of the year
	const lastDateOfYear = moment().format(dateFormat);
	// const lastDateOfYear = moment().format(dateFormat)

	const [startDate, setStartDate] = useState(firstDateOfYear)
	const [endDate, setEndDate] = useState(lastDateOfYear)

	return (
		<div className="dashboard__content bgc-f7 ">
			<div className="row align-items-center pb20">
				<div className="col-xxl-3">
					<div className="dashboard_title_area">
						<h2>{t('REPORTS')}</h2>
					</div>
				</div>
				{/* CALLING DATE FILTER COMPONENT */}
				<div className="col-xxl">
					<DateFilter startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
				</div>
			</div>
			<div className="row">
				<div className="col-xl-12">
					<div className="ps-widget bgc-white bdrs12 default-box-shadow2 pt30 mb30 overflow-hidden position-relative tabView min-height50vh">

					{/* USING TAB VIEW FROM PRIME REACT */}
						<TabView className="pr_tabView"
							onTabChange={(e) => {
								let report
								switch (e?.index) {
									case 0:
										report = "Property"
										break;
									case 1:
										report = "Agent"
										break;
									case 2:
										report = "Location"
										break;
									default:
										report = "Property"
								}

								setActiveTab(e?.index + 1)
								navigate(`${nameBasedProtectedRoutes?.reports?.path}?active=${e?.index + 1}&report=${report}`)
							}}
							activeIndex={activeTab - 1}
						>
						{/* PROPERTY REPORT */}
							<TabPanel header={t("PROPERTY")} key="tab1" headerClassName="pr_tabHeader me-2">
								<PropertyReport startDate={startDate} endDate={endDate}/>
							</TabPanel>
							{/* AGENT REPORT */}
							<TabPanel header={t("AGENT")} key="tab2" headerClassName="pr_tabHeader me-2 flex align-items-center" >
								<AgentReport startDate={startDate} endDate={endDate}/>
							</TabPanel>
							{/* LOCATION REPORT */}
							<TabPanel header={t("LOCATION")} key="tab3" headerClassName="pr_tabHeader me-2 flex align-items-center">
								<LocationReport startDate={startDate} endDate={endDate}/>
							</TabPanel>
						</TabView>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Reports