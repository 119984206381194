import React from 'react'
import { Checkbox, ColorPicker, Radio } from 'antd';
import { Form, Formik } from 'formik';
import AgencyInputText from 'components/common/AgencyInputText';
import { FormLabel } from 'react-bootstrap';
import AgencyFileUpload from 'components/common/AgencyFileUpload';
import { waterMarkConfiguration } from 'services/agencyService';
import { toast } from 'react-toastify';
import defaultImg from "../../assets/images/default-image.jpg";
import { watermarkValidationSchema } from 'validations/ValidationSchema';
import { useTranslation } from 'react-i18next';
const WatermarkForm = ({ agencyInfo, getAgencyData }) => {

	const { t } = useTranslation()

	const handleSubmit = (values) => {

		const formData = new FormData()
		formData.append("watermark", values?.watermark)
		if (values?.watermark === true) {
			formData.append("watermarkPosition", values?.watermark_position)
			formData.append("watermarkType", values?.watermark_type)
			if (values?.watermark_type === "TEXT") {
				formData.append("watermarkText", values?.watermark_text)
			}

			if (values?.watermark_type === "IMAGE" && values?.watermark_image) {
				formData.append("watermarkBrowseImage", values?.watermark_image)
			}

			formData.append("watermarkTextColor", values?.watermark_color)
		}


		waterMarkConfiguration(formData).then((response) => {
			getAgencyData()
			toast.success(response?.message)
		}).catch((error) => {
			toast.error(
				error?.response?.data?.error_description
					? error?.response?.data?.error_description
					: error?.response?.data?.message ?? error?.message
			);
		})
	}

	return (
		<div className="ps-widget bgc-white bdrs12 default-box-shadow2 p30 mb30 overflow-hidden position-relative">
			<h4 className="title fz17 mb30">{t("WATERMARK CONFIGURATION")}</h4>
			<Formik
				enableReinitialize={true}
				initialValues={
					{
						watermark: agencyInfo?.watermark ?? false,
						watermark_type: agencyInfo?.watermarkType ?? "TEXT",
						watermark_text: agencyInfo?.watermarkText ?? "",
						watermark_color: agencyInfo?.watermarkTextColor ?? "#212529",
						watermark_image: null,
						watermark_position: agencyInfo?.watermarkPosition ?? "TOP_LEFT",
						imgUploaded: agencyInfo?.watermarkImage ? true : false
					}}
				validationSchema={watermarkValidationSchema}
				onSubmit={(values, actions) => {
					actions.setSubmitting(false);
					handleSubmit(values)
				}}
			>
				{({
					handleSubmit,
					handleChange,
					handleBlur,
					values,
					setFieldValue,
					setFieldError,
					touched,
					isValid,
					errors,
				}) => (
					<Form className="form-style1">
						<div className='row mb-3'>
							<div className="col ant-check-style">
								<Checkbox
									id="watermark"
									name="watermark"
									onChange={(event) => setFieldValue("watermark", event?.target?.checked)}
									checked={values?.watermark}
								>{t("ADD WATERMARK")}</Checkbox>
							</div>
						</div>
						{
							values?.watermark === true ?
								<div>
									<div className='row mb-3'>
										<div className="col watermark-toggle">
											<Radio.Group
												id="watermark_type"
												name="watermark_type"
												onChange={handleChange}
												value={values?.watermark_type}
												className={` ${touched?.watermark_type && errors?.watermark_type ? "error" : ""
													}`}
											>
												<Radio value={"TEXT"}>{t("WATERMARK TEXT")}</Radio>
												<Radio value={"IMAGE"}>{t("WATERMARK IMAGE")}</Radio>
											</Radio.Group>
										</div>
									</div>
									<div className="row">
										{
											values?.watermark_type === "TEXT" ?
												<div className="col-sm-6 col-xl-6">
													<AgencyInputText
														type="text"
														label={t("WATERMARK TEXT")}
														id="watermark_text"
														placeholder={t("WATERMARK TEXT")}
														name="watermark_text"
														handleChange={handleChange}
														handleBlur={handleBlur}
														value={values?.watermark_text}
														className={`form-control ${touched?.watermark_text && errors?.watermark_text ? "error" : ""
															}`}
													/>
													<div className='mb20'>
														<FormLabel className="heading-color ff-heading fw600 mb20">
															{t("WATERMARK COLOR")}
														</FormLabel>
														<div>
															<ColorPicker
																format='hex'
																disabledAlpha
																size="small"
																showText
																id="watermark_color"
																name="watermark_color"
																onChange={(color) => setFieldValue("watermark_color", color.toHexString())}
																value={values?.watermark_color}
																className={` ${touched?.watermark_color && errors?.watermark_color ? "error" : ""
																	}`}
															/>
														</div>
													</div>
												</div> :
												<div className="col-sm-6 col-xl-6 profile-content">
													<AgencyFileUpload
														id="watermark_image"
														accept=".png"
														name="watermark_image"
														label={t("WATERMARK IMAGE")}
														labelMargin="mb20"
														handleChange={(event) => {
															const uploadedFile = event?.target?.files[0]
															const allowedFileTypes = ["image/png"];
															const allowedFileSize = 10 * 1024 * 1024
															const isValidSize = uploadedFile?.size <= allowedFileSize
															const isAllowedType = allowedFileTypes.includes(uploadedFile?.type);
															if (!isAllowedType) {
																setFieldError("watermark_image", "You can only PNG files!")
															}
															else if (!isValidSize) {
																setFieldError("watermark_image", "You can upload files up to 10MB!")
															}
															else {
																setFieldValue("watermark_image", event?.target?.files[0])
															}
														}}
														fileName={values?.watermark_image}
														// error={errors?.watermark_image ? errors?.watermark_image : ""}
														className={`form-control p15 pl30 ud-btn2 ${touched?.watermark_image &&
															errors?.watermark_image
															? "error"
															: ""
															}`}
													/>
													<div>
														{
															<div className="profile-box position-relative d-md-flex align-items-end mb50">

																{
																	agencyInfo?.watermarkImage ?
																		<div className="profile-img position-relative overflow-hidden bdrs12 mb20-sm">
																			<img className='mxh-250' src={agencyInfo?.watermarkImage} alt="WaterMark-img" />
																		</div>

																		: <div className="profile-img position-relative overflow-hidden bdrs12 mb20-sm">
																			<img className='mxh-250' src={defaultImg} alt="WaterMark-img" />
																		</div>
																}
																{/* WATERMARK IMAGE DELETE BUTTON */}
																{/* <span type="button" className="border-none tag-del" >
															<span className="fas fa-trash-can" />
														</span> */}
															</div>
														}
													</div>
												</div>
										}
										<div className="col-sm-6 col-xl-6">
											<div className='mb20'>
												<FormLabel className="heading-color ff-heading fw600 mb20">
													{t("WATERMARK POSITION")}
												</FormLabel>
												<div>
													<Radio.Group
														id="watermark_position"
														name="watermark_position"
														className='antdRadioButton'
														value={values?.watermark_position}
														onChange={handleChange}
													>
														<Radio.Button value="TOP_LEFT">{t("TOP LEFT")}</Radio.Button>
														<Radio.Button value="TOP_RIGHT">{t("TOP RIGHT")}</Radio.Button>
														<Radio.Button value="CENTER">{t("CENTER")}</Radio.Button>
														<Radio.Button value="BOTTOM_LEFT">{t("BOTTOM LEFT")}</Radio.Button>
														<Radio.Button value="BOTTOM_RIGHT">{t("BOTTOM RIGHT")}</Radio.Button>
													</Radio.Group>
												</div>
											</div>
										</div>
									</div>
								</div> : ""
						}

						<div className="row">
							<div className="col-md-12 text-end">
								<button type="submit" className="ud-btn btn-dark" onClick={handleSubmit}>
									{t("SAVE")}
									<i className="fal fa-arrow-right-long" />
								</button>
							</div>
						</div>
					</Form>)}
			</Formik>
		</div>
	)
}

export default WatermarkForm