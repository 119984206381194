import * as yup from "yup";
import {
  MAXIMUM_30_CHAR,
  MINIMUM_8_CHAR,
  PASSWORD_REGEX,
  CONFIRM_PASSWORD_REQUIRED,
  CURRENT_PASSWORD_REQUIRED,
  NEW_PASSWORD_REQUIRED,
  CONFIRM_PASSWORD_MATCH,
  EMAIL_REQUIRED,
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  VALID_EMAIL,
  MINIMUM_7_DIGIT,
  MAXIMUM_15_DIGIT,
  MINIMUM_3_CHAR,
  MAXIMUM_255_CHAR,
  LANG_KEY_REQUIRED,
  PASSWORD_REQUIRED,
  GOVERNORATE_REQUIRED,
  DIRECTORATE_REQUIRED,
  VILLAGE_REQUIRED,
  BASIN_REQUIRED,
  DISTRICT_REQUIRED,
  OFFERING_TYPE_REQUIRED,
  PROPERTY_TYPE_REQUIRED,
  PROPERTY_CATEGORY_REQUIRED,
  PRICE_REQUIRED,
  PROPERTY_TITLE_REQUIRED,
  PROPERTY_DESCRIPTION_REQUIRED,
  VIDEO_URL_REQUIRED,
  ADDRESS_REQUIRED,
  I_AGREE_REQUIRED,
  AGENCY_REQUIRED,
  URL_REGEX,
  WEBSITE_URL,
  AGENCY_NAME_REQUIRED,
  MAXIMUM_3_DIGIT,
  MAXIMUM_5_DIGIT,
  PHONE_NUMBER_VALIDATION,
  ID_NUMBER_REQUIRED,
  AMAKEN_ID_REQUIRED,
  TITLE_DEED_REQUIRED,
  AMENITIES_REQUIRED,
  NO_NEGATIVE_VALUE,
  COMMENT_REQUIRED,
  CARD_NUMBER,
  CARD_NAME,
  CARD_MIN_LIMIT,
  CARD_MAX_LIMIT,
  YEAR_REQUIRED,
  MONTH_REQUIRED,
  CARD_CVV,
  CARD_CVV_LIMIT,
  INVALID_FORMAT,
  COUNTRY_CODE_REQUIRED,
  OTP_REQUIRED,
  PHONE_NO_REQUIRED,
  ONLY_ARABIC_CHARACTERS,
  ONLY_ENGLISH_CHARACTERS,
  INVALID_COUNTRY_CODE,
  FLOOR_NUMBER_REQUIRED,
  APARTMENT_ID_REQUIRED,
  AVAILABLE_FROM_REQUIRED,
  BUILDING_NUMBER_REQUIRED,
  CHAR_MAX_20,
  FILE_REQUIRED,
  MAXIMUM_1250_CHAR
} from "./ValidationErrors";

// https://chat.openai.com  and www.chat.openai.com
const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
const websiteUrlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$|^www\.[^\s/$.?#].[^\s]*$/
const englishRegex = /^[^\u0600-\u06FF]*$/
const arabicRegex = /^[\u0600-\u06FF0-9\s\W]*$/;

// const passwordRegex = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d.*\d)(?=.*[!$%^()_+<>#*@`~&=\|'";:/?.,])\S+$/);
const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!$}{%^()_+<>#*@`~&=\|'";:/?.,-])[A-Za-z\d!$}{%^()_+<>#*@`~&=\|'";:/?.,-]{8,14}$/;


// FUNCTION FOR VALIDATING URL
function isValidURL(url) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

const phoneNoRegex = /^\d+$/

// Profile
export const ProfileSchema = yup.object().shape({
  current_password: yup
    .string()
    .required(CURRENT_PASSWORD_REQUIRED)
    .matches(passwordRegex, PASSWORD_REGEX),
  new_password: yup
    .string()
    .required(NEW_PASSWORD_REQUIRED)
    .matches(passwordRegex, PASSWORD_REGEX),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("new_password"), ""], CONFIRM_PASSWORD_MATCH),
});

// Add CMS
export const AddCmsSchema = yup.object().shape({
  title: yup.string().required(EMAIL_REQUIRED),
  page_type: yup.string().required(EMAIL_REQUIRED),
  content: yup
    .string()
    .required(EMAIL_REQUIRED)
    .when("page_type", {
      is: "TEXT_WITH_ASSESSMENT_RESULT_APP",
      then: yup
        .string()
        .required(EMAIL_REQUIRED)
        .max(150, "Result app page type must be 150 characters or less."),
    })
    .when("page_type", {
      is: "CONTINUE_ASSESSMENT_APP",
      then: yup
        .string()
        .required(EMAIL_REQUIRED)
        .max(105, "Assessment page type must be 105 characters or less"),
    })
    .when("page_type", {
      is: "RESULT_POP",
      then: yup
        .string()
        .required(EMAIL_REQUIRED)
        .max(150, "Result pop must be 150 characters or less."),
    })
    .when("page_type", {
      is: "START_ASSESSMENT",
      then: yup
        .string()
        .required(EMAIL_REQUIRED)
        .max(150, "Assessment start must be 150 characters or less."),
      otherwise: yup.string().required(EMAIL_REQUIRED),
    }),
});

// Singup
export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .email(VALID_EMAIL)
    .required(EMAIL_REQUIRED)
    .max(300, MAXIMUM_255_CHAR),
  password: yup
    .string()
    .required(EMAIL_REQUIRED)
    .min(8, MINIMUM_8_CHAR)
    .max(14, MAXIMUM_30_CHAR),
});

// Singup
export const Singup = yup.object().shape({
  firstName: yup.string().required(EMAIL_REQUIRED),
  lastName: yup.string().required(EMAIL_REQUIRED),
  email: yup
    .string()
    .email(VALID_EMAIL)
    .required(EMAIL_REQUIRED),
  password: yup
    .string()
    .required(EMAIL_REQUIRED)
    .matches(passwordRegex, PASSWORD_REGEX),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], CONFIRM_PASSWORD_MATCH),
});

// Singup
export const ForgotPasswordSchema = yup.object().shape({
  email: yup.string().email(VALID_EMAIL).required(EMAIL_REQUIRED),
});

// Reset Password
export const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required(NEW_PASSWORD_REQUIRED)
    .matches(passwordRegex, PASSWORD_REGEX),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], CONFIRM_PASSWORD_MATCH),
});

// USER PROFILE CHANGE PASSWORD
export const profileChangePassword = yup.object().shape({
  oldPassword: yup
    .string()
    .required(CURRENT_PASSWORD_REQUIRED)
    .min(8, MINIMUM_8_CHAR)
    .max(14, MAXIMUM_30_CHAR),
  newPassword: yup
    .string()
    .required(NEW_PASSWORD_REQUIRED)
    .min(8, MINIMUM_8_CHAR)
    .test('notEqual', 'New password must not match the current password', function (value) {
      const oldPassword = this.parent.oldPassword;
      return value !== oldPassword;
    }),
  confirmPassword: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("newPassword"), ""], CONFIRM_PASSWORD_MATCH),
});
// AGENT PROFILE UPDATE FORM
export const profileUpdateSchema = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME_REQUIRED).min(3, MINIMUM_3_CHAR).max(255, MAXIMUM_255_CHAR),
  lastName: yup.string().required(LAST_NAME_REQUIRED),
  langKey: yup.string().required(LANG_KEY_REQUIRED),
  phoneNo: yup.string().nullable().min(7, MINIMUM_7_DIGIT).max(15, MAXIMUM_15_DIGIT).matches(phoneNoRegex, PHONE_NUMBER_VALIDATION),
  facebook_url: yup.string().matches(urlRegex, URL_REGEX).max(255, MAXIMUM_255_CHAR),
  instagram_url: yup.string().matches(urlRegex, URL_REGEX).max(255, MAXIMUM_255_CHAR),
  linkedin_url: yup.string().matches(urlRegex, URL_REGEX).max(255, MAXIMUM_255_CHAR),
  about_en: yup.string().nullable().matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  about_ar: yup.string().nullable().matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
})

// Agency Signup Schema
export const AgencySingupSchema = yup.object().shape({
  i_agree: yup.boolean().oneOf([true], I_AGREE_REQUIRED).required(I_AGREE_REQUIRED),
  agencyName: yup.string().required('Agency name is required'),
  contactName: yup.string().required('Contact name is required '),
  agencyAddress: yup.string().required('Agency address is required '),
  commercialRegistration: yup.string().required('Commercial registration is required '),
  companyNationalId: yup.string().required('Company national-id is required ').matches(/^[a-zA-Z0-9]+$/, 'Company national-id should only contain alphanumeric characters'),
  firstName: yup.string().required('Agent first name is required '),
  lastName: yup.string().required('Agent last name is required '),
  area: yup.string().required('Area is required '),
  street: yup.string().required('Street is required '),
  city: yup.string().required('City is required '),
  email: yup.string().email(VALID_EMAIL).required('Agent email is required '),
  agencyEmail: yup
    .string()
    .email(VALID_EMAIL)
    .required(EMAIL_REQUIRED),
  password: yup
    .string()
    .required(PASSWORD_REQUIRED)
    .matches(passwordRegex, PASSWORD_REGEX),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], CONFIRM_PASSWORD_MATCH),
});

// Agent Signup Schema
export const AgentSingupSchema = yup.object().shape({
  i_agree: yup.boolean().oneOf([true], I_AGREE_REQUIRED).required(I_AGREE_REQUIRED),
  firstName: yup.string().required('Agent first name is required '),
  lastName: yup.string().required('Agent last name is required '),
  email: yup.string().email(VALID_EMAIL).required(EMAIL_REQUIRED),
  agencyId: yup
    .object()
    .required(AGENCY_REQUIRED),
  password: yup
    .string()
    .required(PASSWORD_REQUIRED)
    .matches(passwordRegex, PASSWORD_REGEX),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], CONFIRM_PASSWORD_MATCH),
});

// AGENT SCHEMA

export const addAgentSchema = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME_REQUIRED).min(3, MINIMUM_3_CHAR).max(255, MAXIMUM_255_CHAR),
  lastName: yup.string().required(LAST_NAME_REQUIRED).min(3, MINIMUM_3_CHAR).max(255, MAXIMUM_255_CHAR),
  email: yup.string().email(VALID_EMAIL).required(EMAIL_REQUIRED),
  address: yup.string().required(ADDRESS_REQUIRED).max(255, MAXIMUM_255_CHAR),
  about_en: yup.string().nullable().matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  about_ar: yup.string().nullable().matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
  // facebook_url : yup.string().matches(urlRegex ,URL_REGEX),
  facebook_url: yup.string().nullable().test('facebook_url', URL_REGEX, function (value) {
    if (!value) {
      return true; // Allow empty value
    }
    return isValidURL(value);
  }),
  instagram_url: yup.string().nullable().test('instagram_url', URL_REGEX, function (value) {
    if (!value) {
      return true; // Allow empty value
    }
    return isValidURL(value);
  }),
  linkedin_url: yup.string().nullable().test('linkedin_url', URL_REGEX, function (value) {
    if (!value) {
      return true; // Allow empty value
    }
    return isValidURL(value);
  }),
  password: yup
    .string()
    .required(PASSWORD_REQUIRED)
    .matches(passwordRegex, PASSWORD_REGEX),
  confirm_password: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], CONFIRM_PASSWORD_MATCH),
})

// EDIT AGENT SCHEMA
export const editAgentSchema = yup.object().shape({
  firstName: yup.string().required(FIRST_NAME_REQUIRED).min(3, MINIMUM_3_CHAR).max(255, MAXIMUM_255_CHAR),
  lastName: yup.string().required(LAST_NAME_REQUIRED).min(3, MINIMUM_3_CHAR).max(255, MAXIMUM_255_CHAR),
  email: yup.string().required(EMAIL_REQUIRED),
  address: yup.string().required(ADDRESS_REQUIRED).max(255, MAXIMUM_255_CHAR),
  about_en: yup.string().nullable().matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  about_ar: yup.string().nullable().matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
  // facebook_url : yup.string().matches(urlRegex ,URL_REGEX),
  facebook_url: yup.string().nullable().test('facebook_url', URL_REGEX, function (value) {
    if (!value) {
      return true; // Allow empty value
    }
    return isValidURL(value);
  }),
  instagram_url: yup.string().nullable().test('instagram_url', URL_REGEX, function (value) {
    if (!value) {
      return true; // Allow empty value
    }
    return isValidURL(value);
  }),
  linkedin_url: yup.string().nullable().test('linkedin_url', URL_REGEX, function (value) {
    if (!value) {
      return true; // Allow empty value
    }
    return isValidURL(value);
  }),
  password: yup
    .string()
    .matches(passwordRegex, PASSWORD_REGEX),
  confirm_password: yup.string().when('password', {
    is: (password) => password && password.length > 0,
    then: yup.string().required(CONFIRM_PASSWORD_REQUIRED).oneOf([yup.ref('password')], CONFIRM_PASSWORD_MATCH),
    otherwise: yup.string() // Not required if password is empty
  })
  // confirm_password: yup
  // .string().oneOf([yup.ref("password"), ""], CONFIRM_PASSWORD_MATCH),
})

// AGENCY PROFILE SOCIAL INFO SCHEMA
export const agencySocialInfoFormSchema = yup.object().shape({
  facebook_url: yup.string().matches(urlRegex, URL_REGEX).max(255, MAXIMUM_255_CHAR),
  instagram_url: yup.string().matches(urlRegex, URL_REGEX).max(255, MAXIMUM_255_CHAR),
  linkedin_url: yup.string().matches(urlRegex, URL_REGEX).max(255, MAXIMUM_255_CHAR),
  website_url: yup.string().matches(websiteUrlRegex, WEBSITE_URL).max(255, MAXIMUM_255_CHAR)
})

// AGENCY PROFILE SCHEMA
export const agencyProfileSchema = yup.object().shape({
  name: yup.string().required(AGENCY_NAME_REQUIRED).min(3, MINIMUM_3_CHAR).max(255, MAXIMUM_255_CHAR),
  address: yup.string().required(ADDRESS_REQUIRED).min(3, MINIMUM_3_CHAR).max(255, MAXIMUM_255_CHAR),
  contactName: yup.string().required().min(3, MINIMUM_3_CHAR).max(255, MAXIMUM_255_CHAR),
  about_en: yup.string().nullable().matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  about_ar: yup.string().nullable().matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
})

//ADD PROPERTY IDENTIFICATION FORM
export const propertyIdentificationSchema = yup.object().shape({
  id: yup.string().nullable(),
  titleDeedFile: yup.string().when('id', {
    is: (id) => !id || id.trim() === '', // Check if id is empty or has no value
    then: yup.string().required(TITLE_DEED_REQUIRED),
    otherwise: yup.string(),
  }),
  buildingNumber: yup.number().nullable().min(0, NO_NEGATIVE_VALUE),
  // floorNumber: yup.number().required(FLOOR_NUMBER_REQUIRED).min(-5, MINIMUM_5_DIGIT),
  // apartmentId: yup.string().required(APARTMENT_ID_REQUIRED),
  propertyOfferingType: yup.string().required(OFFERING_TYPE_REQUIRED),
  propertyCategory: yup.string().required(PROPERTY_CATEGORY_REQUIRED),
  propertyTypeId: yup.string().required(PROPERTY_TYPE_REQUIRED),
  priceInJOD: yup.number().required(PRICE_REQUIRED).min(0, NO_NEGATIVE_VALUE),
  amakenId: yup.string().required(AMAKEN_ID_REQUIRED),
  agencyRefNo: yup.string().nullable().max(20, CHAR_MAX_20)
})

// DLS KEY GENERATION SCHEMA
export const dlsKeyGenerateSchema = yup.object().shape({
  governorate: yup.string().required(GOVERNORATE_REQUIRED),
  directorate: yup.string().required(DIRECTORATE_REQUIRED),
  village: yup.string().required(VILLAGE_REQUIRED),
  basin: yup.number().required(BASIN_REQUIRED).max(999, MAXIMUM_3_DIGIT).min(0, NO_NEGATIVE_VALUE),
  district: yup.number().required(DISTRICT_REQUIRED).max(999, MAXIMUM_3_DIGIT).min(0, NO_NEGATIVE_VALUE),
  id_number: yup.number().required(ID_NUMBER_REQUIRED).max(99999, MAXIMUM_5_DIGIT).min(0, NO_NEGATIVE_VALUE),
})

// UNDER CONSTRUCTION PROPERTY VALIDATION SCHEMA
export const underConstructPropValidationSchema = yup.object().shape({
  governorate: yup.string().required(GOVERNORATE_REQUIRED),
  directorate: yup.string().required(DIRECTORATE_REQUIRED),
  village: yup.string().required(VILLAGE_REQUIRED),
  basin: yup.number().nullable().max(999, MAXIMUM_3_DIGIT).min(0, NO_NEGATIVE_VALUE),
  district: yup.number().nullable().max(999, MAXIMUM_3_DIGIT).min(0, NO_NEGATIVE_VALUE),
  availableFrom: yup.string().required(AVAILABLE_FROM_REQUIRED),
  id: yup.string().nullable(),
  // longitude:yup.string().required(APARTMENT_ID_REQUIRED),
  // latitude:yup.string().required(APARTMENT_ID_REQUIRED),
  buildingNumber: yup.number().required(BUILDING_NUMBER_REQUIRED).min(0, NO_NEGATIVE_VALUE),
  floorNumber: yup.number().required(FLOOR_NUMBER_REQUIRED),
  apartmentId: yup.string().required(APARTMENT_ID_REQUIRED),
  propertyOfferingType: yup.string().required(OFFERING_TYPE_REQUIRED),
  propertyCategory: yup.string().required(PROPERTY_CATEGORY_REQUIRED),
  propertyTypeId: yup.string().required(PROPERTY_TYPE_REQUIRED),
  priceInJOD: yup.number().required(PRICE_REQUIRED).min(0, NO_NEGATIVE_VALUE),
  amakenId: yup.string().required(AMAKEN_ID_REQUIRED),
  agencyRefNo: yup.string().nullable().max(20, CHAR_MAX_20)
})

// DESCRIPTION FORM SCHEMA IN ADD PROPERTY
export const descriptionFormSchema = yup.object().shape({
  title_en: yup.string().required(PROPERTY_TITLE_REQUIRED).max(255, MAXIMUM_255_CHAR).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  title_ar: yup.string().required(PROPERTY_TITLE_REQUIRED).max(255, MAXIMUM_255_CHAR).matches(arabicRegex, ONLY_ARABIC_CHARACTERS),
  description_en: yup.string().required(PROPERTY_DESCRIPTION_REQUIRED).max(1250, MAXIMUM_1250_CHAR).matches(englishRegex, ONLY_ENGLISH_CHARACTERS),
  description_ar: yup.string().required(PROPERTY_DESCRIPTION_REQUIRED).max(1250, MAXIMUM_1250_CHAR).matches(arabicRegex, ONLY_ARABIC_CHARACTERS),

  videoSource: yup.string()
    .required("VIDEO SOURCE REQUIRED")
    .oneOf(['EMBEDDED', 'FILE', 'NONE'], 'Invalid video source'),

  // Conditional validation for embedded video source
  embeddedVideoSource: yup.string().nullable().when('videoSource', {
    is: 'EMBEDDED',
    then: yup.string()
      .required('Embedded video source is required')
      .max(255, MAXIMUM_255_CHAR),
    otherwise: yup.string().nullable(),
  }),

  embeddedVideoUrl: yup.string().nullable().when('videoSource', {
    is: 'EMBEDDED',
    then: yup.string()
      .required(VIDEO_URL_REQUIRED)
      .test('embeddedVideoUrl', URL_REGEX, function (value) {
        return isValidURL(value);
      }),
    otherwise: yup.string().nullable(),
  }),

  // Conditional validation for file video source
  browseVideo: yup.mixed().nullable().when(['videoSource', 'browseVideoUrl'], {
    is: (videoSource, browseVideoUrl) =>
      videoSource === 'FILE' && !browseVideoUrl,
    then: yup.mixed().required(FILE_REQUIRED),
    otherwise: yup.mixed().nullable(),
  }),

  videoSourceFull: yup.string()
    .required("Video source required!")
    .oneOf(['EMBEDDED', 'FILE', 'NONE'], 'Invalid video source'),

  // Conditional validation for embedded video source
  embeddedVideoSourceFull: yup.string().nullable().when('videoSourceFull', {
    is: 'EMBEDDED',
    then: yup.string()
      .required('Embedded video source is required')
      .max(255, MAXIMUM_255_CHAR),
    otherwise: yup.string().nullable(),
  }),

  embeddedVideoUrlFull: yup.string().nullable().when('videoSourceFull', {
    is: 'EMBEDDED',
    then: yup.string()
      .required(VIDEO_URL_REQUIRED)
      .test('embeddedVideoUrlFull', URL_REGEX, function (value) {
        return isValidURL(value);
      }),
    otherwise: yup.string().nullable(),
  }),

  // Conditional validation for file video source
  browseVideoFull: yup.mixed().nullable().when(['videoSourceFull', 'browseVideoFullUrl'], {
    is: (videoSourceFull, browseVideoFullUrl) =>
      videoSourceFull === 'FILE' && !browseVideoFullUrl,
    then: yup.mixed().required(FILE_REQUIRED),
    otherwise: yup.mixed().nullable(),
  }),

  virtualTourUrl: yup.string().nullable().test('virtualTourUrl', URL_REGEX, function (value) {
    if (!value) {
      return true; // Allow empty value
    }
    return isValidURL(value);
  }),
})

// AMENITIES SCHEMA IN ADD PROPERTY
export const amenitiesFormSchema = yup.object().shape({
  amenities: yup.array().min(1, AMENITIES_REQUIRED).required(AMENITIES_REQUIRED)
})

// ADD COMMENT VALIDATION SCHEMA
export const addCommentValidationSchema = yup.object().shape({
  comment: yup.string().required(COMMENT_REQUIRED)
})


// DYNAMIC SCHEMA FOR ADD PROPERTY DETAILS FORM
export const detailsFormSchema = (formField, langKey) => {
  const visibleFields = formField?.filter((element) => element?.visible === true)
  const schemaObject = visibleFields?.reduce((accumulator, field) => {
    let fieldValidation = yup.string().required(`${field?.field_level[langKey]} is required!`);
    if (['yearOld', 'noOfBedRooms', 'noOfBathRooms', 'floorNumber', 'noOfApartmentInBuilding', 'totalFloor'].includes(field.field_name)) {
      fieldValidation = yup
        .number()
        .required(`${field?.field_level[langKey]} is required`)
        .min(1, `${field?.field_level[langKey]} must be at least 1`)
        .max(100, `${field?.field_level[langKey]} must be at most 100`);
    }
    if (field?.field_name === "floorNumber") {
      fieldValidation = yup
        .number()
        .required(`${field?.field_level[langKey]} is required`)
        .min(-5, `${field?.field_level[langKey]} can not be less than -5`)
        .max(100, `${field?.field_level[langKey]} must be at most 100`);
    }
    return {
      ...accumulator,
      [field?.field_name]: fieldValidation,
    };
  }, {});
  return yup.object().shape(schemaObject);
};

// USER CARD DETAILS
export const userCardDetails = yup.object().shape({
  i_agree: yup.boolean().oneOf([true], I_AGREE_REQUIRED).required(I_AGREE_REQUIRED),
  cardName: yup.string().required(CARD_NAME),
  cardNumber: yup
    .string()
    .required(CARD_NUMBER)
    //.matches(/^\d{16}$/, CARD_MAX_LIMIT)
    .min(8, CARD_MIN_LIMIT)
    .max(19, CARD_MAX_LIMIT)
    .transform((value, originalValue) => {
      // Remove spaces for storing in the form data
      return originalValue.replace(/ /g, '');
    }),
  expiryYear: yup
    .string()
    .required(YEAR_REQUIRED)
    .matches(/^[0-9]{2}$/, INVALID_FORMAT),
  expiryMonth: yup
    .string()
    .required(MONTH_REQUIRED)
    .matches(/^(0[1-9]|1[0-2])$/, INVALID_FORMAT),
});


// PHONE NUMBER VALIDATION SCHEMA
export const phoneNoValidationSchema = yup.object().shape({
  countryCode: yup.string().required(COUNTRY_CODE_REQUIRED).notOneOf(['972'], INVALID_COUNTRY_CODE),
  phoneNumber: yup.string().required(PHONE_NO_REQUIRED).min(7, MINIMUM_7_DIGIT).max(15, MAXIMUM_15_DIGIT).matches(/^\d+$/, PHONE_NUMBER_VALIDATION),
  otp: yup.number().when("isOtpSend", {
    is: true,
    then: yup.number().required(OTP_REQUIRED),
    otherwise: yup.number().nullable()
  })
})

// FLOOR VALIDATION SCHEMA

// Define the validation schema
export const floorValidationSchema = yup.object().shape({
  floors: yup.array()
    .of(
      yup.object().shape({
        floorNumber: yup.string().required('Floor Number is required'),
        floorImg: yup.string().when('imageName', {
          is: (imageName) => !imageName,  // Check if imageName is not present
          then: yup.string().required('Floor plan is required'),
          otherwise: yup.string().notRequired(),  // Not required if imageName is present
        }),
        imageName: yup.string().url('Must be a valid URL'),
      })
    )
    .required('Must have floors')
    .min(1, 'Minimum of 1 floor is required'),
});

// WATERMARK FORM VALIDATION

export const watermarkValidationSchema = yup.object().shape({
  watermark_type: yup.string().required('Watermark type is required'),
  watermark_text: yup.string().when('watermark_type', {
    is: 'TEXT',
    then: yup.string().required('Watermark text is required'),
    otherwise: yup.string().notRequired()
  }),
  watermark_image: yup.mixed().when(['watermark_type', 'imgUploaded'], {
    is: (watermark_type, imgUploaded) => watermark_type === 'IMAGE' && !imgUploaded,
    then: yup.mixed().required('Watermark image is required'),
    otherwise: yup.mixed().notRequired()
  }),
  watermark_position: yup.string().required('Watermark position is required'),
  watermark_color: yup.string().required('Watermark color is required')
});