import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { packageUsageStats } from 'services/dashboardService'
import { LuBuilding2 } from "react-icons/lu";
import { BsBuildingCheck } from "react-icons/bs"
import { LiaUserCheckSolid } from "react-icons/lia";
import { GrThreeD } from "react-icons/gr";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const PackageUsage = () => {

    const { t } = useTranslation()

    const [packageStatsData, setPackageStatsData] = useState({})
    const [loading, setLoading] = useState(true)

    const currentPlan = useSelector((state)=> state?.userPackage)

    // GET PACKAGE USAGE DATA
    const getPackageUsage = () => {
        setLoading(true)
        // CALL PACKAGE STATS API
        packageUsageStats().then((response) => {
            setPackageStatsData(response)
        }).catch((error) => {
            toast.error(
                error?.response?.data?.error_description
                  ? error?.response?.data?.error_description
                  : error?.response?.data?.message ?? error?.message
              );
        }).finally(() => {
            setLoading(false)
        })
    }
    // CALL FUNCTION ON COMPONENT RENDER
    useEffect(() => {
        getPackageUsage()
    }, [])

    return (
        <div className="row">
            <div className='col-12'>
                <h4 className="title fz17 mb25">{t("PACKAGE USAGE")}</h4>
            </div>
            {/* NO OF ACTIVE PROPERTY */}
            <div className="col-sm-6 col-xxl">
                <div className="d-flex justify-content-between statistics_funfact_2 ">
                    <div className="details">
                        <div className="text fz25 ">{t("ACTIVE PROPERTIES")}</div>
                        <div className="title text-theme">{packageStatsData?.noOfActiveProperty ?? 0}/{currentPlan?.noOfActiveProperty ?? 0}</div>
                    </div>
                    <div className="icon text-center text-theme">
                        <BsBuildingCheck />
                    </div>
                </div>
            </div>
            {/* NO OF ACTIVE AGENTS */}
            <div className="col-sm-6 col-xxl">
                <div className="d-flex justify-content-between statistics_funfact_2">
                    <div className="details">
                        <div className="text fz25">{t("ACTIVE AGENTS")}</div>
                        <div className="title text-green" >{packageStatsData?.noOfActiveAgent ?? 0}/{currentPlan?.noOfActiveAgent ?? 0}</div>
                    </div>
                    <div className="icon text-center text-green">
                        <LiaUserCheckSolid />
                    </div>
                </div>
            </div>
            {/* NO OF FEATURED PROPERTIES */}
            <div className="col-sm-6 col-xxl">
                <div className="d-flex justify-content-between statistics_funfact_2">
                    <div className="details">
                        <div className="text fz25">{t("FEATURED PROPERTIES")}</div>
                        <div className="title text-red">{packageStatsData?.noOfActiveFeaturedProperty ?? 0}/{currentPlan?.customNoOfActiveFeaturedProperty ?? 0}</div>
                    </div>
                    <div className="icon text-center text-red">
                        <LuBuilding2 />
                    </div>
                </div>
            </div>
            {/* NO OF 3D VIEWS */}
            <div className="col-sm-6 col-xxl">
                <div className="d-flex justify-content-between statistics_funfact_2">
                    <div className="details">
                        <div className="text fz25"> {t("3D VIEWS")}</div>
                        <div className="title text-orange">{packageStatsData?.noOf3dViewUpload ?? 0}/{currentPlan?.customNoOf3dViewUpload ?? 0}</div>
                    </div>
                    <div className="icon text-center text-orange">
                        <GrThreeD />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PackageUsage