import { MainLayout } from "../components/layouts/MainLayout";
import { Dashboard } from "../pages/Dashboard";
import { Addagent } from "../pages/Addagent";
import { Addproperty } from "../pages/Addproperty";
import { Dashboardagents } from "../pages/Dashboardagents";
import { PackageOld } from "../pages/PackageOld";
// import { Transactions } from "../pages/PackageOld/transactions";
import { Profile } from "../pages/Profile";
import { Profileagency } from "../pages/Profileagency";
import { Properties } from "../pages/Properties";
import EditProperty from "pages/EditProperty";
import EditAgent from "pages/Editagent";
import { Plan } from "../pages/Plan";
import { Audit } from "../pages/Audit"
import { BulkUpload } from "../pages/BulkUpload";
import ActivityLogs from "pages/ActivityLogs";
import Reports from "pages/Reports";
import PaymentCard from "../pages/Package/PaymentCard";
import Package from "pages/Package";
import PaymentResponse from "pages/Response/PaymentResponse";
import RetryPayment from "pages/Package/RetryPayment";
import Orders from "pages/Orders";


/**
 * Routes which can only be accessed after authentication
 * @type {{}}
 */

// SEPARATE AGENT AND AGENCY ROUTES

export const protectedRoutes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "/", element: <Dashboard /> },
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/reports", element: <Reports /> },
      { path: "/agent/add", element: <Addagent /> },
      { path: "/buld-upload", element: <BulkUpload /> },
      { path: "/agent/edit/:agentId", element: <EditAgent /> },
      { path: "/addproperty", element: <Addproperty /> },
      { path: "/properties", element: <Properties /> },
      { path: "/properties/edit/:id", element: <EditProperty /> },
      { path: "/dashboardagents", element: <Dashboardagents /> },
      { path: "/package-old", element: <PackageOld /> },
      {path : "/package",element:<Package/>},
      // { path: "/transactions", element: <Transactions /> },
      { path: "/plans", element: <Plan /> },
      // { path: "/card/payment/:subscribedId", element: <Payment/> },
      { path: "/payment/:planId", element: <PaymentCard/> },
      // {path : "/payment/authenticate-response",element :<AuthenticatePaymentResponse/> },
      // { path: "/payment/response", element: <Response /> },
      { path: "/profile", element: <Profile /> },
      { path: "/profileagency", element: <Profileagency /> },
      { path: "/audit", element: <Audit /> },
      { path: "/activity-logs", element: <ActivityLogs /> },
      {path : "/payment/response" , element : <PaymentResponse/>},
      {path : "/payment/retry", element : <RetryPayment/>},
      {path : "/orders" , element : <Orders/>}
    ],
  },
];

// Named Routes

export const nameBasedProtectedRoutes =
{
  dashboard: {
    name: "Dashboard",
    path: `/dashboard`,
  },
  reports: {
    name: "Report",
    path: "/reports"
  },
  addAgent: {
    name: "Add Agent",
    path: "/agent/add"
  },
  agentListing: {
    name: "Dashboard agents",
    path: "/dashboardagents"
  },
  propertyList: {
    name: "Property List",
    path: `/properties`
  },
  propertyEdit: {
    name: "Property Edit",
    path: `/properties/edit`
  },
  propertyAdd: {
    name: "Property Add",
    path: `/addproperty`
  },
  agentEdit: {
    name: "Agent edit",
    path: `/agent/edit`
  },
  activityLogs: {
    name: "Activity Logs",
    path: "/activity-logs"
  },
  audit: {
    name: "Audit",
    path: "/audit"
  },
  package : {
    name: "Package",
    path: "/package"
  },
  plans : {
    name : "Plan",
    path : "/plans"
  },
  payment : {
    name : "Payment",
    path : "/payment"
  },
  paymentResponse : {
    name : "Payment response",
    path : "/payment/response"
  },
  orders : {
    name : "Orders",
    path : "/orders"
  },
  retryPayment : {
    name : "Retry Payment",
    path : "/payment/retry"
  }
};
