import Axios from "axios";
import storage from "../helpers/storage";
import Cookies from "universal-cookie";

const cookies = new Cookies()
//import { useSelector } from "react-redux";

/**
 * Axios Interceptor
 * @param {*} config
 * @returns {*}
 */
function authRequestInterceptor(config) {
  const token = storage.getToken();

  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }

  const userLanguage = cookies?.get("langKey")

  // Add your custom headers here
  // config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Content-Type"] = "application/json";
  config.headers["Mode"] = "no-cors";
  config.headers["Device-Type"] = "BROWSER";
  config.headers["Accept-Language"] = userLanguage ?? "en";
  // FOR HANDLING FILE UPLOAD WITH FORM
  if (config.data instanceof FormData) {
    config.headers["Content-Type"] = "multipart/form-data";
  }

  return config;
}

/**
 * Axios Object to use
 * @type {*}
 */
export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    if (error?.response?.status === 401) {
      if (storage.getToken() !== null) {
        localStorage.removeItem("AG_refreshToken");
        localStorage.removeItem("AG_token");
        localStorage.removeItem("refresh-token");
        localStorage.removeItem("user-info");
        window.location.href = process.env.REACT_APP_AGENCY_WEB_URL
      }
    }
    return Promise.reject(error)

   
  }
);

