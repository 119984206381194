import React, { useState, useEffect } from "react";
import InputText from "../../components/common/InputText";
import { AgentSingupSchema } from "../../validations/ValidationSchema";
import { agentSignUp, agencyListingListAPI } from "../../services/userService";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import SelectWithSearch from "components/common/SelectWithSearch";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import TermsAndConditionModal from "components/modal/TermsAndConditionModal";
import LoaderMu from "components/common/LoaderMu";
import { nameBasedRoutes } from "router/public";
import { Checkbox } from "antd";

const AgentModal = () => {

  const { t } = useTranslation()
  const [data] = useState([]);
  const [loading, setLoading] = useState();
  const navigate = useNavigate();
  const [agencySummaryListingData, setAgencySummaryListingData] = useState([]);
  // STATE FOR PASSWORD VISIBLE TOGGLE
  const [showEye, setShowEye] = useState(false);
  const [showEyeConfirmPass, setShowEyeConfirmPass] = useState(false);

  const [isOpenTermsCondition, setIsOpenTermsCondition] = useState(false)

  // TOGGLE EYE BUTTON FOR PASSWORD
  const showToggle = (event) => {
    setShowEye(event);
  };

  // TOGGLE EYE BUTTON FOR PASSWORD
  const showToggleConfirmPass = (event) => {
    setShowEyeConfirmPass(event);
  };

  // Submit onboarding handleChange
  const handleSubmit = async (value, actions) => {
    setLoading(true);
    const firstName = value.firstName;
    const lastName = value.lastName;
    const password = value.password;
    const agencyId = value.agencyId.value.toString();
    const email = value.email;

    agentSignUp({
      firstName: firstName.toString(),
      lastName: lastName.toString(),
      email: email.toString(),
      password: password.toString(),
      agencyId: agencyId.toString(),
      // panel: "AGENCY",
    })
      .then((response) => {
        setLoading(false);
        toast.success(response?.message);
        navigate(nameBasedRoutes?.login?.path);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });
  };

  // GET AGENCY LIST API
  useEffect(() => {
    setLoading(true);
    agencyListingListAPI().then((response) => {
      setAgencySummaryListingData(response);
      setLoading(false);
    });
  }, []);

  return (
    <div className="d-flex flex-column pageContainer p-3 h-100">
      <LoaderMu loading={loading} />
      {/* overflow-auto */}
      <Formik
        validationSchema={AgentSingupSchema}
        initialValues={{
          i_agree: false,
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          agencyId: null,
          confirm_password: "",
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
        }) => (
          <Form className="form-style1">
            <div className="d-flex">
              {/* FIRST NAME */}
              <div className="col-md-6 px-1">
                <InputText
                  controlId="firstName"
                  label={t("FIRST NAME")}
                  value={values.firstName}
                  name="firstName"
                  maxLength="300"
                  className="form-control"
                  errorsField={errors.firstName}
                  touched={touched.firstName}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>
              {/* LAST NAME */}
              <div className="col-md-6 px-1">
                <InputText
                  controlId="lastName"
                  label={t("LAST NAME")}
                  value={values.lastName}
                  name="lastName"
                  maxLength="300"
                  className="form-control"
                  errorsField={errors.lastName}
                  touched={touched.lastName}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <span className="form-label fz12 mb0 fw600 mx10 text-danger" />
              </div>
            </div>
            {/* EMAIL ADDRESS */}
            <div className="d-flex">
              <div className="col-12 px-1">
                <InputText
                  controlId="email"
                  label={t("EMAIL ADDRESS")}
                  value={values.email}
                  name="email"
                  maxLength="300"
                  className="form-control"
                  errorsField={errors.email}
                  touched={touched.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
                <span className="form-label fz12 mb0 fw600 mx10 text-danger" />
              </div>
            </div>
            {/* SELECT AGENCY */}
            <div className="d-flex">
              <div className="col-12 px-1">
                <div className="ui-content mb25">
                  <div className="form-style1">
                    <div className="bootselect-multiselect">
                      {/* {JSON.stringify(agencySummaryListingData,null,2)} */}
                      <div
                        className="dropdown bootstrap-select"
                        style={{ width: "100%" }}
                      >
                        <SelectWithSearch
                          options={agencySummaryListingData.map(
                            (agencyObj) => ({
                              label: agencyObj.name,
                              value: agencyObj.id,
                            })
                          )}
                          className="selectpicker"
                          controlId="agencyId"
                          label={t("SELECT AGENCY")}
                          value={values.agencyId}
                          name="agencyId"
                          errorsField={errors.agencyId}
                          touched={touched.agencyId}
                          //handleChange={handleChange}
                          handleBlur={handleBlur}
                          onChange={(e, val) => { setFieldValue('agencyId', val) }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* CREATE PASSWORD */}
            <div className="d-flex">
              <div className="col-12 px-1">

                <div
                  className={`position-relative ${showEye ? "form-right-icon" : ""
                    }`}
                >
                  <InputText
                    controlId="password"
                    label={t("CREATE PASSWORD")}
                    value={values.password}
                    name="password"
                    maxLength="300"
                    type={showEye ? "text" : "password"}
                    className="form-control"
                    errorsField={errors.password}
                    touched={touched.password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <div onClick={() => setShowEye(!showEye)}>
                    {showEye ? (
                      <FaEye
                        size="22"
                        className="eyePosition"
                        onClick={() => showToggle(true)}
                      />
                    ) : (
                      <FaEyeSlash
                        size="22"
                        className="eyePosition"
                        onClick={() => showToggle(true)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* CONFIRM PASSWORD */}
            <div className="d-flex">
              <div className="col-12 px-1 mt20">
                <div
                  className={`position-relative ${showEyeConfirmPass ? "form-right-icon" : ""
                    }`}
                >
                  <InputText
                    controlId="confirm_password"
                    label={t("CONFIRM PASSWORD")}
                    value={values.confirm_password}
                    name="confirm_password"
                    maxLength="300"
                    type={showEyeConfirmPass ? "text" : "password"}
                    className="form-control"
                    errorsField={errors.confirm_password}
                    touched={touched.confirm_password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                  />
                  <div onClick={() => setShowEyeConfirmPass(!showEyeConfirmPass)}>
                    {showEyeConfirmPass ? (
                      <FaEye
                        size="22"
                        className="eyePosition"
                        onClick={() => showToggleConfirmPass(true)}
                      />
                    ) : (
                      <FaEyeSlash
                        size="22"
                        className="eyePosition"
                        onClick={() => showToggleConfirmPass(true)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* AGREE CHECKBOX */}
            <div className="checkbox-style1 px10 mt35">
              <div className="d-flex align-items-end">
               <Checkbox  name="i_agree" checked={values?.i_agree ?? false} onChange={(e)=> setFieldValue("i_agree", e?.target?.checked)}> {t("I AGREE")}</Checkbox>
                {/* <label className="custom_checkbox fz14 ff-heading">
                  {t("I AGREE")}.{" "}
                  <input
                    name="i_agree"
                    onChange={(e) => {
                      setFieldValue("i_agree", e?.target?.checked);
                    }}
                    error={touched?.i_agree && errors?.i_agree}
                    checked={values?.i_agree ?? false}
                    type="checkbox"
                  />
                  <span className="checkmark mt-1" />
                </label> */}
                <button type="button" className="fw600 btn" onClick={() => setIsOpenTermsCondition(true)}>
                  {t("TERMS AND CONDITIONS")}.
                </button>
              </div>
              <span className="text-danger answer-type-error fs-11">
                {errors?.i_agree && touched?.i_agree ? errors?.i_agree : ""}
              </span>
            </div>
            <span className="form-label fz12 px10 fw600 text-danger" />
            {/* HANDLE SUBMIT */}
            <div className="d-grid mb20 mt5">
              <button
                variant="primary"
                onClick={handleSubmit}
                className="ud-btn btn-thm"
                type="submit"
              >
                {t("SUBMIT")} <i className="fal fa-arrow-right-long" />
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {/* TERMS AND CONDITION */}
      <TermsAndConditionModal isModalOpen={isOpenTermsCondition} setIsModalOpen={setIsOpenTermsCondition} />
    </div>

  );
};

export default AgentModal;
