import { useEffect, useState } from "react";
import Footer from "./Footer-agency/Footer"
import { Sidebar } from "./Sidebar/Sidebar";
import { Outlet } from "react-router";
import { Col } from "react-bootstrap";
import ScrollToHome from "./ScrollToHome";
import MobileHeader from "./Header-Agency/MobileHeader";
import { Header } from "./Header-Agency/Header";
import MobileMenu from "./Header-Agency/MobileMenu";
import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

export const MainLayout = () => {
  const [isActiveSidebar, setIsActiveSidebar] = useState(true);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false)

  const {i18n} = useTranslation()

  const cookies = new Cookies()
  const currentLanguage =  i18n?.language ?? cookies.get("langKey") ?? 'en'

  const toggleSidebarButton = (event) => {
    setIsActiveSidebar((current) => !current);
  };
  const closeMobileMenu = ()=>{
    setIsMobileMenuActive(false)
  }

  useEffect(() => {
    // Add a class to the body element when the component mounts
    if(isMobileMenuActive){
      document.body.classList.add('overflow-hidden');
    }else{
      document.body.classList.remove('overflow-hidden');
    }
    // Clean up the class when the component unmounts
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  },[isMobileMenuActive])
  return (
    <main className="mainContentBox" dir = {currentLanguage === 'ar' ? "rtl" : "ltr"}>
      <div className="d-flex position-relative">
        <Col xs className="mw-100 h-100 mw-1">
          {isActiveSidebar ? (
            <div
              onClick={toggleSidebarButton}
              className=" bottom-0 position-fixed start-0 w-100"
            ></div>
          ) : // backdrop bg-black bg-opacity-25
            null}
          <div className="d-flex flex-column h-100 w-100">
            {/* Main Header Nav */}
            <Header
              isActiveSidebar={isActiveSidebar}
              toggleSidebarButton={toggleSidebarButton}
            />
            {/* Mobile Nav  */}
            <MobileHeader setMobileMenu={setIsMobileMenuActive} />
            <div className="flex-grow-1 fs-13 overflow-hidden w-100">
              <div className="wrapper">
                {/* <div className="preloader" />*/}
                <div className="dashboard_content_wrapper">
                  <div
                    className={`dashboard dashboard_wrapper pr30 pr0-xl ${!isActiveSidebar ? "dsh_board_sidebar_hidden" : ""
                      } `}
                  >
                    <Sidebar />
                    <div className="dashboard__main pl0-md">
                      <Outlet />
                      <Footer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ScrollToHome />
          </div>
        </Col>
      </div>
      {/* /.mobile-menu */}
      <MobileMenu isMenuActive={isMobileMenuActive} setMobileMenu={setIsMobileMenuActive} />
      {
        isMobileMenuActive ? <div className="close-wrapper" onClick={closeMobileMenu}>
        </div> : ""
      }
    </main>
  );
};
